<template>
  <v-autocomplete
    label="Client"
    v-model="client"
    v-model:search="search"
    @update:search="
      (newSearch: string) => debounceFilterClients({ ...queryFilters, page: 1, search: newSearch })
    "
    :items="clients"
    item-value="id"
    item-title="name"
    :loading="isLoading"
    :error-messages="error && error.message"
    no-filter
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { reactive } from 'vue'

import { useFetchClients } from '@/composables/client'

import type { IClientData } from '@/models/client'
import type { IClientFilterParam } from '@/services'
import { debounce, merge } from 'lodash'
import { useQueryClient } from '@tanstack/vue-query'

const client = defineModel<null | IClientData | number>({ default: null })
const search = defineModel<string>('search', { default: '' })

const queryFilters = reactive<IClientFilterParam>({
  search: search.value
})

const queryClient = useQueryClient()

const { clients, isLoading, error, queryKey } = useFetchClients(queryFilters)

const debounceFilterClients = debounce(function (filterParams: IClientFilterParam) {
  // mutates filters
  merge(queryFilters, filterParams)
  return queryClient.invalidateQueries({ queryKey: queryKey.value })
}, 200)
</script>

<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card
        :ripple="false"
        v-bind="props"
        theme="dark"
        variant="flat"
        color="transparent"
        class="ma-1"
      >
        <v-toolbar
          absolute
          density="comfortable"
          height="64"
          style="top: 0px"
          :style="`
                transform: translateY(${isHovering ? '0px' : '-64px'});
                zIndex: 5;
                background: linear-gradient(rgba(var(--v-theme-surface), 1), rgba(var(--v-theme-surface), 0))
              `"
        >
          <v-spacer />
          <v-tooltip theme="light" location="bottom" open-delay="450">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="rounded mr-2"
                size="36"
                icon
                density="comfortable"
                @click="openDeleteAttachmentDialog()"
              >
                <v-icon size="22" icon="mdi-delete" />
              </v-btn>
            </template>
            Delete Attachment
          </v-tooltip>
        </v-toolbar>
        <ImageViewer width="100%" height="auto" :src="attachment.file as string" />
      </v-card>
    </template>
  </v-hover>

  <ConfirmationDialog
    v-model="attachmentDeleteDialog"
    v-model:error="error"
    title="Delete Attachment"
  >
    <template v-slot:message>
      Are you sure you want to delete this attachment?
      <br />
      <div class="pt-2">This action cannot be undone.</div>
    </template>

    <template v-slot:actions>
      <v-spacer></v-spacer>

      <v-btn
        density="comfortable"
        color="error"
        variant="flat"
        class="text-capitalize"
        :loading="isPending"
        @click.stop="deleteAttachment(attachment)"
      >
        Delete
      </v-btn>
    </template>
  </ConfirmationDialog>
</template>

<script setup lang="ts">
import type {
  IIncidentReport,
  IIncidentReportActivity,
  IIncidentReportActivityObservation,
  IIncidentReportObservationAttachment,
  IIncidentReportObservationAttachmentData
} from '@/models/report'

import ImageViewer from '@/components/common/ImageViewer.vue'
import { useDeleteIncidentReportActivityObservationAttachment } from '@/composables/incident-report'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import { ref } from 'vue'

interface Props {
  report: IIncidentReport
  activity: IIncidentReportActivity
  observation: IIncidentReportActivityObservation
  attachment: IIncidentReportObservationAttachment
}
const props = defineProps<Props>()

interface Emits {
  (e: 'refresh-report'): void
}
const emit = defineEmits<Emits>()

const { isPending, error, mutate, reset } = useDeleteIncidentReportActivityObservationAttachment(
  props.report.id!,
  props.activity.id!,
  props.observation.id!
)

function deleteAttachment(attachment: IIncidentReportObservationAttachmentData) {
  reset()
  mutate(attachment.id!, {
    onSuccess: () => {
      emit('refresh-report')
      attachmentDeleteDialog.value = false
    }
  })
}

const attachmentDeleteDialog = ref(false)
function openDeleteAttachmentDialog() {
  attachmentDeleteDialog.value = true
}
</script>

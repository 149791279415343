<template>
  <BaseWidget color="white" variant="flat" class="border overflow-hidden">
    <template #top>
      <img
        :src="miniCompanyImage"
        class="position-absolute mask-bottom-card"
        style="right: -25px; top: -16px; width: 178px"
      />
    </template>
    <div class="d-flex flex-row">
      <h1 class="text-h6 font-weight-medium d-flex flex-column align-start">
        <span> Hey {{ currentUser?.first_name }}! </span>
        <span class="text-h6 font-weight-regular"> Welcome to your employee portal</span>
      </h1>
      <!-- holding spacing for card width -->
      <v-sheet min-width="136" color="transparent"></v-sheet>
    </div>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'

import miniCompanyImage from '@/assets/logo.png'

import { useAuthStore } from '@/stores'
import BaseWidget from '../base/BaseWidget.vue'

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>

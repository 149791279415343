import type {
  IReport,
  IReportActivityData,
  IReportActivityObservationData,
  IReportData,
  IReportIncidentStatus,
  IReportState
} from '.'
import type { ICamera, ICameraData } from '../camera'
import type { ISite } from '../client'
import type { IClient } from '../client/client.types'
import type { IEmployee, IEmployeeData } from '../employee'
import type { IUser, IUserData } from '../user'

export interface ICCTVReportStatisticsData {
  draft_count?: number
  approval_pending_count?: number
  waiting_approval_count?: number
  waiting_submission_count?: number
  recently_created_count?: number
}

export interface ICCTVReportData extends IReportData {
  shift_start?: Date | string
  shift_end?: Date | string
  memo?: string
  activities?: ICCTVReportActivityData[]
  guards?: IUserData[]
  approvers?: IEmployeeData[]
}

export interface ICCTVReportActivityData extends IReportActivityData {
  observations?: ICCTVReportActivityObservationData[]
}

export interface ICCTVReportActivityObservationData extends IReportActivityObservationData {
  camera?: number | ICameraData
  status?: ICCTVReportActivityObservationCameraStatusData
  incident_report?: number | null
}

export interface ICCTVReportActivityObservationCameraStatusData {
  value?: number
  display?: string
}

export interface ICCTVReport extends ICCTVReportData, IReport {
  client?: IClient | number
  site: ISite | number
  shift_start: Date
  shift_end: Date
  state?: IReportState
  created?: Date
  modified?: Date
  activities: ICCTVReportActivity[]
  guards?: IUser[]
  approvers: IEmployee[]

  canSubmit(): boolean
  canApprove(): boolean
}

export interface ICCTVReportActivityObservationCameraStatus
  extends ICCTVReportActivityObservationCameraStatusData {}

export interface ICCTVReportActivityObservation extends ICCTVReportActivityObservationData {
  camera?: number | ICamera
  status?: ICCTVReportActivityObservationCameraStatus
  incident_level?: IReportIncidentStatus | number
  incident_report: number | null
}

export interface ICCTVReportActivity extends ICCTVReportActivityData {
  observations?: ICCTVReportActivityObservation[]
  activity_time?: Date
}

export interface ICCTVReportStatistics extends ICCTVReportStatisticsData {}

export enum CCTVReportActivityStatusEnum {
  None = 1,
  Minor = 2,
  Major = 3
}

export enum ObservationCameraStatusEnum {
  Clean = 0,
  Dirty = 1,
  Loss = 2,
  Iced = 3,
  Other = 10
}

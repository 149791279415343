import type { ISite, ISiteData } from '../client'
import type { IClient, IClientData } from '../client/client.types'
import type { IEmployee, IEmployeeData } from '../employee'

export interface IReportData {
  id?: number
  client?: IClientData | number
  site?: ISiteData | number
  state?: IReportStateData
  created?: Date | string
  modified?: Date | string
  report_id?: string
  owner?: string
}

export interface IReportActivityData {
  id?: number
  activity_time?: Date | string
  created_by?: string
  modified_by?: string
  created?: Date | string
  modified?: Date | string
}

export interface IReportActivityObservationData {
  id?: number
  text?: string
  reporter?: string
  incident_level?: IReportIncidentStatusData | number
  file?: string | File
}

export interface IReportShiftData {
  id?: number
  site?: ISiteData | number
  start?: Date | string
  end?: Date | string
  client?: IClientData
  created?: Date | string
  modified?: Date | string
  guards?: IEmployeeData[]
}

export interface IReportStateData {
  value?: number
  display?: string
}

export interface IReportIncidentStatusData {
  value?: number
  display?: string
}

export interface IReportState extends IReportStateData {}
export interface IReportIncidentStatus extends IReportIncidentStatusData {}

export enum ReportStateEnum {
  Draft,
  Submitted,
  Approved
}

export enum ReportActivityStatusEnum {
  SecurityLevel1 = 1,
  SecurityLevel2 = 2,
  SecurityLevel3 = 3
}

export interface IReport extends IReportData {
  client?: IClient | number
  site: ISite | number
  state?: IReportState
  created?: Date
  modified?: Date
}

export interface IReportShift extends IReportShiftData {
  site: ISite | number
  start: Date
  end: Date
  client?: IClient
  created?: Date
  modified?: Date
  guards?: IEmployee[]
}

export class Report implements IReportData {}

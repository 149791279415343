<template>
  <v-autocomplete
    label="Site"
    v-model="site"
    @update:model-value="clearErrors()"
    :items="sites"
    item-value="id"
    item-title="code"
    :loading="loading"
    :disabled="disabled"
    :error-messages="error && error.message"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { ISiteData } from '@/models/client'

import { useFetchSites } from '@/composables/site'

interface Props {
  client: number | null
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  client: null
})

const site = defineModel<null | ISiteData | number>({ default: null })

const clientId = computed(() => props.client!)
const { sites, isLoading: loading, error } = useFetchSites(clientId)

function clearErrors() {
  error.value = null
}
</script>

import type { IEmployeeData } from '@/models/employee'
import type {
  IEmployeeService,
  IEmployeePaginatedListData,
  IEmployeeFilterParam
} from './employee.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'

/**
 * A class representing an EmployeeService API handler.
 */
export class EmployeeService implements IEmployeeService {
  private endpoint: string

  // EmployeeService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/employees/'
  }

  // Method to fetch a list of employees from the API
  fetchEmployees(filter?: IEmployeeFilterParam): Promise<IEmployeePaginatedListData> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined
    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single employee by their ID from the API
  fetchEmployee(id: number): Promise<IEmployeeData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new employee on the API
  createEmployee(data: IEmployeeData): Promise<IEmployeeData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing employee on the API
  updateEmployee(data: IEmployeeData): Promise<IEmployeeData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a employee by their ID from the API
  deleteEmployee(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  /** Method to update a single employee's sites list by their ID from the API */
  setEmployeeSites(id: number, sites: number[]): Promise<IEmployeeData> {
    return this.axios
      .put(`${this.endpoint}${id}/set-sites/`, {
        sites: sites
      })
      .then(({ data }) => data)
  }
}

// Default export instantiation of the EmployeeService
const employeeService = new EmployeeService(axios)
export default employeeService

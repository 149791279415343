import type { IGuardReportActivityData } from '@/models/report'
import type { IGuardReportActivityService } from './guard-report-activity.types'
import type { AxiosInstance } from 'axios'
import axios from '@/axios'

/**
 * A class representing a GuardReportActivityService API handler.
 */
export class GuardReportActivityService implements IGuardReportActivityService {
  private endpoint: string

  // GuardReportActivityService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/guards/'
  }

  // Method to fetch a list of reports from the API
  fetchGuardReportActivities(reportId: number): Promise<IGuardReportActivityData[]> {
    return this.axios.get(`${this.endpoint}${reportId}/activities/`).then(({ data }) => data)
  }

  // Method to fetch a single report by their ID from the API
  fetchGuardReportActivity(reportId: number, id: number): Promise<IGuardReportActivityData> {
    return this.axios.get(`${this.endpoint}${reportId}/activities/${id}/`).then(({ data }) => data)
  }

  // Method to create a new report on the API
  createGuardReportActivity(
    reportId: number,
    data?: IGuardReportActivityData
  ): Promise<IGuardReportActivityData> {
    return this.axios.post(`${this.endpoint}${reportId}/activities/`, data).then(({ data }) => data)
  }

  // Method to update an existing report on the API
  updateGuardReportActivity(
    reportId: number,
    data: IGuardReportActivityData
  ): Promise<IGuardReportActivityData> {
    return this.axios
      .patch(`${this.endpoint}${reportId}/activities/${data.id}/`, data)
      .then(({ data }) => data)
  }

  // Method to delete a report by their ID from the API
  deleteGuardReportActivity(reportId: number, id: number): Promise<void> {
    return this.axios
      .delete(`${this.endpoint}${reportId}/activities/${id}/`)
      .then(({ data }) => data)
  }
}

// Default export instantiation of the GuardReportActivityService
const activityReportService = new GuardReportActivityService(axios)
export default activityReportService

<template>
  <v-container
    :fluid="fluid"
    :class="noPadding ? 'pa-0' : 'py-1 px-4 px-sm-6 px-md-8 px-lg-10'"
    class="al-page-header"
  >
    <v-sheet :class="{ 'ma-0': noMargin }" class="al-page-header--outer">
      <div :class="{ 'flex-nowrap': truncateTitle }" class="al-page-header--inner">
        <div
          :class="{ 'flex-shrink-1': truncateTitle }"
          class="al-page-header--inner-text-container"
        >
          <template v-if="disableTitleStyles">
            <slot name="default">{{ title }}</slot>
            <slot name="subtitle">{{ subtitle }}</slot>
          </template>
          <template v-else>
            <h1
              class="al-page-header--inner-text-container--title font-weight-medium text-h4"
              :class="{ 'al-header-text-truncate': truncateTitle }"
              tabIndex="-1"
              :aria-label="title"
            >
              <slot name="default">{{ title }}</slot>
            </h1>
            <h3
              v-if="slots.subtitle"
              class="al-page-header--inner-text-container--subtitle font-weight-regular subtitle-1"
              :class="{ 'al-header-text-truncate': truncateTitle }"
              tabIndex="-1"
              :aria-label="subtitle"
            >
              <slot name="subtitle">{{ subtitle }}</slot>
            </h3>
          </template>
        </div>
        <div v-if="slots.actions" class="al-page-header--actions">
          <v-defaults-provider
            :defaults="{
              VBtn: {
                class: 'rounded text-capitalize',
                color: 'primary',
                density: 'default'
              }
            }"
          >
            <slot name="actions"> </slot>
          </v-defaults-provider>
        </div>
      </div>

      <div class="al-page-header--bottom">
        <slot name="bottom"> </slot>
      </div>
    </v-sheet>
  </v-container>
</template>

<script lang="ts" setup>
import { useSlots } from 'vue'

defineProps({
  truncateTitle: { type: Boolean, default: () => false },
  disableTitleStyles: { type: Boolean, default: () => false },
  fluid: { type: Boolean, default: () => false },
  noPadding: { type: Boolean, default: () => false },
  noMargin: { type: Boolean, default: () => false },
  title: { type: String, default: () => '' },
  subtitle: { type: String, default: () => '' }
})

const slots = useSlots()
</script>

<style lang="scss" scoped>
.al-page-header {
  background-color: rgba(var(--v-theme-background));
  border-color: rgba(var(--v-theme-background));
}

.al-page-header--outer {
  margin-top: 16px;
  background-color: transparent;
}

.al-header-text-truncate {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.al-page-header--inner-text-container {
  min-width: 0;
  max-width: 100%;
  margin-bottom: 8px;
  flex: 1 0 auto;
}

.al-page-header--inner-text-container--title {
  margin-top: 0;
  outline: none;
  overflow-y: hidden;
}

.al-page-header--inner-text-container--subtitle {
  margin-top: 8px;
  outline: none;
}

.al-page-header--actions {
  max-width: 100%;
  margin-top: auto;
  margin-bottom: 8px;
  margin-left: auto;
  padding-left: 32px;
  flex: 0 0 auto;
  white-space: nowrap;

  .v-btn {
    font-weight: 400 !important;
    text-transform: capitalize !important;
  }
}

.al-page-header--inner {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.al-page-header--bottom {
  margin-top: 8px;
}
</style>

import { ref, toRef, type MaybeRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'

import fileLoaderService from '@/services/file_loader/file-loader'

export function useDownloadFileLoader(filePath: MaybeRef<string>) {
  const filePathRef = toRef(filePath)
  const queryKey = ref(['file-loader', filePathRef])

  const {
    data: file,
    isLoading,
    error,
    refetch
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => fileLoaderService.downloadFile(filePathRef.value),
    staleTime: 60 * 1000, // Cache data for 60 seconds
    enabled: false
  })

  return { file, isLoading, error, queryKey, refetch }
}

import {
  type ICCTVReportActivityObservationCameraStatusData,
  ObservationCameraStatusEnum
} from '@/models/report'

export const cameraStatusList: ICCTVReportActivityObservationCameraStatusData[] = [
  { display: 'Clean', value: ObservationCameraStatusEnum.Clean },
  { display: 'Dirty', value: ObservationCameraStatusEnum.Dirty },
  { display: 'Video Loss', value: ObservationCameraStatusEnum.Loss },
  { display: 'Iced/Snow Covered', value: ObservationCameraStatusEnum.Iced },
  { display: 'Other', value: ObservationCameraStatusEnum.Other }
]

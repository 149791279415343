import type { IUserData } from '@/models/user'
import type { IUserService } from './user.types'
import type { AxiosInstance } from 'axios'
import axios from '@/axios'

/**
 * A class representing a UserService API handler.
 */
export class UserService implements IUserService {
  private endpoint: string

  // UserService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/users/'
  }

  // Method to fetch a list of users from the API
  fetchUsers(): Promise<IUserData[]> {
    return this.axios.get(`${this.endpoint}`).then(({ data }) => data)
  }

  // Method to fetch a single user by their ID from the API
  fetchUser(id: number): Promise<IUserData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new user on the API
  createUser(data: IUserData): Promise<IUserData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing user on the API
  updateUser(data: IUserData): Promise<IUserData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a user by their ID from the API
  deleteUser(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to update an existing user active status on the API
  setUserActiveState(id: number, is_active: boolean): Promise<IUserData> {
    return this.axios
      .put(`${this.endpoint}${id}/set-is-active/`, { is_active: is_active })
      .then(({ data }) => data)
  }

  // Method to update an existing user password on the API
  changeUserPassword(id: number, password: string): Promise<void> {
    return this.axios
      .put(`${this.endpoint}${id}/set-password/`, {
        password: password
      })
      .then(({ data }) => data)
  }
}

// Default export instantiation of the UserService
const userService = new UserService(axios)
export default userService

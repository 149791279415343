import { defineStore } from 'pinia'

import { type IUserData, User } from '@/models/user'

import type { IUserState } from './user.types'

import userService from '@/services/user/user'

// Pinia store for managing user-related states and actions
export const useUserStore = defineStore('user', {
  state: () =>
    ({
      users: [],
      lastPromise: null
    }) as IUserState,

  getters: {
    // Getter to retrieve users from the store
    getUsers: (state: IUserState) => {
      return state.users.map((user) => new User(user))
    },

    // Getter to retrieve a user by ID from the store,
    getUserById: (state: IUserState) => (id: number) => {
      const user = state.users.find((user) => user.id === id)
      if (!user) {
        return undefined
      }
      return new User(user)
    }
  },

  actions: {
    // Action method to fetch list of users and update the store
    async fetchUsers(): Promise<void> {
      const promise = userService.fetchUsers()
      this.lastPromise = promise

      return promise.then((users) => {
        if (promise == this.lastPromise) {
          this.users = users

          this.lastPromise = null
        }
      })
    },

    // Action method to fetch a single user and update the store
    async fetchUser(id: number): Promise<void> {
      const user = await userService.fetchUser(id)

      const index = this.users.findIndex((v) => v.id === id)
      if (index === -1) {
        this.users.push(user)
      } else {
        this.users[index] = user
      }
    },

    // Action method to create a new user
    async createUser(data: IUserData): Promise<void> {
      const user = await userService.createUser(data)
      this.users.push(user)
    },

    // Action method to update an existing user
    async updateUser(data: IUserData): Promise<void> {
      const user = await userService.updateUser(data)
      const index = this.users.findIndex((v) => v.id === data.id)
      this.users[index] = user
    },

    // Action method to delete an existing user
    async deleteUser(id: number): Promise<void> {
      await userService.deleteUser(id)

      const index = this.users.findIndex((v) => v.id === id)
      if (index !== -1) {
        this.users.splice(index, 1)
      }
    },

    async setUserActiveState(id: number, is_active: boolean): Promise<void> {
      const user = await userService.setUserActiveState(id, is_active)
      const index = this.users.findIndex((v) => v.id === id)
      this.users[index] = user
    },

    async changeUserPassword(id: number, password: string): Promise<void> {
      await userService.changeUserPassword(id, password)
    }
  }
})

<template>
  <v-card
    variant="flat"
    color="#fafafa"
    tile
    class="workflow-canvas-container border-e"
    ref="workflowCanvasContainerRef"
    @mousedown="startPanning"
    @mousemove="onPan"
    @mouseup="endPanning"
    @mouseleave="endPanning"
    @wheel.passive="onZoom"
    :style="`cursor: ${isLocked ? 'pointer' : 'grab'};`"
    :loading="loading"
  >
    <ErrorAlert
      v-if="error != null"
      dense
      :error="error"
      @clearErrors="clearErrors"
      class="mb-0 mt-4 rounded"
    />
    <div
      variant="flat"
      color="transparent"
      class="d-flex flex-row position-absolute"
      ref="workflowZoomControlsRef"
      style="
        top: 12px;
        left: 12px;
        z-index: 5;
        transition: top 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
      "
    >
      <div class="zoom-controls d-flex flex-row border rounded">
        <v-btn variant="flat" icon size="32" class="rounded-e-0 border-e" @click="zoomOut()">
          <v-icon size="18" color="high-emphasis" icon="mdi-minus"></v-icon>
        </v-btn>

        <v-sheet
          class="d-flex align-center px-2 text-caption font-weight-medium text-high-emphasis"
          color="background"
          min-width="30"
          style="user-select: none"
          @click.stop="
            () => {
              scale = 1
              renderConnectionLines()
            }
          "
        >
          {{ zoomLevel }}%
        </v-sheet>

        <v-btn variant="flat" icon size="32" class="rounded-s-0 border-s" @click.stop="zoomIn()">
          <v-icon size="18" color="high-emphasis" icon="mdi-plus"></v-icon>
        </v-btn>
      </div>

      <div class="lock-controls d-flex flex-row border rounded ml-2">
        <v-btn variant="flat" icon size="32" @click.stop="toggleLock()">
          <v-icon size="18" color="high-emphasis" :icon="isLocked ? 'mdi-lock' : 'mdi-lock-open'" />
        </v-btn>
      </div>
    </div>

    <div
      class="workflow-canvas"
      ref="workflowCanvasRef"
      :style="{ transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})` }"
    >
      <PostOrderFieldTreeView
        v-if="!(loading && fields.length > 0)"
        :fields="fields"
        ref="postOrderNodeTreeViewRef"
      />
    </div>
    <div class="workflow-canvas-svg-container" v-resize="renderConnectionLines">
      <svg class="workflow-canvas-svg"></svg>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, ref, watch } from 'vue'
import { throttle } from 'lodash'

import { useFetchPostOrderInstructionsFields } from '@/composables/post-order'
import { PostOrderSymbol } from './postOrderProvide'
import type { ISystemError } from '@/models/error'

import type { VCard } from 'vuetify/components'
import PostOrderFieldTreeView from './tree/PostOrderFieldTreeView.vue'
import ErrorAlert from '../common/ErrorAlert.vue'

const postOrderContext = inject(PostOrderSymbol)

if (!postOrderContext) throw new Error('[Post Order] Could not find injected post order context')

const {
  fields: postOrderFields,
  isLoading: loading,
  error: fetchFieldsError
} = useFetchPostOrderInstructionsFields(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const error = ref<ISystemError | null>(null)

function clearErrors() {
  error.value = null
}

watch(fetchFieldsError, (value) => {
  if (value) {
    error.value = fetchFieldsError.value
  } else {
    clearErrors()
  }
})

const fields = computed(() => postOrderFields.value ?? [])

watch(fields, () => {
  // wait for next frame to re-render connection lines
  nextTick(() => renderConnectionLines())
})

const workflowCanvasContainerRef = ref<VCard>()

// Canvas Panning
const isLocked = ref(false)
function toggleLock() {
  isLocked.value = !isLocked.value
}

const isPanning = ref(false)

const postOrderNodeTreeViewRef = ref<typeof PostOrderFieldTreeView>()

const renderConnectionLines = () => {
  nextTick(() => {
    if (postOrderNodeTreeViewRef.value) {
      postOrderNodeTreeViewRef.value.renderConnectionLines()
    }
  })
}

const startX = ref(0)
const startY = ref(0)

const startPanning = (event: MouseEvent) => {
  if (!isLocked.value) {
    isPanning.value = true
    startX.value = event.clientX - offsetX.value
    startY.value = event.clientY - offsetY.value
    renderConnectionLines()
  }
}

const onPan = throttle(
  (event: MouseEvent) => {
    if (isPanning.value) {
      offsetX.value = event.clientX - startX.value
      offsetY.value = event.clientY - startY.value
      renderConnectionLines()
    }
  },
  50,
  {
    leading: true
  }
)

const endPanning = () => {
  if (isPanning.value) {
    renderConnectionLines()
    isPanning.value = false
  }
}

const offsetX = ref(0)
const offsetY = ref(0)

// ZOOM SCROLLING
/** scale to determine zoom scale */
const scale = ref(1)

/** The amount zoom per scroll */
const scaleStep = 0.1

/** 60% is the lowest zoom level */
const minScale = 0.6

/** 200% is the highest zoom level */
const maxScale = 2

/** The percentage representation for the current zoom level */
const zoomLevel = computed(() => Number(scale.value * 100).toFixed(0))

/**
 * Action done on scroll to impact the current zoom level
 * @param event
 */
function onZoom(event: WheelEvent) {
  event.stopPropagation()
  if (!isLocked.value) {
    if (event.deltaY < 0) {
      zoomIn()
    } else {
      zoomOut()
    }
  }
}

function zoomIn() {
  scale.value = Math.min(maxScale, scale.value + scaleStep)
  renderConnectionLines()
}

function zoomOut() {
  scale.value = Math.max(minScale, scale.value - scaleStep)
  renderConnectionLines()
}

/**
 * resets viewport to default
 * @public
 */
function resetViewPort() {
  scale.value = 1
  startX.value = 0
  startY.value = 0
  offsetX.value = 0
  offsetY.value = 0
  nextTick(() => renderConnectionLines())
}

defineExpose({
  resetViewPort
})
</script>

<style lang="scss">
.workflow-canvas-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-image: radial-gradient(circle, #e0e0e0 1px, transparent 1px);
  background-size: 20px 20px;
}

.workflow-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
}
.workflow-canvas-svg-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.workflow-canvas-svg {
  width: 100%;
  height: 100%;
  z-index: 2;
}
</style>

<template>
  <SearchBox
    label="Search"
    prepend-inner-icon="mdi-magnify"
    hide-details="auto"
    variant="outlined"
    density="comfortable"
    style="max-width: 400px"
    v-model="search"
    @filter-applied="applyFilter()"
    :filters-applied="filtersApplied"
  >
    <template #filters>
      <div class="filter-line-item">
        <label>Is Active</label>
        <div class="filter-line-item-value">
          <v-checkbox
            clearable
            color="primary"
            v-model="localFilters.is_active"
            true-value="true"
            false-value="false"
            indeterminate
          />
        </div>
      </div>
    </template>
  </SearchBox>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import type { IEmployeeFilterParam } from '@/services'
import { cloneDeep, omit } from 'lodash'

import SearchBox from '@/components/common/SearchBox.vue'

const search = defineModel<string>('search')

interface Props {
  filters: IEmployeeFilterParam
}
interface Emits {
  (e: 'update:filters', filter: IEmployeeFilterParam): void
}
const emit = defineEmits<Emits>()
const props = defineProps<Props>()

const localFilters = ref<IEmployeeFilterParam>({})

const filtersApplied = computed(() => omit(props.filters, ['page', 'search']))

watch(
  () => props.filters,
  (value) => {
    // do not assign reference value to localFilter
    localFilters.value = cloneDeep(value)
  },
  {
    immediate: true,
    deep: true
  }
)

function applyFilter() {
  emit('update:filters', localFilters.value)
}
</script>

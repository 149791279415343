<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 400 : 677">
    <AlFormCard title="Post Order Option Form Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mt-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form @submit.prevent ref="form" v-model="isDetailsValid" validate-on="submit lazy">
        <v-row class="mt-1">
          <v-col cols="12">
            <v-text-field
              v-model.number="postOrderFieldOptionData.option!.value"
              label="Option value*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="
                error &&
                (error.fieldErrors['option'] as IFieldErrors) &&
                ((error.fieldErrors['option'] as IFieldErrors)['value'] as string[])
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'
import type { IFieldErrors, ISystemError } from '@/models/error'
import type { VForm } from 'vuetify/components'

import { diffDeep } from '@/utils/helpers'
import { cloneDeep } from 'lodash'
import { requiredValidator } from '@/utils/validators'
import { PostOrderSymbol } from './postOrderProvide'
import { useQueryClient } from '@tanstack/vue-query'

import {
  useCreatePostOrderInstructionsFieldOption,
  useFetchPostOrderInstructionsFields,
  useUpdatePostOrderInstructionsFieldOption
} from '@/composables/post-order'

import {
  PostOrderInstructionsFieldOption,
  type IPostOrderInstructionsFieldOption,
  type IPostOrderInstructionsFieldOptionData
} from '@/models/post-order'

import AlFormCard from '../common/AlFormCard.vue'
import ErrorAlert from '../common/ErrorAlert.vue'

interface Props {
  option: IPostOrderInstructionsFieldOption | null

  postOrderFieldId: number

  isEdit: boolean
}

const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog', { default: false })

const postOrderFieldOptionData = ref<IPostOrderInstructionsFieldOptionData>({
  option: {}
})

watch(dialog, (value) => {
  if (value) {
    clearErrors()

    // if editing and no options were provided
    postOrderFieldOptionData.value = !props.option
      ? ({ option: {} } as IPostOrderInstructionsFieldOptionData)
      : cloneDeep(props.option!)
  }
})

const form = ref<VForm>()
const isDetailsValid = ref(false)

const subtitle = computed(
  () => `${props.isEdit ? 'Update' : 'Create'} option condition information`
)

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const postOrderContext = inject(PostOrderSymbol)

if (!postOrderContext) throw new Error('[Post Order] Could not find injected post order context')

const postOrderFieldId = computed(() => props.postOrderFieldId)

const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updatePostOrderOption,
  reset: updateReset
} = useUpdatePostOrderInstructionsFieldOption(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId,
  postOrderFieldId
)
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createPostOrderOption,
  reset: createReset
} = useCreatePostOrderInstructionsFieldOption(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId,
  postOrderFieldId
)

const useFetchPostOrderInstructionFieldsQuery = useFetchPostOrderInstructionsFields(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const queryClient = useQueryClient()

const localError = ref<ISystemError | null>(null)

const loading = computed(() => (props.isEdit ? isUpdatePending.value : isCreatePending.value))

const error = computed({
  get: () => {
    return localError.value || props.isEdit ? updateError.value : createError.value
  },
  set: (value) => {
    localError.value = value
  }
})

function clearErrors() {
  props.isEdit ? updateReset() : createReset()
  error.value = null
}

async function save() {
  clearErrors()

  const { valid } = await form.value!.validate()

  if (valid) {
    let postOrderFieldOptionInstance: IPostOrderInstructionsFieldOption | null = null

    try {
      postOrderFieldOptionInstance = new PostOrderInstructionsFieldOption(
        postOrderFieldOptionData.value
      )
    } catch (e: any) {
      // When constructing the instance before saving the possibility of an error can occur the local error is to store that error instance and display it
      error.value = e
    }

    if (postOrderFieldOptionInstance != null) {
      const saveAction = props.isEdit ? updatePostOrderOption : createPostOrderOption

      let payload = postOrderFieldOptionInstance
      if (props.isEdit) {
        payload = diffDeep(postOrderFieldOptionInstance, props.option, true)
      }

      saveAction(payload, {
        async onSuccess() {
          closeDialog()
          await queryClient.invalidateQueries({
            queryKey: useFetchPostOrderInstructionFieldsQuery.queryKey
          })
        }
      })
    }
  }
}
</script>

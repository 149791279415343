export const stateColorThemeTokens = {
  draft: '#9E9E9E',
  submitted: '#F7931E',
  approved: '#4caf50',
  reject: '#D32F2F',
  cancel: '#FF5722',
  done: '#00791E'
}

const themeVariables = {
  'medium-high-emphasis-opacity': 0.75
}

export const lightTheme = {
  colors: {
    primary: '#D63637',
    secondary: '#242424',
    background: '#F2F2F2',

    // error
    error: '#ba1a1a',
    'on-error': '#ffffff',
    'error-container': '#ffdad6',
    'on-error-container': '#410002',

    // inverse colors
    'inverse-surface': '#2e3133',
    'inverse-on-surface': '#f0f1f3',

    ...stateColorThemeTokens
  },
  variables: themeVariables
}

// implemented for color access on component to use its dark themed variant
// e.g. AppBar, profile-menu avatar
export const darkTheme = {
  dark: true,
  colors: {
    primary: '#D63637',
    secondary: '#242424',
    background: '#F2F2F2',

    // error
    error: '#ffb4ab',
    'on-error': '#690005',
    'error-container': '#93000a',
    'on-error-container': '#ffb4ab',

    // inverse colors
    'inverse-surface': '#e2e2e5',
    'inverse-on-surface': '#2e3133',

    ...stateColorThemeTokens
  },
  variables: themeVariables
}

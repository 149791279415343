import { AbstractModel } from '@/models/base'
import type { IForm, IFormData } from './form.types'

/**
 * A class representing an Form.
 */
export class Form extends AbstractModel implements IForm {
  readonly id?: number
  readonly title: string
  readonly description: string
  readonly owner?: string

  constructor(data: IFormData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormData)[] = ['title', 'description']
    this.validate(data, requiredFields)

    this.title = data.title!
    this.description = data.description!

    if (data.id) {
      this.id = data.id
    }

    if (data.owner) {
      this.owner = data.owner
    }
  }

  toString() {
    return this.title
  }
}

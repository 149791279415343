<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 400 : 677">
    <AlFormCard title="Client Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mt-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form @submit.prevent ref="form" v-model="valid" validate-on="submit lazy">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="clientData.name"
              label="Name*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['name'] as string[])"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { ISystemError } from '@/models/error'
import { useCreateClient, useUpdateClient } from '@/composables/client'

import AlFormCard from '../common/AlFormCard.vue'
import ErrorAlert from '../common/ErrorAlert.vue'
import { Client, type IClient, type IClientData } from '@/models/client'
import type { VForm } from 'vuetify/components'
import { diffDeep } from '@/utils/helpers'
import { cloneDeep } from 'lodash'
import { requiredValidator } from '@/utils/validators'

interface Props {
  client?: IClient
  isEdit: boolean
}

const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog', { default: false })

const clientData = ref<IClientData>({})

watch(dialog, (value) => {
  if (value) {
    clearErrors()
    clientData.value = !props.isEdit ? ({} as IClientData) : cloneDeep(props.client!)
  }
})

const form = ref<VForm>()
const valid = ref(false)

const subtitle = computed(() => `${props.isEdit ? 'Update' : 'Create'} client information`)

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updateClient,
  reset: updateReset
} = useUpdateClient()
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createClient,
  reset: createReset
} = useCreateClient()

const localError = ref<ISystemError | null>(null)

const loading = computed(() => (props.isEdit ? isUpdatePending.value : isCreatePending.value))

const error = computed({
  get: () => {
    return localError.value || props.isEdit ? updateError.value : createError.value
  },
  set: (value) => {
    localError.value = value
  }
})

function clearErrors() {
  props.isEdit ? updateReset() : createReset()
  error.value = null
}

async function save() {
  clearErrors()
  const { valid } = await form.value!.validate()

  if (valid) {
    let clientInstance: IClient | null = null

    try {
      clientInstance = new Client(clientData.value)
    } catch (e: any) {
      // When constructing the instance before saving the possibility of an error can occur the local error is to store that error instance and display it
      error.value = e
    }

    if (clientInstance != null) {
      const saveAction = props.isEdit ? updateClient : createClient

      let payload = clientInstance
      if (props.isEdit) {
        payload = diffDeep(clientInstance, props.client, true)
      }

      saveAction(payload, {
        onSuccess: () => {
          closeDialog()
        }
      })
    }
  }
}
</script>

import type { IAuthPermissions, IAuthPermissionsData } from './auth.types'

/**
 * A class representing a Auth User Permissions.
 */
export class AuthPermissions implements IAuthPermissions {
  [key: string]: boolean

  constructor(data: IAuthPermissionsData) {
    for (const key in data) {
      this[key] = data[key]
    }
  }
}

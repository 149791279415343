<template>
  <v-list-item v-bind="attrs" ref="navItem">
    <slot name="default"> </slot>
  </v-list-item>
</template>

<script lang="ts" setup>
import { computed, resolveDynamicComponent, useAttrs, watch } from 'vue'

import type { UseLinkOptions, RouterLink as _RouterLink } from 'vue-router'
import { VListItem } from 'vuetify/components'

const emit = defineEmits<{
  'select:nav-item': []
}>()
const RouterLink = resolveDynamicComponent('RouterLink') as typeof _RouterLink // | string

const attrs = useAttrs()
const useLink = RouterLink.useLink(attrs as UseLinkOptions)

const isActive = computed(() => useLink.isActive?.value)

watch(
  isActive,
  (value) => {
    if (value) {
      emit('select:nav-item')
    }
  },
  {
    immediate: true
  }
)
</script>

<template>
  <BasePage>
    <template #page-header:subtitle>
      <BackButton class="mr-4" ref="backButton" :fallback-to="{ name: 'clients' }" />
      {{ postOrder?.site }} - Post Orders
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <template #page-header:actions>
      <v-btn
        v-if="
          currentUser?.hasPermission('post_orders.add_postorderinstructions') && selectedInstruction
        "
        class="rounded text-capitalize"
        color="primary"
        variant="elevated"
        @click="openPostOrderInstructionEditFormDialog(selectedInstruction)"
      >
        <template #prepend>
          <v-icon start class="hidden-sm-and-down me-0"> mdi-pencil</v-icon>
        </template>
        Edit Instruction
      </v-btn>

      <v-btn
        v-if="
          selectedInstruction &&
          currentUser?.hasPermission('post_orders.change_postorderinstructions')
        "
        :color="selectedInstruction.isPublished() ? '#4CBB17' : 'surface-lighten-1'"
        class="rounded text-capitalize ms-2"
        variant="elevated"
        :theme="selectedInstruction.isPublished() ? 'light' : 'dark'"
        :loading="isInstructionSetStatePending"
      >
        <template #prepend>
          <v-icon start class="hidden-sm-and-down me-0">
            {{ selectedInstruction.isPublished() ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon
          >
        </template>
        {{ selectedInstruction.isPublished() ? 'Published' : 'Unpublished' }}

        <v-icon
          v-if="currentUser?.hasPermission('post_orders.change_postorderinstructions')"
          left
          class="hidden-sm-and-down mr-n1"
          >mdi-chevron-down
        </v-icon>

        <v-menu
          theme="light"
          activator="parent"
          :disabled="!currentUser?.hasPermission('post_orders.change_postorderinstructions')"
        >
          <v-list density="compact" class="py-1">
            <template v-for="(status, i) in postOrderInstructionStateVisuals">
              <v-list-item
                v-if="status.state != selectedInstruction.state"
                :class="{ 'mb-2': i !== postOrderInstructionStateVisuals.length - 1 }"
                :key="i"
                @click="updatePostOrderInstructionState(status.state)"
              >
                <v-list-item-title>{{ status.display }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-btn>
    </template>

    <v-card
      flat
      height="calc(100vh - 226px)"
      class="d-flex flex-column rounded-lg"
      color="background"
      :loading="isLoading"
    >
      <v-card variant="flat" density="compact" min-height="90" color="background" class="py-6">
        <div class="pa-1 post-order-instructions-tab">
          <template
            v-for="(
              postOrderInstructionsSet, postOrderInstructionsSetIndex
            ) in postOrderInstructionsSetList"
            :key="postOrderInstructionsSetIndex"
          >
            <v-divider v-if="postOrderInstructionsSetIndex" />

            <v-btn
              :variant="
                isSelectedInstructionSameType(postOrderInstructionsSet.value) ? 'outlined' : 'flat'
              "
              :active="isSelectedInstructionSameType(postOrderInstructionsSet.value)"
              :color="
                isSelectedInstructionSameType(postOrderInstructionsSet.value) ? 'success' : ''
              "
              class="text-h6 px-8"
              width="200"
              v-if="postOrderInstructionsSet.value == PostOrderInstructionTypeEnum.Patrol"
            >
              {{ postOrderInstructionsSet.title }}<v-icon size="18" icon="mdi-chevron-down" />
              <v-menu activator="parent">
                <v-list density="compact" nav class="px-3">
                  <template
                    v-for="(instruction, i) in postOrderInstructionsSet.instructions"
                    :key="i"
                  >
                    <v-list-item @click="setSelectedInstruction(instruction)">
                      <v-list-item-title>{{ instruction.template.title }}</v-list-item-title>
                    </v-list-item>
                  </template>

                  <v-divider v-if="postOrderInstructionsSet.instructions.length > 0" />
                  <v-list-item
                    class="mb-0"
                    @click="
                      openPostOrderInstructionCreateFormDialog(postOrderInstructionsSet.value)
                    "
                  >
                    <v-list-item-title
                      >Add new {{ postOrderInstructionsSet.title }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-btn>

            <v-btn
              :variant="
                isSelectedInstructionSameType(postOrderInstructionsSet.value) ? 'outlined' : 'flat'
              "
              class="text-h6 px-8"
              :color="
                isSelectedInstructionSameType(postOrderInstructionsSet.value) ? 'success' : ''
              "
              :active="isSelectedInstructionSameType(postOrderInstructionsSet.value)"
              @click="
                postOrderInstructionsSet.instructions[0]
                  ? setSelectedInstruction(postOrderInstructionsSet.instructions[0])
                  : displayNoInstructionState(postOrderInstructionsSet.value)
              "
              v-else
            >
              {{ postOrderInstructionsSet.title }}
            </v-btn>
          </template>
        </div>
      </v-card>

      <PostOrderInstructionDetail
        v-if="postOrderInstruction && noInstructionStateType == null"
        :post-order-id="postOrderId"
        :instruction="postOrderInstruction"
      />

      <NoDataAvailablePlaceholder
        v-else-if="
          noInstructionStateType != null || (!isInstructionsPending && !postOrderInstruction)
        "
        class="pt-6"
        width="400"
        :header="
          noInstructionStateType != null
            ? 'No instructions available'
            : `No instruction${postOrderInstructions!.length > 0 ? ' selected' : 's available'} `
        "
        header-class="font-weight-medium text-h5"
      >
        <template #description>
          <p class="mt-0 mb-6 text-caption text-medium-emphasis">
            <span>
              <template v-if="noInstructionStateType != null">
                You do not have a `{{
                  postOrderInstructionTypeDisplayRepresentation(noInstructionStateType)
                }}` instruction for this post order. Create a
                <span class="text-primary">{{
                  postOrderInstructionTypeDisplayRepresentation(noInstructionStateType)
                }}</span>
                instruction here</template
              >
              <template v-else-if="postOrderInstructions!.length > 0">
                You do not have an instruction selected. Please select one from the instruction drop
                down above
              </template>
            </span>
          </p>
        </template>

        <template #primaryAction>
          <v-btn
            v-if="noInstructionStateType != null"
            color="primary"
            depressed
            @click="createPostOrderInstructionFromType(noInstructionStateType)"
            :loading="useCreateInstructionMutation.isPending.value"
          >
            <template #prepend>
              <v-icon left class="hidden-sm-and-down"> mdi-plus </v-icon>
            </template>
            Create
            {{ postOrderInstructionTypeDisplayRepresentation(noInstructionStateType!) }} Instruction
          </v-btn>
        </template>
      </NoDataAvailablePlaceholder>
    </v-card>
  </BasePage>

  <PostOrderInstructionFormDialog
    v-if="selectedFormInstruction"
    v-model:dialog="isPostOrderInstructionFormDialogActive"
    :post-order-id="postOrderId"
    :post-order-instruction="selectedFormInstruction"
    :is-edit="isEditingInstruction"
  />
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

import type { ISystemError } from '@/models/error'

import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores'
import { useQueryClient } from '@tanstack/vue-query'
import {
  useCreatePostOrderInstruction,
  useFetchPostOrder,
  useFetchPostOrderInstruction,
  useFetchPostOrderInstructions,
  useSetPostOrderInstructionState
} from '@/composables/post-order'

import {
  PostOrderInstruction,
  PostOrderInstructionStateEnum,
  PostOrderInstructionTypeEnum,
  type IPostOrderInstruction,
  type IPostOrderInstructionData
} from '@/models/post-order'

import BasePage from '@/components/base/BasePage.vue'
import BackButton from '@/components/common/BackButton.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import PostOrderInstructionDetail from '@/components/post-order/PostOrderInstructionDetail.vue'
import PostOrderInstructionFormDialog from '@/components/post-order/PostOrderInstructionFormDialog.vue'
import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'

const route = useRoute()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

// data
const postOrderId = computed(() => Number(route.params.id))

const selectedInstruction = ref<null | IPostOrderInstruction>(null)
const selectedInstructionId = computed(() => selectedInstruction.value?.id!)

function setSelectedInstruction(instruction: IPostOrderInstruction) {
  selectedInstruction.value = instruction

  noInstructionStateType.value = null
}

const queryClient = useQueryClient()

const {
  postOrder,
  isPending: isPostOrderPending,
  error: postOrderFetchError
} = useFetchPostOrder(postOrderId)

const {
  instructions: postOrderInstructions,
  isLoading: isInstructionsPending,
  error: instructionsFetchError,
  queryKey: instructionsQueryKey
} = useFetchPostOrderInstructions(postOrderId)

const { postOrderInstruction: postOrderInstruction, isLoading: isInstructionPending } =
  useFetchPostOrderInstruction(postOrderId, selectedInstructionId)

// update error to crud action error display
watch(
  postOrderInstructions,
  (value) => {
    if (value) {
      if (value.length == 0) {
        displayNoInstructionState(PostOrderInstructionTypeEnum.Start)
      } else {
        if (selectedInstruction.value) {
          const instruction = value.find((instruction) =>
            instruction.sections.find((section) => section.id == selectedInstruction.value!.id)
          )
          if (instruction) {
            setSelectedInstruction(instruction)
          }
        } else {
          setSelectedInstruction(value[0])
        }
      }
    }
  },
  {
    deep: true
  }
)

const postOrderInstructionsSetList = computed(() => {
  return [
    {
      title: 'Start of Shift',
      value: PostOrderInstructionTypeEnum.Start,
      instructions: (postOrderInstructions.value ?? []).filter(
        (instruction) => instruction.type == PostOrderInstructionTypeEnum.Start
      )
    },
    {
      title: 'Patrols',
      value: PostOrderInstructionTypeEnum.Patrol,
      instructions: (postOrderInstructions.value ?? []).filter(
        (instruction) => instruction.type == PostOrderInstructionTypeEnum.Patrol
      )
    },
    {
      title: 'End of Shift',
      value: PostOrderInstructionTypeEnum.End,
      instructions: (postOrderInstructions.value ?? []).filter(
        (instruction) => instruction.type == PostOrderInstructionTypeEnum.End
      )
    }
  ]
})

function isSelectedInstructionSameType(type: PostOrderInstructionTypeEnum) {
  return selectedInstruction.value ? selectedInstruction.value.type == type : false
}

const noInstructionStateType = ref<PostOrderInstructionTypeEnum | null>(null)

function displayNoInstructionState(type: PostOrderInstructionTypeEnum) {
  noInstructionStateType.value = type

  selectedInstruction.value = null
}

const isEditingInstruction = ref(false)
const selectedFormInstruction = ref<IPostOrderInstruction | null>(null)
const isPostOrderInstructionFormDialogActive = ref(false)

function openPostOrderInstructionCreateFormDialog(type?: PostOrderInstructionTypeEnum) {
  selectedFormInstruction.value = new PostOrderInstruction({
    template: {
      title: 'Untitled',
      description: ''
    },
    type: type
  })

  isEditingInstruction.value = false

  isPostOrderInstructionFormDialogActive.value = true
}
function openPostOrderInstructionEditFormDialog(instruction: IPostOrderInstruction) {
  selectedFormInstruction.value = instruction

  // rendering the form dialog after the selectedFormInstruction is set will not set the prop in the component
  nextTick(() => {
    isEditingInstruction.value = true
    isPostOrderInstructionFormDialogActive.value = true
  })
}

const isLoading = computed(
  () => isInstructionPending.value || isInstructionsPending.value || isPostOrderPending.value
)

const error = ref<ISystemError | null>(null)

// update error to crud action error display
watch(
  () => instructionsFetchError.value || postOrderFetchError.value,
  (value) => {
    error.value = value
  }
)

function clearErrors() {
  error.value = null
}

const postOrderInstructionStateVisuals = [
  {
    state: PostOrderInstructionStateEnum.Draft,
    display: 'Unpublished'
  },
  {
    state: PostOrderInstructionStateEnum.Published,
    display: 'Published'
  }
]

function postOrderInstructionTypeDisplayRepresentation(type: PostOrderInstructionTypeEnum) {
  switch (type) {
    case PostOrderInstructionTypeEnum.Start:
      return 'Start of shift'
    case PostOrderInstructionTypeEnum.Patrol:
      return 'Patrol'
    case PostOrderInstructionTypeEnum.End:
      return 'End of shift'
  }
}

const useCreateInstructionMutation = useCreatePostOrderInstruction(postOrderId)

function createPostOrderInstructionFromType(type: PostOrderInstructionTypeEnum) {
  const instructionData: IPostOrderInstructionData = {
    template: {
      title: postOrderInstructionTypeDisplayRepresentation(type),
      description: `Instruction for ${postOrderInstructionTypeDisplayRepresentation(type)}`
    },

    type: type
  }
  useCreateInstructionMutation.mutate(instructionData, {
    async onSuccess(data) {
      // wait on refreshing instructions before is loading is cancelled
      await queryClient.invalidateQueries({
        queryKey: instructionsQueryKey
      })

      // then select instruction to display
      setSelectedInstruction(new PostOrderInstruction(data))
    }
  })
}

const {
  isPending: isInstructionSetStatePending,
  error: setStateFetchError,
  mutate: setPostOrderInstructionState,
  reset: clearSetPostOrderInstructionStateErrors
} = useSetPostOrderInstructionState(postOrderId, selectedInstructionId)

function updatePostOrderInstructionState(state: PostOrderInstructionStateEnum) {
  clearSetPostOrderInstructionStateErrors()
  setPostOrderInstructionState(state)
}

// update error to crud action error display
watch(
  () => setStateFetchError.value,
  (value) => {
    error.value = value
  }
)
</script>

<style lang="scss">
.post-order-instructions-tab {
  align-items: center;
  display: flex;
  position: relative;
  overflow-x: auto;
  justify-content: space-between;
  z-index: 1;
}
</style>

<template>
  <v-data-table-server
    v-model:search="filters.search"
    :items-per-page="25"
    :items-per-page-options="[{ value: 25, title: '25' }]"
    v-model:page="filters.page"
    :items-length="count"
    no-filter
    :hover="contacts.length > 0"
    item-value="id"
    :headers="contactDataTableHeaders"
    :items="contacts"
    :loading="loading"
    color="primary"
    sticky
    fixed-header
    density="comfortable"
    height="calc(100% - 37px)"
    style="height: calc(100% - 93px); width: 100%"
    :row-props="generateRowProps"
  >
    <template #[`item.index`]="{ index }">
      {{ index + 1 }}
    </template>

    <template #[`item.full_name`]="{ item }">
      <div class="d-flex align-center">
        <v-avatar variant="tonal" color="primary" size="36" class="me-2">{{
          item.getInitials()
        }}</v-avatar>
        <div class="d-flex flex-column">
          <h6
            class="text-body-2 font-weight-medium text-link"
            @click="emit('contact-selected', item)"
          >
            {{ item.getFullName() }}
          </h6>
          <div class="text-caption font-weight-regular text-medium-emphasis pt-n1">
            {{ item.title }}
          </div>
        </div>
      </div>
    </template>

    <template #[`item.email`]="{ item }">
      <a
        :href="`mailto:${item.email}`"
        target="_blank"
        rel="noopener noreferrer"
        class="font-weight-medium text-link"
      >
        {{ item.email }}
      </a>
    </template>

    <template #[`item.phone`]="{ item }">
      <a
        :href="parsePhoneNumber(item.phone)?.getURI()"
        target="_blank"
        rel="noopener noreferrer"
        class="font-weight-medium text-link"
        v-if="item.phone"
      >
        {{ parsePhoneNumber(item.phone)?.formatNational() }}
      </a>
      <span v-else> - </span>
    </template>

    <template #[`item.actions`]="{ item }">
      <ContactDetailOptionsButton
        :contact="item"
        @contact-edit-pressed="(contact: IContact) => emit('contact-edit-pressed', contact)"
        @contact-delete-pressed="(contact: IContact) => emit('contact-delete-pressed', contact)"
      />
    </template>
    <template v-slot:no-data>
      <NoDataAvailablePlaceholder
        width="400"
        header="No contacts available"
        header-class="font-weight-medium text-h5"
      >
        <template #description>
          <p class="mt-0 mb-6 text-caption text-medium-emphasis">
            <span v-if="currentUser?.hasPermission('contacts.add_contact')">
              <template v-if="filters.search">
                Oops! No contacts match your current search criteria. Try adjusting your filters for
                better results.
              </template>
              <template v-else>
                It looks like there are currently no contacts in the contact list. Get started by
                adding new contacts here to build your contact list.</template
              >
            </span>
            <span v-else
              >You do not have the permission to create a contact. Contact your administrator for
              more details
            </span>
          </p>
        </template>

        <template #primaryAction>
          <v-btn
            color="primary"
            depressed
            v-if="currentUser?.hasPermission('contacts.add_contact')"
            @click="emit('contact-create-pressed')"
          >
            <template #prepend>
              <v-icon left class="hidden-sm-and-down"> {{ route.meta.icon }} </v-icon>
            </template>
            Create Contact
          </v-btn>
        </template>
      </NoDataAvailablePlaceholder>
    </template>
  </v-data-table-server>
</template>

<script lang="ts" setup>
import parsePhoneNumber from 'libphonenumber-js'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import type { IContact } from '@/models/contact'
import { useAuthStore } from '@/stores'
import type { IContactFilterParam } from '@/services'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import ContactDetailOptionsButton from './ContactDetailOptionsButton.vue'

interface Props {
  contacts: IContact[]
  count: number
  loading: boolean
}

interface Emit {
  (e: 'contact-selected', contact: IContact): void
  (e: 'contact-edit-pressed', contact: IContact): void
  (e: 'contact-create-pressed'): void
  (e: 'contact-delete-pressed', contact: IContact): void
}

const filters = defineModel<IContactFilterParam>('filters', { required: true })

const emit = defineEmits<Emit>()
defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const route = useRoute()

const contactDataTableHeaders = ref<any[]>([
  {
    title: '#',
    align: 'start',
    key: 'index',
    width: 20,
    sortable: false
  },
  {
    title: 'Name',
    align: 'start',
    key: 'full_name',
    sortable: false
  },
  {
    title: 'Email',
    align: 'start',
    key: 'email',
    sortable: false
  },
  {
    title: 'Phone',
    align: 'start',
    key: 'phone',
    sortable: false
  },
  {
    title: 'Title',
    align: 'start',
    key: 'title',
    value: (item: IContact) => (item.title ? item.title : ' - '),
    sortable: false
  },
  {
    title: 'Client',
    align: 'start',
    key: 'client.name',
    value: (item: IContact) => (item.client ? item.client.toString() : ' - '),
    sortable: false
  },

  { title: '', key: 'actions', align: 'center', sortable: false }
])

function generateRowProps(props: { index: number; item: IContact }) {
  return {
    // apply click listener to row props to open detail drawer
    onClick: () => emit('contact-selected', props.item)
  }
}
</script>

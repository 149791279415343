import { AbstractModel } from '../base'
import { Client, Site, type IClient, type ISite } from '../client'
import {
  FormField,
  FormFieldConstraint,
  FormFieldConstraintCondition,
  FormFieldOption,
  FormFieldSubTypeEnum
} from '../form'
import type {
  IFormField,
  IFormFieldConstraint,
  IFormFieldConstraintCondition,
  IFormFieldOption
} from '../form'
import { FormSection, type IFormSection } from '../form'

import { Form, type IForm } from '../form'
import { ReportActivityStatusEnum } from '../report'
import {
  PostOrderInstructionSectionTypeEnum,
  PostOrderInstructionStateEnum,
  PostOrderInstructionTypeEnum
} from './post-order.constants'
import type {
  IPostOrder,
  IPostOrderInstructionsField,
  IPostOrderInstructionsFieldData,
  IPostOrderInstructionsFieldOption,
  IPostOrderInstructionsFieldOptionData,
  IPostOrderInstructionsSection,
  IPostOrderInstructionsSectionData,
  IPostOrderInstruction,
  IPostOrderInstructionData,
  IPostOrderInstructionsFieldConstraint,
  IPostOrderInstructionsFieldConstraintCondition,
  IPostOrderInstructionsFieldConstraintData,
  IPostOrderInstructionsFieldConstraintConditionData,
  IPostOrderData
} from './post-order.types'

/**
 * A class representing a PostOrder.
 */
export class PostOrder extends AbstractModel implements IPostOrder {
  readonly id?: number
  readonly client?: IClient | number
  readonly site: ISite | number

  constructor(data: IPostOrderData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderData)[] = ['site']
    this.validate(data, requiredFields)

    this.site = typeof data.site == 'number' ? data.site : new Site(data.site!)

    if (data.id) {
      this.id = data.id
    }
    if (data.client) {
      this.client = typeof data.client == 'number' ? data.client : new Client(data.client!)
    }
  }

  toString() {
    return this.site.toString()
  }
}

/**
 * A class representing a PostOrderInstructionsField.
 */
export class PostOrderInstructionsField
  extends AbstractModel
  implements IPostOrderInstructionsField
{
  readonly id?: number
  readonly field: IFormField
  readonly subtype: number

  readonly constraints: IPostOrderInstructionsFieldConstraint[] = []
  readonly options: IPostOrderInstructionsFieldOption[] = []

  constructor(data: IPostOrderInstructionsFieldData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsFieldData)[] = ['field']
    this.validate(data, requiredFields)

    this.field = new FormField(data.field!)

    if (data.id) {
      this.id = data.id
    }

    this.subtype = data.subtype ?? FormFieldSubTypeEnum.VehicleLog

    if (data.constraints) {
      this.constraints = data.constraints.map(
        (section) => new PostOrderInstructionsFieldConstraint(section)
      )
    }

    if (data.options) {
      this.options = data.options.map((section) => new PostOrderInstructionsFieldOption(section))
    }
  }

  toString() {
    return this.field.toString()
  }
}

/**
 * A class representing a PostOrderInstructionsFieldConstraintCondition.
 */
export class PostOrderInstructionsFieldConstraintCondition
  extends AbstractModel
  implements IPostOrderInstructionsFieldConstraintCondition
{
  readonly id?: number
  readonly condition: IFormFieldConstraintCondition

  constructor(data: IPostOrderInstructionsFieldConstraintConditionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsFieldConstraintConditionData)[] = [
      'condition'
    ]
    this.validate(data, requiredFields)

    this.condition = new FormFieldConstraintCondition(data.condition!)

    if (data.id) {
      this.id = data.id
    }
  }

  toString() {
    return ''
  }
}

/**
 * A class representing a PostOrderInstructionsFieldOption.
 */
export class PostOrderInstructionsFieldConstraint
  extends AbstractModel
  implements IPostOrderInstructionsFieldConstraint
{
  readonly id?: number
  readonly constraint: IFormFieldConstraint
  readonly conditions: IPostOrderInstructionsFieldConstraintCondition[] = []

  constructor(data: IPostOrderInstructionsFieldConstraintData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsFieldConstraintData)[] = ['constraint']
    this.validate(data, requiredFields)

    this.constraint = new FormFieldConstraint(data.constraint!)

    if (data.conditions) {
      this.conditions = data.conditions.map(
        (section) => new PostOrderInstructionsFieldConstraintCondition(section)
      )
    }

    if (data.id) {
      this.id = data.id
    }
  }

  toString() {
    return ''
  }
}

/**
 * A class representing a PostOrderInstructionsFieldOption.
 */
export class PostOrderInstructionsFieldOption
  extends AbstractModel
  implements IPostOrderInstructionsFieldOption
{
  readonly id?: number
  readonly option: IFormFieldOption
  readonly observation_text: string = ''
  readonly incident_level: ReportActivityStatusEnum = ReportActivityStatusEnum.SecurityLevel1

  constructor(data: IPostOrderInstructionsFieldOptionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsFieldOptionData)[] = ['option']
    this.validate(data, requiredFields)

    this.option = new FormFieldOption(data.option!)

    if (data.observation_text) {
      this.observation_text = data.observation_text
    }

    if (data.incident_level) {
      this.incident_level = data.incident_level
    }

    if (data.id) {
      this.id = data.id
    }
  }

  toString() {
    return this.option.value
  }
}

/**
 * A class representing a PostOrderInstructionsSection.
 */
export class PostOrderInstructionsSection
  extends AbstractModel
  implements IPostOrderInstructionsSection
{
  readonly id?: number
  readonly section: IFormSection
  readonly type: PostOrderInstructionSectionTypeEnum

  readonly fields: IPostOrderInstructionsField[] = []

  constructor(data: IPostOrderInstructionsSectionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionsSectionData)[] = ['section']
    this.validate(data, requiredFields)

    this.section = new FormSection(data.section!)

    this.type = data.type ?? PostOrderInstructionSectionTypeEnum.Checkpoint

    if (data.id) {
      this.id = data.id
    }

    if (data.fields) {
      this.fields = data.fields.map((section) => new PostOrderInstructionsField(section))
    }
  }

  toString() {
    return this.section.toString()
  }
}

/**
 * A class representing a PostOrderInstruction.
 */
export class PostOrderInstruction extends AbstractModel implements IPostOrderInstruction {
  readonly id?: number
  readonly template: IForm

  readonly state: PostOrderInstructionStateEnum
  readonly type: PostOrderInstructionTypeEnum
  readonly sections: IPostOrderInstructionsSection[] = []

  constructor(data: IPostOrderInstructionData) {
    super()

    // validate data
    const requiredFields: (keyof IPostOrderInstructionData)[] = ['template', 'type']
    this.validate(data, requiredFields)

    this.template = new Form(data.template!)

    this.type = data.type!

    this.state = data.state ?? PostOrderInstructionStateEnum.Draft

    if (data.id) {
      this.id = data.id
    }

    if (data.sections) {
      this.sections = data.sections.map((section) => new PostOrderInstructionsSection(section))
    }
  }

  toString() {
    return ''
  }

  isPublished() {
    return this.state == PostOrderInstructionStateEnum.Published
  }
}

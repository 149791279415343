<template>
  <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color">
    {{ snackbar.message }}
    <template v-slot:actions>
      <v-btn @click="snackbar.closeSnackbar()">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { useSnackbarStore } from '@/stores/snackbar'

const snackbar = useSnackbarStore()
</script>

import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation } from '@tanstack/vue-query'
import siteManagementService from '@/services/site_management/site-management'
import type { ISetSiteApproverPayload } from '@/services'

export function useSetSiteApprover() {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['set-site-approver-employee'],
    mutationFn: (data: ISetSiteApproverPayload) => siteManagementService.setSiteApprover(data),

    onSuccess: () => {
      snackbarStore.showSnackbar('Site Approver assigned successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

import type { AxiosInstance } from 'axios'
import axios from '@/axios'

import type {
  IGeoAuditLogFilterParam,
  IGeoAuditLogPaginatedListData,
  IGeoAuditLogService
} from './geo-audit-log.types'
import type { IGeoAuditLogData } from '@/models/geo-audit-log'

/**
 * A class representing a GeoAuditLogService API handler.
 */
export class GeoAuditLogService implements IGeoAuditLogService {
  private endpoint: string

  // GeoAuditLogService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/geoaudit/logs/'
  }

  // LOGS
  fetchGeoAuditLogs(filter: IGeoAuditLogFilterParam): Promise<IGeoAuditLogPaginatedListData> {
    return this.axios.get(`${this.endpoint}`, { params: filter }).then(({ data }) => data)
  }

  fetchGeoAuditLog(id: number): Promise<IGeoAuditLogData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the GeoAuditLogService
const vehicleService = new GeoAuditLogService(axios)
export default vehicleService

<template>
  <AlDialog
    v-model="dialog"
    title="Incident Report Detail"
    color="background"
    @clearErrors="clearErrors()"
  >
    <v-card color="background" variant="flat" class="mx-n10">
      <IncidentReportDetailCard no-header v-if="reportId" :reportId="reportId" />
    </v-card>
  </AlDialog>
</template>

<script setup lang="ts">
import AlDialog from '@/components/common/AlDialog.vue'
import IncidentReportDetailCard from '@/components/reports/incident/IncidentReportDetailCard.vue'

interface Props {
  title?: string
  subtitle?: string
  reportId: number | null
}
defineProps<Props>()

interface Emit {
  (e: 'clearErrors'): void
}

const emit = defineEmits<Emit>()

const dialog = defineModel<boolean>('dialog', { default: false })

function clearErrors() {
  emit('clearErrors')
}
</script>

<template>
  <v-select
    v-model="country"
    :items="countries"
    item-value="code"
    item-title="name"
    v-bind="$attrs"
  >
  </v-select>
</template>

<script setup lang="ts">
import { useCountryStore } from '@/stores/country'
import { storeToRefs } from 'pinia'

const country = defineModel()

const countryStore = useCountryStore()
const { getCountries: countries } = storeToRefs(countryStore)
</script>

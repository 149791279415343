<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 'auto' : 677">
    <AlFormCard title="Contact Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mb-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form ref="form" v-model="valid" validate-on="submit lazy">
        <h2 class="text-subtitle-2 font-weight-medium my-3">Details</h2>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="contactData.first_name"
              label="First Name*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['first_name'] as string[])"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="contactData.last_name"
              label="Last Name*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['last_name'] as string[])"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="contactData.title"
              label="Title"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :error-messages="error && (error.fieldErrors['title'] as string[])"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="contactData.email"
              label="Email"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[emailValidator]"
              :error-messages="error && (error.fieldErrors['email'] as string[])"
              validate-on="input"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <PhoneField
              v-model="contactData.phone"
              label="Phone"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :error-messages="error && (error.fieldErrors['phone'] as string[])"
            ></PhoneField>
          </v-col>
        </v-row>
        <h2 class="text-subtitle-2 font-weight-medium my-3 pt-3">Client</h2>
        <v-row>
          <v-col cols="12">
            <ClientAutoCompletePicker
              v-model="contactData.client"
              label="Client"
              variant="outlined"
              hide-details="auto"
              density="compact"
              :disabled="disableClientPicker"
              @update:model-value="clearErrors()"
              :error-messages="error && (error.fieldErrors['client'] as string[])"
              clearable
            />
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'

import type { ISystemError } from '@/models/error'
import type { VForm } from 'vuetify/components'
import { Contact, type IContact, type IContactData } from '@/models/contact'

import { useContactStore } from '@/stores/contact'
import { useSnackbarStore } from '@/stores/snackbar'
import { diffDeep } from '@/utils/helpers'

import AlFormCard from '../common/AlFormCard.vue'
import ErrorAlert from '../common/ErrorAlert.vue'
import ClientAutoCompletePicker from '../clients/ClientAutoCompletePicker.vue'
import { emailValidator, requiredValidator } from '@/utils/validators'
import PhoneField from '../common/PhoneField.vue'

interface Props {
  contact?: IContact
  isEdit: boolean
  clientId?: number
  disableClientPicker?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disableClientPicker: true
})

const contactData = ref<IContactData>({})

const dialog = defineModel<boolean>('dialog', { required: true, default: false })

watch(dialog, (value) => {
  if (value) {
    clearErrors()

    contactData.value = !props.isEdit ? { client: props.clientId } : cloneDeep(props.contact!)
  }
})

const form = ref<VForm>()
const loading = ref(false)
const valid = ref(false)

const subtitle = computed(() => `${props.isEdit ? 'Update' : 'Create'} contact information`)

const error = ref<ISystemError | null>(null)

function clearErrors() {
  error.value = null
}

const contactStore = useContactStore()
const snackbarStore = useSnackbarStore()

function closeDialog() {
  clearErrors()
  dialog.value = false
}

async function save() {
  clearErrors()
  const { valid } = await form.value!.validate()

  if (valid) {
    let contactInstance: IContact | null = null

    try {
      contactInstance = new Contact(contactData.value)
    } catch (e: any) {
      error.value = e
    }

    if (contactInstance != null) {
      loading.value = true

      const saveAction = props.isEdit ? contactStore.updateContact : contactStore.createContact

      let payload = contactInstance

      if (props.isEdit) {
        payload = diffDeep(contactInstance, props.contact, true)
      }

      saveAction(payload)
        .then(() => {
          const actionText = props.isEdit ? 'updated' : 'created'
          snackbarStore.showSnackbar(`Contact ${actionText} successfully`)
          closeDialog()
        })
        .catch((e) => {
          error.value = e
        })
        .finally(() => {
          loading.value = false
        })
    }
  }
}
</script>

import { createApp } from 'vue'

import '@/styles/main.scss'

import { registerPlugins } from '@/plugins'
import * as Sentry from '@sentry/vue'

import App from './App.vue'

const app = createApp(App)

Sentry.init({
  app: app,
  dsn: 'https://e139f5552995eb63c9aac84f20162973@o4506380650676224.ingest.sentry.io/4506462949867520',
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api\.taginternational\.ca/]
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

registerPlugins(app)

app.mount('#app')

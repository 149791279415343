import type { IClientData } from '@/models/client'
import type { IClientService, IClientPaginatedListData, IClientFilterParam } from './client.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'

/**
 * A class representing a ClientService API handler.
 */
export class ClientService implements IClientService {
  private endpoint: string

  // ClientService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/clients/'
  }

  // Method to fetch a list of clients from the API
  fetchClients(filter?: IClientFilterParam): Promise<IClientPaginatedListData> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined
    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single client by their ID from the API
  fetchClient(id: number): Promise<IClientData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new client on the API
  createClient(data: IClientData): Promise<IClientData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing client on the API
  updateClient(data: IClientData): Promise<IClientData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a client by their ID from the API
  deleteClient(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the ClientService
const clientService = new ClientService(axios)
export default clientService

import { AuthPermissions, type IAuthPermissions } from '@/models/auth'
import { AbstractModel } from '@/models/base'

import type { IUser, IUserData, IUserRole, IUserRoleData } from './user.types'

/**
 * A class representing a User UserRole.
 */
export class UserRole extends AbstractModel implements IUserRole {
  readonly type: number
  readonly label: string

  constructor(data: IUserRoleData) {
    super()

    // Validate data
    const requiredFields: (keyof IUserRoleData)[] = ['type', 'label']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.type = data.type!
    this.label = data.label!
  }
}

/**
 * A class representing a User.
 */
export class User extends AbstractModel implements IUser {
  readonly id?: number
  readonly username: string
  readonly first_name: string
  readonly last_name: string
  readonly email?: string
  readonly is_active: boolean = false
  readonly roles: IUserRole[] = []
  readonly permissions: IAuthPermissions = {}
  readonly last_login?: Date

  constructor(data: IUserData) {
    super()

    // Validate data
    const requiredFields: (keyof IUserData)[] = ['username', 'first_name', 'last_name']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.username = data.username!
    this.first_name = data.first_name!
    this.last_name = data.last_name!

    if (data.id) {
      this.id = data.id
    }

    if (data.email) {
      this.email = data.email
    }

    if (data.is_active) {
      this.is_active = data.is_active
    }

    if (data.roles) {
      this.roles = data.roles.map((role) => {
        return new UserRole(role)
      })
    }

    if (data.permissions) {
      this.permissions = new AuthPermissions(data.permissions)
    }

    if (data.last_login) {
      this.last_login =
        typeof data.last_login === 'string' ? new Date(data.last_login) : data.last_login
    }
  }

  // Returns the user's first and last name
  getFullName(): string {
    return `${this.first_name} ${this.last_name}`
  }

  // Returns the user's name initials
  getInitials(): string {
    const firstInitial = this.first_name[0]
    const lastInitial = this.last_name[0]

    return `${firstInitial}${lastInitial}`.toUpperCase()
  }

  // Returns user's name as string representation of object
  toString(): string {
    return this.getFullName()
  }

  hasPermission(permission: string): boolean {
    // Check if at least one of the required permissions exists in the user's permissions
    return !!this.permissions[permission]
  }
}

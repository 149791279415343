<template>
  <v-autocomplete
    label="Groups"
    v-model="internalValue"
    @update:model-value="clearError()"
    :items="getPermissionGroups"
    item-value="id"
    item-title="name"
    :loading="loading"
    :disabled="disabled"
    :error-messages="error?.message"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

import type { IPermissionGroup } from '@/models/permission'

import { usePermissionStore } from '@/stores/permission'
import type { ISystemError } from '@/models/error'

interface Props {
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false
})

const internalValue = defineModel<
  null | (number | IPermissionGroup) | (number | IPermissionGroup)[]
>({
  default: null
})

const loading = ref(false)

const permissionStore = usePermissionStore()
const { getPermissionGroups } = storeToRefs(permissionStore)

const error = ref<ISystemError | null>(null)

function clearError() {
  error.value = null
}

function fetchPermissionGroups() {
  clearError()

  loading.value = true
  return permissionStore
    .fetchPermissionGroups()
    .catch((e) => {
      error.value = e
    })
    .finally(() => {
      loading.value = false
    })
}

watch(
  () => props.disabled,
  (value) => {
    if (!value) {
      fetchPermissionGroups()
    }
  },
  {
    immediate: true
  }
)
</script>

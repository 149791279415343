<template>
  <v-menu location="bottom" offset="10" v-bind="$attrs" :close-on-content-click="false">
    <v-card class="profile-menu-card pa-0" v-if="user">
      <v-card-item class="align-start pt-4">
        <template v-slot:prepend>
          <v-avatar class="pr-0" size="40" color="grey-lighten-1">
            <span class="text-white"> {{ user.getInitials() }} </span>
          </v-avatar>
        </template>

        <template v-slot:title>
          <v-list-item-title>{{ user.getFullName() }}</v-list-item-title>
        </template>

        <template v-slot:subtitle>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </template>
      </v-card-item>

      <v-card-text class="py-0 text-start">
        <RouterLink class="text-black d-block" :to="{ name: 'user-profile' }">
          <v-btn
            class="text-capitalize"
            height="40"
            style="justify-content: left"
            flat
            block
            prepend-icon="mdi-account"
            ><template v-slot:prepend> <v-icon size="large" class="mr-2"></v-icon> </template>Manage
            Profile</v-btn
          >
        </RouterLink>

        <RouterLink class="text-black d-block" :to="{ name: 'change-password' }">
          <v-btn
            class="text-capitalize"
            height="40"
            style="justify-content: left"
            flat
            block
            prepend-icon="mdi-lock"
          >
            <template v-slot:prepend>
              <v-icon size="large" class="mr-2"></v-icon>
            </template>
            Change Password</v-btn
          >
        </RouterLink>

        <RouterLink class="text-black d-block" :to="{ name: 'privacy' }">
          <v-btn
            class="text-capitalize mb-3"
            height="40"
            style="justify-content: left"
            flat
            block
            prepend-icon="mdi-eye-off"
            ><template v-slot:prepend> <v-icon size="large" class="mr-2"></v-icon> </template
            >Privacy Policy</v-btn
          >
        </RouterLink>

        <v-divider></v-divider>
      </v-card-text>

      <v-card-actions class="px-4 justify-center">
        <v-btn
          class="text-capitalize pl-4"
          flat
          block
          color="primary"
          height="40"
          style="justify-content: left"
          @click="signOut"
          prepend-icon="mdi-logout"
          ><template v-slot:prepend> <v-icon size="large" class="mr-2"></v-icon> </template>Sign
          Out</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { resetRouter } from '@/router'
import { useAuthStore } from '@/stores'

import { useQueryClient } from '@tanstack/vue-query'

const authStore = useAuthStore()

const user = authStore.getUser
const router = useRouter()
const queryClient = useQueryClient()

const signOut = () => {
  authStore.logout().then(() => {
    queryClient.removeQueries()
    router.push({ name: 'login' }).then(() => {
      // reset router after logout to not enforce bug in rendering links
      resetRouter()
    })
  })
}
</script>

<style lang="scss">
.profile-menu-card {
  .v-card-subtitle {
    opacity: 1 !important;
  }
  .v-card-item__prepend {
    padding-right: 10px;
  }

  a {
    text-decoration: none;
  }
}
</style>

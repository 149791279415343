<template>
  <v-dialog v-model="dialog" persistent :width="964">
    <AlFormCard :title="title" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mb-0 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <div class="d-flex flex-column">
        <v-stepper v-model="wizardStep" elevation="0" v-if="!props.isEdit">
          <v-stepper-header class="elevation-0">
            <v-stepper-item
              title="Profile"
              :value="WIZARD_STEP.Profile"
              :complete="wizardStep > WIZARD_STEP.Profile"
              :color="wizardStep > WIZARD_STEP.Profile ? 'success' : ''"
              class="py-5 pl-0"
              :class="wizardStep > WIZARD_STEP.Profile ? 'text-success' : ''"
              :style="wizardStep > WIZARD_STEP.Profile ? 'opacity: 1;' : ''"
            ></v-stepper-item>

            <v-divider></v-divider>

            <v-stepper-item
              class="py-5 pr-0"
              title="Site Permission"
              :value="WIZARD_STEP.Site"
            ></v-stepper-item>
          </v-stepper-header>
        </v-stepper>

        <v-window class="mx-0 mb-4" v-model="wizardStep">
          <v-window-item :value="WIZARD_STEP.Profile">
            <v-form ref="form" v-model="isProfileFormValid" validate-on="submit lazy">
              <h2 class="text-subtitle-2 font-weight-medium my-3">User Details</h2>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="employeeData.first_name"
                    label="First Name*"
                    variant="outlined"
                    hide-details="auto"
                    density="compact"
                    @update:model-value="clearErrors()"
                    :rules="[requiredValidator]"
                    :error-messages="error && (error.fieldErrors['first_name'] as string[])"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="employeeData.last_name"
                    label="Last Name*"
                    variant="outlined"
                    hide-details="auto"
                    density="compact"
                    @update:model-value="clearErrors()"
                    :rules="[requiredValidator]"
                    :error-messages="error && (error.fieldErrors['last_name'] as string[])"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="employeeData.username"
                    label="Username*"
                    variant="outlined"
                    hide-details="auto"
                    density="compact"
                    @update:model-value="clearErrors()"
                    :error-messages="error && (error.fieldErrors['username'] as string[])"
                  ></v-text-field>
                </v-col>
              </v-row>
              <h2 class="text-subtitle-2 font-weight-medium my-3 pt-3">Employee Profile</h2>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="employeeData.profile!.title"
                    label="Title"
                    variant="outlined"
                    hide-details="auto"
                    density="compact"
                    @update:model-value="clearErrors()"
                    :error-messages="
                      error &&
                      error.fieldErrors['profile'] &&
                      ((error.fieldErrors['profile'] as IFieldErrors)['title'] as string[])
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <PhoneField
                    validate-on="submit lazy"
                    v-model="employeeData.profile!.phone"
                    label="Phone*"
                    variant="outlined"
                    hide-details="auto"
                    placeholder="+1 123 456 7890"
                    density="compact"
                    @update:model-value="clearErrors()"
                    :rules="[requiredValidator]"
                    :error-messages="
                      error &&
                      error.fieldErrors['profile'] &&
                      ((error.fieldErrors['profile'] as IFieldErrors)['phone'] as string[])
                    "
                  ></PhoneField>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="employeeData.email"
                    label="Email"
                    variant="outlined"
                    hide-details="auto"
                    placeholder="jdoe@email.com"
                    density="compact"
                    @update:model-value="clearErrors()"
                    :rules="[emailValidator]"
                    :error-messages="error && (error.fieldErrors['email'] as string[])"
                    validate-on="input"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    type="number"
                    hide-spin-buttons
                    v-model="employeeData.profile!.security_license"
                    label="Security License"
                    variant="outlined"
                    hide-details="auto"
                    placeholder="1234"
                    density="compact"
                    clearable
                    @update:model-value="clearErrors()"
                    :error-messages="
                      error &&
                      error.fieldErrors['profile'] &&
                      ((error.fieldErrors['profile'] as IFieldErrors)[
                        'security_license'
                      ] as string[])
                    "
                  />
                </v-col>

                <v-col cols="12">
                  <PermissionGroupAutoCompletePicker
                    multiple
                    v-model="employeeData.groups"
                    label="Permissions*"
                    variant="outlined"
                    density="compact"
                    @update:model-value="clearErrors()"
                    :rules="[requiredValidator]"
                    :error-messages="error && (error.fieldErrors['groups'] as string[])"
                    chips
                    closable-chips
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-window-item>

          <v-window-item :value="WIZARD_STEP.Site">
            <EmployeeDetailSitesForm
              v-if="employeeId"
              :employee-id="employeeId"
              ref="employeeDetailSitesFormRef"
              @save="closeDialog()"
            />
          </v-window-item>
        </v-window>
      </div>

      <template #actions>
        <v-btn
          v-if="wizardStep == WIZARD_STEP.Site"
          class="mr-2"
          variant="outlined"
          :disabled="isEmployeeSitesPending"
          @click="wizardStep = WIZARD_STEP.Profile"
          >Back</v-btn
        >
        <v-spacer></v-spacer>

        <v-btn
          v-if="wizardStep == WIZARD_STEP.Profile"
          color="primary"
          variant="flat"
          :loading="loading"
          @click="saveEmployee()"
          >{{ props.isEdit ? 'Save' : 'Next' }}</v-btn
        >
        <v-btn
          v-else
          color="primary"
          variant="flat"
          :loading="isEmployeeSitesPending"
          @click="saveEmployeeSites()"
          >Save</v-btn
        >
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'

import type { ISystemError, IFieldErrors } from '@/models/error'
import type { VForm } from 'vuetify/components'
import { Employee, type IEmployee, type IEmployeeData } from '@/models/employee'

import { diffDeep } from '@/utils/helpers'
import { emailValidator, requiredValidator } from '@/utils/validators'
import { useCreateEmployee, useUpdateEmployee } from '@/composables/employee'

import AlFormCard from '../common/AlFormCard.vue'
import EmployeeDetailSitesForm from './EmployeeDetailSitesForm.vue'
import ErrorAlert from '../common/ErrorAlert.vue'
import PermissionGroupAutoCompletePicker from '../permission/PermissionGroupAutoCompletePicker.vue'
import PhoneField from '../common/PhoneField.vue'

interface Props {
  employee?: IEmployee
  isEdit: boolean
}

const props = defineProps<Props>()

const employeeData = ref<IEmployeeData>({
  profile: {}
})

const originalEmployeeData = ref<IEmployeeData>({
  profile: {}
})

const dialog = defineModel<boolean>('dialog', { required: true, default: false })

watch(dialog, (value) => {
  if (value) {
    clearErrors()
    wizardStep.value = WIZARD_STEP.Profile
    employeeData.value = !props.isEdit
      ? ({
          profile: {}
        } as IEmployeeData)
      : cloneDeep(props.employee!)
  }
})

const form = ref<VForm>()
const isProfileFormValid = ref(false)

enum WIZARD_STEP {
  Profile = 1,
  Site = 2
}

const wizardStep = ref(WIZARD_STEP.Profile)

const title = computed(() =>
  wizardStep.value == WIZARD_STEP.Profile ? 'Employee Detail' : 'Employee Site Access'
)
const subtitle = computed(() =>
  wizardStep.value == WIZARD_STEP.Profile
    ? `${isEditing.value ? 'Update' : 'Create'} employee information`
    : 'Give your employee access to view specific TAG sites'
)

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updateMutate,
  reset: updateReset
} = useUpdateEmployee()
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createMutate,
  reset: createReset
} = useCreateEmployee()

const loading = computed(() => (isEditing.value ? isUpdatePending.value : isCreatePending.value))
const localError = ref<ISystemError | null>(null)
const error = computed({
  get: () => {
    return localError.value || isEditing.value
      ? updateError.value
      : createError.value || employeeDetailSitesFormRef.value?.error
  },
  set: (value) => {
    localError.value = value
  }
})

function clearErrors() {
  isEditing.value ? updateReset() : createReset()
  employeeDetailSitesFormRef.value?.clearErrors()
  error.value = null
}

const employeeId = computed(() => employeeData.value.id)

const isEditing = computed(() => props.isEdit || !!employeeId.value)

async function saveEmployee() {
  clearErrors()
  const { valid } = await form.value!.validate()

  if (valid) {
    let employeeInstance: IEmployee | null = null

    try {
      employeeInstance = new Employee(employeeData.value)
    } catch (e: any) {
      error.value = e
    }

    if (employeeInstance != null) {
      const saveAction = isEditing.value ? updateMutate : createMutate

      let payload = employeeInstance

      if (isEditing.value) {
        payload = diffDeep(
          employeeInstance,
          props.isEdit ? props.employee : new Employee(originalEmployeeData.value),
          true
        )
      }

      saveAction(payload, {
        onSuccess: (data) => {
          // set employeeData with newly saved data from api

          employeeData.value = cloneDeep(data)
          originalEmployeeData.value = cloneDeep(data)

          if (props.isEdit) {
            closeDialog()
          } else {
            wizardStep.value = WIZARD_STEP.Site
          }
        }
      })
    }
  }
}

const employeeDetailSitesFormRef = ref<typeof EmployeeDetailSitesForm>()

const isEmployeeSitesPending = computed<boolean>(() => employeeDetailSitesFormRef.value?.isPending)

function saveEmployeeSites() {
  return employeeDetailSitesFormRef.value?.saveEmployeeSites()
}
</script>

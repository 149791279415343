<template>
  <v-btn
    variant="flat"
    density="compact"
    color="transparent"
    icon="mdi-arrow-left"
    depressed
    :to="backPath"
  >
  </v-btn>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter, type RouteLocationRaw } from 'vue-router'

interface Props {
  fallbackTo: RouteLocationRaw
}

const props = defineProps<Props>()

const backPath = computed<RouteLocationRaw>(() => {
  return (useRouter().options.history.state.back as string) ?? props.fallbackTo
})

defineExpose({ backPath: backPath.value })
</script>

import type { IPermissionGroup, IPermissionGroupData } from './permission.types'
import { AbstractModel } from '../base'

/**
 * A class representing an Auth Group
 */
export class PermissionGroup extends AbstractModel implements IPermissionGroup {
  readonly id?: number
  readonly name: string

  constructor(data: IPermissionGroupData) {
    super()

    // Validate data
    const requiredFields: (keyof IPermissionGroupData)[] = ['name']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    if (data.id) {
      this.id = data.id
    }

    this.name = data.name!
  }

  toString() {
    return this.name
  }
}

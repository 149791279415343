import { computed, ref, toRef, type MaybeRef } from 'vue'

import {
  PostOrderInstruction,
  PostOrder,
  PostOrderInstructionsField,
  PostOrderInstructionStateEnum,
  type IPostOrderInstructionsSectionData,
  type IPostOrderInstructionData,
  type IPostOrderData,
  type IPostOrderInstructionsFieldData,
  type IPostOrderInstructionsFieldOptionData,
  type IPostOrderInstructionsFieldConstraintData,
  type IPostOrderInstructionsFieldConstraintConditionData,
  type IPostOrderInstruction
} from '@/models/post-order'
import type { IPostOrderFilterParam } from '@/services/post_order'

import postOrderService from '@/services/post_order/post-order'

import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

// POST ORDER

export function useFetchPostOrders(filter?: IPostOrderFilterParam) {
  const queryKey = ref(['post-orders', filter])

  const {
    isLoading,
    isError,
    error,
    data: postOrders
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => postOrderService.fetchPostOrders(filter),

    select: (data) => {
      return data.map((template) => new PostOrder(template))
    }
  })

  return { queryKey, postOrders, error, isError, isLoading }
}

export function useFetchPostOrder(id: MaybeRef<number>) {
  const postOrderIdRef = toRef(id)

  const queryKey = ref(['post-order', postOrderIdRef])

  const {
    data: postOrder,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(postOrderIdRef.value)),
    queryFn: () => postOrderService.fetchPostOrder(postOrderIdRef.value),
    select: (data) => new PostOrder(data)
  })

  return { postOrder, isPending, error, queryKey }
}

export function useCreatePostOrder() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-post-order'],
    mutationFn: (postOrder: IPostOrderData) => postOrderService.createPostOrder(postOrder),

    onSuccess: (data) => {
      queryClient.setQueryData(['post-order', data.id], data)
      queryClient.invalidateQueries({ queryKey: ref(['post-orders']) })
      snackbarStore.showSnackbar('Post order created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdatePostOrder() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-post-order'],
    mutationFn: (postOrder: IPostOrderData) => postOrderService.updatePostOrder(postOrder),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['post-order', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['post-orders']) })
      snackbarStore.showSnackbar('Post order updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeletePostOrder() {
  const mutationKey = ref(['delete-post-order'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) => postOrderService.deletePostOrder(id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['post-orders']) })
      snackbarStore.showSnackbar('Post order deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// POST ORDER INSTRUCTIONS
export function useFetchPostOrderInstructions(postOrderId: MaybeRef<number>) {
  const postOrderIdRef = toRef(postOrderId)

  const queryKey = ref(['post-order-instructions', postOrderIdRef])

  const {
    isLoading,
    isError,
    error,
    data: instructions
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => postOrderService.fetchPostOrderInstructions(postOrderIdRef.value),

    select: (data) => {
      return data.map((instruction) => new PostOrderInstruction(instruction))
    }
  })

  return { queryKey, instructions, error, isError, isLoading }
}

export function useFetchPostOrderInstruction(
  postOrderId: MaybeRef<number>,
  id: MaybeRef<number | null>
) {
  const queryClient = useQueryClient()
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(id)

  const queryKey = ref(['post-order-instruction', postOrderInstructionIdRef])
  const {
    data: postOrderInstruction,
    isPending,
    isLoading,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(postOrderIdRef.value && postOrderInstructionIdRef.value)),
    queryFn: () =>
      postOrderService.fetchPostOrderInstruction(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value!
      ),

    initialData: () => {
      return queryClient
        .getQueryData<IPostOrderInstruction[]>(ref(['post-order-instructions', postOrderIdRef]))
        ?.find((instruction) => instruction.id === postOrderInstructionIdRef.value!)
    },
    select: (data) => new PostOrderInstruction(data)
  })

  return { postOrderInstruction, isPending, isLoading, error, queryKey }
}

export function useCreatePostOrderInstruction(postOrderId: MaybeRef<number>) {
  const postOrderIdRef = toRef(postOrderId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-post-order-instruction'],
    mutationFn: (instruction: IPostOrderInstructionData) =>
      postOrderService.createPostOrderInstruction(postOrderIdRef.value, instruction),

    onSuccess: (data) => {
      queryClient.setQueryData(['post-order-instruction', data.id], data)
      queryClient.invalidateQueries({
        queryKey: ref(['post-order-instructions', postOrderIdRef.value])
      })
      snackbarStore.showSnackbar('Post order instruction created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdatePostOrderInstruction(postOrderId: MaybeRef<number>) {
  const postOrderIdRef = toRef(postOrderId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-post-order-instruction'],
    mutationFn: (instruction: IPostOrderInstructionData) =>
      postOrderService.updatePostOrderInstruction(postOrderIdRef.value, instruction),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['post-order-instruction', data.id]) })
      queryClient.invalidateQueries({
        queryKey: ref(['post-order-instructions', postOrderIdRef])
      })
      snackbarStore.showSnackbar('Post order instruction updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeletePostOrderInstruction(postOrderId: MaybeRef<number>) {
  const postOrderIdRef = toRef(postOrderId)

  const mutationKey = ref(['delete-post-order-instruction'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) =>
      postOrderService.deletePostOrderInstruction(postOrderIdRef.value, id),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ref(['post-order-instructions', postOrderIdRef])
      })
      snackbarStore.showSnackbar('Post order instruction deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useSetPostOrderInstructionState(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['set-post-order-instruction-state'],
    mutationFn: (state: PostOrderInstructionStateEnum) =>
      postOrderService.setPostOrderInstructionState(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        state
      ),

    onSuccess: async (data) => {
      // wait for the loading of the newly update instructions before setting mutation loading to false
      await queryClient.invalidateQueries({ queryKey: ref(['post-order-instruction', data.id]) })
      queryClient.invalidateQueries({
        queryKey: ref(['post-order-instructions', postOrderIdRef])
      })
      snackbarStore.showSnackbar('Post order instruction updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Sections
export function useCreatePostOrderInstructionsSection(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-post-order-instruction-section'],
    mutationFn: (section: IPostOrderInstructionsSectionData) =>
      postOrderService.createPostOrderInstructionsSection(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        section
      ),

    onSuccess: async () => {
      // Wait for the re-validation of instruction before finishing mutation
      await queryClient.invalidateQueries({
        queryKey: ref(['post-order-instruction', postOrderInstructionIdRef])
      })
      snackbarStore.showSnackbar('Post order instruction section created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdatePostOrderInstructionsSection(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-post-order-instruction-section'],
    mutationFn: (section: IPostOrderInstructionsSectionData) =>
      postOrderService.updatePostOrderInstructionsSection(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        section
      ),

    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ref(['post-order-instructions', postOrderIdRef]) })
      // Wait for the re-validation of instruction before finishing mutation
      await queryClient.invalidateQueries({
        queryKey: ref(['post-order-instruction', postOrderInstructionIdRef])
      })
      snackbarStore.showSnackbar('Post order instruction section updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeletePostOrderInstructionsSection(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)

  const mutationKey = ref(['delete-post-order-instruction'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) =>
      postOrderService.deletePostOrderInstructionsSection(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        id
      ),

    onSuccess: async () => {
      // Wait for the re-validation of instruction before finishing mutation
      await queryClient.invalidateQueries({
        queryKey: ref(['post-order-instruction', postOrderInstructionIdRef])
      })
      snackbarStore.showSnackbar('Post order instruction section deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Fields
export function useFetchPostOrderInstructionsFields(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>
) {
  const queryClient = useQueryClient()
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)

  const queryKey = ref([
    'post-orders-instruction-section-fields',
    postOrderInstructionIdRef,
    postOrderInstructionSectionIdRef
  ])

  const {
    data: fields,
    isLoading,
    isError,
    error,
    isSuccess
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(postOrderInstructionSectionIdRef.value)),
    queryFn: () =>
      postOrderService.fetchPostOrderInstructionsFields(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value
      ),

    initialData: () => {
      // Initially load data from the fetched instruction query
      const cachedInstruction = queryClient.getQueryData<IPostOrderInstruction>(
        ref(['post-order-instruction', postOrderInstructionIdRef])
      )

      if (cachedInstruction) {
        return cachedInstruction.sections.find(
          (section) => section.id! === postOrderInstructionSectionIdRef.value
        )?.fields
      }
    },

    select: (data) => {
      return data.map((field) => new PostOrderInstructionsField(field))
    }
  })
  return {
    isLoading,
    isError,
    error,
    isSuccess,
    fields,
    queryKey
  }
}

export function useFetchPostOrderInstructionsField(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>,
  fieldId: MaybeRef<number | undefined>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)
  const postOrderInstructionSectionFieldIdRef = toRef(fieldId)

  const queryKey = ref([
    'post-orders-instruction-section-field',
    postOrderInstructionIdRef,
    postOrderInstructionSectionIdRef,
    postOrderInstructionSectionFieldIdRef
  ])

  const {
    data: field,
    isPending,
    isError,
    error,
    isSuccess
  } = useQuery({
    queryKey: queryKey,

    enabled: computed(() => Boolean(postOrderInstructionSectionFieldIdRef.value)),
    queryFn: () =>
      postOrderService.fetchPostOrderInstructionsField(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        postOrderInstructionSectionFieldIdRef.value!
      ),
    select: (data) => new PostOrderInstructionsField(data)
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    field,
    queryKey
  }
}

export function useCreatePostOrderInstructionsField(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)

  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-post-order-field'],
    mutationFn: (field: IPostOrderInstructionsFieldData) =>
      postOrderService.createPostOrderInstructionsField(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        field
      ),

    onSuccess: () => {
      // Wait for the re-validation of instruction before finishing mutation
      snackbarStore.showSnackbar('Post order instruction field created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdatePostOrderInstructionsField(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)

  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['update-post-order-field'],
    mutationFn: (field: IPostOrderInstructionsFieldData) =>
      postOrderService.updatePostOrderInstructionsField(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        field
      ),

    onSuccess: () => {
      snackbarStore.showSnackbar('Post order instruction field updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useDeletePostOrderInstructionsField(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['delete-post-order-field'],
    mutationFn: (fieldId: number) =>
      postOrderService.deletePostOrderInstructionsField(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        fieldId
      ),

    onSuccess: async () => {
      // Wait for the re-validation of instruction before finishing mutation
      await queryClient.invalidateQueries({
        queryKey: ref([
          'post-orders-instruction-section-fields',
          postOrderInstructionIdRef,
          postOrderInstructionSectionIdRef
        ])
      })
      snackbarStore.showSnackbar('Post order instruction section deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

// Field Options
export function useCreatePostOrderInstructionsFieldOption(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>,
  fieldId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)
  const postOrderInstructionSectionFieldIdRef = toRef(fieldId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-post-order-field-option'],
    mutationFn: (option: IPostOrderInstructionsFieldOptionData) =>
      postOrderService.createPostOrderInstructionsFieldOption(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        postOrderInstructionSectionFieldIdRef.value,
        option
      ),

    onSuccess: async () => {
      // Wait for the re-validation of instruction before finishing mutation
      await queryClient.invalidateQueries({
        queryKey: ref([
          'post-orders-instruction-section-fields',
          postOrderInstructionIdRef,
          postOrderInstructionSectionIdRef
        ])
      })
      snackbarStore.showSnackbar('Post order instruction section field option created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdatePostOrderInstructionsFieldOption(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>,
  fieldId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)
  const postOrderInstructionSectionFieldIdRef = toRef(fieldId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['update-post-order-field-option'],
    mutationFn: (option: IPostOrderInstructionsFieldOptionData) =>
      postOrderService.updatePostOrderInstructionsFieldOption(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        postOrderInstructionSectionFieldIdRef.value,
        option
      ),

    onSuccess: async () => {
      // Wait for the re-validation of instruction before finishing mutation
      await queryClient.invalidateQueries({
        queryKey: ref([
          'post-orders-instruction-section-fields',
          postOrderInstructionIdRef,
          postOrderInstructionSectionIdRef
        ])
      })
      snackbarStore.showSnackbar('Post order instruction section field option updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useDeletePostOrderInstructionsFieldOption(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['delete-post-order-field-option'],
    mutationFn: ({ fieldId, optionId }: { fieldId: number; optionId: number }) =>
      postOrderService.deletePostOrderInstructionsFieldOption(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        fieldId,
        optionId
      ),

    onSuccess: async () => {
      // Wait for the re-validation of instruction before finishing mutation
      await queryClient.invalidateQueries({
        queryKey: ref([
          'post-orders-instruction-section-fields',
          postOrderInstructionIdRef,
          postOrderInstructionSectionIdRef
        ])
      })
      snackbarStore.showSnackbar(
        'Post order instruction section field option deleted successfully',
        ''
      )
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

// Field Constraints
export function useCreatePostOrderInstructionsFieldConstraint(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)

  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-post-order-field-constraint'],
    mutationFn: ({
      fieldId,
      constraint
    }: {
      fieldId: number
      constraint: IPostOrderInstructionsFieldConstraintData
    }) =>
      postOrderService.createPostOrderInstructionsFieldConstraint(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        fieldId,
        constraint
      ),

    onError: () => {
      snackbarStore.showSnackbar(
        'Post order instruction section field constraint failed to create',
        ''
      )
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdatePostOrderInstructionsFieldConstraint(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>,
  fieldId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)
  const postOrderInstructionSectionFieldIdRef = toRef(fieldId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['update-post-order-field-constraint'],
    mutationFn: (constraint: IPostOrderInstructionsFieldConstraintData) =>
      postOrderService.updatePostOrderInstructionsFieldConstraint(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        postOrderInstructionSectionFieldIdRef.value,
        constraint
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ref(['post-order-instruction', postOrderInstructionIdRef])
      })
      snackbarStore.showSnackbar(
        'Post order instruction section field constraint updated successfully'
      )
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useDeletePostOrderInstructionsFieldConstraint(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>,
  fieldId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)
  const postOrderInstructionSectionFieldIdRef = toRef(fieldId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['delete-post-order-field-constraint'],
    mutationFn: (constraintId: number) =>
      postOrderService.deletePostOrderInstructionsFieldConstraint(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        postOrderInstructionSectionFieldIdRef.value,
        constraintId
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ref(['post-order-instruction', postOrderInstructionIdRef])
      })
      snackbarStore.showSnackbar(
        'Post order instruction section field constraint created successfully'
      )
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useCreatePostOrderInstructionsFieldConstraintCondition(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)

  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-post-order-field-constraint'],
    mutationFn: ({
      fieldId,
      constraintId,
      condition
    }: {
      fieldId: number
      constraintId: number
      condition: IPostOrderInstructionsFieldConstraintConditionData
    }) =>
      postOrderService.createPostOrderInstructionsFieldConstraintCondition(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        fieldId,
        constraintId,
        condition
      ),

    onError: () => {
      snackbarStore.showSnackbar(
        'Post order instruction section field constraint condition failed to create',
        ''
      )
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}
export function useUpdatePostOrderInstructionsFieldConstraintCondition(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>,
  fieldId: MaybeRef<number>,
  constraintId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)
  const postOrderInstructionSectionFieldIdRef = toRef(fieldId)
  const postOrderInstructionSectionFieldConstraintIdRef = toRef(constraintId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['update-post-order-field-constraint'],
    mutationFn: (condition: IPostOrderInstructionsFieldConstraintConditionData) =>
      postOrderService.updatePostOrderInstructionsFieldConstraintCondition(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        postOrderInstructionSectionFieldIdRef.value,
        postOrderInstructionSectionFieldConstraintIdRef.value,
        condition
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ref(['post-order-instruction', postOrderInstructionIdRef])
      })
      snackbarStore.showSnackbar(
        'Post order instruction section field constraint condition created successfully'
      )
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useDeletePostOrderInstructionsFieldConstraintCondition(
  postOrderId: MaybeRef<number>,
  instructionId: MaybeRef<number>,
  sectionId: MaybeRef<number>,
  fieldId: MaybeRef<number>,
  constraintId: MaybeRef<number>
) {
  const postOrderIdRef = toRef(postOrderId)
  const postOrderInstructionIdRef = toRef(instructionId)
  const postOrderInstructionSectionIdRef = toRef(sectionId)
  const postOrderInstructionSectionFieldIdRef = toRef(fieldId)
  const postOrderInstructionSectionFieldConstraintIdRef = toRef(constraintId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['delete-post-order-field-constraint'],
    mutationFn: (conditionId: number) =>
      postOrderService.deletePostOrderInstructionsFieldConstraintCondition(
        postOrderIdRef.value,
        postOrderInstructionIdRef.value,
        postOrderInstructionSectionIdRef.value,
        postOrderInstructionSectionFieldIdRef.value,
        postOrderInstructionSectionFieldConstraintIdRef.value,
        conditionId
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ref(['post-order-instruction', postOrderInstructionIdRef])
      })
      snackbarStore.showSnackbar(
        'Post order instruction section field constraint condition deleted successfully',
        ''
      )
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

<template>
  <div>
    <BasePage :title="`${route.meta.title}`" :subtitle="`Manage ${route.meta.title}`">
      <template #page-header:actions>
        <v-btn
          color="primary"
          depressed
          @click="open"
          v-if="currentUser?.hasPermission('cctv_reports.add_cctvreport')"
        >
          <v-icon left class="hidden-sm-and-down mr-1"> {{ route.meta.icon }} </v-icon>
          Create Report
        </v-btn>
      </template>

      <template #page-header:bottom>
        <ErrorAlert
          dense
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors"
          class="mb-0 mt-4 rounded"
        />
      </template>

      <v-card flat class="px-4 rounded-lg rounded-b-0">
        <v-toolbar flat height="84" color="transparent">
          <slot name="search"></slot>
        </v-toolbar>
      </v-card>
      <v-card flat height="calc(100vh - 370px)" class="px-4 rounded-lg rounded-t-0">
        <slot name="content"></slot>
      </v-card>
    </BasePage>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

import BasePage from '@/components/base/BasePage.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'

const route = useRoute()
const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
const emit = defineEmits(['openDialog'])

const error = defineModel<Error | null>('error', {
  default: null
})
const clearErrors = () => {
  error.value = null
}

const open = () => {
  emit('openDialog')
}
</script>

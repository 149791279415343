import { type VueQueryPluginOptions } from '@tanstack/vue-query'

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
        retry: false
      },
      mutations: {
        retry: false
      }
    }
  }
}

export { vueQueryPluginOptions }

<template>
  <img ref="activatorRef" :src="src" :alt="alt" :style="styles" />

  <v-dialog
    :activator="activatorRef"
    fullscreen
    v-model="isActive"
    scrim="rgba(var(--v-theme-on-surface), 1)"
    class="image-viewer ma-10"
  >
    <v-hover v-model="isImageOptionsActive">
      <template v-slot:default="{ isHovering, props }">
        <v-card
          @click.stop="isImageOptionsActive = true"
          :ripple="false"
          v-bind="props"
          theme="dark"
          color="surface-lighten-1"
          variant="elevated"
          class="border rounded-lg"
        >
          <v-toolbar
            absolute
            density="comfortable"
            style="top: 0px"
            :style="imageViewHeaderStyles(isHovering)"
          >
            <v-spacer />
            <v-btn
              variant="tonal"
              class="rounded mr-4"
              size="32"
              @click.stop="closeImageViewer()"
              icon
              density="comfortable"
            >
              <v-icon size="18" icon="mdi-close" />
            </v-btn>
          </v-toolbar>
          <v-img class="rounded-lg rounded-t-0" contain :src="src" :alt="alt" />
        </v-card>
      </template>
    </v-hover>
  </v-dialog>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'

interface Props {
  src: string
  height?: string | number
  width?: string | number
  alt?: string
}
const props = withDefaults(defineProps<Props>(), {
  height: 'auto',
  width: 'auto'
})

const styles = computed(() => {
  return {
    width: parseMeasurementToCSSUnit(props.width),
    height: parseMeasurementToCSSUnit(props.height),
    cursor: 'zoom-in'
  }
})

const activatorRef = ref<HTMLImageElement>()

const isActive = ref(false)

// im case of mobile devices clicking will activate the options display
const isImageOptionsActive = ref(false)

function imageViewHeaderStyles(hovering: boolean | null = null): Record<string, string | number> {
  return {
    transform: `translateY(${hovering ? '0px' : '-56px'})`,
    zIndex: 5,
    background: 'linear-gradient(rgba(var(--v-theme-surface), 1), rgba(var(--v-theme-surface), 0))'
  }
}

function closeImageViewer() {
  isActive.value = false
}

function parseMeasurementToCSSUnit(value: string | number) {
  let cssValue = value
  let parsedValue: number = +value // + is short hand for ''.valueOf()

  if (!isNaN(parsedValue)) {
    cssValue = `${parsedValue}px`
  }

  return cssValue
}
</script>

<style lang="scss">
.image-viewer {
  .v-overlay__scrim {
    opacity: 0.55 !important;
  }
  .v-overlay__content {
    border-radius: 8px;
  }
}
</style>

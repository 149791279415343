import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import type { ICameraFilterParam } from '@/services'
import { useCameraStore } from '@/stores/camera'
import { useSnackbarStore } from '@/stores/snackbar'
import type { ISystemError } from '@/models/error'

export function useFetchCameras() {
  const cameraStore = useCameraStore()

  const { getCameras: cameras, count: numCameras } = storeToRefs(cameraStore)

  const loading = ref(false)

  const error = ref<ISystemError | null>(null)

  const clearErrors = () => {
    error.value = null
  }

  function fetchCameras(params?: ICameraFilterParam) {
    clearErrors()

    loading.value = true
    return cameraStore
      .fetchCameras(params)
      .catch((e: any) => {
        error.value = e
        // set error and throw error
        throw e
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    // only returning current immutable state of loading
    loading: computed(() => loading.value),
    cameras,
    numCameras,
    error,
    // functions
    clearErrors,
    fetchCameras
  }
}

export function useDeleteCameras() {
  const cameraStore = useCameraStore()
  const snackbarStore = useSnackbarStore()

  const loading = ref(false)

  const error = ref<ISystemError | null>(null)

  const clearErrors = () => {
    error.value = null
  }

  function deleteCamera(cameraId: number) {
    clearErrors()

    loading.value = true
    return cameraStore
      .deleteCamera(cameraId)
      .then(() => {
        snackbarStore.showSnackbar('Camera deleted successfully')
      })
      .catch((e: any) => {
        error.value = e
        // set error and throw error
        throw e
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    // only returning current immutable state of loading
    loading: computed(() => loading.value),
    error,
    // functions
    clearErrors,
    deleteCamera
  }
}

import type { AxiosInstance } from 'axios'

import type { IFileLoaderService } from './file-loader.types'
import axios from '@/axios'

export class FileLoaderService implements IFileLoaderService {
  constructor(private axios: AxiosInstance) {}

  downloadFile(path: string): Promise<Blob> {
    return this.axios.get<Blob>(path, { responseType: 'blob' }).then(({ data }) => data)
  }
}

// Default export instantiation of the FileLoaderService
const fileLoaderService = new FileLoaderService(axios)
export default fileLoaderService

/**
 * Updates all the old filter with keys from the new filter while only maintaining the keys from the old filter
 * @param oldFilter
 * @param newFilter
 */
export function updateFilters<T>(oldFilter: Partial<T>, newFilter: Partial<T>) {
  for (const filter in oldFilter) {
    const filterKey = filter as keyof T

    const updatedValue = newFilter[filterKey]
    // filter fields that are not null and that match the filter object
    if (updatedValue !== null) {
      oldFilter[filterKey] = updatedValue as any
    } else {
      // reset other fields that are not set
      oldFilter[filterKey] = undefined
    }
  }
}

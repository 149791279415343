<template>
  <v-app-bar
    id="app-bar"
    theme="dark"
    app
    flat
    short
    :border="true"
    color="secondary"
    class="px-0 px-sm-2"
  >
    <template #prepend>
      <v-app-bar-nav-icon
        v-if="isLayoutVertical"
        class="me-2"
        @click="appStore.toggleDrawer()"
      ></v-app-bar-nav-icon>
      <v-responsive class="d-flex align-center me-4 me-md-8">
        <v-app-bar-title>
          <router-link :to="{ name: 'dashboard' }">
            <v-img max-height="56" :src="fullLogo" width="235" />
          </router-link>
        </v-app-bar-title>
      </v-responsive>
    </template>

    <v-spacer />

    <v-btn id="profile-avatar" height="40" variant="text">
      <v-avatar size="32" color="inverse-surface">
        <span class="text-primary"> {{ user!.getInitials() }} </span>
      </v-avatar>
      <span class="ml-2 text-none">{{ user!.first_name }} </span>
      <v-icon
        class="ml-2"
        size="14"
        end
        :icon="isProfileMenuActive ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      />
    </v-btn>
    <UserProfileMenu theme="light" v-model="isProfileMenuActive" activator="#profile-avatar">
    </UserProfileMenu>
  </v-app-bar>
  <v-divider dark class="mx-3" />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useDisplay } from 'vuetify'
import { useAppStore, useAuthStore } from '@/stores'
import UserProfileMenu from './UserProfileMenu.vue'

// images
import fullLogo from '@/assets/full-logo.png'

const authStore = useAuthStore()
const appStore = useAppStore()

const user = authStore.getUser

const isProfileMenuActive = ref(false)

const { xs } = useDisplay()
const isLayoutVertical = computed(() => xs.value)
</script>

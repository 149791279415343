import type { ICCTVReportActivityData } from '@/models/report'
import type { ICCTVActivityService } from './cctv-activity.types'
import type { AxiosInstance } from 'axios'
import axios from '@/axios'

/**
 * A class representing a CCTVActivityService API handler.
 */
export class CCTVActivityService implements ICCTVActivityService {
  private endpoint: string

  // CCTVActivityService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/cctv/'
  }

  // Method to fetch a list of reports from the API
  fetchCCTVActivities(reportId: number): Promise<ICCTVReportActivityData[]> {
    return this.axios.get(`${this.endpoint}${reportId}/activities/`).then(({ data }) => data)
  }

  // Method to fetch a single report by their ID from the API
  fetchCCTVActivity(reportId: number, id: number): Promise<ICCTVReportActivityData> {
    return this.axios.get(`${this.endpoint}${reportId}/activities/${id}/`).then(({ data }) => data)
  }

  // Method to create a new report on the API
  createCCTVActivity(
    reportId: number,
    data?: ICCTVReportActivityData
  ): Promise<ICCTVReportActivityData> {
    return this.axios.post(`${this.endpoint}${reportId}/activities/`, data).then(({ data }) => data)
  }

  // Method to update an existing report on the API
  updateCCTVActivity(
    reportId: number,
    data: ICCTVReportActivityData
  ): Promise<ICCTVReportActivityData> {
    return this.axios
      .patch(`${this.endpoint}${reportId}/activities/${data.id}/`, data)
      .then(({ data }) => data)
  }

  // Method to delete a report by their ID from the API
  deleteCCTVActivity(reportId: number, id: number): Promise<void> {
    return this.axios
      .delete(`${this.endpoint}${reportId}/activities/${id}/`)
      .then(({ data }) => data)
  }
}

// Default export instantiation of the CCTVActivityService
const activityReportService = new CCTVActivityService(axios)
export default activityReportService

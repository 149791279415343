<template>
  <MoreOptionsMenuButton
    v-if="
      currentUser?.hasPermission('incident_reports.change_incidentreport') ||
      currentUser?.hasPermission('incident_reports.delete_incidentreport') ||
      canDownloadReport
    "
  >
    <template #button="props">
      <v-btn
        v-bind="props"
        :loading="loading"
        :disabled="disabled"
        class="text-capitalize"
        append-icon="mdi-menu-down"
        variant="outlined"
      >
        More Options
      </v-btn>
    </template>
    <v-list-item
      v-if="canDownloadReport"
      title="Download Report"
      @click="emit('download-report-pressed')"
    >
    </v-list-item>
    <v-list-item
      v-if="currentUser!.hasPermission('incident_reports.change_incidentreport')"
      title="Edit Report"
      @click="emit('edit-report-pressed')"
    >
    </v-list-item>
    <v-list-item
      v-if="currentUser!.hasPermission('incident_reports.delete_incidentreport')"
      title="Delete Report"
      @click="emit('delete-report-pressed')"
    >
    </v-list-item>
    <v-list-item title="View Audit Logs" @click="emit('view-report-logs-pressed')"> </v-list-item>
  </MoreOptionsMenuButton>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'

import { ReportStateEnum } from '@/models/report'
import { useAuthStore } from '@/stores'

import MoreOptionsMenuButton from '@/components/common/MoreOptionsMenuButton.vue'

interface Props {
  reportState: ReportStateEnum
  loading?: boolean
  disabled?: boolean
}
const props = defineProps<Props>()

interface Emit {
  (e: 'download-report-pressed'): void
  (e: 'edit-report-pressed'): void
  (e: 'delete-report-pressed'): void
  (e: 'view-report-logs-pressed'): void
}
const emit = defineEmits<Emit>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const canDownloadReport = computed(
  () =>
    props.reportState == ReportStateEnum.Submitted &&
    currentUser.value?.hasPermission('incident_reports.download_incidentreport')
)
</script>

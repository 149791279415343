import { ref, toRef, type MaybeRef } from 'vue'

import {
  GuardReport,
  type IGuardReportActivityObservationData,
  type IGuardReportData,
  type IReportActivityData
} from '@/models/report'
import guardReportService from '@/services/guard_report/guard-report'
import guardReportActivityReportService from '@/services/guard_report/guard-report-activity'
import guardReportActivityObservationReportService from '@/services/guard_report/guard-report-activity-observation'

import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import type { IReportFilterParam } from '@/services'
import { useSnackbarStore } from '@/stores'

export function useFetchGuardReports(filter?: IReportFilterParam) {
  const count = ref<number>(0)

  const queryKey = ref(['guard-reports', filter])

  const {
    data: reports,
    isFetching,
    error
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => guardReportService.fetchGuardReports(filter),
    select: (data) => {
      count.value = data.count
      return data.results.map((report) => new GuardReport(report))
    }
  })

  return { queryKey, reports, count, isFetching, error }
}

export function useFetchGuardReport(id: number) {
  const queryKey = ref(['guard-report', id])

  const {
    data: report,
    isLoading,
    error
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => guardReportService.fetchGuardReport(id),
    select: (data) => new GuardReport(data)
  })

  return { report, isLoading, error, queryKey }
}

// TODO - LH  - 2024-01-31 - Check why inferred typing of useMutation cannot be found
export function useDeleteGuardReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-guard-report'],
    mutationFn: (reportId: number) => guardReportService.deleteGuardReport(reportId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['guard-reports']) })
      snackbarStore.showSnackbar('Guard Report deleted successfully', '')
    }
  })
  return {
    deleteInProgress: isPending,
    isDeleteError: isError,
    deleteError: error,
    isDeleteSuccess: isSuccess,
    deleteGuardReport: mutate,
    clearDeleteErrors: reset
  }
}

export function useCreateGuardReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['create-guard-report'],
    mutationFn: (report: IGuardReportData) => guardReportService.createGuardReport(report),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['guard-report', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['guard-reports']) })
      snackbarStore.showSnackbar('Guard Report created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useUpdateGuardReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-guard-report'],
    mutationFn: (report: IGuardReportData) => guardReportService.updateGuardReport(report),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['guard-report', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['guard-reports']) })
      snackbarStore.showSnackbar('Guard Report updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Activities
export function useCreateGuardReportActivity(reportId: number) {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['create-guard-report-activity'],
    mutationFn: () => guardReportActivityReportService.createGuardReportActivity(reportId),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useUpdateGuardReportActivity(reportId: number) {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-guard-report-activity'],
    mutationFn: (activity: IReportActivityData) =>
      guardReportActivityReportService.updateGuardReportActivity(reportId, activity),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteGuardReportActivity(reportId: number) {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-guard-report-activity'],
    mutationFn: (activityId: number) =>
      guardReportActivityReportService.deleteGuardReportActivity(reportId, activityId),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Activity Observations

export function useCreateGuardReportActivityObservation(
  reportId: number,
  activityId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['create-guard-report-activity-observation', activityIdRef.value],
    mutationFn: (data: IGuardReportActivityObservationData) =>
      guardReportActivityObservationReportService.createGuardReportActivityObservation(
        reportId,
        activityIdRef.value,
        data
      ),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useUpdateGuardReportActivityObservation(
  reportId: number,
  activityId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['update-guard-report-activity-observation', activityIdRef.value],
    mutationFn: (observation: IGuardReportActivityObservationData) =>
      guardReportActivityObservationReportService.updateGuardReportActivityObservation(
        reportId,
        activityIdRef.value,
        observation
      ),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useDeleteGuardReportActivityObservation(
  reportId: number,
  activityId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-guard-report-activity-observation', activityIdRef.value],
    mutationFn: (observationId: number) =>
      guardReportActivityObservationReportService.deleteGuardReportActivityObservation(
        reportId,
        activityIdRef.value,
        observationId
      ),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation deleted successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

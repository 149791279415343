<template>
  <v-data-table-server
    v-model:page="page"
    :items-per-page="25"
    :items-per-page-options="[{ value: 25, title: '25' }]"
    :items-length="count"
    no-filter
    hover
    item-value="id"
    :headers="clientDataTableHeaders"
    :items="clients"
    :loading="loading"
    color="primary"
    sticky
    fixed-header
    density="comfortable"
    height="calc(100% - 37px)"
    style="height: calc(100% - 93px); width: 100%"
    :row-props="generateRowProps"
  >
    <template #[`item.index`]="{ index }">
      {{ index + 1 }}
    </template>

    <template #[`item.name`]="{ item }">
      <span class="font-weight-medium text-link" @click="emit('client-selected', item)">
        {{ item.name }}
      </span>
    </template>

    <template #[`item.actions`]="{ item }">
      <ClientDetailOptionsButton
        :client="item"
        @client-edit-pressed="(client: IClient) => emit('client-edit-pressed', client)"
        @client-delete-pressed="(client: IClient) => emit('client-delete-pressed', client)"
      />
    </template>
    <template v-slot:no-data>
      <NoDataAvailablePlaceholder
        width="400"
        header="No clients available"
        header-class="font-weight-medium text-h5"
      >
        <template #description>
          <p class="mt-0 mb-6 text-caption text-medium-emphasis">
            <span v-if="currentUser?.hasPermission('clients.add_client')">
              <template v-if="search">
                Oops! No clients match your current search criteria. Try adjusting your filters for
                better results.
              </template>
              <template v-else>
                It looks like there are currently no clients in the client list. Get started by
                adding new clients here to build your client list.</template
              >
            </span>
            <span v-else
              >You do not have the permission to create a client. Client your administrator for more
              details
            </span>
          </p>
        </template>

        <template #primaryAction>
          <v-btn
            color="primary"
            depressed
            v-if="currentUser?.hasPermission('clients.add_client')"
            @click="emit('client-create-pressed')"
          >
            <template #prepend>
              <v-icon left class="hidden-sm-and-down"> {{ route.meta.icon }} </v-icon>
            </template>
            Create Client
          </v-btn>
        </template>
      </NoDataAvailablePlaceholder>
    </template>
  </v-data-table-server>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useDate } from 'vuetify'
import { useRoute } from 'vue-router'

import type DateFnsUtils from '@date-io/date-fns/build/date-fns-utils'

import type { Client, IClient } from '@/models/client'
import { useAuthStore } from '@/stores'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import ClientDetailOptionsButton from './ClientDetailOptionsButton.vue'

interface Props {
  clients: IClient[]
  count: number
  loading: boolean
  search?: string
}

interface Emit {
  (e: 'client-selected', client: IClient): void
  (e: 'client-edit-pressed', client: IClient): void
  (e: 'client-create-pressed'): void
  (e: 'client-delete-pressed', client: IClient): void
}

const page = defineModel<number>('page')

const emit = defineEmits<Emit>()
defineProps<Props>()

const dateAdapter = useDate() as DateFnsUtils

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const route = useRoute()

const clientDataTableHeaders = ref<any[]>([
  {
    title: '#',
    align: 'start',
    key: 'index',
    width: 20,
    sortable: false
  },
  {
    title: 'Name',
    align: 'start',
    key: 'name',
    sortable: false,
    cellProps: {
      class: 'flex-grow-1'
    },
    minWidth: '300'
  },
  {
    title: 'Created',
    key: 'created',
    align: 'start',
    value: (client: Client) => dateAdapter.formatByString(client.created!, 'MMMM d, yyyy h:mma'),
    sortable: false
  },

  {
    title: 'Modified',
    key: 'modified',
    align: 'start',
    value: (client: Client) => dateAdapter.formatByString(client.modified!, 'MMMM d, yyyy h:mma'),
    sortable: false
  },

  { title: '', key: 'actions', align: 'center', sortable: false, width: '150' }
])

function generateRowProps(props: { index: number; item: IClient }) {
  return {
    // apply click listener to row props to open detail drawer
    onClick: () => emit('client-selected', props.item)
  }
}
</script>

import type { IGuardReportActivityObservationData } from '@/models/report'
import type { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'

import axios, { isAxios401Error } from '@/axios'
import { objectToFormData } from '@/utils/helpers'

import type { IGuardReportActivityObservationService } from './guard-report-activity-observation.types'
/**
 * A class representing a GuardReportActivityService API handler.
 */
export class GuardReportActivityObservationService
  implements IGuardReportActivityObservationService
{
  private endpoint: string

  // GuardReportActivityService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/guards/'
  }

  // Method to fetch a list of reports from the API
  fetchGuardReportActivityObservations(
    reportId: number,
    activityId: number
  ): Promise<IGuardReportActivityObservationData[]> {
    return this.axios
      .get(`${this.endpoint}${reportId}/activities/${activityId}/observations/`)
      .then(({ data }) => data)
  }

  // Method to fetch a single report by their ID from the API
  fetchGuardReportActivityObservation(
    reportId: number,
    activityId: number,
    id: number
  ): Promise<IGuardReportActivityObservationData> {
    return this.axios
      .get(`${this.endpoint}${reportId}/activities/${activityId}/observations/${id}/`)
      .then(({ data }) => data)
  }

  // Method to create a new report on the API
  createGuardReportActivityObservation(
    reportId: number,
    activityId: number,
    data: IGuardReportActivityObservationData
  ): Promise<IGuardReportActivityObservationData> {
    const formData = objectToFormData(data)
    return this.axios
      .post(`${this.endpoint}${reportId}/activities/${activityId}/observations/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        // LF-2023-01-13: Hack to ensure file is set on token refresh (if required).
        // Required if 401 occurred while handling multipart/form-data
        'axios-retry': {
          retries: 1,
          retryCondition: isAxios401Error || axiosRetry.isSafeRequestError
        }
      })
      .then(({ data }) => data)
  }

  // Method to update an existing report on the API
  updateGuardReportActivityObservation(
    reportId: number,
    activityId: number,
    data: IGuardReportActivityObservationData
  ): Promise<IGuardReportActivityObservationData> {
    const formData = objectToFormData(data)
    return this.axios
      .patch(
        `${this.endpoint}${reportId}/activities/${activityId}/observations/${data.id}/`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          // LF-2023-01-13: Hack to ensure file is set on token refresh (if required).
          // Required if 401 occurred while handling multipart/form-data
          'axios-retry': {
            retries: 1,
            retryCondition: isAxios401Error || axiosRetry.isSafeRequestError
          }
        }
      )
      .then(({ data }) => data)
  }

  // Method to delete a report by their ID from the API
  deleteGuardReportActivityObservation(
    reportId: number,
    activityId: number,
    id: number
  ): Promise<void> {
    return this.axios
      .delete(`${this.endpoint}${reportId}/activities/${activityId}/observations/${id}/`)
      .then(({ data }) => data)
  }
}

// Default export instantiation of the GuardReportActivityObservationService
const activityGuardReportService = new GuardReportActivityObservationService(axios)
export default activityGuardReportService

<template>
  <v-list-subheader
    class="site-subheader d-inline-flex align-center font-weight-medium mb-2 px-6"
    v-if="currentUser?.hasPermission('clients.view_site')"
  >
    <div class="text-subtitle-2 d-inline-flex align-center justify-start">
      <div class="me-2 align-self-center text-high-emphasis">Sites</div>

      <v-card
        variant="tonal"
        width="24"
        height="24"
        style="display: inline-grid"
        class="align-center justify-center text-caption font-weight-bold"
      >
        <span v-if="!loading">
          {{ sites.length }}
        </span>
        <span v-else> 0 </span>
      </v-card>
    </div>
    <div class="ml-auto">
      <v-fade-transition mode="out-in">
        <v-progress-circular v-if="loading" indeterminate color="primary" width="2" size="20" />
        <div v-else-if="actionable && currentUser.hasPermission('clients.add_site')">
          <v-tooltip location="bottom" open-delay="450">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="action-btn rounded"
                variant="text"
                color="primary"
                size="32"
                icon
                density="comfortable"
                @click="openSiteFormDialog()"
              >
                <v-icon size="18" icon="mdi-plus" />
              </v-btn>
            </template>
            Add Site
          </v-tooltip>
        </div>
      </v-fade-transition>
    </div>
  </v-list-subheader>
  <v-expand-transition>
    <div v-show="!loading">
      <div class="d-flex flex-column px-6">
        <template v-if="sites.length > 0">
          <v-list class="site-list px-0 rounded-lg mb-6" variant="flat" nav>
            <v-item-group class="d-flex flex-column">
              <template v-for="(site, siteIndex) in sites" :key="siteIndex">
                <v-item v-slot="{ isSelected, select }">
                  <SiteListItem
                    :actionable="actionable"
                    :cameras="siteCameras(site.code!)"
                    :cameras-loading="camerasLoading"
                    :client-id="client.id!"
                    :site="site"
                    :is-selected="isSelected"
                    @site-selected="select"
                    @site-edit-pressed="openSiteFormDialog"
                    @site-create-pressed="openSiteFormDialog()"
                  />
                </v-item>
              </template>
            </v-item-group>
          </v-list>
        </template>
        <template v-else>
          <NoDataAvailablePlaceholder width="300" header="Add a site here" class="mt-0 mb-0">
            <template #description>
              <p class="mt-0 mb-6 text-caption text-medium-emphasis">
                <span v-if="currentUser?.hasPermission('clients.add_site')">
                  Looks like you haven't added any sites yet. Get started by creating a new site or
                  explore our features to enhance your client experience!
                </span>
                <span v-else>
                  It seems you currently don't have the necessary permissions to add a new site. To
                  request access or for further assistance, please contact your system
                  administrator.
                </span>
              </p>
            </template>

            <template
              #primaryAction
              v-if="actionable && currentUser?.hasPermission('clients.add_site')"
            >
              <v-btn @click="openSiteFormDialog()">Add Site</v-btn>
            </template>
          </NoDataAvailablePlaceholder>
        </template>

        <SiteFormDialog
          v-model:dialog="isSiteFormDialogActive"
          :site="selectedSite"
          :isEdit="!!selectedSite"
          :client-id="client.id!"
        />
      </div>
    </div>
  </v-expand-transition>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores'

import type { IClient, ISite } from '@/models/client'
import type { ICamera } from '@/models/camera'

import SiteFormDialog from './SiteFormDialog.vue'
import SiteListItem from './SiteListItem.vue'
import NoDataAvailablePlaceholder from '../common/NoDataAvailablePlaceholder.vue'

interface Props {
  client: IClient
  sites: ISite[]
  // camera
  cameras?: ICamera[]
  camerasLoading?: boolean

  actionable?: boolean
  loading: boolean
}

const props = withDefaults(defineProps<Props>(), {
  actionable: true
})

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const siteCameras = (site: number) => {
  return props.cameras?.filter((camera) => (camera.site as ISite).code === site)
}

const isSiteFormDialogActive = ref(false)
const selectedSite = ref<ISite>()

function openSiteFormDialog(site?: ISite) {
  selectedSite.value = site

  isSiteFormDialogActive.value = true
}
</script>

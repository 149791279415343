<template>
  <ReportActivityTimeFormDialog
    v-model:dialog="dialog"
    :reportId="reportId"
    :activity="activity"
    :isEdit="isEdit"
    :error="error"
    @clearErrors="reset()"
    ref="reportActivityTimeFormDialog"
  >
    <template #actions="data">
      <v-spacer></v-spacer>
      <v-btn color="primary" variant="flat" :loading="isPending" @click="save(data)">Save</v-btn>
    </template>
  </ReportActivityTimeFormDialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'

import type { IReportActivityData } from '@/models/report'

import ReportActivityTimeFormDialog from '../common/ReportActivityTimeFormDialog.vue'
import { useFetchCCTVReport, useUpdateCCTVReportActivity } from '@/composables/cctv-report'

interface Props {
  reportId: number
  activity: IReportActivityData
  isEdit: boolean
}
const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog')

const queryClient = useQueryClient()

const reportActivityTimeFormDialog = ref<typeof ReportActivityTimeFormDialog>()

const { queryKey } = useFetchCCTVReport(props.reportId)

const { isPending, error, mutate, reset } = useUpdateCCTVReportActivity(props.reportId)

async function save(activity: IReportActivityData) {
  reset()

  const { valid } = await reportActivityTimeFormDialog.value!.validate()

  if (valid) {
    mutate(activity, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey })
        dialog.value = false
      }
    })
  }
}
</script>

import type { ICCTVReportData, ICCTVReportStatisticsData } from '@/models/report'
import type {
  ICCTVReportService,
  ICCTVReportPaginatedListData,
  ICCTVReportFilterParam
} from './cctv-report.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'

/**
 * A class representing a CCTVReportService API handler.
 */
export class CCTVReportService implements ICCTVReportService {
  private endpoint: string

  // CCTVReportService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/cctv/'
  }

  // Method to fetch a list of reports from the API
  fetchCCTVReports(filter?: ICCTVReportFilterParam): Promise<ICCTVReportPaginatedListData> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined
    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single report by their ID from the API
  fetchCCTVReport(id: number): Promise<ICCTVReportData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new report on the API
  createCCTVReport(data: ICCTVReportData): Promise<ICCTVReportData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing report on the API
  updateCCTVReport(data: ICCTVReportData): Promise<ICCTVReportData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a report by their ID from the API
  deleteCCTVReport(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // stats
  fetchCCTVReportStatistics(): Promise<ICCTVReportStatisticsData> {
    return this.axios.get(`${this.endpoint}statistics/`).then(({ data }) => data)
  }

  //exports
  exportCCTVReportPdf(id: number): Promise<Blob> {
    return this.axios
      .get(`${this.endpoint}${id}/export-pdf/`, {
        responseType: 'blob',
        timeout: 30 * 1000 // 30 seconds
      })
      .then(({ data }) => data)
  }

  //transitions
  submitCCTVReport(id: number): Promise<ICCTVReportData> {
    return this.axios.put(`${this.endpoint}${id}/submit/`).then(({ data }) => data)
  }
  approveCCTVReport(id: number): Promise<ICCTVReportData> {
    return this.axios.put(`${this.endpoint}${id}/approve/`).then(({ data }) => data)
  }

  //guards
  assignCCTVReportGuard(id: number, employeeId: number): Promise<ICCTVReportData> {
    return this.axios
      .put(`${this.endpoint}${id}/assign_guard/`, { guard: employeeId })
      .then(({ data }) => data)
  }
  unassignCCTVReportGuard(id: number, employeeId: number): Promise<ICCTVReportData> {
    return this.axios
      .put(`${this.endpoint}${id}/unassign_guard/`, { guard: employeeId })
      .then(({ data }) => data)
  }
}

// Default export instantiation of the CCTVReportService
const cctvReportService = new CCTVReportService(axios)
export default cctvReportService

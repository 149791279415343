import { defineStore } from 'pinia'

import permissionGroupService from '@/services/permission/permission'

import type { IPermissionState } from './permission.types'
import {
  PermissionGroup,
  type IPermissionGroup,
  type IPermissionGroupData
} from '@/models/permission'

// Pinia store for managing permission-related states and actions
export const usePermissionStore = defineStore('permission', {
  state: (): IPermissionState => ({
    permissionGroups: [],
    lastPromise: null
  }),

  getters: {
    // Getter to retrieve a group by ID from the store,
    getPermissionGroupById: (state: IPermissionState) => (id: number) => {
      const group = state.permissionGroups.find((group) => group.id === id)
      if (!group) {
        return undefined
      }
      return new PermissionGroup(group)
    },

    getPermissionGroups(state: IPermissionState): IPermissionGroup[] {
      return state.permissionGroups.map((group) => new PermissionGroup(group))
    }
  },

  actions: {
    // Action method to fetch list of permission groups and update the store
    async fetchPermissionGroups(): Promise<void> {
      const promise = permissionGroupService.fetchPermissionGroups()
      this.lastPromise = promise

      return promise.then((groups) => {
        if (promise == this.lastPromise) {
          this.permissionGroups = groups
          this.lastPromise = null
        }
      })
    },

    // Action method to fetch a single permission group and update the store
    async fetchPermissionGroup(id: number): Promise<void> {
      const permissionGroup = await permissionGroupService.fetchPermissionGroup(id)

      const index = this.permissionGroups.findIndex((v) => v.id === id)
      if (index === -1) {
        this.permissionGroups.push(permissionGroup)
      } else {
        this.permissionGroups[index] = permissionGroup
      }
    },

    // Action method to create a new permission group
    async createPermissionGroup(data: IPermissionGroupData): Promise<void> {
      const permissionGroup = await permissionGroupService.createPermissionGroup(data)
      this.permissionGroups.push(permissionGroup)
    },

    // Action method to update an existing permissionGroup
    async updatePermissionGroup(data: IPermissionGroupData): Promise<void> {
      const permissionGroup = await permissionGroupService.updatePermissionGroup(data)
      const index = this.permissionGroups.findIndex((v) => v.id === data.id)
      this.permissionGroups[index] = permissionGroup
    },

    // Action method to delete an existing permission group
    async deletePermissionGroup(id: number): Promise<void> {
      await permissionGroupService.deletePermissionGroup(id)

      const index = this.permissionGroups.findIndex((v) => v.id === id)
      if (index !== -1) {
        this.permissionGroups.splice(index, 1)
      }
    }
  }
})

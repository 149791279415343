<template>
  <AlDialog v-model="dialog" title="Choose an input field type:" color="background">
    <v-card color="background" variant="flat" class="mx-n4 d-flex flex-column">
      <v-list
        nav
        class="d-flex flex-row flex-wrap bg-background px-3"
        style="gap: 12px"
        lines="one"
        density="default"
      >
        <v-list-item
          v-for="(availableField, fieldIndex) in availableFieldTypesList"
          :key="fieldIndex"
          class="border field-type-card"
          :id="`available-field-${availableField.value}`"
          :title="availableField.text"
          :subtitle="availableField.subtitle"
          style="max-width: calc(50% - 12px)"
          slim
          nav
          variant="outlined"
          @click="selectFieldType(availableField.value)"
        >
          <template #prepend>
            <v-icon class="ml-n2" density="compact">mdi-drag-vertical</v-icon>
            <v-icon class="pl-2" density="compact" size="24" color="black">{{
              availableField.icon
            }}</v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-card>
  </AlDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { FormFieldTypeEnum } from '@/models/form'

import AlDialog from '@/components/common/AlDialog.vue'

const dialog = defineModel<boolean>('dialog', { default: false })

interface Emits {
  (event: 'selected-type', type: FormFieldTypeEnum): void
}

const emit = defineEmits<Emits>()

const availableFieldTypesList = computed(() => [
  {
    icon: 'mdi-format-text',
    text: 'Label',
    subtitle: 'Label text indicator',
    value: FormFieldTypeEnum.Label
  },
  {
    icon: 'mdi-form-textbox',
    text: 'Input box',
    subtitle: 'Multiple line text input',
    value: FormFieldTypeEnum.TextArea
  },
  {
    icon: 'mdi-form-dropdown',
    text: 'Dropdown',
    subtitle: 'Single option input from list',
    value: FormFieldTypeEnum.ComboBox
  },
  {
    icon: 'mdi-order-bool-ascending-variant',
    text: 'Checklist',
    subtitle: 'Multiple option input',
    value: FormFieldTypeEnum.CheckBox
  },
  {
    icon: 'mdi-image',
    text: 'Image Picker',
    subtitle: 'Single file input',
    value: FormFieldTypeEnum.FileField
  }
])

function selectFieldType(type: FormFieldTypeEnum) {
  emit('selected-type', type)

  dialog.value = false
}
</script>

<style lang="scss">
.field-type-card {
  flex-basis: 50%;
  align-items: center;
  display: flex;

  flex-grow: 0;
  flex-shrink: 0;
}
</style>

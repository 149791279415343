<template>
  <v-app-bar
    theme="dark"
    color="surface-lighten-1"
    location="top"
    density="compact"
    tag="nav"
    flat
    class="px-6"
  >
    <v-tabs
      height="40"
      v-model="selectedTab"
      theme="dark"
      slider-color="transparent"
      bg-color="surface-lighten-1"
      show-arrows
    >
      <template v-for="(route, routeIndex) in props.items">
        <v-menu
          eager
          max-width="400"
          open-delay="100"
          v-if="route.children && route.children.length > 0"
          :key="`menu_${routeIndex}`"
          location="bottom"
          offset="4"
        >
          <template v-slot:activator="{ isActive, props }">
            <v-tab
              height="32"
              hide-slider
              tag="li"
              v-bind="props"
              class="mr-3 my-1 px-3 text-none"
              active-class="bg-primary"
              selectedClass="bg-primary"
              :active="isActive"
              style="font-size: 16px"
            >
              <v-icon size="18" start :icon="route.prependIcon" />
              {{ route.title }}
              <v-icon color="white" size="14" icon="mdi-chevron-down" />
            </v-tab>
          </template>

          <template v-slot:default>
            <v-list density="comfortable">
              <HorizontalNavBarListItem
                @select:nav-item="selectedTab = routeIndex"
                v-for="(child, childIndex) in route.children"
                :key="childIndex"
                :title="child.title"
                :to="child.to"
              />
            </v-list>
          </template>
        </v-menu>
        <v-tab
          v-else
          height="32"
          :key="`tab_${routeIndex}`"
          :to="route.to"
          class="mr-3 my-1 px-3 text-none"
          hide-slider
          :text="route.title"
          active-class="bg-primary"
          selectedClass="bg-primary"
          style="font-size: 16px"
        >
          <v-icon size="18" start :icon="route.prependIcon" />
          {{ route.title }}
        </v-tab>
      </template>
    </v-tabs>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { type INavItem } from '@/router/utils'

import HorizontalNavBarListItem from './HorizontalNavBarListItem.vue'
import type { PropType } from 'vue'

const props = defineProps({
  items: {
    type: Array as PropType<INavItem[]>,
    default: () => []
  }
})

const selectedTab = ref<number>()
</script>

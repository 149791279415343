<template>
  <v-text-field v-bind="$attrs" prepend-inner-icon="mdi-magnify" ref="searchBox" v-model="search">
    <template #append-inner>
      <v-badge
        :model-value="numFiltersApplied > 0"
        bordered
        color="primary"
        :content="numFiltersApplied"
      >
        <v-icon
          v-if="$slots.filters"
          id="search-filter"
          size="24"
          icon="mdi-tune"
          style="cursor: pointer"
          :color="isSearchFilterActive ? 'primary-darken-3' : ''"
        />
      </v-badge>
    </template>
  </v-text-field>

  <v-menu
    v-model="isSearchFilterActive"
    location="bottom"
    :close-on-content-click="false"
    activator="#search-filter"
    :target="searchBox"
  >
    <AlFormCard title="Filters">
      <template #title>
        <h3 class="text-h6" tabIndex="-1" aria-label="filters">Filters</h3>
      </template>

      <template #appendAction>
        <v-btn flat density="compact" icon="mdi-close" @click="closeFilterMenu()"></v-btn>
      </template>

      <v-form ref="form" v-model="valid" validate-on="submit lazy">
        <v-card-text class="pb-3 mt-n6">
          <v-defaults-provider :defaults="inputDefaults">
            <slot name="filters"> </slot>
          </v-defaults-provider>
        </v-card-text>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="reset()" height="32" class="px-4">Reset</v-btn>
        <v-btn
          color="primary"
          variant="flat"
          @click="applyFilter()"
          :disabled="!valid"
          height="32"
          class="px-4"
          >Apply</v-btn
        >
      </template>
    </AlFormCard>
  </v-menu>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { VForm, VTextField } from 'vuetify/components'

import AlFormCard from './AlFormCard.vue'

const search = defineModel<string>()

interface Props {
  filtersApplied?: Record<string, any> | null
}

const props = withDefaults(defineProps<Props>(), {
  filtersApplied: null
})

const emit = defineEmits(['filter-applied'])

const searchBox = ref<VTextField>()
const form = ref<VForm>()

const isSearchFilterActive = ref(false)
const valid = ref(false)

// This is the default applied for every vuetify input component
const inputDefaults = ref({
  global: {
    singleLine: true,
    variant: 'underlined',
    density: 'compact',
    hideDetails: 'auto'
  }
})

async function applyFilter() {
  const { valid } = await form.value!.validate()

  if (valid) {
    closeFilterMenu()
    emit('filter-applied')
  }
}

function reset() {
  form.value!.reset()
}

function closeFilterMenu() {
  isSearchFilterActive.value = false
}

const numFiltersApplied = computed<number>(() => {
  return props.filtersApplied
    ? Object.values(props.filtersApplied)
        .filter((value) => value != null)
        .reduce((accumulator: number) => accumulator + 1, 0)
    : 0
})
</script>

<style lang="scss">
.filter-line-item {
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  min-height: 24px;

  > label {
    display: inline-block;
    font-size: 13px;
    line-height: 20px;
    width: 120px;
    margin-right: 12px;
  }

  > div.filter-line-item-value {
    flex: 1;
    overflow: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}
.filter-line-item:not(:only-child) {
  padding-bottom: 24px;
}
</style>

<template>
  <v-autocomplete
    label="Incident Report"
    v-model="incidentReport"
    :items="reports"
    v-model:search="search"
    @update:search="
      (newSearch: string) => debounceFilterGuardReports({ ...filter, page: 1, search: newSearch })
    "
    item-value="id"
    :item-title="(item?: IIncidentReport) => (item ? item.toString() : '')"
    :loading="isLoading"
    :error-messages="combinedErrorMessages"
    no-filter
    variant="outlined"
    v-bind="$attrs"
  >
    <template v-if="$slots['append-item']" v-slot:append-item>
      <slot name="append-item"></slot>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { debounce, isArray, merge } from 'lodash'
import { useQueryClient } from '@tanstack/vue-query'

import { useFetchIncidentReports } from '@/composables/incident-report'

import { type IIncidentReport } from '@/models/report'
import type { IReportFilterParam } from '@/services'

const incidentReport = defineModel<IIncidentReport | number | null>({ default: null })
const search = defineModel<string>('search', { default: '' })

interface Props {
  errorMessages: string | string[] | null
  filter?: IReportFilterParam
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  errorMessages: null,
  filter: () => ({}),
  loading: false
})

const queryClient = useQueryClient()

const combinedErrorMessages = computed(() => {
  let error: string[] = []

  if (fetchError.value) {
    error.push(fetchError.value.message)
  }
  if (isArray(props.errorMessages)) {
    error.concat(props.errorMessages)
  } else {
    if (props.errorMessages) {
      error.push(props.errorMessages)
    }
  }
  return error
})

const queryFilters = reactive<IReportFilterParam>({
  search: '',
  ...props.filter
})

const { reports, isPending, error: fetchError, queryKey } = useFetchIncidentReports(queryFilters)

const isLoading = computed(() => props.loading || isPending.value)
// debouncing
const debounceFilterGuardReports = debounce(function (filterParams: IReportFilterParam) {
  // mutates filters
  merge(queryFilters, filterParams)
  return queryClient.invalidateQueries({ queryKey: queryKey.value })
}, 200)
</script>

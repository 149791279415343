import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import type { IContactFilterParam } from '@/services'
import { useContactStore } from '@/stores/contact'
import { useSnackbarStore } from '@/stores/snackbar'
import type { ISystemError } from '@/models/error'

export function useFetchContacts() {
  const contactStore = useContactStore()

  const { getContacts: contacts, count: numContacts, getContactById } = storeToRefs(contactStore)

  const loading = ref(false)

  const error = ref<ISystemError | null>(null)

  const clearErrors = () => {
    error.value = null
  }

  function fetchContacts(params?: IContactFilterParam) {
    clearErrors()

    loading.value = true

    return contactStore
      .fetchContacts(params)
      .catch((e: any) => {
        error.value = e
        // set error and throw error
        throw e
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    // only returning current immutable state of loading
    loading: computed(() => loading.value),
    contacts,
    getContactById,
    numContacts,
    error,
    // functions
    clearErrors,
    fetchContacts
  }
}

export function useDeleteContacts() {
  const contactStore = useContactStore()
  const snackbarStore = useSnackbarStore()

  const loading = ref(false)

  const error = ref<ISystemError | null>(null)

  const clearErrors = () => {
    error.value = null
  }

  function deleteContact(contactId: number) {
    clearErrors()

    loading.value = true
    return contactStore
      .deleteContact(contactId)
      .then(() => {
        snackbarStore.showSnackbar('Contact deleted successfully')
      })
      .catch((e: any) => {
        error.value = e
        // set error and throw error
        throw e
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    // only returning current immutable state of loading
    loading: computed(() => loading.value),
    error,
    // functions
    clearErrors,
    deleteContact
  }
}

<template>
  <div class="auth-wrapper d-flex align-center justify-space-around pa-4">
    <v-col cols="11" md="5" lg="5">
      <div class="position-relative my-sm-16">
        <v-card class="auth-card px-4" rounded color="transparent">
          <v-card-item class="py-0 pb-8">
            <v-card-title>
              <v-img :src="fullLogo" max-width="700" />
            </v-card-title>
          </v-card-item>

          <v-card-text class="pt-4" style="width: fit-content">
            <h4 class="text-h4 h4-title mb-1">Connect to your Employee Portal</h4>
            <RouterView v-slot="{ Component }">
              <component :is="Component" v-model:error="error" />
            </RouterView>
          </v-card-text>
        </v-card>
      </div>

      <!-- only show alert if there's no field error -->
      <ErrorAlert
        v-if="error && Object.keys(error.fieldErrors).length == 0"
        :error="error"
        @clearErrors="clearErrors()"
        class="mb-2"
      >
      </ErrorAlert>
    </v-col>
    <v-col class="hidden-sm-and-down" cols="6">
      <v-img :src="person" style="border-radius: 10px"></v-img>
    </v-col>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import fullLogo from '@/assets/full-logo.png'
import person from '@/assets/home-tag-protection-uniformed-security-guards.jpeg'
import { type ISystemError } from '@/models/error'

const error = ref<ISystemError | null>(null)

const clearErrors = () => {
  error.value = null
}
</script>

<style lang="scss">
@use '@/styles/auth/page-auth.scss';
</style>

import { COUNTRY_CODE_MAX_LENGTH } from '.'

import { AbstractModel } from '../base'
import type { ICountry, ICountryData } from './country.types'

export class Country extends AbstractModel implements ICountry {
  readonly code: string
  readonly name: string

  /**
   * A class representing a Country
   */
  constructor(data: ICountryData) {
    super()

    // Validate data
    const requiredFields: (keyof ICountryData)[] = ['code', 'name']
    this.validate(data, requiredFields)

    if (data.code!.length != COUNTRY_CODE_MAX_LENGTH) {
      throw new Error(`Country code does not meet length of ${COUNTRY_CODE_MAX_LENGTH} characters`)
    }

    // Initialize object assuming data valid
    this.code = data.code!
    this.name = data.name!
  }

  toString() {
    return this.name
  }
}

<template>
  <MoreOptionsMenuButton
    v-bind="attrs"
    v-if="
      currentUser?.hasPermission('fleet.change_vehiclelog') ||
      currentUser?.hasPermission('fleet.delete_vehiclelog')
    "
  >
    <v-list-item
      v-if="currentUser.hasPermission('fleet.change_vehiclelog')"
      @click="emit('vehicle-log-edit-pressed', vehicleLog)"
      title="Edit Vehicle Log"
    >
      <template #prepend>
        <v-icon size="18" icon="mdi-pencil" class="me-1" />
      </template>
    </v-list-item>

    <template v-if="currentUser.hasPermission('fleet.delete_vehiclelog')">
      <v-divider class="pb-1" />
      <v-list-item
        class="text-error"
        @click="emit('vehicle-log-delete-pressed', vehicleLog)"
        title="Delete Vehicle Log"
      >
        <template #prepend>
          <v-icon size="18" icon="mdi-delete" class="me-1" />
        </template>
      </v-list-item>
    </template>
  </MoreOptionsMenuButton>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue'
import { storeToRefs } from 'pinia'

import type { IVehicleLog } from '@/models/fleet-management'

import { useAuthStore } from '@/stores'

import MoreOptionsMenuButton from '@/components/common/MoreOptionsMenuButton.vue'

interface Props {
  vehicleLog: IVehicleLog
}
defineProps<Props>()

interface Emit {
  (e: 'vehicle-log-edit-pressed', vehicleLog: IVehicleLog): void
  (e: 'vehicle-log-delete-pressed', vehicleLog: IVehicleLog): void
}

const emit = defineEmits<Emit>()

const attrs = useAttrs()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>

<template>
  <v-navigation-drawer v-model="drawer" :rail="mini" app width="250" floating color="secondary">
    <v-list
      class="tag-list"
      active-class="tag-item--active"
      nav
      density="comfortable"
      :items="props.items"
      item-props
    />

    <template v-slot:append>
      <div class="hidden-md-and-down">
        <v-divider />
        <v-list-item
          @click.stop="mini = !mini"
          :append-icon="!mini ? 'mdi-chevron-left' : undefined"
          :prepend-icon="mini ? 'mdi-chevron-right' : undefined"
        >
        </v-list-item>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import { type INavItem } from '@/router/utils'

import { useAppStore } from '@/stores'

const appStore = useAppStore()

const props = defineProps({
  items: {
    type: Array as PropType<INavItem[]>,
    default: () => []
  }
})

const drawer = computed({
  get(): boolean {
    return appStore.getDrawer
  },
  set() {
    appStore.toggleDrawer()
  }
})

const mini = computed({
  get(): boolean {
    return appStore.getMini
  },
  set() {
    appStore.toggleMini()
  }
})
</script>

<style lang="scss">
.tag-list {
  .v-list-group__items .v-list-item {
    padding-inline-start: 24px !important;
  }

  .v-list-group__header.tag-item--active {
    background: rgba(var(--v-theme-primary)) !important;

    .v-list-item__content,
    .v-list-item__prepend,
    .v-list-item__append {
      color: #ffffff !important;
    }
  }

  .v-list-group__items .tag-item--active {
    background: transparent !important;
    color: inherit !important;

    > .v-list-item__overlay {
      opacity: calc(var(--v-activated-opacity) * var(--v-theme-overlay-multiplier));
    }
  }

  .tag-item--active {
    background: rgba(var(--v-theme-primary)) !important;

    > .v-list-item__overlay {
      opacity: 0;
    }

    .v-list-item__content,
    .v-list-item__prepend,
    .v-list-item__append {
      color: #ffffff !important;
    }
  }

  .v-list-item {
    border-radius: 8px !important;
  }
}
</style>

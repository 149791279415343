import { computed, ref, toRef, type MaybeRef } from 'vue'

import type { IEmployeeFilterParam } from '@/services'
import { Employee, type IEmployeeData } from '@/models/employee'

import employeeService from '@/services/employee/employee'

import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import userService from '@/services/user/user'
import { User } from '@/models/user'

export function useFetchEmployees(filter?: IEmployeeFilterParam) {
  const queryKey = ref(['employees', filter])

  const count = ref<number>(0)

  const {
    isLoading,
    isError,
    error,
    data: employees
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => employeeService.fetchEmployees(filter),

    select: (data) => {
      count.value = data.count
      return data.results.map((employee) => new Employee(employee))
    }
  })

  return { queryKey, employees, count, error, isError, isLoading }
}

export function useFetchEmployee(id: MaybeRef<number>) {
  const employeeIdRef = toRef(id)

  const queryKey = ref(['employee', employeeIdRef])

  const {
    data: employee,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    enabled: computed(() => Boolean(employeeIdRef.value)),
    queryFn: () => employeeService.fetchEmployee(employeeIdRef.value),
    select: (data) => new Employee(data)
  })

  return { employee, isPending, error, queryKey }
}

export function useCreateEmployee() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-employee'],
    mutationFn: (employee: IEmployeeData) => employeeService.createEmployee(employee),

    onSuccess: (data) => {
      queryClient.setQueryData(['employee', data.id], data)
      queryClient.invalidateQueries({ queryKey: ref(['employees']) })
      snackbarStore.showSnackbar('Employee created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateEmployee() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-employee'],
    mutationFn: (employee: IEmployeeData) => employeeService.updateEmployee(employee),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['employee', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['employees']) })
      snackbarStore.showSnackbar('Employee updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteEmployee() {
  const mutationKey = ref(['delete-employee'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) => employeeService.deleteEmployee(id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['employees']) })
      snackbarStore.showSnackbar('Employee deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useActiveStateEmployee(id: MaybeRef<number>) {
  const employeeIdRef = toRef(id)

  const mutationKey = ref(['employee', employeeIdRef.value])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    // Look to refactor when user composable has to be used
    mutationFn: (isActive: boolean) =>
      userService.setUserActiveState(employeeIdRef.value, isActive),

    onSuccess: (data) => {
      const user = new User(data)
      queryClient.setQueryData(['employee', data.id], (oldEmployee: IEmployeeData) =>
        oldEmployee
          ? {
              ...oldEmployee,
              is_active: data.is_active
            }
          : oldEmployee
      )
      snackbarStore.showSnackbar(
        `${user.getFullName()} has been ${user.is_active ? 'activated' : 'deactivated'}`,
        user.is_active ? 'success' : ''
      )
    },
    onSettled: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['employees'] })
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useChangePasswordEmployee(id: MaybeRef<number>) {
  const employeeIdRef = toRef(id)
  const mutationKey = ref(['employee', employeeIdRef.value])

  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (password: string) => userService.changeUserPassword(employeeIdRef.value, password),
    onSuccess: () => {
      snackbarStore.showSnackbar('Password has been updated for user', 'success')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useSetEmployeeSites(id: MaybeRef<number>) {
  const idRef = toRef(id)
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { data, isPending, isError, error, mutate, reset } = useMutation({
    mutationKey: ref(['employee', idRef.value]),
    mutationFn: (sites: number[]) => employeeService.setEmployeeSites(idRef.value, sites),
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ref(['employee', data.id]) })
      snackbarStore.showSnackbar('Employee sites updated successfully')
    }
  })

  return {
    data,
    isPending,
    isError,
    error,
    mutate,
    reset
  }
}

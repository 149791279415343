import type { IIncidentReportData, IIncidentReportStatisticsData } from '@/models/report'
import type {
  IIncidentReportService,
  IIncidentReportPaginatedListData,
  ISetIncidentReportLevelParams
} from './incident-report.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'
import type { IReportFilterParam } from '..'
import type { ILogEntryData } from '@/models/log-entry'

/**
 * A class representing a IncidentReportService API handler.
 */
export class IncidentReportService implements IIncidentReportService {
  private endpoint: string

  // IncidentReportService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/incidents/'
  }

  // Method to fetch a list of reports from the API
  fetchIncidentReports(filter?: IReportFilterParam): Promise<IIncidentReportPaginatedListData> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined
    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single report by their ID from the API
  fetchIncidentReport(id: number): Promise<IIncidentReportData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new report on the API
  createIncidentReport(data: IIncidentReportData): Promise<IIncidentReportData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing report on the API
  updateIncidentReport(data: IIncidentReportData): Promise<IIncidentReportData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a report by their ID from the API
  deleteIncidentReport(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // stats
  fetchIncidentReportStatistics(): Promise<IIncidentReportStatisticsData> {
    return this.axios.get(`${this.endpoint}statistics/`).then(({ data }) => data)
  }

  //exports
  exportIncidentReportPdf(id: number): Promise<Blob> {
    return this.axios
      .get(`${this.endpoint}${id}/export-pdf/`, {
        responseType: 'blob',
        timeout: 30 * 1000 // 30 seconds
      })
      .then(({ data }) => data)
  }

  //transitions
  submitIncidentReport(id: number): Promise<IIncidentReportData> {
    return this.axios.put(`${this.endpoint}${id}/submit/`).then(({ data }) => data)
  }
  approveIncidentReport(id: number): Promise<IIncidentReportData> {
    return this.axios.put(`${this.endpoint}${id}/approve/`).then(({ data }) => data)
  }

  // Actions
  setIncidentLevel(data: ISetIncidentReportLevelParams): Promise<IIncidentReportData> {
    return this.axios
      .put(`${this.endpoint}${data.id}/set-incident-level/`, data)
      .then(({ data }) => data)
  }

  fetchIncidentReportLogs(id: number): Promise<ILogEntryData[]> {
    return this.axios.get(`${this.endpoint}${id}/audit-logs/`).then(({ data }) => data)
  }
}

// Default export instantiation of the IncidentReportService
const incidentReportService = new IncidentReportService(axios)
export default incidentReportService

<template>
  <div class="d-flex flex-row flex-grow-1 align-start" style="flex: 1 1 auto">
    <v-text-field
      class="time-picker--input"
      single-line
      variant="filled"
      v-model="hour12"
      type="number"
      max="12"
      min="1"
      minLength="2"
      hide-details="auto"
      style="flex: 1 1 auto; width: 120px"
    >
    </v-text-field>

    <div class="align-self-center font-weight-bold text-h3 px-2 mt-n1 mb-1">:</div>

    <v-text-field
      class="time-picker--input mr-2"
      single-line
      variant="filled"
      v-model="paddedMinutes"
      type="number"
      max="59"
      min="00"
      minLength="2"
      maxLength="2"
      hide-details="auto"
      style="flex: 1 1 auto; width: 120px"
    >
    </v-text-field>
    <v-btn-toggle
      v-model="suffix"
      color="primary"
      mandatory
      class="d-flex flex-column border"
      style="flex: 1 1 auto; height: 80px"
      @update:model-value="updatedTime()"
    >
      <v-btn height="40" class="rounded-b-0" value="AM" text="AM" />
      <v-btn height="40" class="rounded-t-0" style="padding-block-end: 2px" value="PM" text="PM" />
    </v-btn-toggle>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

const minutes = defineModel<number>('minutes', { default: 0 })
const hours = defineModel<number>('hours', { required: true })

const suffix = ref(hours.value >= 12 ? 'PM' : 'AM')

watch(
  () => hours.value,
  (newValue) => {
    suffix.value = newValue >= 12 ? 'PM' : 'AM'
  }
)

const hour12 = computed({
  //convert hours to a 12 hour format
  get(): string {
    //return the modulus of 12 but if the hour equals the modulus, return 12 instead of 0
    return pad(String(hours.value % 12 || 12))
  },

  //set hours to army time
  set(value: string) {
    hours.value = convertHourToArmyTime(Number(value))
  }
})

const paddedMinutes = computed({
  //return minutes as a padded value
  get(): string {
    return pad(String(minutes.value))
  },

  set(value: string) {
    if (!isNaN(Number(value))) {
      // indices to slice the new value on
      const padMinLength = Math.max(0, value.length - 2)
      const padMaxLength = Math.max(2, value.length)

      // pad the last 2 letters of the stringified number
      const paddedValue = pad(value.slice(padMinLength, padMaxLength))

      // always clamp the value before setting minute model
      minutes.value = clamp(Number(paddedValue), 0, 59)
    }
  }
})

function convertHourToArmyTime(hour: number) {
  //if the suffix is PM then we want to add 12 to the value to convert to army time
  if (suffix.value == 'PM') {
    hour = hour + 12

    if (hour === 24) {
      hour = 12 //12 PM
    }
  } else if (hour === 12) {
    hour = 0 //12 AM
  }

  return hour
}

const updatedTime = () => {
  hours.value = convertHourToArmyTime(Number(hour12.value))
}

const pad = (value: string) => {
  let padLength = 2
  let padString = '0'

  if (value.length > padLength) {
    return String(value)
  }

  padLength = padLength - value.length
  if (padLength > padString.length) {
    padString += padString.repeat(padLength / padString.length)
  }

  return padString.slice(0, padLength) + String(value)
}

//For keeping a number value in between min and max inclusive.
function clamp(value: number, min = 0, max = 1) {
  return Math.max(min, Math.min(max, value))
}
</script>

<style lang="scss">
.time-picker--input.v-input--density-default .v-field {
  --v-input-control-height: 80px !important;

  input[type='number'] {
    font-size: 32px;
    font-weight: 600 !important;
    text-align: center;
  }
}
</style>

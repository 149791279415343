<template>
  <v-combobox
    v-model="region"
    :items="Address.getRegionsByCountry(country)"
    item-value="code"
    item-title="name"
    :return-object="false"
    v-bind="$attrs"
  >
  </v-combobox>
</template>

<script setup lang="ts">
import { Address } from '@/models/address'

const region = defineModel<string>()

interface Props {
  country?: string
}
// default country to empty string for type management
withDefaults(defineProps<Props>(), {
  country: ''
})
</script>

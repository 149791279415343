import type { IIncidentReportActivityData } from '@/models/report'
import type { IIncidentReportActivityService } from './incident-report-activity.types'
import type { AxiosInstance } from 'axios'
import axios from '@/axios'

/**
 * A class representing a IncidentReportActivityService API handler.
 */
export class IncidentReportActivityService implements IIncidentReportActivityService {
  private endpoint: string

  // IncidentReportActivityService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/incidents/'
  }

  // Method to fetch a list of reports from the API
  fetchIncidentReportActivities(reportId: number): Promise<IIncidentReportActivityData[]> {
    return this.axios.get(`${this.endpoint}${reportId}/activities/`).then(({ data }) => data)
  }

  // Method to fetch a single report by their ID from the API
  fetchIncidentReportActivity(reportId: number, id: number): Promise<IIncidentReportActivityData> {
    return this.axios.get(`${this.endpoint}${reportId}/activities/${id}/`).then(({ data }) => data)
  }

  // Method to create a new report on the API
  createIncidentReportActivity(
    reportId: number,
    data?: IIncidentReportActivityData
  ): Promise<IIncidentReportActivityData> {
    return this.axios.post(`${this.endpoint}${reportId}/activities/`, data).then(({ data }) => data)
  }

  // Method to update an existing report on the API
  updateIncidentReportActivity(
    reportId: number,
    data: IIncidentReportActivityData
  ): Promise<IIncidentReportActivityData> {
    return this.axios
      .patch(`${this.endpoint}${reportId}/activities/${data.id}/`, data)
      .then(({ data }) => data)
  }

  // Method to delete a report by their ID from the API
  deleteIncidentReportActivity(reportId: number, id: number): Promise<void> {
    return this.axios
      .delete(`${this.endpoint}${reportId}/activities/${id}/`)
      .then(({ data }) => data)
  }
}

// Default export instantiation of the IncidentReportActivityService
const activityReportService = new IncidentReportActivityService(axios)
export default activityReportService

<template>
  <div>
    <v-btn
      v-if="currentUser?.hasPermission('cctv_reports.download_cctvreport')"
      class="mr-3 text-capitalize"
      color="primary"
      flat
      :loading="loading"
      @click="emit('download-report-pressed')"
      >Download PDF</v-btn
    >
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores'

interface Props {
  loading?: boolean
}
defineProps<Props>()

interface Emit {
  (e: 'download-report-pressed'): void
}
const emit = defineEmits<Emit>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>

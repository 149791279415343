<template>
  <div class="d-flex">
    <v-btn
      v-if="canApprove && currentUser?.hasPermission('incident_reports.approve_incidentreport')"
      class="mr-3 px-6 text-capitalize"
      color="primary"
      flat
      :loading="loading"
      @click="emit('approved')"
      >Approve</v-btn
    >

    <IncidentReportActionsMenuBtn
      :report-state="ReportStateEnum.Submitted"
      :disabled="loading"
      @edit-report-pressed="emit('edit-report-pressed')"
      @delete-report-pressed="emit('delete-report-pressed')"
      @download-report-pressed="emit('download-report-pressed')"
      @view-report-logs-pressed="emit('view-report-logs-pressed')"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores'

import { ReportStateEnum } from '@/models/report'

import IncidentReportActionsMenuBtn from './IncidentReportActionsMenuBtn.vue'

interface Props {
  canApprove: boolean
  loading?: boolean
}
defineProps<Props>()

interface Emit {
  (e: 'approved'): void
  (e: 'edit-report-pressed'): void
  (e: 'delete-report-pressed'): void
  (e: 'download-report-pressed'): void
  (e: 'view-report-logs-pressed'): void
}
const emit = defineEmits<Emit>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>

<template>
  <v-dialog v-model="modelValue" v-bind="$attrs" :width="width">
    <template #activator="{ props }">
      <slot name="activator" v-bind="{ props }"> </slot>
    </template>

    <v-card :subtitle="subtitle">
      <slot name="error">
        <ErrorAlert v-if="error != null" :error="error" @clearErrors="clearErrors()" class="mb-2">
        </ErrorAlert>
      </slot>

      <v-card-item class="al-card-item align-start">
        <template #title>
          <div class="text-h6 font-weight-medium mb-2">
            {{ title }}
          </div>
        </template>

        <span class="text-medium-emphasis" style="line-height: 19px">
          <slot name="message"></slot>
        </span>

        <template #append>
          <v-btn
            variant="tonal"
            class="rounded"
            size="32"
            @click="closeDialog()"
            icon
            color="default"
            density="comfortable"
          >
            <v-icon size="18" icon="mdi-close" />
          </v-btn>
        </template>
      </v-card-item>

      <v-card-text class="pb-3">
        <slot name="default"> </slot>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="px-6">
        <v-defaults-provider
          :defaults="{
            VBtn: {
              class: 'rounded text-capitalize'
            }
          }"
        >
          <slot name="actions"> </slot>
        </v-defaults-provider>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import type { ISystemError } from '@/models/error'
import ErrorAlert from './ErrorAlert.vue'

const modelValue = defineModel<boolean>()
const error = defineModel<ISystemError | null>('error', { default: null })

defineProps({
  title: String,
  subtitle: String,
  width: {
    type: [Number, String],
    default: () => 'auto'
  }
})

const closeDialog = () => {
  clearErrors()
  modelValue.value = false
}

const clearErrors = () => {
  error.value = null
}
</script>

<template>
  <v-dialog v-model="dialog" v-bind="$attrs" persistent max-width="1200">
    <AlFormCard
      title="Guard Report Coordinates"
      width="100%"
      :show-actions="false"
      :loading="isLoading"
    >
      <template #error>
        <ErrorAlert v-if="error != null" :error="error" @clearErrors="clearErrors()" class="mb-2">
        </ErrorAlert>
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <div>
        <v-card flat height="calc(100vh - 228px)">
          <GeoAuditLogMap
            style="height: 100%; width: 100%"
            :geo-audit-logs="logs ?? []"
            :loading="isLoading"
          />
        </v-card>
      </div>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from 'vue'

import { merge } from 'lodash'
import { format } from 'date-fns'
import { type IGuardReport } from '@/models/report'
import { useQueryClient } from '@tanstack/vue-query'
import { useFetchGeoAuditLogs } from '@/composables/geo-audit-log'
import type { IGeoAuditLogFilterParam } from '@/services'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import GeoAuditLogMap from '@/components/geo-audit-log/GeoAuditLogMap.vue'

const dialog = defineModel<boolean>('dialog', { required: true })

interface Props {
  guardReport: IGuardReport
}
const props = defineProps<Props>()

const geoAuditLogFilters = reactive<IGeoAuditLogFilterParam>({})

const enableFetch = computed(() => !!props.guardReport && dialog.value)

watch(enableFetch, (value) => {
  if (value) {
    merge(geoAuditLogFilters, {
      search: '',
      page: 1,
      user:
        props.guardReport.shift.guards!.length! > 0
          ? props.guardReport.shift.guards![0].id
          : undefined,
      timestamp_after: format(props.guardReport.shift.start!, 'yyyy-MM-dd'),
      timestamp_before: format(props.guardReport.shift.end!, 'yyyy-MM-dd')
    })

    queryClient.invalidateQueries({ queryKey: queryKey.value })
  }
})

const { logs, isLoading, error, queryKey } = useFetchGeoAuditLogs(geoAuditLogFilters, enableFetch)
const queryClient = useQueryClient()

function clearErrors() {
  error.value = null
}

function closeDialog() {
  dialog.value = false
  clearErrors()
}
</script>

<style lang="scss"></style>

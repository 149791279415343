<template>
  <AuditLogsDialog
    v-model:dialog="dialog"
    title="Incident Report Audit Logs"
    subtitle="Audit Logs for the incident report here"
    :logEntries="logEntries || []"
    :error="logEntiresError"
    :loading="isPending"
    @clearErrors="clearErrors()"
  >
  </AuditLogsDialog>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'
import { LogEntry } from '@/models/log-entry'

import { useQuery } from '@tanstack/vue-query'

import incidentReportService from '@/services/incident_report/incident-report'

import AuditLogsDialog from '@/components/common/AuditLogsDialog.vue'

interface Props {
  reportId?: number | null
}

const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog', { default: false })

watch(dialog, (value) => {
  if (value) {
    clearErrors()
  }
})

// audit logs
const selectedReport = computed(() => props.reportId)
const isAuditLogEnabled = computed(() => !!selectedReport.value)

const {
  data: logEntries,
  isPending,
  error: logEntiresError
} = useQuery({
  queryKey: ['incident-report-logs', selectedReport],
  queryFn: () => incidentReportService.fetchIncidentReportLogs(selectedReport.value!),
  select: (data) => data.map((log) => new LogEntry(log)),
  enabled: isAuditLogEnabled
})

function clearErrors() {
  logEntiresError.value = null
}
</script>

import type { ISiteData } from '@/models/client'
import type { ISiteService } from './site.types'
import type { AxiosInstance } from 'axios'
import axios from '@/axios'

/**
 * A class representing a SiteService API handler.
 */
export class SiteService implements ISiteService {
  private endpoint: string

  // SiteService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/clients/'
  }

  // Method to fetch a list of sites from the API
  fetchSites(client: number): Promise<ISiteData[]> {
    return this.axios.get(`${this.endpoint}${client}/sites/`).then(({ data }) => data)
  }

  // Method to fetch a single site by their ID from the API
  fetchSite(client: number, code: number): Promise<ISiteData> {
    return this.axios.get(`${this.endpoint}${client}/sites/${code}/`).then(({ data }) => data)
  }

  // Method to create a new site on the API
  createSite(client: number, data: ISiteData): Promise<ISiteData> {
    return this.axios.post(`${this.endpoint}${client}/sites/`, data).then(({ data }) => data)
  }

  // Method to update an existing site on the API
  updateSite(client: number, data: ISiteData): Promise<ISiteData> {
    return this.axios
      .patch(`${this.endpoint}${client}/sites/${data.code}/`, data)
      .then(({ data }) => data)
  }

  // Method to delete a site by their ID from the API
  deleteSite(client: number, code: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${client}/sites/${code}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the SiteService
const siteService = new SiteService(axios)
export default siteService

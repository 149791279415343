import { AbstractModel } from '../base'
import { Client, Site, type IClient, type ISite } from '../client'
import { Employee, type IEmployee } from '../employee'
import type { IReportShift, IReportShiftData } from './report.types'

export class ReportShift extends AbstractModel implements IReportShift {
  readonly id?: number
  readonly site: number | ISite
  readonly start: Date
  readonly end: Date
  readonly client?: IClient
  readonly guards?: IEmployee[]
  readonly created?: Date
  readonly modified?: Date

  constructor(data: IReportShiftData) {
    super()

    // Validate data
    const requiredFields: (keyof IReportShiftData)[] = ['site', 'start', 'end']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    if (data.id) {
      this.id = data.id
    }

    this.site = typeof data.site! === 'number' ? data.site! : new Site(data.site!)
    this.start = typeof data.start! === 'string' ? new Date(data.start!) : data.start!
    this.end = typeof data.end! === 'string' ? new Date(data.end!) : data.end!

    if (data.client) {
      this.client = new Client(data.client)
    }

    if (data.guards) {
      this.guards = data.guards.map((guard) => new Employee(guard))
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }
  }
}

<template>
  <v-row cols="12" v-if="quickAccessRouteLinks.length > 0">
    <v-col cols="12">
      <div class="d-flex align-center">
        <h2 class="text-h5 font-weight-light ps-2">Quick Access</h2>
      </div>
    </v-col>
    <v-divider class="mb-4 mx-4" thickness="2"></v-divider>
    <v-col cols="12">
      <v-row>
        <template v-for="(item, index) in quickAccessRouteLinks">
          <template v-if="item.children">
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-for="(child, childIndex) in item.children"
              :key="childIndex"
            >
              <QuickAccessWidget :item="child" />
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" sm="6" md="4" lg="3" :key="index">
              <QuickAccessWidget :item="item" />
            </v-col>
          </template>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useAuthStore } from '@/stores'

import { asyncRoutes, constantRoutes } from '@/router/routes'
import { generateNavItem, generateNavRoutes, generateDynamicRoutes } from '@/router/utils'
import QuickAccessWidget from './QuickAccessWidget.vue'
import { useRouter } from 'vue-router'

const authStore = useAuthStore()
const router = useRouter()

const dynamicRoutes = generateDynamicRoutes(authStore.user!.permissions!, asyncRoutes)
const navRoutes = generateNavRoutes(constantRoutes.concat(dynamicRoutes))

const quickAccessRouteLinks = computed(() =>
  navRoutes
    // remove home page from nav quickAccessRouteLinks rendered
    .filter((route) => route.path !== '/')
    .map((route) => {
      return generateNavItem(router, route)
    })
)
</script>

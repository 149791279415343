import type { IFieldErrors, IFieldErrorsData, ISystemError, ISystemErrorData } from './error.types'
import * as Sentry from '@sentry/vue'

export class FieldErrors implements IFieldErrors {
  [fieldName: string]: string[] | FieldErrors

  constructor(data: IFieldErrorsData) {
    for (const fieldName in data) {
      if (Array.isArray(data[fieldName])) {
        // If the field error is an array of strings, directly assign it
        this[fieldName] = data[fieldName] as string[]
      } else {
        // If the field error is nested, create a new FieldErrors instance for that field
        this[fieldName] = new FieldErrors(data[fieldName] as IFieldErrorsData)
      }
    }
  }

  // to type check the value as the same type of IFieldErrorData
  static isFieldError(value: any): value is IFieldErrorsData {
    if (typeof value === 'object' && value !== null) {
      for (const key in value) {
        return Array.isArray(value[key]) || FieldErrors.isFieldError(value[key])
      }
    }
    return false
  }
}

export class SystemError extends Error implements ISystemError {
  readonly name: string
  readonly detail: string
  readonly fieldErrors: IFieldErrors = {}

  constructor(data: ISystemErrorData) {
    super(data.message) // 'Error' breaks prototype chain here
    this.name = data.name ?? 'SystemError'
    this.detail = data.detail ?? ''

    if (data.fieldErrors) {
      this.fieldErrors = new FieldErrors(data.fieldErrors)
    }

    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain

    // Push axios error to sentry for future debug (if required)
    // TODO: LF-2023-12-31 - Move to SystemError model once merged
    Sentry.captureException(this)
  }
}

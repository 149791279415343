<template>
  <BasePage title="Contacts" subtitle="Manage contacts contact information">
    <template #page-header:actions>
      <v-btn
        color="primary"
        depressed
        v-if="currentUser?.hasPermission('contacts.add_contact')"
        @click="openContactFormDialog()"
      >
        <template #prepend>
          <v-icon start class="hidden-sm-and-down me-0"> {{ route.meta.icon }} </v-icon>
        </template>
        Create Contact
      </v-btn>
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-card flat height="calc(100vh - 286px)" class="px-4 rounded-lg">
      <v-toolbar flat height="84" color="transparent">
        <SearchBox
          label="Search"
          single-line
          hide-details="auto"
          variant="outlined"
          density="comfortable"
          style="max-width: 400px"
          v-model="contactFilters.search"
          @filter-applied="_fetchContacts(contactFilters)"
          :filters-applied="filtersApplied"
        >
          <template #filters>
            <div class="filter-line-item">
              <label>Clients</label>
              <div class="filter-line-item-value">
                <ClientAutoCompletePicker
                  clearable
                  :readonly="contactFilters.client != null"
                  v-model="contactFilters.client"
                />
              </div>
            </div>
          </template>
        </SearchBox>
      </v-toolbar>

      <ContactList
        :loading="loading"
        :count="numContacts"
        :contacts="contacts"
        v-model:filters="contactFilters"
        @contact-selected="openContactDetailDrawer"
        @contact-create-pressed="openContactFormDialog()"
        @contact-edit-pressed="openContactFormDialog"
        @contact-delete-pressed="openDeleteContactDialog"
      />
    </v-card>

    <ConfirmationDialog
      v-if="selectedContact"
      width="450"
      title="Delete contact"
      v-model="deleteDialog"
      v-model:error="deleteError"
    >
      <template #message>
        Are you sure you want to delete
        <span class="text-medium-high-emphasis font-weight-bold">{{
          selectedContact.getFullName()
        }}</span
        >?
        <br />
        <div class="pt-2">This action cannot be undone.</div>
      </template>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          variant="flat"
          :disabled="deleteInProgress"
          :loading="deleteInProgress"
          @click="deleteSelectedContact(selectedContact.id!)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationDialog>
  </BasePage>

  <ContactDetailDrawer
    v-if="selectedContact"
    v-model:drawer="rightDrawer"
    :contact-id="selectedContact.id!"
    @contact-edit-pressed="(contact: IContact) => openContactFormDialog(contact)"
    @contact-delete-pressed="openDeleteContactDialog"
  />

  <ContactFormDialog
    v-model:dialog="isContactFormDialogActive"
    :contact="selectedContact!"
    :isEdit="!!selectedContact"
    :disable-client-picker="false"
    ref="contactFormDialogRef"
  />
</template>

<script lang="ts" setup>
import { omit } from 'lodash'
import { storeToRefs } from 'pinia'
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import BasePage from '@/components/base/BasePage.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import SearchBox from '@/components/common/SearchBox.vue'
import ContactList from '@/components/contacts/ContactList.vue'

import type { IContactFilterParam } from '@/services'
import { useFetchContacts, useDeleteContacts } from '@/composables/contacts'

import type { IContact } from '@/models/contact'
import { useAuthStore } from '@/stores'

import ClientAutoCompletePicker from '@/components/clients/ClientAutoCompletePicker.vue'

import ContactDetailDrawer from '@/components/contacts/ContactDetailDrawer.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import ContactFormDialog from '@/components/contacts/ContactFormDialog.vue'

const route = useRoute()
const router = useRouter()

const rightDrawer = ref(false)

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const { loading, contacts, numContacts, error, clearErrors, fetchContacts } = useFetchContacts()

const selectedContact = ref<IContact>()

function openContactDetailDrawer(contact: IContact) {
  selectedContact.value = contact

  rightDrawer.value = true
}

// set default filters from route query param
// only use valid filter fields from routes query params including `search` and `page` and other valid fields
let contactFilters = reactive<IContactFilterParam>({
  search: route.query.search as string,
  page: route.query.page ? Number(route.query.page) : 1,
  client: route.query.client ? Number(route.query.client) : undefined
})

const filtersApplied = ref<IContactFilterParam | null>(null)

function _fetchContacts(filters: IContactFilterParam) {
  clearErrors()

  /* this will update the URL query based on param values. */
  router
    .replace({
      name: route.name!,
      query: filters as Record<string, any>
    })
    .catch((e: Error) => e) //catch navigation duplication error

  return fetchContacts(filters).then(() => {
    // update filters applied to search box
    filtersApplied.value = omit(filters, ['page', 'search'])
  })
}

watch(
  () => contactFilters.page,
  (newPage) => {
    _fetchContacts({ page: newPage, ...contactFilters })
  }
)
watch(
  () => contactFilters.search,
  () => {
    // set page to 1 when updating search query
    _fetchContacts({ ...contactFilters, page: 1 }).then(() => {
      contactFilters.page = 1
    })
  }
)

onMounted(() => {
  _fetchContacts(contactFilters)
})

const isContactFormDialogActive = ref(false)
function openContactFormDialog(contact?: IContact) {
  selectedContact.value = contact

  isContactFormDialogActive.value = true
}

const deleteDialog = ref(false)

function openDeleteContactDialog(contact: IContact) {
  deleteDialog.value = true

  // incase of delete in detail drawer
  selectedContact.value = contact
}

function closeDrawerAndDialog() {
  deleteDialog.value = false

  // incase of delete in detail drawer
  rightDrawer.value = false
}

const { loading: deleteInProgress, error: deleteError, deleteContact } = useDeleteContacts()

function deleteSelectedContact(contactId: number) {
  return deleteContact(contactId).then(() => {
    closeDrawerAndDialog()
  })
}
</script>

<style lang="scss">
.site-list .v-list-item--three-line .v-list-item-subtitle {
  -webkit-line-clamp: 5;
}

.card-list {
  --v-card-list-gap: 0.25rem;

  &.v-list {
    padding-block: 0;
  }

  .v-list-item {
    min-block-size: unset;
    min-block-size: auto !important;
    padding-block: 0 !important;
    padding-inline: 0 !important;

    > .v-ripple__container {
      opacity: 0;
    }

    &:not(:last-child) {
      padding-block-end: var(--v-card-list-gap) !important;
    }
  }

  .v-list-item:hover,
  .v-list-item:focus,
  .v-list-item:active,
  .v-list-item.active {
    > .v-list-item__overlay {
      opacity: 0 !important;
    }
  }
}
</style>

<template>
  <ConfirmationDialog
    width="450"
    :title="`${capitalize(actionText)} user`"
    v-model="dialog"
    v-model:error="error"
    :open-on-click="user.is_active ? true : false"
  >
    <template #activator="{ props }">
      <slot
        name="activator"
        v-bind="{
          props: {
            ...props,
            onClick: user.is_active ? props.onClick : toggleUserActive
          },
          loading: toggleUserActiveInProgress,
          active: user.is_active
        }"
      />
    </template>

    <template #message>
      Are you sure you want to
      <span class="text-primary font-weight-medium">{{ actionText }}</span> this user?
    </template>

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn variant="flat" :disabled="toggleUserActiveInProgress" @click="closeDialog()">
        Cancel
      </v-btn>
      <v-btn
        :color="actionColor"
        variant="flat"
        :disabled="toggleUserActiveInProgress"
        :loading="toggleUserActiveInProgress"
        @click="toggleUserActive()"
      >
        {{ actionText }}
      </v-btn>
    </template>
  </ConfirmationDialog>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

import { capitalize } from 'lodash'

import type { IUser } from '@/models/user'

import ConfirmationDialog from '../common/ConfirmationDialog.vue'
import { useActiveStateEmployee } from '@/composables/employee'

interface Props {
  user: IUser
}

const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog', { default: false })

const actionText = computed(() => (props.user.is_active ? 'deactivate' : 'activate'))
const actionColor = computed(() => (props.user.is_active ? 'error' : 'success'))

function closeDialog() {
  dialog.value = false
}

const userId = computed(() => props.user.id!)
const {
  isPending: toggleUserActiveInProgress,
  error,
  mutate,
  reset
} = useActiveStateEmployee(userId)

async function toggleUserActive() {
  reset()

  mutate(!props.user.is_active, {
    onSuccess: () => {
      closeDialog()
    }
  })
}
</script>

<template>
  <v-navigation-drawer
    v-model="isFieldDetailDrawerActive"
    location="right"
    width="350"
    disable-resize-watcher
    class="border"
    absolute
    permanent
  >
    <template #prepend>
      <v-toolbar density="compact" color="transparent" class="border-b">
        <template #prepend>
          <v-icon :icon="fieldTypeIcon" />
        </template>
        <v-toolbar-title
          class="text-subtitle-1 font-weight-medium ms-2"
          :text="fieldTypeLabel"
        ></v-toolbar-title>
      </v-toolbar>
    </template>

    <div class="pa-2">
      <v-form v-model="isFormValid" ref="form">
        <div
          class="d-flex flex-column flex-grow-1 pb-4 px-2 align-space-between justify-space-between"
        >
          <v-label
            for="field-label"
            text="Label*"
            class="font-weight-medium text-caption text-high-emphasis pb-1"
            style="opacity: 0.9"
          />

          <v-text-field
            id="field-label"
            single-line
            variant="outlined"
            density="compact"
            v-model="postOrderField.field.label"
            @update:model-value="debounceSaveField"
            :rules="[requiredValidator]"
            hide-details="auto"
            :error-messages="
              fieldFormError &&
              fieldFormError.fieldErrors['field'] &&
              ((fieldFormError.fieldErrors['field'] as IFieldErrors)['label'] as string[])
            "
            :loading="isFieldUpdatePending"
          />
        </div>
      </v-form>

      <PostOrderInstructionDetailFieldOptionsSection
        v-if="isFieldOptionsAllowed && postOrderField"
        :options="postOrderField.options"
        :field="postOrderField"
      />
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'
import { cloneDeep, debounce } from 'lodash'

import { requiredValidator } from '@/utils/validators'
import { FormFieldTypeEnum } from '@/models/form/form-field/form-field.constants'
import type { IFieldErrors } from '@/models/error'

import { type IPostOrderInstructionsField } from '@/models/post-order'
import PostOrderInstructionDetailFieldOptionsSection from './PostOrderInstructionDetailFieldOptionsSection.vue'
import {
  useFetchPostOrderInstructionsFields,
  useUpdatePostOrderInstructionsField
} from '@/composables/post-order'
import { PostOrderSymbol } from './postOrderProvide'
import { diffDeep } from '@/utils/helpers'
import type { VForm } from 'vuetify/components'
import { useQueryClient } from '@tanstack/vue-query'

interface Props {
  field: IPostOrderInstructionsField
}

const props = defineProps<Props>()

interface Emits {
  (name: 'refresh:fields'): void
}

const emit = defineEmits<Emits>()

const postOrderField = ref<IPostOrderInstructionsField>(props.field)

watch(
  () => props.field,
  (value) => {
    if (value) {
      postOrderField.value = cloneDeep(props.field)
    }
  },
  {
    immediate: true,
    deep: true
  }
)

const isFieldDetailDrawerActive = defineModel<boolean>()

const isFieldOptionsAllowed = computed(() => {
  let isAllowed = false
  switch (props.field.field.type) {
    case FormFieldTypeEnum.ComboBox:
    case FormFieldTypeEnum.CheckBox:
    case FormFieldTypeEnum.RadioButton: {
      isAllowed = true
      break
    }
    default: {
      isAllowed = false
      break
    }
  }
  return isAllowed
})

const postOrderContext = inject(PostOrderSymbol)

if (!postOrderContext) throw new Error('[Post Order] Could not find injected post order context')

const form = ref<VForm>()

const {
  error: fieldFormError,
  reset: clearErrors,
  mutate: updatePostOrderFormField,
  isPending: isFieldUpdatePending
} = useUpdatePostOrderInstructionsField(
  postOrderContext.postOrderId,
  postOrderContext.instructionId.value,
  postOrderContext.sectionId
)

const useFetchPostOrderInstructionFieldsQuery = useFetchPostOrderInstructionsFields(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const queryClient = useQueryClient()

async function saveField() {
  clearErrors()
  const { valid } = await form.value!.validate()

  if (valid) {
    const payload = diffDeep(postOrderField.value, props.field, true)!

    updatePostOrderFormField(payload, {
      async onSuccess() {
        emit('refresh:fields')
        await queryClient.invalidateQueries({
          queryKey: useFetchPostOrderInstructionFieldsQuery.queryKey
        })
      }
    })
  }
}

/** wait 1s for the field to update itself after */
const debounceSaveField = debounce(saveField, 1000)

const isFormValid = ref(false)

const fieldTypeLabel = computed(() => {
  switch (postOrderField.value.field.type) {
    case FormFieldTypeEnum.Label:
      return 'Label'

    case FormFieldTypeEnum.TextArea:
      return 'Long input field'

    case FormFieldTypeEnum.ComboBox:
      return 'Dropdown field'

    case FormFieldTypeEnum.CheckBox:
      return 'Multiple option field'

    case FormFieldTypeEnum.RadioButton:
      return 'Radio button field'

    case FormFieldTypeEnum.FileField:
      return 'Image picker field'

    case FormFieldTypeEnum.TextField:
      return 'Short input box field'

    default:
      return ''
  }
})
const fieldTypeIcon = computed(() => {
  switch (postOrderField.value.field.type) {
    case FormFieldTypeEnum.Label:
      return 'mdi-format-text'

    case FormFieldTypeEnum.TextArea:
      return 'mdi-form-textbox'

    case FormFieldTypeEnum.ComboBox:
      return 'mdi-form-dropdown'

    case FormFieldTypeEnum.CheckBox:
      return 'mdi-order-bool-ascending-variant'

    case FormFieldTypeEnum.FileField:
      return 'mdi-image'

    default:
      return 'mdi-solid'
  }
})
</script>

<style lang="scss">
.v-tab__slider {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: currentColor;
  pointer-events: none;
  opacity: 0.12;
}
</style>

<template>
  <v-col
    order-md="1"
    order="2"
    cols="12"
    md="5"
    class="d-flex flex-column pb-0"
    style="position: relative"
  >
    <ErrorAlert v-if="isError" :error="error!" @clearErrors="reset()" class="mb-0 mb-4 rounded" />

    <div>
      <b>Checkpoint:</b>
      <div class="text-report-grey">{{ observation.checkpoint }}</div>
    </div>

    <div class="pt-2">
      <b>Reported By:</b>
      <div class="text-report-grey">{{ observation.reporter }}</div>
    </div>

    <div class="pt-2">
      <b class="mr-1">Notes:</b>
      <span class="text-report-grey" :style="{ whiteSpace: 'pre-line' }">{{
        observation.text
      }}</span>
    </div>

    <div class="pt-auto mt-auto">
      <v-btn
        :color="reportIncidentStatusVisual?.color"
        height="35"
        block
        class="text-white text-none mt-4"
        variant="flat"
        :loading="isPending"
      >
        <div class="font-weight-medium pr-1" style="font-size: large">
          {{ reportIncidentStatusVisual?.display }}
        </div>
        <v-icon
          v-if="currentUser?.hasPermission('guard_reports.change_guardreport')"
          left
          class="hidden-sm-and-down mr-n1"
          >mdi-chevron-down
        </v-icon>

        <v-menu
          activator="parent"
          :disabled="!currentUser?.hasPermission('guard_reports.change_guardreport')"
        >
          <v-list density="compact" class="py-1">
            <template v-for="(status, i) in incidentStatusVisuals">
              <v-list-item
                :class="{ 'mb-2': i !== incidentStatusVisuals.length - 1 }"
                :style="`border-left: solid ${status.color} 8px;`"
                :key="i"
                v-if="status.state != (observation.incident_level! as IReportIncidentStatus).value"
                @click="checkIncidentReportLevelUpdate(status)"
              >
                <v-list-item-title>{{ status.display }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-btn>

      <ReportIncidentLevelDialog
        v-model:dialog="incidentLevelDialog"
        :report="report"
        :observation="observation"
        :status="incidentLevelStatus"
        :loading="isPending"
        :error="error"
        @clearErrors="reset()"
        @save-incident-report="saveIncidentLevelDialog"
      />
    </div>

    <div class="pt-4 d-flex">
      <v-row dense>
        <v-col
          v-if="currentUser?.hasPermission('guard_reports.change_guardreport')"
          cols="6"
          md="auto"
          class="flex-grow-1"
        >
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                flat
                variant="tonal"
                block
                class="text-capitalize d-flex"
                @click="emit('open-activity-observation-detail')"
              >
                <v-icon left class="mr-1">mdi-pencil </v-icon>Edit</v-btn
              >
            </template>
            <span> Edit observation </span>
          </v-tooltip>
        </v-col>

        <v-col
          cols="6"
          md="auto"
          class="flex-grow-1"
          v-if="currentUser?.hasPermission('guard_reports.delete_guardreport')"
        >
          <ConfirmationDialog
            width="auto"
            title="Delete observation"
            v-model="observationDeleteDialog"
            v-model:error="deleteError"
          >
            <template #activator="{ props: tooltipProps }">
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="{ ...props, ...tooltipProps }"
                    density="compact"
                    flat
                    variant="tonal"
                    block
                    class="text-capitalize d-flex"
                  >
                    <v-icon left class="mr-1">mdi-delete </v-icon>Delete</v-btn
                  >
                </template>
                <span> Delete observation </span>
              </v-tooltip>
            </template>

            <template #message>
              Are you sure you want to this observation?
              <br />
              <div class="pt-2">This action cannot be undone.</div>
            </template>

            <template #actions>
              <v-spacer></v-spacer>
              <v-btn
                variant="flat"
                :disabled="deleteInProgress"
                @click="closeDeleteObservationDialog()"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                variant="flat"
                :loading="deleteInProgress"
                @click="removeObservation()"
              >
                Delete
              </v-btn>
            </template>
          </ConfirmationDialog>
        </v-col>

        <v-col
          cols="12"
          md="auto"
          class="flex-grow-1"
          v-if="
            observation.incident_report &&
            currentUser?.hasPermission('guard_reports.change_guardreport')
          "
        >
          <v-tooltip>
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                flat
                variant="tonal"
                class="text-capitalize d-flex"
                block
                target="_blank"
                @click="emit('incident-report-selected', observation.incident_report!)"
                :color="reportIncidentStatusVisual?.color"
              >
                <v-icon left class="mr-1">mdi-clipboard-alert </v-icon>Incident-{{
                  observation.incident_report.toString().padStart(4, '0000')
                }}</v-btn
              >
            </template>
            Navigate to this incident report here
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </v-col>
  <v-col order-md="2" order="1" cols="12" md="7" class="px-0 pb-0 pt-1">
    <div class="d-flex">
      <ImageViewer width="100%" :src="observation.file as string" />
    </div>
  </v-col>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'

import { useAuthStore, useReportStore, type IReportIncidentStateVisual } from '@/stores'

import { useCreateIncidentReport, useSetIncidentReportLevel } from '@/composables/incident-report'

import {
  useFetchGuardReport,
  useUpdateGuardReportActivityObservation,
  useDeleteGuardReportActivityObservation
} from '@/composables/guard-report'
import { useQueryClient } from '@tanstack/vue-query'

import type { ISetIncidentReportLevelParams } from '@/services'
import {
  GuardReportActivityObservation,
  ReportActivityStatusEnum,
  type IGuardReport,
  type IGuardReportActivity,
  type IGuardReportActivityObservation,
  type IGuardReportActivityObservationData,
  type IReportIncidentStatus
} from '@/models/report'
import type { ISite } from '@/models/client'

import ErrorAlert from '@/components/common/ErrorAlert.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import ImageViewer from '@/components/common/ImageViewer.vue'
import ReportIncidentLevelDialog from '../common/ReportIncidentLevelDialog.vue'

interface Props {
  report: IGuardReport
  activity: IGuardReportActivity
  observation: IGuardReportActivityObservation
}
const props = defineProps<Props>()

interface Emits {
  (e: 'refreshReport'): void
  (e: 'open-activity-observation-detail'): void
  (
    e: 'incident-report-created',
    payload: {
      observation: IGuardReportActivityObservation
      incidentReportId: number
      fileAttachment?: File | string
    }
  ): void
  (e: 'incident-report-selected', incidentReportId: number): void
}
const emit = defineEmits<Emits>()

const queryClient = useQueryClient()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const reportStore = useReportStore()
const { incidentStatusVisuals, getReportIncidentStatusVisualByState } = storeToRefs(reportStore)

const observationDeleteDialog = ref(false)

function closeDeleteObservationDialog() {
  observationDeleteDialog.value = false
}

const reportIncidentStatusVisual = computed(() =>
  getReportIncidentStatusVisualByState.value(
    (props.observation.incident_level as IReportIncidentStatus).value!
  )
)

const { queryKey } = useFetchGuardReport(props.report.id!)

const incidentLevelDialog = ref(false)
const incidentLevelStatus = ref<IReportIncidentStateVisual>(reportIncidentStatusVisual.value!)

const { isPending, isError, error, mutate, reset } = useSetIncidentReportLevel()

function updateIncidentReportLevel(
  incidentReportId: number,
  incidentLevel: number,
  reason: string
) {
  const params: ISetIncidentReportLevelParams = {
    id: incidentReportId,
    incident_level: incidentLevel,
    reason: reason
  }

  mutate(params, {
    onSuccess: () => {
      closeIncidentLevelActivityDialog()
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey })
  })
}

const { mutate: updateIncidentReportActivityObservation } = useUpdateGuardReportActivityObservation(
  props.report.id!,
  props.activity.id!
)

const updateGuardReportObservationMutation = useUpdateGuardReportActivityObservation(
  props.report.id!,
  props.activity.id!
)

function openIncidentLevelActivityDialog(status: IReportIncidentStateVisual) {
  incidentLevelDialog.value = true

  incidentLevelStatus.value = status
}

function closeIncidentLevelActivityDialog() {
  incidentLevelDialog.value = false
}

function checkIncidentReportLevelUpdate(status: IReportIncidentStateVisual) {
  const state = status.state

  switch (state) {
    case ReportActivityStatusEnum.SecurityLevel1: {
      const observation: IGuardReportActivityObservationData = {
        id: props.observation.id,
        incident_report: null,
        incident_level: ReportActivityStatusEnum.SecurityLevel1
      }

      updateGuardReportObservationMutation.mutate(observation, {
        onSettled: () => queryClient.invalidateQueries({ queryKey })
      })

      break
    }
    case ReportActivityStatusEnum.SecurityLevel2:
    case ReportActivityStatusEnum.SecurityLevel3: {
      openIncidentLevelActivityDialog(status)

      break
    }
  }
}

const createIncidentReportMutation = useCreateIncidentReport()

async function saveIncidentLevelDialog(data: {
  id: number
  incident_level: number
  incident_report: number
  reason: string
  is_new_incident_report: boolean
}) {
  let incidentReportId = data.incident_report
  // Create Incident Report if flag is true
  if (data.is_new_incident_report) {
    const incidentReportPayload = {
      site: (props.report.site as ISite).code,
      incident_level: data.incident_level
    }
    const incidentReport = await createIncidentReportMutation.mutateAsync(incidentReportPayload)
    incidentReportId = incidentReport.id!
  }

  const payload = {
    id: data.id,
    incident_level: data.incident_level as number,
    incident_report: incidentReportId
  } as IGuardReportActivityObservationData

  updateIncidentReportActivityObservation(payload, {
    onSuccess: (observation) => {
      if (data.is_new_incident_report) {
        emit('incident-report-created', {
          incidentReportId: incidentReportId,
          observation: new GuardReportActivityObservation(observation),
          fileAttachment: props.observation.file
        })
      }
      updateIncidentReportLevel(incidentReportId, data.incident_level!, data.reason)
    }
  })
}

const {
  isPending: deleteInProgress,
  error: deleteError,
  reset: deleteReset,
  mutate: deleteObservation
} = useDeleteGuardReportActivityObservation(props.report.id!, props.activity.id!)

function removeObservation() {
  deleteReset()
  deleteObservation(props.observation.id!, {
    onSuccess: () => {
      emit('refreshReport')
      closeDeleteObservationDialog()
    }
  })
}
</script>

<template>
  <v-row
    v-if="
      currentUser?.hasPermission('cctv_reports.view_cctvreport') ||
      currentUser?.hasPermission('guard_reports.view_guardreport') ||
      currentUser?.hasPermission('incident_reports.view_incidentreport')
    "
  >
    <v-col cols="12">
      <div class="d-flex align-center">
        <h2 class="text-h5 font-weight-light ps-2">Reports</h2>
      </div>
    </v-col>
    <v-divider class="mb-4 mx-4" thickness="2" />

    <v-col class="mt-0" cols="12" v-if="currentUser?.hasPermission('cctv_reports.view_cctvreport')">
      <CCTVReportWidgetSection />
    </v-col>
    <v-col
      class="mt-0"
      cols="12"
      v-if="currentUser?.hasPermission('guard_reports.view_guardreport')"
    >
      <GuardReportWidgetSection />
    </v-col>
    <v-col
      class="mt-0"
      cols="12"
      v-if="currentUser?.hasPermission('incident_reports.view_incidentreport')"
    >
      <IncidentReportWidgetSection />
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'

import CCTVReportWidgetSection from './CCTVReportWidgetSection.vue'
import GuardReportWidgetSection from './GuardReportWidgetSection.vue'
import IncidentReportWidgetSection from './IncidentReportWidgetSection.vue'

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>

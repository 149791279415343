import { defineStore } from 'pinia'

import { type IUser, type IUserData, User } from '@/models/user'
import authService from '@/services/auth/auth'
import * as Sentry from '@sentry/vue'

import type { IAuthState } from './auth.types'
import type { IResetPasswordPayload } from '@/services'

// Pinia store for managing auth-related states and actions
export const useAuthStore = defineStore('auth', {
  state: (): IAuthState => ({
    user: null
  }),

  getters: {
    // Getter to retrieve current authenticate user from the store
    getUser(state: IAuthState): IUser | null {
      let user = null
      if (state.user != null) {
        user = new User(state.user)
      }
      return user
    }
  },

  actions: {
    // Action method to login a user via username and password
    async login(username: string, password: string): Promise<void> {
      return authService.login(username, password).then((user) => {
        this.user = user
        Sentry.setUser({ username: username }) // identify current user in sentry
      })
    },

    // Action method to logout the currently authenticated user
    async logout(): Promise<void> {
      this.user = null // Intentional - Must clear state first before calling logout service
      Sentry.setUser(null) // clear currently set user in sentry
      await authService.logout()
    },

    async forgotPassword(email: string): Promise<void> {
      await authService.forgotPassword(email)
    },

    async resetPassword(payload: IResetPasswordPayload): Promise<void> {
      await authService.resetPassword(payload)
    },

    async changePassword(new_password1: string, new_password2: string): Promise<void> {
      await authService.changePassword(new_password1, new_password2)
    },

    // Action method to fetch currently authenticated user
    async fetchAuthUser(): Promise<void> {
      this.user = await authService.fetchAuthUser()
    },

    async tryGetOrFetchAuthUser(): Promise<IUser | null> {
      // return user if available
      if (this.user == null) {
        await this.fetchAuthUser()
      }
      return this.getUser
    },

    // Action method to update currently authenticated user
    async updateAuthUser(payload: IUserData): Promise<void> {
      this.user = await authService.updateAuthUser(payload)
    }
  }
})

<template>
  <ReportView v-model:error="fetchError" @openDialog="openIncidentFormDialog()">
    <template #search>
      <ReportsSearchBox
        v-model:search="currentSearch"
        @update:search="
          (newSearch: string) =>
            debounceFilterIncidentReports({ ...filters, page: 1, search: newSearch })
        "
        :filters="filters"
        @filter-applied="(newFilter) => updateFilters(filters, newFilter)"
      />
    </template>
    <template #content>
      <v-data-table-server
        v-model:page="filters.page"
        @update:page="
          (newPage: number) => debounceFilterIncidentReports({ page: newPage, ...filters })
        "
        :headers="reportStore.headers"
        :items="reports"
        :loading="isFetching"
        density="comfortable"
        class="data-table"
        :items-per-page-options="[{ value: 25, title: '25' }]"
        :items-per-page="25"
        :items-length="count"
        no-filter
        fixed-header
        height="calc(100% - 37px)"
        style="height: 100%; width: 100%"
        :row-props="generateRowProps"
        :hover="reports ? reports.length > 0 : false"
      >
        <template v-slot:[`item.report_id`]="{ value }">
          <div class="text-body-2 font-weight-medium text-link">{{ value }}</div>
        </template>

        <template v-slot:[`item.approvers`]="{ value }">
          <ReportApproversDisplay v-if="value && value.length > 0" :approvers="value" />
          <span v-else>-</span>
        </template>

        <template v-slot:[`item.state`]="{ value }">
          <StatusChip :color="getReportVisualByState(value.value)?.color" :text="value.display">
          </StatusChip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <MoreOptionsMenuButton
            v-if="
              currentUser?.hasPermission('incident_reports.change_incidentreport') ||
              currentUser?.hasPermission('incident_reports.delete_incidentreport') ||
              item.state?.value !== ReportStateEnum.Approved
            "
          >
            <v-list-item
              v-if="currentUser?.hasPermission('incident_reports.change_incidentreport')"
              title="Edit Report"
              @click="openIncidentFormDialog(item)"
            >
              <template #prepend>
                <v-icon size="18" icon="mdi-pencil" class="me-1" />
              </template>
            </v-list-item>

            <template v-if="currentUser?.hasPermission('incident_reports.delete_incidentreport')">
              <v-divider class="pb-1" />
              <v-list-item class="text-error" title="Delete Report" @click="openDeleteDialog(item)">
                <template #prepend>
                  <v-icon size="18" icon="mdi-delete" class="me-1" />
                </template>
              </v-list-item>
            </template>

            <v-list-item title="View Audit Logs" @click="openAuditLogsDialog(item)">
              <template #prepend>
                <v-icon size="18" icon="mdi-list-box-outline" class="me-1" />
              </template>
            </v-list-item>
          </MoreOptionsMenuButton>
        </template>
        <template v-slot:no-data>
          <ReportNoDataAvailablePlaceholder :search-filtered="!!filters.search" />
        </template>
      </v-data-table-server>
    </template>
  </ReportView>

  <IncidentReportDetailFormDialog
    v-model:dialog="incidentFormDialog"
    :incident-report="incidentReport"
    :is-edit="!!incidentReport"
    @saved-report="filterReports()"
  />

  <IncidentReportAuditLogsDialog :report-id="selectedReportId" v-model:dialog="auditLogsDialog" />

  <ConfirmationDialog
    v-model="incidentDeleteDialog"
    v-model:error="deleteError"
    title="Delete Incident Report"
  >
    <template v-slot:message>
      Are you sure you want to delete Incident Report
      <span class="text-medium-high-emphasis font-weight-bold"
        >#{{ incidentReport!.report_id }}</span
      >?

      <div class="pt-2">This action cannot be undone.</div>
    </template>

    <template v-slot:actions>
      <v-spacer></v-spacer>

      <v-btn
        color="error"
        variant="flat"
        class="text-capitalize"
        :loading="deleteInProgress"
        @click.stop="removeIncidentReport(incidentReport!)"
      >
        Delete
      </v-btn>
    </template>
  </ConfirmationDialog>
</template>

<script lang="ts" setup>
import { ref, reactive, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { debounce, merge } from 'lodash'
import { updateFilters } from '@/utils/filters'

import { useAuthStore } from '@/stores'
import { useFetchIncidentReports, useDeleteIncidentReport } from '@/composables/incident-report'
import { useReportStore } from '@/stores/report'
import { useQueryClient } from '@tanstack/vue-query'

import { ReportStateEnum, type IIncidentReport } from '@/models/report'
import type { IReportFilterParam } from '@/services'

import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import MoreOptionsMenuButton from '@/components/common/MoreOptionsMenuButton.vue'
import ReportsSearchBox from '@/components/reports/common/ReportsSearchBox.vue'
import ReportView from './common/ReportView.vue'
import ReportNoDataAvailablePlaceholder from '@/components/reports/common/ReportNoDataAvailablePlaceholder.vue'
import IncidentReportDetailFormDialog from '@/components/reports/incident/IncidentReportDetailFormDialog.vue'
import IncidentReportAuditLogsDialog from '@/components/reports/incident/IncidentReportAuditLogsDialog.vue'
import ReportApproversDisplay from '@/components/reports/common/ReportApproversDisplay.vue'

// CORE
const queryClient = useQueryClient()
const route = useRoute()
const router = useRouter()

const filters = reactive<IReportFilterParam>({
  search: route.query.search as string,
  page: route.query.page ? Number(route.query.page) : 1,
  state: route.query.state ? Number(route.query.state) : undefined,
  site: route.query.site ? Number(route.query.site) : undefined,
  client: route.query.client ? Number(route.query.client) : undefined,
  owner: route.query.owner ? Number(route.query.owner) : undefined,
  created_after: route.query.created_after as string,
  created_before: route.query.created_before as string
})

const currentSearch = ref<string>(filters.search ?? '')

const incidentReport = ref<IIncidentReport>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const reportStore = useReportStore()
const { getReportVisualByState } = storeToRefs(reportStore)

// FETCH
const { reports, count, isFetching, error: fetchError, queryKey } = useFetchIncidentReports(filters)

function filterReports() {
  queryClient.fetchQuery({ queryKey: queryKey.value })
}

watch(
  filters,
  (value) => {
    /* this will update the URL query based on param values. */
    router
      .replace({
        name: route.name!,
        query: value as Record<string, any>
      })
      .catch((e: Error) => e) //catch navigation duplication error
  },
  {
    immediate: true
  }
)

// DELETE
const incidentDeleteDialog = ref(false)

function openDeleteDialog(report: IIncidentReport) {
  incidentReport.value = report
  incidentDeleteDialog.value = true
}

const { deleteInProgress, deleteError, deleteIncidentReport } = useDeleteIncidentReport()

function removeIncidentReport(report: IIncidentReport) {
  deleteIncidentReport(report.id!, {
    onSuccess: () => {
      incidentDeleteDialog.value = false
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: queryKey.value })
  })
}

// CREATE / EDIT
const incidentFormDialog = ref(false)

function openIncidentFormDialog(report?: IIncidentReport) {
  incidentReport.value = report
  incidentFormDialog.value = true
}

// UTILS
function generateRowProps(prop: { index: number; item: IIncidentReport }) {
  return {
    onClick: () => router.push({ name: 'incident-detail', params: { id: prop.item.id } })
  }
}

// debouncing
const debounceFilterIncidentReports = debounce(function (filterParams: IReportFilterParam) {
  // mutates filters
  merge(filters, filterParams)
  queryClient.invalidateQueries({ queryKey: queryKey.value })
}, 300)

// audit logs
const selectedReportId = ref<number | null>(null)
const auditLogsDialog = ref(false)

function openAuditLogsDialog(incidentReport: IIncidentReport) {
  selectedReportId.value = incidentReport.id!
  auditLogsDialog.value = true
}
</script>

<template>
  <ReportView v-model:error="fetchError" @openDialog="openCCTVFormDialog()">
    <template #search>
      <ReportsSearchBox
        v-model:search="currentSearch"
        @update:search="
          (newSearch: string) =>
            debounceFilterCCTVReports({ ...filters, page: 1, search: newSearch })
        "
        :filters="filters"
        @filter-applied="(newFilter) => updateFilters(filters, newFilter)"
      />
    </template>

    <template #content>
      <v-data-table-server
        v-model:page="filters.page"
        :headers="cctvReportTableHeaders"
        :items="reports"
        :loading="isFetching"
        density="comfortable"
        class="data-table"
        :items-per-page-options="[{ value: 25, title: '25' }]"
        :items-per-page="25"
        :items-length="count"
        no-filter
        fixed-header
        height="calc(100% - 37px)"
        style="height: 100%; width: 100%"
        :row-props="generateRowProps"
        :hover="reports ? reports.length > 0 : false"
      >
        <template v-slot:[`item.report_id`]="{ value }">
          <div class="text-body-2 font-weight-medium text-link">{{ value }}</div>
        </template>

        <template v-slot:[`item.approvers`]="{ value }">
          <ReportApproversDisplay :approvers="value" />
        </template>

        <template v-slot:[`item.client`]="{ value }">
          <div>{{ value.name }}</div>
        </template>

        <template v-slot:[`item.state`]="{ value }">
          <StatusChip :color="getReportVisualByState(value.value)?.color" :text="value.display">
          </StatusChip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <MoreOptionsMenuButton
            v-if="
              currentUser?.hasPermission('cctv_reports.change_cctvreport') ||
              currentUser?.hasPermission('cctv_reports.delete_cctvreport') ||
              item.state?.value !== ReportStateEnum.Approved
            "
          >
            <v-list-item
              v-if="currentUser?.hasPermission('cctv_reports.change_cctvreport')"
              title="Edit Report"
              @click="openCCTVFormDialog(item)"
            >
              <template #prepend>
                <v-icon size="18" icon="mdi-pencil" class="me-1" />
              </template>
            </v-list-item>

            <template v-if="currentUser?.hasPermission('cctv_reports.delete_cctvreport')">
              <v-divider class="pb-1" />
              <v-list-item class="text-error" title="Delete Report" @click="openDeleteDialog(item)">
                <template #prepend>
                  <v-icon size="18" icon="mdi-delete" class="me-1" />
                </template>
              </v-list-item>
            </template>
          </MoreOptionsMenuButton>
        </template>

        <template v-slot:no-data>
          <ReportNoDataAvailablePlaceholder :search-filtered="!!filters.search" />
        </template>
      </v-data-table-server>
    </template>
  </ReportView>

  <CCTVReportDetailFormDialog
    v-model:dialog="cctvFormDialog"
    :cctv-report="cctvReport"
    :is-edit="!!cctvReport"
    @saved-report="filterReports()"
  />
  <ConfirmationDialog
    v-model="cctvDeleteDialog"
    v-model:error="deleteError"
    title="Delete CCTV Report"
  >
    <template v-slot:message>
      Are you sure you want to delete CCTV Report
      <span class="text-medium-high-emphasis font-weight-bold">#{{ cctvReport?.report_id }}</span
      >?

      <div class="pt-2">This action cannot be undone.</div>
    </template>

    <template v-slot:actions>
      <v-spacer></v-spacer>

      <v-btn
        color="error"
        variant="flat"
        class="text-capitalize"
        :loading="deleteInProgress"
        @click.stop="removeCCTVReport(cctvReport!)"
      >
        Delete
      </v-btn>
    </template>
  </ConfirmationDialog>
</template>

<script lang="ts" setup>
import { ref, reactive, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { debounce, merge } from 'lodash'
import { updateFilters } from '@/utils/filters'

import { useAuthStore } from '@/stores'
import { useFetchCCTVReports, useDeleteCCTVReport } from '@/composables/cctv-report'
import { useReportStore } from '@/stores/report'
import { useQueryClient } from '@tanstack/vue-query'

import { ReportStateEnum, type ICCTVReport } from '@/models/report'
import type { IReportFilterParam } from '@/services'

import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import MoreOptionsMenuButton from '@/components/common/MoreOptionsMenuButton.vue'
import ReportsSearchBox from '@/components/reports/common/ReportsSearchBox.vue'
import ReportView from './common/ReportView.vue'
import ReportNoDataAvailablePlaceholder from '@/components/reports/common/ReportNoDataAvailablePlaceholder.vue'
import CCTVReportDetailFormDialog from '@/components/reports/cctv/CCTVReportDetailFormDialog.vue'
import ReportApproversDisplay from '@/components/reports/common/ReportApproversDisplay.vue'
import { format } from 'date-fns'
import { computed } from 'vue'

// CORE
const queryClient = useQueryClient()
const route = useRoute()
const router = useRouter()

// TODO:LF-2023-01-15 - Why aren't all the fields (except page) defaulted to undefined
const filters = reactive<IReportFilterParam>({
  search: route.query.search as string,
  page: route.query.page ? Number(route.query.page) : 1,
  state: route.query.state ? Number(route.query.state) : undefined,
  site: route.query.site ? Number(route.query.site) : undefined,
  client: route.query.client ? Number(route.query.client) : undefined,
  owner: route.query.owner ? Number(route.query.owner) : undefined,
  created_after: route.query.created_after as string,
  created_before: route.query.created_before as string
})

const currentSearch = ref<string>(filters.search ?? '')

const cctvReport = ref<ICCTVReport>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const reportStore = useReportStore()
const { getReportVisualByState } = storeToRefs(reportStore)

const cctvReportTableHeaders = computed(() => {
  const headers = [...reportStore.headers]

  // location column header index
  const ownerHeaderIndex = headers.findIndex((value) => value.value === 'owner')

  // inserting shift columns after location column
  headers.splice(
    ownerHeaderIndex,
    0, // no elements to remove
    {
      title: 'Shift Start',
      key: 'shift.start',
      value(item: any) {
        return format(item.shift_start, 'MMMM dd, yyyy - HH:mm')
      }
    },
    {
      title: 'Shift Start',
      key: 'shift.end',
      value(item: any) {
        return format(item.shift_end, 'MMMM dd, yyyy - HH:mm')
      }
    }
  )

  return headers
})

// FETCH
const { reports, count, isFetching, error: fetchError, queryKey } = useFetchCCTVReports(filters)

function filterReports() {
  queryClient.fetchQuery({ queryKey: queryKey.value })
}

watch(
  filters,
  (value) => {
    /* this will update the URL query based on param values. */
    router
      .replace({
        name: route.name!,
        query: value as Record<string, any>
      })
      .catch((e: Error) => e) //catch navigation duplication error
  },
  {
    immediate: true
  }
)

// DELETE
const cctvDeleteDialog = ref(false)

function openDeleteDialog(report: ICCTVReport) {
  cctvReport.value = report
  cctvDeleteDialog.value = true
}

const { deleteInProgress, deleteError, deleteCCTVReport } = useDeleteCCTVReport()

function removeCCTVReport(report: ICCTVReport) {
  deleteCCTVReport(report.id!, {
    onSuccess: () => {
      cctvDeleteDialog.value = false
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: queryKey.value })
  })
}

// CREATE / EDIT
const cctvFormDialog = ref(false)

function openCCTVFormDialog(report?: ICCTVReport) {
  cctvReport.value = report
  cctvFormDialog.value = true
}

// UTILS
function generateRowProps(prop: { index: number; item: ICCTVReport }) {
  return {
    onClick: () => router.push({ name: 'cctv-detail', params: { id: prop.item.id } })
  }
}

// debouncing
const debounceFilterCCTVReports = debounce(function (filterParams: IReportFilterParam) {
  // mutates filters
  merge(filters, filterParams)
  queryClient.invalidateQueries({ queryKey: queryKey.value })
}, 300)
</script>

import type {
  IPostOrderInstructionData,
  IPostOrderInstructionsSectionData,
  IPostOrderInstructionsFieldData,
  IPostOrderInstructionsFieldOptionData,
  IPostOrderData,
  IPostOrderInstructionsFieldConstraintData,
  IPostOrderInstructionsFieldConstraintConditionData
} from '@/models/post-order'
import type { IPostOrderFilterParam, IPostOrderService } from './post-order.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'

/**
 * A class representing an PostOrderService API handler.
 */
export class PostOrderService implements IPostOrderService {
  private endpoint: string

  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/post-orders/'
  }

  /* ### Post Order ### */
  /** Method to fetch a list of post orders from the API */
  fetchPostOrders(filter?: IPostOrderFilterParam): Promise<IPostOrderData[]> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined

    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  /** Method to fetch a single post order by their ID from the API */
  fetchPostOrder(id: number): Promise<IPostOrderData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  /** Method to create a new post order on the API */
  createPostOrder(data: IPostOrderData): Promise<IPostOrderData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  /** Method to update an existing post order on the API */
  updatePostOrder(data: IPostOrderData): Promise<IPostOrderData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  /** Method to delete a post order by their ID from the API */
  deletePostOrder(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  /* ### Instructions ### */
  /** Method to fetch a list of post order instructions from the API */
  fetchPostOrderInstructions(postOrderId: number): Promise<IPostOrderInstructionData[]> {
    return this.axios.get(`${this.endpoint}${postOrderId}/instructions/`).then(({ data }) => data)
  }

  /** Method to fetch a single post order instruction by their ID from the API */
  fetchPostOrderInstruction(postOrderId: number, id: number): Promise<IPostOrderInstructionData> {
    return this.axios
      .get(`${this.endpoint}${postOrderId}/instructions/${id}/`)
      .then(({ data }) => data)
  }

  /** Method to create a new post order instruction on the API */
  createPostOrderInstruction(
    postOrderId: number,
    data: IPostOrderInstructionData
  ): Promise<IPostOrderInstructionData> {
    return this.axios
      .post(`${this.endpoint}${postOrderId}/instructions/`, data)
      .then(({ data }) => data)
  }

  /** Method to update an existing post order instruction on the API */
  updatePostOrderInstruction(
    postOrderId: number,
    data: IPostOrderInstructionData
  ): Promise<IPostOrderInstructionData> {
    return this.axios
      .patch(`${this.endpoint}${postOrderId}/instructions/${data.id}/`, data)
      .then(({ data }) => data)
  }

  /** Method to delete a post order instruction by their ID from the API */
  deletePostOrderInstruction(postOrderId: number, id: number): Promise<void> {
    return this.axios
      .delete(`${this.endpoint}${postOrderId}/instructions/${id}/`)
      .then(({ data }) => data)
  }

  /** Method to update an existing post order instruction on the API */
  setPostOrderInstructionState(
    postOrderId: number,
    instructionId: number,
    state: number
  ): Promise<IPostOrderInstructionData> {
    return this.axios
      .put(`${this.endpoint}${postOrderId}/instructions/${instructionId}/set-state/${state}/`)
      .then(({ data }) => data)
  }

  /* ### Sections ### */
  /** Method to fetch a list of post order section from the API */
  fetchPostOrderInstructionsSections(
    postOrderId: number,
    instructionId: number
  ): Promise<IPostOrderInstructionsSectionData[]> {
    return this.axios
      .get(`${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/`)
      .then(({ data }) => data)
  }

  /** Method to fetch a single post order section by their ID from the API */
  fetchPostOrderInstructionsSection(
    postOrderId: number,
    instructionId: number,
    id: number
  ): Promise<IPostOrderInstructionsSectionData> {
    return this.axios
      .get(`${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${id}/`)
      .then(({ data }) => data)
  }

  /** Method to create a new post order section on the API */
  createPostOrderInstructionsSection(
    postOrderId: number,
    instructionId: number,
    data: IPostOrderInstructionsSectionData
  ): Promise<IPostOrderInstructionsSectionData> {
    return this.axios
      .post(`${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/`, data)
      .then(({ data }) => data)
  }

  /** Method to update an existing post order section on the API */
  updatePostOrderInstructionsSection(
    postOrderId: number,
    instructionId: number,
    data: IPostOrderInstructionsSectionData
  ): Promise<IPostOrderInstructionsSectionData> {
    return this.axios
      .patch(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${data.id}/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to delete a post order section by their ID from the API */
  deletePostOrderInstructionsSection(
    postOrderId: number,
    instructionId: number,
    id: number
  ): Promise<void> {
    return this.axios
      .delete(`${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${id}/`)
      .then(({ data }) => data)
  }

  /* ### Fields ### */
  /** Method to fetch a list of post order section fields from the API */
  fetchPostOrderInstructionsFields(
    postOrderId: number,
    instructionId: number,
    sectionId: number
  ): Promise<IPostOrderInstructionsFieldData[]> {
    return this.axios
      .get(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/`
      )
      .then(({ data }) => data)
  }

  /** Method to fetch a single post order section field by their ID from the API */
  fetchPostOrderInstructionsField(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    id: number
  ): Promise<IPostOrderInstructionsFieldData> {
    return this.axios
      .get(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${id}/`
      )
      .then(({ data }) => data)
  }

  /** Method to create a new post order section field on the API */
  createPostOrderInstructionsField(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    data: IPostOrderInstructionsFieldData
  ): Promise<IPostOrderInstructionsFieldData> {
    return this.axios
      .post(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to update an post existing post order section field on the API */
  updatePostOrderInstructionsField(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    data: IPostOrderInstructionsFieldData
  ): Promise<IPostOrderInstructionsFieldData> {
    return this.axios
      .patch(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${data.id}/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to delete a post order section field by their ID from the API */
  deletePostOrderInstructionsField(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    id: number
  ): Promise<void> {
    return this.axios
      .delete(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${id}/`
      )
      .then(({ data }) => data)
  }

  /* ### Field Options ### */
  /** Method to fetch a list of post order section field option from the API */
  fetchPostOrderInstructionsFieldOptions(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number
  ): Promise<IPostOrderInstructionsFieldOptionData[]> {
    return this.axios
      .get(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/options/`
      )
      .then(({ data }) => data)
  }

  /** Method to fetch a single post order section field option by their ID from the API */
  fetchPostOrderInstructionsFieldOption(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    id: number
  ): Promise<IPostOrderInstructionsFieldOptionData> {
    return this.axios
      .get(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/options/${id}/`
      )
      .then(({ data }) => data)
  }

  /** Method to create a new post order section field option on the API */
  createPostOrderInstructionsFieldOption(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    data: IPostOrderInstructionsFieldOptionData
  ): Promise<IPostOrderInstructionsFieldOptionData> {
    return this.axios
      .post(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/options/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to update an existing post order section field option on the API */
  updatePostOrderInstructionsFieldOption(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    data: IPostOrderInstructionsFieldOptionData
  ): Promise<IPostOrderInstructionsFieldOptionData> {
    return this.axios
      .patch(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/options/${data.id}/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to delete a post order section field option by their ID from the API */
  deletePostOrderInstructionsFieldOption(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    id: number
  ): Promise<void> {
    return this.axios
      .delete(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/options/${id}/`
      )
      .then(({ data }) => data)
  }

  /* ### Field Constraints ### */
  /** Method to fetch a list of post order section field constraint from the API */
  fetchPostOrderInstructionsFieldConstraints(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number
  ): Promise<IPostOrderInstructionsFieldConstraintData[]> {
    return this.axios
      .get(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/`
      )
      .then(({ data }) => data)
  }

  /** Method to fetch a single post order section field constraint by their ID from the API */
  fetchPostOrderInstructionsFieldConstraint(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    id: number
  ): Promise<IPostOrderInstructionsFieldConstraintData> {
    return this.axios
      .get(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/${id}/`
      )
      .then(({ data }) => data)
  }

  /** Method to create a new post order section field constraint on the API */
  createPostOrderInstructionsFieldConstraint(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    data: IPostOrderInstructionsFieldConstraintData
  ): Promise<IPostOrderInstructionsFieldConstraintData> {
    return this.axios
      .post(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to update an existing post order section field constraint on the API */
  updatePostOrderInstructionsFieldConstraint(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    data: IPostOrderInstructionsFieldConstraintData
  ): Promise<IPostOrderInstructionsFieldConstraintData> {
    return this.axios
      .patch(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/${data.id}/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to delete a post order section field constraint by their ID from the API */
  deletePostOrderInstructionsFieldConstraint(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    id: number
  ): Promise<void> {
    return this.axios
      .delete(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/${id}/`
      )
      .then(({ data }) => data)
  }

  /* ### Field Constraint Conditions ### */
  /** Method to fetch a list of post order section field constraint from the API */
  fetchPostOrderInstructionsFieldConstraintConditions(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    constraintId: number
  ): Promise<IPostOrderInstructionsFieldConstraintConditionData[]> {
    return this.axios
      .get(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/${constraintId}/conditions/`
      )
      .then(({ data }) => data)
  }

  /** Method to fetch a single post order section field constraint by their ID from the API */
  fetchPostOrderInstructionsFieldConstraintCondition(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    constraintId: number,
    id: number
  ): Promise<IPostOrderInstructionsFieldConstraintConditionData> {
    return this.axios
      .get(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/${constraintId}/conditions/${id}/`
      )
      .then(({ data }) => data)
  }

  /** Method to create a new post order section field constraint on the API */
  createPostOrderInstructionsFieldConstraintCondition(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    constraintId: number,
    data: IPostOrderInstructionsFieldConstraintConditionData
  ): Promise<IPostOrderInstructionsFieldConstraintConditionData> {
    return this.axios
      .post(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/${constraintId}/conditions/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to update an existing post order section field constraint on the API */
  updatePostOrderInstructionsFieldConstraintCondition(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    constraintId: number,
    data: IPostOrderInstructionsFieldConstraintConditionData
  ): Promise<IPostOrderInstructionsFieldConstraintConditionData> {
    return this.axios
      .patch(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/${constraintId}/conditions/${data.id}/`,
        data
      )
      .then(({ data }) => data)
  }

  /** Method to delete a post order section field constraint by their ID from the API */
  deletePostOrderInstructionsFieldConstraintCondition(
    postOrderId: number,
    instructionId: number,
    sectionId: number,
    fieldId: number,
    constraintId: number,
    id: number
  ): Promise<void> {
    return this.axios
      .delete(
        `${this.endpoint}${postOrderId}/instructions/${instructionId}/sections/${sectionId}/fields/${fieldId}/constraints/${constraintId}/conditions/${id}/`
      )
      .then(({ data }) => data)
  }
}

// Default export instantiation of the PostOrderService
const postOrderService = new PostOrderService(axios)
export default postOrderService

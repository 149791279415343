import { createRouter, createWebHistory } from 'vue-router'
import { setupGuards } from './guards'
import { asyncRoutes, constantRoutes } from './routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: constantRoutes
})

setupGuards(router)

export function resetRouter() {
  // Iterate over the async routes and remove each one from the router
  asyncRoutes.forEach((route) => {
    if (route.children) {
      route.children.forEach((childRoute) => {
        if (childRoute.name && router.hasRoute(childRoute.name)) {
          router.removeRoute(childRoute.name)
        }
      })
    } else {
      if (route.name && router.hasRoute(route.name)) {
        router.removeRoute(route.name)
      }
    }
  })
}

export default router

import 'flag-icons/css/flag-icons.min.css'
import 'v-phone-input/dist/v-phone-input.css'
// v-phone-input does not support the correct typing as yet
// @ts-ignore:next-line
import { createVPhoneInput } from 'v-phone-input'

// https://github.com/paul-thebaud/v-phone-input
export const vPhoneInput = createVPhoneInput({
  countryIconMode: 'svg',
  displayFormat: 'international',
  defaultCountry: 'CA'
})

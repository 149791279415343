<template>
  <v-textarea ref="sampleObservationTextAreaRef" v-model="text" :loading="loading" v-bind="$attrs">
    <template #append-inner>
      <v-tooltip
        location="bottom"
        open-delay="450"
        v-if="!text && !fetchError && samples.length > 0"
      >
        <template #activator="{ props }">
          <v-icon
            v-bind="props"
            @click="menu = !menu"
            style="margin-inline-start: 4px"
            class="v-textarea__menu-icon"
            icon="$dropdown"
            :class="{ 'v-textarea--active-menu': menu }"
          />
        </template>
        Add note from template
      </v-tooltip>
    </template>
  </v-textarea>
  <v-menu
    v-model="menu"
    max-height="310"
    :open-on-click="false"
    :close-on-content-click="false"
    :target="sampleObservationTextAreaRef"
  >
    <v-list select-strategy="single-independent" tabindex="-1">
      <v-virtual-scroll renderless :items="samples">
        <template #default="{ item, index, itemRef }">
          <v-list-item
            :key="index"
            :ref="itemRef"
            :title="item"
            @click.stop="selectSampleObservation(item)"
          />
        </template>
      </v-virtual-scroll>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { storeToRefs } from 'pinia'
import type { VTextarea } from 'vuetify/components'

import { useSampleObservationStore } from '@/stores/report'

import type { ISystemError } from '@/models/error'

const text = defineModel<string>()

const menu = ref(false)
const sampleObservationTextAreaRef = ref<VTextarea>()

const sampleObservationStore = useSampleObservationStore()

const { getSampleObservations: sampleObservations } = storeToRefs(sampleObservationStore)

const loading = ref(false)

const fetchError = ref<ISystemError | null>(null)

const clearErrors = () => {
  fetchError.value = null
}

function fetchSampleObservations() {
  clearErrors()

  loading.value = true

  return sampleObservationStore
    .fetchSampleObservations()
    .catch((e: any) => {
      fetchError.value = e
      console.warn(e)
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  fetchSampleObservations()
})

const samples = computed(() => {
  return sampleObservations.value.map((observation) => {
    return observation.text
  })
})

function selectSampleObservation(item: string) {
  text.value = item
  menu.value = false
}
</script>

<style lang="scss">
.v-textarea {
  &__menu-icon {
    margin-inline-start: 4px;
    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
  & .v-textarea--active-menu {
    opacity: var(--v-high-emphasis-opacity);
    transform: rotate(180deg);
  }
}
</style>

import { defineStore } from 'pinia'
import type { IReportStoreState } from './report.types'
import { ReportStateEnum, ReportActivityStatusEnum } from '@/models/report'

export const useReportStore = defineStore('report', {
  state: () =>
    ({
      headers: [
        { title: 'ID', value: 'report_id' },
        { title: 'Client', value: 'client' },
        {
          title: 'Location',
          value: 'site.address.address1'
        },
        { title: 'Reporter', value: 'owner' },
        { title: 'State', value: 'state' },
        { title: 'Approved by', value: 'approvers' },
        { title: '', value: 'actions' }
      ],

      reportStateVisuals: [
        {
          state: ReportStateEnum.Draft,
          icon: 'mdi-file-document-outline',
          color: 'rgba(var(--v-theme-draft))'
        },
        {
          state: ReportStateEnum.Submitted,
          icon: 'mdi-file-send-outline',
          color: 'rgba(var(--v-theme-submitted))'
        },
        {
          state: ReportStateEnum.Approved,
          icon: 'mdi-check',
          color: 'rgba(var(--v-theme-approved))'
        }
      ],

      incidentStatusVisuals: [
        {
          state: ReportActivityStatusEnum.SecurityLevel1,
          display: 'Security Level 1',
          color: '#39B24A'
        },
        {
          state: ReportActivityStatusEnum.SecurityLevel2,
          display: 'Security Level 2',
          color: '#F7931E'
        },
        {
          state: ReportActivityStatusEnum.SecurityLevel3,
          display: 'Security Level 3',
          color: '#FF0000'
        }
      ]
    }) as IReportStoreState,

  getters: {
    getReportVisualByState: (state: IReportStoreState) => (reportState: ReportStateEnum) => {
      return state.reportStateVisuals.find((stateVisual) => stateVisual.state == reportState)
    },

    getReportIncidentStatusVisualByState:
      (state: IReportStoreState) => (reportState: ReportActivityStatusEnum) => {
        return state.incidentStatusVisuals.find((stateVisual) => stateVisual.state == reportState)
      }
  }
})

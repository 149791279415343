<template>
  <BasePage title="Employees" subtitle="Manage employees employee information">
    <template #page-header:actions>
      <v-btn
        color="primary"
        depressed
        v-if="currentUser?.hasPermission('employees.add_employee')"
        @click="openEmployeeFormDialog()"
      >
        <template #prepend>
          <v-icon start class="hidden-sm-and-down me-0"> {{ route.meta.icon }} </v-icon>
        </template>
        Create Employee
      </v-btn>
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-card flat height="calc(100vh - 286px)" class="px-4 rounded-lg">
      <v-toolbar flat height="84" color="transparent">
        <EmployeesSearchBox
          v-model:search="currentSearch"
          @update:search="
            (newSearch: string) =>
              debounceFilterEmployees({ ...employeeFilters, page: 1, search: newSearch })
          "
          :filters="employeeFilters"
          @update:filters="(newFilter) => updateFilters(employeeFilters, newFilter)"
        />
      </v-toolbar>

      <EmployeeList
        v-model:page="currentPage"
        :loading="isLoading"
        :employees="employees ?? []"
        :count="count"
        @employee-selected="openEmployeeDetailDrawer"
        @employee-create-pressed="openEmployeeFormDialog()"
        @employee-edit-pressed="openEmployeeFormDialog"
        @employee-delete-pressed="openDeleteEmployeeDialog"
        @employee-change-password-pressed="openEmployeeChangePasswordDialog"
        @employee-change-sites-pressed="openEmployeeSitesDialog"
        @employee-set-active-pressed="openEmployeeSetActiveDialog"
      />
    </v-card>

    <ConfirmationDialog
      v-if="selectedEmployee"
      width="500"
      title="Delete employee"
      v-model="employeeDeleteDialog"
      v-model:error="deleteError"
    >
      <template #message>
        Are you sure you want to delete
        <span class="text-medium-high-emphasis font-weight-bold">{{
          selectedEmployee.getFullName()
        }}</span
        >?
        <br />
        <div class="pt-2">This action cannot be undone.</div>
      </template>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          variant="flat"
          :disabled="deleteInProgress"
          :loading="deleteInProgress"
          @click="removeEmployee(selectedEmployee!)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationDialog>
  </BasePage>

  <EmployeeDetailDrawer
    v-if="selectedEmployee"
    v-model:drawer="rightDrawer"
    :employee-id="selectedEmployee.id!"
    @employee-edit-pressed="openEmployeeFormDialog"
    @employee-delete-pressed="openDeleteEmployeeDialog"
    @employee-change-password-pressed="openEmployeeChangePasswordDialog"
    @employee-set-active-pressed="openEmployeeSetActiveDialog"
  >
  </EmployeeDetailDrawer>

  <EmployeeFormDialog
    v-model:dialog="isEmployeeFormDialogActive"
    :employee="selectedEmployee!"
    :isEdit="!!selectedEmployee"
    ref="employeeFormDialogRef"
  />

  <EmployeeDetailSitesFormDialog
    v-if="selectedEmployee"
    :employeeId="selectedEmployee.id!"
    v-model:dialog="isEmployeeSitesDialogActive"
  />

  <template v-if="selectedEmployee && currentUser?.hasPermission('users.change_password')">
    <UserChangePasswordFormDialog v-model:dialog="changePasswordDialog" :user="selectedEmployee" />

    <UserSetActiveFormDialog v-model:dialog="setActiveDialog" :user="selectedEmployee" />
  </template>
</template>

<script lang="ts" setup>
import { debounce, merge } from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'

import type { IEmployeeFilterParam } from '@/services'
import type { IEmployee } from '@/models/employee'

import { useRoute, useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import { useAuthStore } from '@/stores'
import { useDeleteEmployee, useFetchEmployees } from '@/composables/employee'
import { updateFilters } from '@/utils/filters'
import { DebounceDelay } from '@/utils/constants'

import BasePage from '@/components/base/BasePage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import EmployeeDetailDrawer from '@/components/employees/EmployeeDetailDrawer.vue'
import EmployeeDetailSitesFormDialog from '@/components/employees/EmployeeDetailSitesFormDialog.vue'
import EmployeeFormDialog from '@/components/employees/EmployeeFormDialog.vue'
import EmployeeList from '@/components/employees/EmployeeList.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import EmployeesSearchBox from '@/components/employees/EmployeesSearchBox.vue'
import UserChangePasswordFormDialog from '@/components/user/UserChangePasswordFormDialog.vue'
import UserSetActiveFormDialog from '@/components/user/UserSetActiveFormDialog.vue'

const route = useRoute()
const router = useRouter()

const rightDrawer = ref(false)

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

// set default filters from route query param
// only use valid filter fields from routes query params including `search` and `page` and other valid fields
const employeeFilters = reactive<IEmployeeFilterParam>({
  search: route.query.search as string,
  page: route.query.page ? Number(route.query.page) : 1,
  is_active: route.query.is_active as string
})

//We use a separate ref for the search model in order to not update the queryKey once the search is updated
const currentSearch = ref<string>(employeeFilters.search ?? '')

watch(
  employeeFilters,
  (value) => {
    /* this will update the URL query based on param values. */
    router
      .replace({
        name: route.name!,
        query: value as Record<string, any>
      })
      .catch((e: Error) => e) //catch navigation duplication error
  },
  {
    immediate: true
  }
)

const currentPage = computed({
  get() {
    return employeeFilters.page!
  },
  set(page: number) {
    employeeFilters.page = page
  }
})

const selectedEmployee = ref<IEmployee>()

function openEmployeeDetailDrawer(employee: IEmployee) {
  selectedEmployee.value = employee

  rightDrawer.value = true
}

const isEmployeeFormDialogActive = ref(false)

function openEmployeeFormDialog(employee?: IEmployee) {
  selectedEmployee.value = employee

  isEmployeeFormDialogActive.value = true
}

const isEmployeeSitesDialogActive = ref(false)

function openEmployeeSitesDialog(employee: IEmployee) {
  selectedEmployee.value = employee

  isEmployeeSitesDialogActive.value = true
}

const employeeDeleteDialog = ref(false)

function openDeleteEmployeeDialog(employee: IEmployee) {
  selectedEmployee.value = employee
  employeeDeleteDialog.value = true
}

function closeDrawerAndDialog() {
  employeeDeleteDialog.value = false

  // incase of delete in detail drawer
  rightDrawer.value = false
}

const changePasswordDialog = ref(false)
const setActiveDialog = ref(false)

function openEmployeeChangePasswordDialog(employee: IEmployee) {
  selectedEmployee.value = employee

  changePasswordDialog.value = true
}
function openEmployeeSetActiveDialog(employee: IEmployee) {
  selectedEmployee.value = employee

  setActiveDialog.value = true
}

// CORE
const queryClient = useQueryClient()

const { employees, count, isLoading, error, queryKey } = useFetchEmployees(employeeFilters)

// debouncing
const debounceFilterEmployees = debounce(function (filterParams: IEmployeeFilterParam) {
  // mutates filters
  merge(employeeFilters, filterParams)
  queryClient.invalidateQueries({ queryKey: queryKey.value })
}, DebounceDelay.Long)

function clearErrors() {
  error.value = null
}
const {
  isPending: deleteInProgress,
  error: deleteError,
  mutate: deleteEmployee
} = useDeleteEmployee()

function removeEmployee(employee: IEmployee) {
  deleteEmployee(employee.id!, {
    onSuccess: () => {
      closeDrawerAndDialog()
    }
  })
}
</script>

<style lang="scss">
.site-list .v-list-item--three-line .v-list-item-subtitle {
  -webkit-line-clamp: 5;
}

.card-list {
  --v-card-list-gap: 0.25rem;

  &.v-list {
    padding-block: 0;
  }

  .v-list-item {
    min-block-size: unset;
    min-block-size: auto !important;
    padding-block: 0 !important;
    padding-inline: 0 !important;

    > .v-ripple__container {
      opacity: 0;
    }

    &:not(:last-child) {
      padding-block-end: var(--v-card-list-gap) !important;
    }
  }

  .v-list-item:hover,
  .v-list-item:focus,
  .v-list-item:active,
  .v-list-item.active {
    > .v-list-item__overlay {
      opacity: 0 !important;
    }
  }
}
</style>

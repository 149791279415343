<template>
  <v-select
    v-model="modelValue"
    label="State"
    hide-details="auto"
    :items="reportStateVisuals"
    :item-title="(item: IReportStateVisual) => ReportStateEnum[item.state]"
    item-value="state"
    chips
    v-bind="$attrs"
  >
    <template #chip="{ item, props }">
      <v-list-item
        density="compact"
        class="pl-2 py-0"
        v-bind="props"
        :title="`${ReportStateEnum[item.raw.state]}`"
      ></v-list-item>
    </template>
    <template #item="{ index, props }">
      <v-list-item v-bind="props" :class="{ 'mb-2': index !== reportStateVisuals.length - 1 }">
      </v-list-item>
    </template>
  </v-select>
</template>

<script setup lang="ts">
import { ReportStateEnum } from '@/models/report'
import { useReportStore, type IReportStateVisual } from '@/stores'
import { storeToRefs } from 'pinia'

const reportStore = useReportStore()
const { reportStateVisuals } = storeToRefs(reportStore)

const modelValue = defineModel({ required: false })
</script>

<style lang="sass">
.step-badge
  float: left
  font-size: 25px
  line-height: 50px
  margin-right: 18px
  text-align: center
  border-width: 2px

  &__inner
    height: 40px
    width: 40px
    margin: 0 6px
    border-width: 2px
    border-style: solid
    display: flex
    justify-content: center
    align-items: center

    background: #fff
    border-radius: 50%
    width: 50px
    height: 50px
    line-height: 46px
    box-sizing: border-box
</style>

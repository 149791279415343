<template>
  <BaseRightDrawer v-model:drawer="drawer" @right-drawer-close="drawer = false">
    <template #loading>
      <v-progress-linear
        class="position-absolute"
        style="top: 0"
        indeterminate
        :active="isPending"
      />
    </template>

    <template #header-before-close>
      <ClientDetailOptionsButton
        v-if="client"
        class="me-4"
        :client="client"
        @client-edit-pressed="(client: IClient) => emit('client-edit-pressed', client)"
        @client-delete-pressed="(client: IClient) => emit('client-delete-pressed', client)"
      />
    </template>

    <template #error>
      <ErrorAlert
        v-if="isErrorAlertActive"
        :error="error! || sitesError! || contactsError! || camerasError!"
        @clearErrors="clearErrors()"
      />
    </template>
    <div class="d-flex flex-column flex-grow-1" v-if="client">
      <v-list-item lines="three" class="position-relative rounded-lg mx-6 mb-2 px-0 pb-1 pt-0">
        <v-list-item-action>
          <v-avatar
            variant="flat"
            color="secondary"
            size="74"
            class="mb-6 px-3 text-h4"
            style="border-radius: 5px"
          >
            <span class="text-white font-weight-medium">{{ client.getInitial() }}</span></v-avatar
          >
        </v-list-item-action>
        <v-list-item-title class="text-high-emphasis text-h4 font-weight-bold pt-1 pb-8">
          {{ client.name }}
        </v-list-item-title>
      </v-list-item>

      <v-divider class="mb-4 mx-6 text-medium-emphasis" thickness="2" />

      <div class="d-flex flex-column">
        <SiteListGroup
          :loading="sitesLoading"
          :client="client"
          :sites="sites ?? []"
          :cameras="cameras"
          :cameras-loading="camerasLoading"
        />

        <v-divider
          v-if="currentUser?.hasPermission('clients.view_site')"
          class="mb-4 mx-6 text-medium-emphasis"
          thickness="1"
        />

        <!-- CONTACTS -->
        <ContactListItemGroup :loading="contactsLoading" :client="client" :contacts="contacts" />
        <!-- CONTACTS -->
      </div>

      <v-divider class="mb-4 mx-6 text-medium-emphasis" thickness="1" />

      <v-list-item lines="two" class="position-relative rounded-lg mt-6 mx-6 mb-2 px-0 pb-1 pt-0">
        <v-list-subheader
          class="text-medium-emphasis text-body-2 font-weight-regular pb-3"
          style="min-height: 22px"
        >
          Client Information
        </v-list-subheader>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Last Modified
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              {{ dateAdapter.formatByString(client.modified!, 'PPP pp') }}
            </div>
          </div>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Created At
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              {{ dateAdapter.formatByString(client.created!, 'PPP pp') }}
            </div>
          </div>
        </v-list-item-subtitle>
      </v-list-item>
    </div>
  </BaseRightDrawer>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useDate } from 'vuetify'
import { storeToRefs } from 'pinia'

import type DateFnsUtils from '@date-io/date-fns/build/date-fns-utils'
import type { IClient } from '@/models/client'

import { useAuthStore } from '@/stores'
import { useFetchContacts } from '@/composables/contacts'
import { useFetchSites } from '@/composables/site'
import { useFetchCameras } from '@/composables/camera'
import { useFetchClient } from '@/composables/client'

import BaseRightDrawer from '@/components/base/BaseRightDrawer.vue'
import ClientDetailOptionsButton from './ClientDetailOptionsButton.vue'
import ContactListItemGroup from '../contacts/ContactListItemGroup.vue'
import SiteListGroup from '../sites/SiteListGroup.vue'
import ErrorAlert from '../common/ErrorAlert.vue'

interface Props {
  clientId: number
}

const props = defineProps<Props>()
interface Emit {
  (e: 'client-edit-pressed', client: IClient): void
  (e: 'client-delete-pressed', client: IClient): void
}
const emit = defineEmits<Emit>()

const dateAdapter = useDate() as DateFnsUtils

const drawer = defineModel<boolean>('drawer')

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const clientId = computed(() => props.clientId!)
const { client, isPending, error } = useFetchClient(clientId)

const { sites, isLoading: sitesLoading, error: sitesError } = useFetchSites(clientId)

const {
  loading: contactsLoading,
  error: contactsError,
  contacts,
  clearErrors: clearContactsErrors,
  fetchContacts
} = useFetchContacts()

const {
  loading: camerasLoading,
  error: camerasError,
  clearErrors: clearCameraErrors,
  cameras,
  fetchCameras
} = useFetchCameras()

const isErrorAlertActive = computed(
  () => sitesError.value != null || contactsError.value != null || camerasError.value != null
)

function clearErrors() {
  // clear all errors for possible api calls
  sitesError.value = null

  clearContactsErrors()
  clearCameraErrors()
}

watch(
  () => props.clientId,
  (newClientId, oldClientId) => {
    if (newClientId && newClientId != oldClientId) {
      if (currentUser.value?.hasPermission('contacts.view_contact')) {
        fetchContacts({ client: newClientId })
      }
      if (currentUser.value?.hasPermission('cameras.view_camera')) {
        fetchCameras({ client: newClientId })
      }
    }
  },
  { immediate: true }
)
</script>

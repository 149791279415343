<template>
  <BaseWidget
    variant="flat"
    class="border"
    height="100%"
    max-height="220"
    color="white"
    :loading="weatherLoading"
  >
    <div class="d-flex justify-space-start" v-if="!weatherLoading">
      <div class="d-flex flex-column pe-7 justify-start">
        <h5 class="text-h5 mb-2 text-no-wrap">Weather</h5>
        <span class="mb-7">
          <div v-if="weatherData && weatherData.current_weather">
            {{ weatherDataFormattedCurrentTime }}
          </div>
        </span>
        <div class="text-h3 mb-2">
          <template v-if="weatherErrorMessage">
            <span class="text-h5 text-warning"> {{ weatherErrorMessage }} </span>
          </template>

          <template v-else-if="weatherData">
            {{ weatherData.current_weather.temperature }}&deg;C
          </template>
        </div>
        <div v-if="weatherData">
          <v-chip color="success" label variant="tonal">
            <v-icon start>mdi-send</v-icon>
            <span>{{ weatherData.current_weather.windspeed }}km/h</span>
          </v-chip>
        </div>
      </div>
      <div class="d-flex flex-column align-start justify-start ml-auto" v-if="weatherData">
        <div class="align-self-start">
          <v-icon size="45"> mdi-weather-cloudy </v-icon>
        </div>
      </div>
    </div>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { computed, onMounted } from 'vue'
import { useDate } from 'vuetify'

import type DateFnsUtils from '@date-io/date-fns/build/date-fns-utils'
import BaseWidget from '../base/BaseWidget.vue'

// weather widget
export interface IWeatherParams {
  latitude: string
  longitude: string
  current_weather: string
  timezone: string
}

export interface IWeatherData {
  current_weather: ICurrentWeather
  elevation: number
  latitude: number
  longitude: number
  timezone: string
  timezone_abbreviation: string
  utc_offset_seconds: number
}

export interface ICurrentWeather {
  temperature: number
  windspeed: number
  time: string
}

const dateAdapter = useDate() as DateFnsUtils

const weatherLoading = ref(false)
const weatherErrorMessage = ref('')
const weatherData = ref<IWeatherData>()

const weatherDataFormattedCurrentTime = computed(() =>
  dateAdapter.formatByString(new Date(weatherData.value?.current_weather.time ?? ''), 'E h:mma')
)

//it's of type GeolocationPosition however there is typescript problem on compile
//https://stackoverflow.com/questions/65916073/angular-11-why-cant-the-compiler-find-geolocationposition-during-compiling
function successCallback(position: any) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const params = {
    latitude: position.coords.latitude.toString(),
    longitude: position.coords.longitude.toString(),
    current_weather: 'true',
    timezone: timezone
  }

  genWeatherForecast(params)
}

//user blocks geo location
function errorCallback() {
  weatherErrorMessage.value = 'Not available'
}

async function genWeatherForecast(params: IWeatherParams) {
  weatherLoading.value = true
  await fetch('https://api.open-meteo.com/v1/forecast?' + new URLSearchParams({ ...params }))
    .then(async (response) => {
      weatherData.value = await response.json()
    })
    .catch(errorCallback)
    .finally(() => {
      weatherLoading.value = false
    })
}

onMounted(() => {
  //gets user's current geo location
  navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
})
</script>

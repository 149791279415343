import { AbstractModel } from '../base'
import { Client } from '../client/client'
import type { IClient } from '../client/client.types'
import type { IContact, IContactData } from './contact.type'

/**
 * A class representing a Country
 */
export class Contact extends AbstractModel implements IContact {
  readonly id?: number
  readonly first_name: string
  readonly last_name: string
  readonly email?: string
  readonly phone: string
  readonly title: string
  readonly client: number | IClient | null = null

  constructor(data: IContactData) {
    super()

    // Validate data
    const requiredFields: (keyof IContactData)[] = ['first_name', 'last_name']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.first_name = data.first_name!
    this.last_name = data.last_name!
    this.title = data.title ?? ''
    this.phone = data.phone ?? ''

    if (data.id) {
      this.id = data.id
    }

    if (data.email) {
      this.email = data.email
    }

    if (data.client) {
      this.client = typeof data.client === 'number' ? data.client : new Client(data.client)
    }
  }

  // Returns the user's first and last name
  getFullName(): string {
    return `${this.first_name} ${this.last_name}`
  }

  // Returns the user's name initials
  getInitials(): string {
    const firstInitial = this.first_name[0]
    const lastInitial = this.last_name[0]

    return `${firstInitial}${lastInitial}`.toUpperCase()
  }
}

import type { ICountryData } from '../country'

export const COUNTRY_CODE_MAX_LENGTH = 2

export const countries: ICountryData[] = [
  {
    code: 'CA',
    name: 'Canada'
  },
  {
    code: 'US',
    name: 'United States of America'
  }
]

import { AbstractModel } from '@/models/base'
import type { FieldConstraintState, FormFieldTypeEnum } from './form-field.constants'
import type {
  IFormField,
  IFormFieldConfig,
  IFormFieldConfigData,
  IFormFieldConstraint,
  IFormFieldConstraintCondition,
  IFormFieldConstraintConditionData,
  IFormFieldConstraintData,
  IFormFieldData,
  IFormFieldOption,
  IFormFieldOptionData
} from './form-field.types'

/**
 * A class representing an FormField.
 */
export class FormField extends AbstractModel implements IFormField {
  readonly id?: number
  readonly label: string
  readonly type: FormFieldTypeEnum
  readonly order: number
  readonly config: IFormFieldConfig
  readonly hint: string = ''
  readonly meta_label: string = ''

  constructor(data: IFormFieldData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormFieldData)[] = ['label', 'type', 'order', 'config']
    this.validate(data, requiredFields)

    this.label = data.label!
    this.type = data.type!
    this.order = data.order!
    this.config = new FormFieldConfig(data.config!)

    if (data.id) {
      this.id = data.id
    }
    if (data.hint) {
      this.hint = data.hint
    }
    if (data.meta_label) {
      this.meta_label = data.meta_label
    }
  }

  toString(): string {
    return `(${this.order}). ${this.label}`
  }
}

/**
 * A class representing an FormFieldConfig.
 */
export class FormFieldConfig extends AbstractModel implements IFormFieldConfig {
  readonly id?: number
  readonly width: number = 12
  readonly required: boolean = false
  readonly disabled: boolean = false

  constructor(data: IFormFieldConfigData) {
    super()

    // Validate data
    this.validate(data, [])

    this.width = data.width ?? 12
    this.required = data.required ?? false
    this.disabled = data.disabled ?? false

    if (data.id) {
      this.id = data.id
    }
  }
}

/**
 * A class representing an FormFieldOption.
 */
export class FormFieldOption extends AbstractModel implements IFormFieldOption {
  readonly id?: number
  readonly value: string
  readonly order: number
  readonly field?: number

  constructor(data: IFormFieldOptionData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormFieldOptionData)[] = ['value', 'order']
    this.validate(data, requiredFields)

    this.value = data.value!
    this.order = data.order!

    if (data.id) {
      this.id = data.id
    }

    if (data.field) {
      this.field = data.field
    }
  }

  toString(): string {
    return this.value
  }
}

/**
 * A class representing an FormFieldConstraint.
 */
export class FormFieldConstraint extends AbstractModel implements IFormFieldConstraint {
  readonly id?: number
  readonly state: FieldConstraintState

  constructor(data: IFormFieldConstraintData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormFieldConstraintData)[] = ['state']
    this.validate(data, requiredFields)

    this.state = data.state!

    if (data.id) {
      this.id = data.id
    }
  }

  toString(): string {
    return this.state.toString()
  }
}

/**
 * A class representing an FormFieldConstraintCondition.
 */
export class FormFieldConstraintCondition
  extends AbstractModel
  implements IFormFieldConstraintCondition
{
  readonly id?: number
  readonly option: IFormFieldOption | number
  readonly constraint?: number

  constructor(data: IFormFieldConstraintConditionData) {
    super()

    // Validate data
    const requiredFields: (keyof IFormFieldConstraintConditionData)[] = ['option']
    this.validate(data, requiredFields)

    this.option =
      typeof data.option! === 'number' ? data.option! : new FormFieldOption(data.option!)

    if (data.constraint) {
      this.constraint = data.constraint
    }

    if (data.id) {
      this.id = data.id
    }
  }

  toString(): string {
    return ''
  }
}

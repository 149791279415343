<template>
  <v-col cols="12" md="2" order="2" :order-md="1" class="pt-2">
    <div class="pb-3">
      <b>Location:</b>
      <div class="text-report-grey">{{ observation.location }}</div>
    </div>
    <div class="pb-3">
      <b>Reported By:</b>
      <div class="text-report-grey">{{ observation.reporter }}</div>
    </div>

    <div class="d-flex flex-row">
      <v-btn
        density="compact"
        flat
        variant="tonal"
        class="text-capitalize"
        @click="emit('open-activity-observation-detail')"
        v-if="currentUser?.hasPermission('incident_reports.change_incidentreport')"
      >
        <v-icon left class="hidden-sm-and-down mr-1">mdi-pencil</v-icon>Edit
      </v-btn>

      <ConfirmationDialog
        v-if="currentUser?.hasPermission('incident_reports.delete_incidentreport')"
        width="auto"
        title="Delete observation"
        v-model="observationDeleteDialog"
        v-model:error="deleteError"
      >
        <template #activator="{ props: tooltipProps }">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-btn
                v-bind="{ ...props, ...tooltipProps }"
                density="compact"
                flat
                variant="tonal"
                class="text-capitalize ml-1"
              >
                <v-icon left class="mr-1">mdi-delete </v-icon>Delete</v-btn
              >
            </template>
            <span> Delete observation </span>
          </v-tooltip>
        </template>

        <template #message>
          Are you sure you want to this observation?
          <br />
          <div class="pt-2">This action cannot be undone.</div>
        </template>

        <template #actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="flat"
            :disabled="deleteInProgress"
            @click="closeDeleteObservationDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            variant="flat"
            :loading="deleteInProgress"
            @click="removeObservation()"
          >
            Delete
          </v-btn>
        </template>
      </ConfirmationDialog>
    </div>
  </v-col>
  <v-col cols="12" md="10" order="1" :order-md="2" class="pb-0 pt-2">
    <div class="d-flex">
      <span class="text-report-grey" :style="{ whiteSpace: 'pre-line' }"
        ><b class="mr-1">Notes:</b>{{ observation.text }}</span
      >
    </div>
  </v-col>
  <v-col cols="12" order="3" class="pt-4 pb-0">
    <div
      v-for="(attachment, attachmentIndex) in observation.attachments"
      :key="attachmentIndex"
      class="pb-3"
    >
      <IncidentReportDetailObservationAttachment
        :report="report"
        :activity="activity"
        :observation="observation"
        :attachment="attachment"
        @refresh-report="emit('refresh-report')"
      />
    </div>
  </v-col>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'

import type {
  IIncidentReport,
  IIncidentReportActivity,
  IIncidentReportActivityObservation
} from '@/models/report'

import { useDeleteIncidentReportActivityObservation } from '@/composables/incident-report'

import IncidentReportDetailObservationAttachment from './IncidentReportDetailObservationAttachment.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'

interface Props {
  report: IIncidentReport
  activity: IIncidentReportActivity
  observation: IIncidentReportActivityObservation
}
const props = defineProps<Props>()

interface Emits {
  (e: 'refresh-report'): void
  (e: 'open-activity-observation-detail'): void
}
const emit = defineEmits<Emits>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const observationDeleteDialog = ref(false)

function closeDeleteObservationDialog() {
  observationDeleteDialog.value = false
}

const {
  isPending: deleteInProgress,
  error: deleteError,
  reset: deleteReset,
  mutate: deleteObservation
} = useDeleteIncidentReportActivityObservation(props.report.id!, props.activity.id!)

function removeObservation() {
  deleteReset()
  deleteObservation(props.observation.id!, {
    onSuccess: () => {
      emit('refresh-report')
      closeDeleteObservationDialog()
    }
  })
}
</script>

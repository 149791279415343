<template>
  <ErrorAlert
    v-if="error != null"
    :error="error"
    @clearErrors="error = null"
    class="mb-0 mb-4 rounded"
  />
  <NoDataAvailablePlaceholder
    v-if="activities?.length == 0"
    style="margin-top: 32px !important; margin-bottom: 0 !important"
    header="No activities available"
    :description="
      currentUser?.hasPermission('cctv_reports.add_cctvreportactivity')
        ? 'No activities available, you can create an activity here'
        : ''
    "
  >
    <template #primaryAction>
      <v-btn
        v-if="currentUser?.hasPermission('cctv_reports.add_cctvreportactivity')"
        prepend-icon=""
        class="text-capitalize"
        @click="createCCTVReportActivity()"
      >
        <v-icon left class="hidden-sm-and-down mr-1"> {{ route.meta.icon }} </v-icon>
        Create Activity
      </v-btn>
    </template>
  </NoDataAvailablePlaceholder>

  <template v-else>
    <v-progress-linear v-if="isLoading" indeterminate color="primary" />

    <div v-for="(activity, i) in activities" :key="i" class="mb-3">
      <CCTVReportDetailActivityCardHeader
        :report="report"
        :activity="activity"
        @refresh-report="updateReportCache()"
      />

      <div>
        <v-row class="ma-0 mb-4" v-for="(observation, i) in activity.observations" :key="i">
          <CCTVReportDetailActivityObservationCard
            :report="report"
            :activity="activity"
            :observation="observation"
            @open-activity-observation-detail="openActivityObservationDialog(activity, observation)"
            @refresh-report="updateReportCache()"
            @incident-report-selected="openIncidentReportDialog"
            @incident-report-created="incidentReportCreatedHandler"
          />
        </v-row>
        <v-row
          class="d-flex ma-0"
          v-if="currentUser?.hasPermission('cctv_reports.change_cctvreport')"
        >
          <v-col class="pr-0">
            <v-btn
              block
              flat
              variant="tonal"
              color="secondary"
              class="text-capitalize"
              @click="openActivityObservationDialog(activity)"
            >
              <v-icon left class="hidden-sm-and-down mr-1">mdi-eye </v-icon>
              Create observation
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-btn
      block
      flat
      class="text-capitalize mb-4"
      color="secondary"
      @click="createCCTVReportActivity()"
      :loading="isCreateActivityLoading"
    >
      <v-icon left class="hidden-sm-and-down mr-1"> {{ route.meta.icon }} </v-icon>
      Create Activity
    </v-btn>
  </template>

  <CCTVReportActivityDetailTimeFormDialog
    v-if="selectedActivity"
    v-model:dialog="activityDetailDialog"
    :activity="selectedActivity"
    :is-edit="!!selectedActivity"
    :report-id="report.id!"
  />

  <CCTVReportDetailObservationFormDialog
    v-if="selectedActivity"
    :report="report"
    v-model:dialog="activityDetailObservationDialog"
    :activity-id="selectedActivity?.id!"
    :is-edit="!!selectedObservation"
    :observation="selectedObservation"
    @saved-activity-observation="updateReportCache()"
    @incident-report-created="incidentReportCreatedHandler"
  />

  <IncidentReportDetailDialog
    v-model="incidentReportDetailDialog"
    :report-id="selectedIncidentReportId"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { storeToRefs } from 'pinia'

import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores'
import {
  useCreateCCTVReportActivity,
  useFetchCCTVReportActivities
} from '@/composables/cctv-report'
import { useCreateIncidentReportFromObservation } from '@/composables/incident-report'

import { useQueryClient } from '@tanstack/vue-query'

import {
  type ICCTVReportActivityObservation,
  type ICCTVReport,
  type ICCTVReportActivity,
  CCTVReportActivity,
  type IIncidentReportActivityObservationData
} from '@/models/report'
import type { ICamera } from '@/models/camera'
import type { ISite } from '@/models/client'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import CCTVReportActivityDetailTimeFormDialog from './CCTVReportActivityDetailTimeFormDialog.vue'
import CCTVReportDetailObservationFormDialog from './CCTVReportDetailObservationFormDialog.vue'
import CCTVReportDetailActivityCardHeader from './CCTVReportDetailActivityCardHeader.vue'
import CCTVReportDetailActivityObservationCard from './CCTVReportDetailActivityObservationCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import IncidentReportDetailDialog from '../incident/IncidentReportDetailDialog.vue'

interface Props {
  report: ICCTVReport
}

const queryClient = useQueryClient()

const props = defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const route = useRoute()

const { activities, isLoading, error, queryKey } = useFetchCCTVReportActivities(props.report.id!)

const activityDetailDialog = ref(false)
const activityDetailObservationDialog = ref(false)

const selectedActivity = ref<ICCTVReportActivity>()
const selectedObservation = ref<ICCTVReportActivityObservation>()

function updateReportCache() {
  queryClient.invalidateQueries({ queryKey })
}

const { isPending: isCreateActivityLoading, mutate: createActivityMutate } =
  useCreateCCTVReportActivity(props.report.id!)

function createCCTVReportActivity() {
  createActivityMutate(undefined, {
    onSuccess: (activity) => {
      openActivityObservationDialog(new CCTVReportActivity(activity))
    }
  })
}

function openActivityObservationDialog(
  activity: ICCTVReportActivity,
  observation?: ICCTVReportActivityObservation
) {
  selectedObservation.value = observation
  selectedActivity.value = activity

  activityDetailObservationDialog.value = true
}

const incidentReportDetailDialog = ref(false)

const { selectedIncidentReportId, incidentReportCreatedHandler: _incidentReportCreatedHandler } =
  useCreateIncidentReportFromObservation(() => {
    incidentReportDetailDialog.value = true
  })

function incidentReportCreatedHandler(data: {
  observation: ICCTVReportActivityObservation
  incidentReportId: number
  fileAttachment?: File | string
}) {
  const payload = {
    observation: {
      location: ((data.observation.camera as ICamera).site as ISite).address.getAddressString(),
      text: data.observation.text
    } as IIncidentReportActivityObservationData,
    incidentReportId: data.incidentReportId,
    fileAttachment: data.fileAttachment
  }

  _incidentReportCreatedHandler(payload)
}

function openIncidentReportDialog(incidentReportId: number) {
  incidentReportDetailDialog.value = true

  selectedIncidentReportId.value = incidentReportId
}
</script>

<template>
  <AlDialog
    v-model="dialog"
    :title="title"
    :subtitle="subtitle"
    :error="error"
    @clearErrors="clearErrors()"
  >
    <v-data-table
      :items="logEntries"
      :headers="auditLogEntryTableHeaders"
      :items-per-page="25"
      :items-per-page-options="[{ value: 25, title: '25' }]"
      :loading="loading"
      :height="tableHeight"
      fixed-header
    >
      <template #[`item.additional_data`]="{ value }">
        <v-btn variant="text" v-if="value">
          <v-icon icon="mdi-information-variant-circle" />
          <v-menu
            activator="parent"
            :close-on-content-click="false"
            location="bottom"
            min-width="300"
          >
            <v-card width="300" title="Details">
              <v-card-text>
                <div>
                  <template v-for="(itemValue, key) of value" :key="key">
                    <div class="d-flex flex-row flex-grow-1">
                      <div class="mr-auto">{{ startCase(String(key)) }}:&Tab;{{ itemValue }}</div>
                    </div>
                    <v-divider class="mt-2" />
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-btn>
      </template>

      <template #[`item.changes`]="{ value }">
        <li class="d-flex flex-column justify-center">
          <ol
            v-for="(keyValue, key) of value"
            :key="key"
            class="d-flex flex-row justify-self-center"
          >
            <span
              >Updated <strong>{{ startCase(String(key)) }}</strong> from
              <template v-for="(keyPairValue, index) in keyValue" :key="index">
                <strong>{{ keyPairValue }}</strong
                ><template v-if="index === 0">&nbsp;to&nbsp;</template>
              </template>
            </span>
          </ol>
        </li>
      </template>

      <template #bottom></template>
    </v-data-table>
  </AlDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { format } from 'date-fns'
import { clamp, startCase } from 'lodash'

import type { ReadonlyTableHeaders } from '@/utils/tables'
import type { ILogEntry } from '@/models/log-entry'
import type { ISystemError } from '@/models/error'

import { VDataTable } from 'vuetify/components/VDataTable'
import AlDialog from './AlDialog.vue'
interface Props {
  title?: string
  subtitle?: string
  logEntries: ILogEntry[]
  error?: ISystemError | null
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  error: null
})

interface Emit {
  (e: 'clearErrors'): void
}

const emit = defineEmits<Emit>()

const dialog = defineModel<boolean>('dialog', { default: false })

const auditLogEntryTableHeaders: ReadonlyTableHeaders = [
  {
    title: 'Who',
    align: 'start',
    key: 'actor',
    sortable: false,
    width: 150
  },

  {
    title: 'When',
    key: 'timestamp',
    align: 'start',
    value: (entry: any) => format(entry.timestamp, 'MMMM d, yyyy h:mma'),
    sortable: false,
    width: 220
  },

  {
    title: 'Changes',
    key: 'changes',
    align: 'start',
    sortable: false,
    width: 370
  },
  { title: '', key: 'additional_data', align: 'end', sortable: false, width: 100 }
]

function clearErrors() {
  emit('clearErrors')
}

/**
 * Only display a maximum of 6 table rows allowing the height the data table not to surpass that height
 */
const tableHeight = computed(() => {
  const tableRowHeight = 52
  const tableHeaderRowHeight = 56

  const maxRowsDisplayed = 6

  return tableRowHeight * clamp(props.logEntries.length, 0, maxRowsDisplayed) + tableHeaderRowHeight
})
</script>

<template>
  <v-card variant="flat" :loading="isPending">
    <ErrorAlert
      v-if="error != null"
      dense
      :error="error"
      @clearErrors="clearErrors"
      class="mb-0 mt-4 rounded"
    />
    <div class="d-flex flex-row flex-grow-1 align-space-between justify-space-between px-2">
      <v-label
        for="field-select"
        text="Field options"
        class="font-weight-medium text-caption text-high-emphasis pb-1"
        style="opacity: 0.9"
      />

      <v-tooltip text="Add an option" location="bottom" open-delay="450">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            class="rounded"
            variant="text"
            size="32"
            color="primary"
            icon
            density="comfortable"
            @click="openOptionFormDialog()"
          >
            <v-icon icon="mdi-plus" size="18" />
          </v-btn>
        </template>
      </v-tooltip>
    </div>

    <div class="mt-4" ref="optionList">
      <PostOrderInstructionDetailFieldOptionListItem
        v-for="(option, optionIndex) in localOptions"
        :key="optionIndex"
        class="mb-3 pa-3 border rounded"
        :option="option"
        :option-index="optionIndex"
        :post-order-field-id="postOrderFieldId"
      />
    </div>

    <PostOrderInstructionDetailFieldOptionFormDialog
      v-model:dialog="isOptionFormDialogActive"
      :option="selectedFieldOption"
      :is-edit="false"
      :post-order-field-id="postOrderFieldId"
    />
  </v-card>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, ref, watch } from 'vue'

import { useDraggable } from 'vue-draggable-plus'
import {
  useFetchPostOrderInstructionsFields,
  useUpdatePostOrderInstructionsFieldOption
} from '@/composables/post-order'

import { FormFieldOption } from '@/models/form'
import {
  PostOrderInstructionsFieldOption,
  type IPostOrderInstructionsField,
  type IPostOrderInstructionsFieldOption
} from '@/models/post-order'

import { PostOrderSymbol } from './postOrderProvide'

import ErrorAlert from '../common/ErrorAlert.vue'
import PostOrderInstructionDetailFieldOptionListItem from './PostOrderInstructionDetailFieldOptionListItem.vue'
import PostOrderInstructionDetailFieldOptionFormDialog from './PostOrderInstructionDetailFieldOptionFormDialog.vue'
import { useQueryClient } from '@tanstack/vue-query'

interface Props {
  options: IPostOrderInstructionsFieldOption[]

  field: IPostOrderInstructionsField
}
const props = defineProps<Props>()

const isOptionFormDialogActive = ref(false)

const selectedFieldOption = ref<IPostOrderInstructionsFieldOption | null>(null)

function openOptionFormDialog() {
  const newOrder = props.options.length + 1
  selectedFieldOption.value = new PostOrderInstructionsFieldOption({
    option: new FormFieldOption({
      order: newOrder,
      value: `Option ${newOrder}`
    })
  })
  isOptionFormDialogActive.value = true
}

const localOptions = ref<IPostOrderInstructionsFieldOption[]>([])

const optionList = ref<HTMLDivElement | null>(null)

const draggable = useDraggable(optionList, localOptions, {
  onEnd: updateOptionListOrder,
  handle: '.handle'
})

onMounted(() => {
  draggable.start()
})

watch(
  () => props.options,
  (value) => {
    localOptions.value = [...value]
  },
  {
    immediate: true
  }
)

function compareOptionOrder(otherArray: IPostOrderInstructionsFieldOption[]) {
  return function (current: IPostOrderInstructionsFieldOption) {
    return (
      otherArray.filter(function (other: IPostOrderInstructionsFieldOption) {
        return other.id == current.id && other.option.order == current.option.order
      }).length == 0
    )
  }
}

async function updateOptionListOrder() {
  clearErrors()
  const optionsClone: IPostOrderInstructionsFieldOption[] = [...localOptions.value]

  const options = optionsClone.map(
    (postOrderOption: IPostOrderInstructionsFieldOption, index: number) => {
      return new PostOrderInstructionsFieldOption({
        ...postOrderOption,
        option: new FormFieldOption({
          ...postOrderOption.option,
          order: index + 1
        })
      })
    }
  )

  const difference = options.filter(compareOptionOrder(optionsClone))

  for (let index = 0; index < difference.length; index++) {
    const option = difference[index]
    try {
      await updatePostOrderInstructionOption(option)
    } catch {
      localOptions.value = [...props.options]
      // stop loop
      break
    }
  }

  queryClient.invalidateQueries({
    queryKey: useFetchPostOrderInstructionFieldsQuery.queryKey
  })
}

const postOrderContext = inject(PostOrderSymbol)

if (!postOrderContext) throw new Error('[Post Order] Could not find injected post order context')

const postOrderFieldId = computed(() => props.field.id!)

const queryClient = useQueryClient()
const useFetchPostOrderInstructionFieldsQuery = useFetchPostOrderInstructionsFields(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const {
  isPending: isPending,
  error: error,
  mutateAsync: updatePostOrderInstructionOption,
  reset: clearErrors
} = useUpdatePostOrderInstructionsFieldOption(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId,
  postOrderFieldId
)
</script>

<template>
  <BasePage title="Vehicles Logs" subtitle="Manage vehicles logs information">
    <template #page-header:actions> </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-card flat height="calc(100vh - 286px)" class="px-4 rounded-lg">
      <v-toolbar flat height="84" color="transparent">
        <v-text-field
          label="Search"
          single-line
          hide-details="auto"
          variant="outlined"
          density="comfortable"
          style="max-width: 400px"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
        />
      </v-toolbar>

      <VehicleLogList
        :loading="isLoading"
        :vehicle-logs="logs ?? []"
        :search="search"
        @vehicle-log-selected="openVehicleLogDetailDrawer"
        @vehicle-log-edit-pressed="openVehicleFormDialog"
        @vehicle-log-delete-pressed="openDeleteVehicleDialog"
      />
    </v-card>

    <ConfirmationDialog
      v-if="selectedVehicleLog"
      width="500"
      title="Delete vehicle"
      v-model="vehicleDeleteDialog"
      v-model:error="deleteError"
    >
      <template #message>
        Are you sure you want to delete this vehicle log?
        <br />
        <div class="pt-2">This action cannot be undone.</div>
      </template>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          variant="flat"
          :disabled="deleteInProgress"
          :loading="deleteInProgress"
          @click="removeVehicle(selectedVehicleLog!)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationDialog>
  </BasePage>

  <VehicleLogDetailDrawer
    v-if="selectedVehicleLog"
    v-model:drawer="rightDrawer"
    :vehicle-log-id="selectedVehicleLog.id!"
    @vehicle-log-edit-pressed="openVehicleFormDialog"
    @vehicle-log-delete-pressed="openDeleteVehicleDialog"
  />

  <VehicleLogFormDialog
    v-model:dialog="isVehicleLogFormDialogActive"
    :vehicle-log="selectedVehicleLog!"
    :isEdit="!!selectedVehicleLog"
    ref="vehicleLogFormDialogRef"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import type { IVehicleLog } from '@/models/fleet-management'

import { useDeleteVehicleLog, useFetchVehicleLogs } from '@/composables/vehicle-log'

import BasePage from '@/components/base/BasePage.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'

import VehicleLogFormDialog from '@/components/fleet-management/vehicles-logs/VehicleLogFormDialog.vue'
import VehicleLogDetailDrawer from '@/components/fleet-management/vehicles-logs/VehicleLogDetailDrawer.vue'
import VehicleLogList from '@/components/fleet-management/vehicles-logs/VehicleLogList.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'

const rightDrawer = ref(false)

const search = ref<string>('')

const selectedVehicleLog = ref<IVehicleLog>()

function openVehicleLogDetailDrawer(vehicle: IVehicleLog) {
  selectedVehicleLog.value = vehicle

  rightDrawer.value = true
}

const isVehicleLogFormDialogActive = ref(false)

function openVehicleFormDialog(vehicle?: IVehicleLog) {
  selectedVehicleLog.value = vehicle

  isVehicleLogFormDialogActive.value = true
}

const vehicleDeleteDialog = ref(false)

function openDeleteVehicleDialog(vehicle: IVehicleLog) {
  selectedVehicleLog.value = vehicle
  vehicleDeleteDialog.value = true
}

function closeDrawerAndDialog() {
  vehicleDeleteDialog.value = false

  // incase of delete in detail drawer
  rightDrawer.value = false
}

// CORE
const { logs, isLoading, error } = useFetchVehicleLogs()

function clearErrors() {
  error.value = null
}
const {
  isPending: deleteInProgress,
  error: deleteError,
  mutate: deleteVehicle
} = useDeleteVehicleLog()

function removeVehicle(vehicle: IVehicleLog) {
  deleteVehicle(vehicle.id!, {
    onSuccess: () => {
      closeDrawerAndDialog()
    }
  })
}
</script>

<style lang="scss">
.site-list .v-list-item--three-line .v-list-item-subtitle {
  -webkit-line-clamp: 5;
}

.card-list {
  --v-card-list-gap: 0.25rem;

  &.v-list {
    padding-block: 0;
  }

  .v-list-item {
    min-block-size: unset;
    min-block-size: auto !important;
    padding-block: 0 !important;
    padding-inline: 0 !important;

    > .v-ripple__container {
      opacity: 0;
    }

    &:not(:last-child) {
      padding-block-end: var(--v-card-list-gap) !important;
    }
  }

  .v-list-item:hover,
  .v-list-item:focus,
  .v-list-item:active,
  .v-list-item.active {
    > .v-list-item__overlay {
      opacity: 0 !important;
    }
  }
}
</style>

<template>
  <BasePage title="Clients" subtitle="Manage clients contact information">
    <template #page-header:actions>
      <v-btn
        color="primary"
        v-if="currentUser?.hasPermission('clients.add_client')"
        @click="openClientFormDialog()"
      >
        <template #prepend>
          <v-icon start class="hidden-sm-and-down me-0"> {{ route.meta.icon }} </v-icon>
        </template>
        Create Client
      </v-btn>
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors()"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-card flat height="calc(100vh - 286px)" class="px-4 rounded-lg">
      <v-toolbar flat height="84" color="transparent">
        <SearchBox
          label="Search"
          single-line
          hide-details="auto"
          variant="outlined"
          density="comfortable"
          style="max-width: 400px"
          :model-value="clientFilters.search"
          @update:model-value="
            (newSearch: string) =>
              debounceFilterClients({ ...clientFilters, page: 1, search: newSearch })
          "
          @filter-applied="(newFilter) => updateFilters(clientFilters, newFilter)"
          :filters-applied="filtersApplied"
        />
      </v-toolbar>
      <ClientList
        v-model:page="currentPage"
        :loading="isLoading"
        :search="clientFilters.search"
        :clients="clients ?? []"
        :count="count"
        @client-selected="openClientDetailDrawer"
        @client-create-pressed="openClientFormDialog()"
        @client-edit-pressed="(client: IClient) => openClientFormDialog(client)"
        @client-delete-pressed="openDeleteClientDialog"
      />
    </v-card>

    <ConfirmationDialog
      width="500"
      title="Delete Client"
      v-model="deleteDialog"
      v-model:error="deleteError"
    >
      <template #message>
        Are you sure you want to delete
        <span class="text-medium-high-emphasis font-weight-bold">{{ selectedClient }}</span
        >?
        <br />
        <div class="pt-2">This action cannot be undone.</div>
      </template>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          variant="flat"
          :disabled="deleteInProgress"
          :loading="deleteInProgress"
          @click="removeClient(selectedClient!)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationDialog>
  </BasePage>

  <ClientDetailDrawer
    v-if="selectedClient"
    v-model:drawer="rightDrawer"
    :client-id="selectedClient.id!"
    @client-edit-pressed="(client: IClient) => openClientFormDialog(client)"
    @client-delete-pressed="openDeleteClientDialog"
  />

  <ClientFormDialog
    v-model:dialog="isClientFormDialogActive"
    :client="selectedClient"
    :isEdit="!!selectedClient"
    ref="clientFormDialogRef"
  />
</template>

<script lang="ts" setup>
import { debounce, merge, pick } from 'lodash'
import { storeToRefs } from 'pinia'
import { watch, reactive, ref, computed } from 'vue'

import { updateFilters } from '@/utils/filters'

import { useRoute, useRouter } from 'vue-router'

import type { IClient } from '@/models/client'
import type { IClientFilterParam } from '@/services'

import { useAuthStore } from '@/stores'
import { useFetchClients, useDeleteClient } from '@/composables/client'

import { useQueryClient } from '@tanstack/vue-query'

import BasePage from '@/components/base/BasePage.vue'
import ClientDetailDrawer from '@/components/clients/ClientDetailDrawer.vue'
import ClientFormDialog from '@/components/clients/ClientFormDialog.vue'
import ClientList from '@/components/clients/ClientList.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import SearchBox from '@/components/common/SearchBox.vue'
import { DebounceDelay } from '@/utils/constants'

const rightDrawer = ref(false)

const route = useRoute()
const router = useRouter()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const clientFilters = reactive<IClientFilterParam>(
  // set default filters from route query param
  // only use valid filter fields from routes query params including `search` and `page` and other valid fields
  merge({ page: 1 }, pick<IClientFilterParam>(route.query, ['search', 'page']))
)

const selectedClient = ref<IClient>()

watch(
  clientFilters,
  (value) => {
    /* this will update the URL query based on param values. */
    router
      .replace({
        name: route.name!,
        query: value as Record<string, any>
      })
      .catch((e: Error) => e) //catch navigation duplication error
  },
  {
    immediate: true
  }
)

const currentPage = computed({
  get() {
    return Number(clientFilters.page!)
  },
  set(page: number) {
    clientFilters.page = page
  }
})

const filtersApplied = ref<IClientFilterParam | null>(null)

function openClientDetailDrawer(client: IClient) {
  rightDrawer.value = true

  selectedClient.value = client
}

const isClientFormDialogActive = ref(false)
function openClientFormDialog(client?: IClient) {
  selectedClient.value = client

  isClientFormDialogActive.value = true
}

const deleteDialog = ref(false)

function openDeleteClientDialog(client: IClient) {
  clearDeleteErrors()
  deleteDialog.value = true
  selectedClient.value = client
}

function closeDrawerAndDialog() {
  deleteDialog.value = false

  // incase of delete in detail drawer
  rightDrawer.value = false
}

// CORE
const queryClient = useQueryClient()

const { clients, count, isLoading, error, queryKey } = useFetchClients(clientFilters)

// debouncing
const debounceFilterClients = debounce(function (filterParams: IClientFilterParam) {
  // mutates filters
  merge(clientFilters, filterParams)
  // TODO: Check why are we invalidating instead of just updating the query key
  queryClient.invalidateQueries({ queryKey: queryKey.value })
}, DebounceDelay.Long)

const {
  isPending: deleteInProgress,
  error: deleteError,
  mutate: deleteClient,
  reset: clearDeleteErrors
} = useDeleteClient()

function removeClient(client: IClient) {
  deleteClient(client.id!, {
    onSuccess: () => {
      closeDrawerAndDialog()
    }
  })
}

function clearErrors() {
  error.value = null
}
</script>

<style lang="scss">
.site-list .v-list-item--three-line .v-list-item-subtitle {
  -webkit-line-clamp: 5;
}

.site-subheader {
  .v-list-subheader__text {
    align-items: center;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
  }
}
</style>

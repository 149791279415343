<template>
  <v-dialog v-model="dialog" v-bind="$attrs" persistent max-width="900">
    <AlFormCard class="wizard-form" title="Incident Report" width="100%" height="100%">
      <template #error>
        <ErrorAlert v-if="error != null" :error="error" @clearErrors="clearErrors()" class="mb-2">
        </ErrorAlert>
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-window v-model="windowStep">
        <v-window-item :value="IncidentReportWindowSteps.Client">
          <div class="content-container pt-8">
            <div class="create-event-content">
              <div class="main-content">
                <h1>Let’s start by selecting the client and site.</h1>
                <div class="d-flex">
                  <v-col cols="5" class="pr-0">
                    <v-form ref="clientFormRef" v-model="isClientValid" validate-on="submit lazy">
                      <div class="flex-grow-1">
                        <v-label
                          for="reportClientId"
                          text="Client"
                          class="text-body-2 text-high-emphasis"
                        />
                        <ClientAutoCompletePicker
                          class="mt-2"
                          label=""
                          id="reportClientId"
                          variant="outlined"
                          hide-details="auto"
                          density="compact"
                          v-model="reportFormData.client"
                          :rules="[requiredValidator]"
                          :error-messages="error && (error.fieldErrors['client'] as string[])"
                        />
                      </div>
                    </v-form>
                  </v-col>

                  <v-col cols="7" class="pl-5">
                    <v-card
                      :loading="clientSitesLoading"
                      :disabled="clientSitesLoading"
                      variant="flat"
                      class="site-container bg-transparent"
                    >
                      <v-fade-transition mode="out-in">
                        <NoDataAvailablePlaceholder
                          v-if="!reportFormData.client || sites?.length == 0"
                          width="400"
                          header="No site available"
                          header-class="font-weight-medium text-h5"
                        >
                          <template #description>
                            <p class="mt-0 text-caption text-medium-emphasis">
                              <span>
                                You do not have any sites available.
                                <template v-if="!reportFormData.client"
                                  >Please select a Client</template
                                >
                              </span>
                            </p>
                          </template>
                        </NoDataAvailablePlaceholder>
                        <ReportSiteForm
                          v-else
                          ref="reportSiteFormRef"
                          v-model="reportFormData.site"
                          :sites="sites ?? []"
                          :error="sitesError"
                        >
                        </ReportSiteForm>
                      </v-fade-transition>
                    </v-card>
                  </v-col>
                </div>
              </div>
            </div>
          </div>
        </v-window-item>

        <v-window-item :value="IncidentReportWindowSteps.Incident">
          <div class="content-container pt-8">
            <div class="create-event-content">
              <div class="main-content">
                <v-form ref="incidentFormRef" v-model="isIncidentValid">
                  <h1>Also, what level of an incident is this?</h1>
                  <div class="d-flex flex-column">
                    <div class="ml-4 mr-16 mt-5">
                      <IncidentStatusSelectField
                        v-model.number="reportFormData.incident_level"
                        @update:model-value="clearErrors()"
                        :error-messages="error && (error.fieldErrors['incident_level'] as string[])"
                        :rules="[requiredValidator, incidentLevelValidator]"
                      />
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </v-window-item>
      </v-window>

      <template #actions>
        <template v-if="windowStep == IncidentReportWindowSteps.Client">
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="flat" @click="next()">Continue</v-btn>
        </template>

        <template v-if="windowStep == IncidentReportWindowSteps.Incident">
          <v-btn
            class="ml-n4"
            color="primary"
            variant="text"
            @click="windowStep = IncidentReportWindowSteps.Client"
            >Previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="flat"
            @click="save()"
            :loading="isPending"
            :disabled="!isIncidentValid"
          >
            Save
          </v-btn>
        </template>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { cloneDeep } from 'lodash'
import { useRouter } from 'vue-router'

import type { IClientData, ISite } from '@/models/client'
import {
  IncidentReport,
  type IIncidentReport,
  type IIncidentReportData,
  ReportActivityStatusEnum
} from '@/models/report'

import { useCreateIncidentReport, useUpdateIncidentReport } from '@/composables/incident-report'
import { useSnackbarStore } from '@/stores'
import { requiredValidator } from '@/utils/validators'

import type { VForm } from 'vuetify/components/VForm'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import ClientAutoCompletePicker from '@/components/clients/ClientAutoCompletePicker.vue'
import ReportSiteForm from '../common/ReportSiteForm.vue'
import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import IncidentStatusSelectField from './IncidentStatusSelectField.vue'
import { useFetchSites } from '@/composables/site'

enum IncidentReportWindowSteps {
  Client,
  Incident
}

const dialog = defineModel<boolean>('dialog')

interface Props {
  incidentReport?: IIncidentReport
  isEdit: boolean
}
const props = defineProps<Props>()

interface Emits {
  (e: 'saved-report'): void
}
const emit = defineEmits<Emits>()

const router = useRouter()
const snackbarStore = useSnackbarStore()

const reportFormData = ref<IIncidentReportData>({})

watch(
  () => dialog.value,
  () => {
    reportFormData.value = props.isEdit
      ? cloneDeep(props.incidentReport!)
      : ({
          shift_end: new Date()
        } as IIncidentReportData)
  }
)

// incident validation rule
function incidentLevelValidator(incidentLevel: number) {
  return (
    incidentLevel > ReportActivityStatusEnum.SecurityLevel1 ||
    'Incident must be greater that Security Level 1'
  )
}

const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updateMutate,
  reset: updateReset
} = useUpdateIncidentReport()
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createMutate,
  reset: createReset
} = useCreateIncidentReport()

const isPending = computed(() => (props.isEdit ? isUpdatePending.value : isCreatePending.value))

const error = computed(() => (props.isEdit ? updateError.value : createError.value))

const windowStep = ref(IncidentReportWindowSteps.Client)

const isIncidentValid = ref(false)
const isClientValid = ref(false)
const incidentFormRef = ref<typeof VForm>()
const clientFormRef = ref<typeof VForm>()
const reportSiteFormRef = ref<typeof ReportSiteForm>()

function clearErrors() {
  clientFormRef.value?.resetValidation()
  reportSiteFormRef.value?.clearErrors()
  props.isEdit ? updateReset() : createReset()
}

function closeDialog() {
  dialog.value = false
  clearErrors()
  windowStep.value = IncidentReportWindowSteps.Client
}

async function next() {
  clearErrors()
  switch (windowStep.value) {
    case IncidentReportWindowSteps.Client: {
      if ((await validateClientForm()) && (await validateSiteForm())) {
        windowStep.value = IncidentReportWindowSteps.Incident
      }
      break
    }
  }
}

async function validateClientForm(): Promise<boolean> {
  const { valid } = await clientFormRef.value!.validate()
  return valid
}

async function validateSiteForm(): Promise<boolean> {
  // in case of no sites found
  if (!reportSiteFormRef.value) return false
  const { valid } = await reportSiteFormRef.value.validate()
  return valid
}

async function validateIncidentForm(): Promise<boolean> {
  const { valid } = await incidentFormRef.value!.validate()
  return valid
}

async function save() {
  clearErrors()

  const valid = await validateIncidentForm()

  if (valid) {
    let report: IIncidentReport | null = null

    try {
      report = new IncidentReport(reportFormData.value)
    } catch (e: any) {
      console.warn(e)
    }

    if (report != null) {
      let payload = {
        id: report.id,
        site: (report.site! as ISite).code! ?? report.site,
        incident_level: report.incident_level
      } as IIncidentReportData

      const mutate = props.isEdit ? updateMutate : createMutate

      mutate(payload, {
        onSuccess: (data) => {
          const actionText = props.isEdit ? 'updated' : 'created'
          snackbarStore.showSnackbar(`Incident Report ${actionText} successfully`)
          closeDialog()
          emit('saved-report')
          if (!props.isEdit) {
            router.push({ name: 'incident-detail', params: { id: data.id } })
          }
        },
        onError: (err) => {
          if (err.fieldErrors) {
            if (err.fieldErrors['site']) {
              windowStep.value = IncidentReportWindowSteps.Client
            }
          }
        }
      })
    }
  }
}
const selectedClient = computed(() => {
  const clientId =
    (reportFormData.value.client as IClientData)?.id ?? (reportFormData.value.client as number)
  return clientId
})

const { sites, isLoading: clientSitesLoading, error: sitesError } = useFetchSites(selectedClient)
</script>

<template>
  <v-data-table-server
    v-model:page="page"
    :items-length="count"
    :items-per-page="25"
    :items-per-page-options="[{ value: 25, title: '25' }]"
    :hover="employees.length > 0"
    item-value="name"
    :headers="employeeDataTableHeaders"
    :items="employees"
    :loading="loading"
    color="primary"
    sticky
    no-filter
    fixed-header
    density="default"
    height="calc(100% - 37px)"
    style="height: calc(100% - 93px); width: 100%"
    :row-props="generateRowProps"
  >
    <template #[`item.index`]="{ index }">
      {{ index + 1 }}
    </template>

    <template #[`item.full_name`]="{ item }">
      <div class="d-flex align-center">
        <v-avatar variant="tonal" color="primary" size="36" class="me-2">
          {{ item.getInitials() }}
        </v-avatar>
        <div class="d-flex flex-column">
          <h6
            class="text-body-2 font-weight-medium text-link"
            @click="emit('employee-selected', item)"
          >
            {{ item.getFullName() }}
          </h6>
        </div>
      </div>
    </template>

    <template #[`item.is_active`]="{ item }">
      <EmployeeActiveStatusChip :is-active="item.is_active!" />
    </template>

    <template #[`item.email`]="{ item }">
      <a
        :href="`mailto:${item.email}`"
        target="_blank"
        rel="noopener noreferrer"
        class="font-weight-medium text-link"
      >
        {{ item.email }}
      </a>
    </template>

    <template #[`item.profile.phone`]="{ item }">
      <a
        v-if="item.profile && item.profile.phone"
        :href="parsePhoneNumber(item.profile.phone)?.getURI()"
        target="_blank"
        rel="noopener noreferrer"
        class="font-weight-medium text-link"
      >
        {{ parsePhoneNumber(item.profile.phone)?.formatNational() }}
      </a>
      <span v-else> - </span>
    </template>

    <template #[`item.actions`]="{ item }">
      <EmployeeDetailOptionsButton
        :employee="item"
        @employee-edit-pressed="(employee: IEmployee) => emit('employee-edit-pressed', employee)"
        @employee-delete-pressed="
          (employee: IEmployee) => emit('employee-delete-pressed', employee)
        "
        @employee-change-password-pressed="
          (employee: IEmployee) => emit('employee-change-password-pressed', employee)
        "
        @employee-change-sites-pressed="
          (employee: IEmployee) => emit('employee-change-sites-pressed', employee)
        "
        @employee-set-active-pressed="
          (employee: IEmployee) => emit('employee-set-active-pressed', employee)
        "
      />
    </template>

    <template v-slot:no-data>
      <NoDataAvailablePlaceholder
        header="No employees available"
        header-class="font-weight-medium text-h5"
      >
        <template #prepend>
          <v-card
            variant="flat"
            class="mask-bottom-card mb-3"
            color="transparent"
            width="180"
            height="120"
          >
            <v-list
              style="color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity))"
              variant="outlined"
              density="compact"
              lines="two"
              class="pt-0"
            >
              <v-list-item class="rounded-lg-xl mb-3 px-3">
                <template v-slot:prepend>
                  <v-avatar size="32" class="me-n1" color="green-darken-2 text-white">
                    JD
                  </v-avatar>
                </template>
                <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
              </v-list-item>
              <v-list-item class="rounded-lg-xl mb-3 px-3">
                <template v-slot:prepend>
                  <v-avatar size="32" class="me-n1" color="amber-darken-2 text-white">
                    DM
                  </v-avatar>
                </template>
                <v-skeleton-loader class="me-n3 ms-n4 my-n2" type="text, text" boilerplate />
              </v-list-item>
            </v-list>
          </v-card>
        </template>

        <template #description>
          <p class="mt-0 mb-6 text-caption text-medium-emphasis">
            <span v-if="currentUser?.hasPermission('employees.add_employee')">
              No employees available you can create a employee here</span
            >
            <span v-else>
              You do not have the permission to create a employee. Employee your administrator for
              more details
            </span>
          </p>
        </template>

        <template #primaryAction>
          <v-btn
            color="primary"
            depressed
            v-if="currentUser?.hasPermission('employees.add_employee')"
            @click="emit('employee-create-pressed')"
          >
            <template #prepend>
              <v-icon left class="hidden-sm-and-down"> {{ route.meta.icon }} </v-icon>
            </template>
            Create Employee
          </v-btn>
        </template>
      </NoDataAvailablePlaceholder>
    </template>
  </v-data-table-server>
</template>

<script lang="ts" setup>
import parsePhoneNumber from 'libphonenumber-js'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import type { IEmployee } from '@/models/employee'
import { useAuthStore } from '@/stores'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'
import EmployeeActiveStatusChip from './EmployeeActiveStatusChip.vue'
import EmployeeDetailOptionsButton from './EmployeeDetailOptionsButton.vue'

interface Props {
  employees: IEmployee[]
  count: number
  loading: boolean
}

interface Emit {
  (e: 'employee-selected', employee: IEmployee): void
  (e: 'employee-create-pressed'): void
  (e: 'employee-edit-pressed', employee: IEmployee): void
  (e: 'employee-delete-pressed', employee: IEmployee): void
  (e: 'employee-change-password-pressed', employee: IEmployee): void
  (e: 'employee-change-sites-pressed', employee: IEmployee): void
  (e: 'employee-set-active-pressed', employee: IEmployee): void
}

const page = defineModel<number>('page')

const emit = defineEmits<Emit>()
defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const route = useRoute()

const employeeDataTableHeaders = ref<any[]>([
  {
    title: '#',
    align: 'start',
    key: 'index',
    width: 20,
    sortable: false
  },
  {
    title: 'Name',
    align: 'start',
    key: 'full_name',
    sortable: false
  },
  {
    title: 'Email',
    align: 'start',
    key: 'email',
    sortable: false
  },
  {
    title: 'Phone',
    align: 'start',
    key: 'profile.phone',
    sortable: false
  },
  {
    title: 'Status',
    align: 'start',
    key: 'is_active',
    sortable: false
  },

  { title: '', key: 'actions', align: 'center', sortable: false }
])

function generateRowProps(props: { index: number; item: IEmployee }) {
  return {
    // apply click listener to row props to open detail drawer
    onClick: () => emit('employee-selected', props.item)
  }
}
</script>

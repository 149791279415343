<template>
  <MoreOptionsMenuButton
    v-bind="attrs"
    v-if="
      currentUser?.hasPermission('employees.change_employee') ||
      currentUser?.hasPermission('employees.delete_employee') ||
      currentUser?.hasPermission('users.change_user') ||
      currentUser?.hasPermission('users.change_password')
    "
  >
    <v-list-item
      v-if="currentUser.hasPermission('employees.change_employee')"
      @click="emit('employee-edit-pressed', employee)"
      title="Edit Employee"
    >
      <template #prepend>
        <v-icon size="18" icon="mdi-pencil" class="me-1" />
      </template>
    </v-list-item>

    <v-list-item
      v-if="currentUser.hasPermission('employees.change_employee')"
      @click="emit('employee-change-sites-pressed', employee)"
      title="Manage Sites"
    >
      <template #prepend>
        <v-icon size="18" icon="mdi-domain-switch" class="me-1" />
      </template>
    </v-list-item>

    <v-list-item
      v-if="currentUser.hasPermission('users.change_password')"
      @click="emit('employee-change-password-pressed', employee)"
      title="Change Password"
    >
      <template #prepend>
        <v-icon size="18" icon="mdi-account-lock" class="me-1" />
      </template>
    </v-list-item>

    <v-list-item
      v-if="currentUser.hasPermission('users.change_user')"
      @click="emit('employee-set-active-pressed', employee)"
      color="error"
      :title="`${employee.is_active ? 'Deactivate' : 'Activate'} Employee`"
    >
      <template #prepend>
        <v-icon
          size="18"
          :icon="employee.is_active ? 'mdi-account-off' : 'mdi-account-reactivate'"
          class="me-1"
        />
      </template>
    </v-list-item>

    <template v-if="currentUser.hasPermission('employees.delete_employee')">
      <v-divider class="pb-1" />
      <v-list-item
        class="text-error"
        @click="emit('employee-delete-pressed', employee)"
        title="Delete Employee"
      >
        <template #prepend>
          <v-icon size="18" icon="mdi-delete" class="me-1" />
        </template>
      </v-list-item>
    </template>
  </MoreOptionsMenuButton>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue'
import { storeToRefs } from 'pinia'

import type { IEmployee } from '@/models/employee'

import { useAuthStore } from '@/stores'

import MoreOptionsMenuButton from '../common/MoreOptionsMenuButton.vue'

interface Props {
  employee: IEmployee
}
defineProps<Props>()

interface Emit {
  (e: 'employee-edit-pressed', employee: IEmployee): void
  (e: 'employee-delete-pressed', employee: IEmployee): void
  (e: 'employee-change-password-pressed', employee: IEmployee): void
  (e: 'employee-change-sites-pressed', employee: IEmployee): void
  (e: 'employee-set-active-pressed', employee: IEmployee): void
}
const emit = defineEmits<Emit>()

const attrs = useAttrs()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>

<template>
  <v-dialog v-model="dialog" persistent :width="dialogWidth">
    <AlCard :color="color" :title="title" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mt-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <slot name="default"></slot>

      <template #actions>
        <slot name="actions"> </slot>
      </template>
    </AlCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import { useDisplay } from 'vuetify'
import type { ISystemError } from '@/models/error'

import AlCard from './AlFormCard.vue'
import ErrorAlert from './ErrorAlert.vue'

interface Props {
  color?: string
  title?: string
  subtitle?: string
  error?: ISystemError | null
  width?: number
}

const props = withDefaults(defineProps<Props>(), {
  error: null
})

interface Emit {
  (e: 'clearErrors'): void
}

const emit = defineEmits<Emit>()

const dialog = defineModel<boolean>({ default: false })

watch(dialog, (value) => {
  if (value) {
    clearErrors()
  }
})

function closeDialog() {
  clearErrors()
  dialog.value = false
}

function clearErrors() {
  emit('clearErrors')
}

const { smAndDown } = useDisplay()

const dialogWidth = computed(() => {
  if (props.width) return props.width

  return smAndDown.value ? 864 : 1204
})
</script>

<template>
  <v-tooltip v-bind="tooltipProps" :disabled="tooltipDisabled">
    <template #activator="{ props }">
      <v-btn
        v-bind="{ ...props, ...btnProps }"
        class="action-btn rounded"
        variant="text"
        :icon="!!icon"
        :size="size"
        density="comfortable"
        :color="color"
        :disabled="disabled"
        @click.stop="($event: MouseEvent) => emit('click', $event)"
      >
        <template v-slot:default>
          <slot name="default" v-bind="{ iconSize, icon }">
            <v-icon :size="iconSize" :icon="icon" />
          </slot>
        </template>
      </v-btn>
    </template>
    <template v-if="slots['tooltip']" v-slot:default>
      <slot name="tooltip"></slot>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">
import { omit } from 'lodash'

import { VBtn, VTooltip } from 'vuetify/components'

interface Props {
  color?: string
  disabled?: boolean
  icon?: string | (string | [path: string, opacity: number])[]
  iconSize?: number | string
  size?: number | string
  tooltipDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 32,
  iconSize: 18
})

interface Emit {
  (e: 'click', el: MouseEvent): void
}
const emit = defineEmits<Emit>()

const slots = defineSlots()

const btnProps = VBtn.filterProps(props)

const btnPropKeysList = Object.keys(btnProps) as (keyof typeof btnProps)[]
const tooltipProps = VTooltip.filterProps(
  omit(props, [...btnPropKeysList, 'iconSize', 'tooltipDisabled'])
)
</script>

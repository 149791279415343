import { transform, isEqual, isObject } from 'lodash'
import type { ComponentPublicInstance } from 'vue'

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @param  {Boolean} includeId flag to control including ID with difference
 * @return {Object}        Return a new object who represent the diff
 */
export function diffDeep<T>(object: T, base: any, includeId = false): T {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        if (Array.isArray(value) && Array.isArray(base[key])) {
          result[key] = []

          // loop through each entry of array
          for (let i = 0; i < value.length; i++) {
            if (isObject(value[i])) {
              const base_entry = base[key].find((data: { id?: any }) => data?.id === value[i]?.id)

              if (base_entry) {
                if (!isEqual(value[i], base_entry)) {
                  // get the differences
                  result[key].push(changes(value[i], base_entry))
                } else {
                  // We always need to include ID for array entries
                  // even if there was no change - otherwise item
                  // gets deleted when data written to database
                  result[key].push({ id: value[i].id })
                }
              } else {
                result[key].push(value[i])
              }
            } else {
              result[key].push(value[i])
            }
          }
        } else {
          result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value

          // include ID field if applicable
          if (includeId) {
            result['id'] = base['id']
          }
        }
      } else {
        // save id regardless even if equal
        if (includeId) {
          result['id'] = base['id']
        }
      }
    })
  }
  return changes(object, base)
}

export function objectToFormData(
  object: any,
  form?: FormData,
  namespace = '',
  isArray = false
): FormData {
  const formData = form || new FormData()

  for (const property in object) {
    if (
      // if the object[property] value is falsy except for boolean and null then we skip it
      !Object.prototype.hasOwnProperty.call(object, property) ||
      object[property] === undefined
    )
      continue

    const formKey = namespace
      ? isArray
        ? `${namespace}${property}`
        : `${namespace}.${property}`
      : property

    if (object[property] === null) {
      // https://stackoverflow.com/questions/62303002/angular-9-formdata-appendkey-null-actually-appends-null-string
      // only way to send null in formdata is to send empty string
      formData.append(formKey, '')
    }
    if (object[property] instanceof Date)
      // handle Dates
      formData.append(formKey, object[property].toISOString())
    else if (object[property] instanceof Array) {
      // handle Arrays
      object[property].forEach((element: any, index: any) => {
        const tempFormKey = `${formKey}[${index}]`
        objectToFormData(element, formData, tempFormKey, true)
      })
    } else if (object[property] instanceof File) {
      // Handle Files
      formData.append(formKey, object[property], object[property].name)
    } else if (typeof object[property] === 'object') {
      // Handle Generic Objects
      objectToFormData(object[property], formData, formKey)
    } else {
      formData.append(formKey, object[property].toString())
    }
  }
  return formData
}

export function createPdf(data: Blob, fileName: string) {
  return createFile(data, fileName, 'pdf')
}

export function createFile(data: Blob, fileName: string, fileExtension?: string) {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  let linkName = `${fileName}`

  if (fileExtension) {
    linkName = linkName.concat(`.${fileExtension}`)
  }

  link.href = url

  link.setAttribute('download', linkName) //or any other extension
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
}

export function getTarget(el: ComponentPublicInstance | HTMLElement | string | undefined) {
  return typeof el === 'string' ? document.querySelector<HTMLElement>(el) : refElement(el)
}

export function refElement(
  obj?: ComponentPublicInstance<any> | HTMLElement
): HTMLElement | undefined {
  if (obj && '$el' in obj) {
    const el = obj.$el as HTMLElement
    if (el?.nodeType === Node.TEXT_NODE) {
      // Multi-root component, use the first element
      return el.nextElementSibling as HTMLElement
    }
    return el
  }
  return obj as HTMLElement
}

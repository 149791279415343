<template>
  <BaseRightDrawer v-model:drawer="drawer" @right-drawer-close="drawer = false">
    <template #header-before-close v-if="contact">
      <ContactDetailOptionsButton
        class="me-4"
        :contact="contact"
        @contact-edit-pressed="(contact: IContact) => emit('contact-edit-pressed', contact)"
        @contact-delete-pressed="(contact: IContact) => emit('contact-delete-pressed', contact)"
      />
    </template>

    <div class="d-flex flex-column flex-grow-1" v-if="contact">
      <v-list-item lines="three" class="position-relative rounded-lg mx-6 mb-2 px-0 pb-1 pt-0">
        <v-list-item-action>
          <v-avatar
            variant="flat"
            color="secondary"
            size="74"
            class="mb-6 px-3 text-h4"
            style="border-radius: 5px"
          >
            <span class="text-white font-weight-medium">{{ contact.getInitials() }}</span></v-avatar
          >
        </v-list-item-action>
        <v-list-item-title class="text-high-emphasis text-h4 font-weight-bold pt-1">
          {{ contact.getFullName() }}
          <div
            class="text-h6 font-weight-regular text-black pt-1 pb-6"
            style="padding-inline-start: 2px"
          >
            {{ contact.title }}
          </div>
        </v-list-item-title>
        <v-list-subheader
          class="text-medium-emphasis text-body-2 font-weight-regular pb-3"
          style="min-height: 22px"
        >
          Contact Information
        </v-list-subheader>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Email Address
            </div>
            <a
              class="text-body-2 font-weight-medium text-link text-black"
              :href="`mailto:${contact.email}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ contact.email }}
            </a>
          </div>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Phone Number
            </div>
            <a
              class="text-body-2 font-weight-medium text-link text-black"
              :href="parsePhoneNumber(contact.phone)?.getURI()"
              target="_blank"
              rel="noopener noreferrer"
              v-if="contact.phone"
            >
              {{ parsePhoneNumber(contact.phone)?.formatInternational() }}
            </a>
            <div v-else class="text-high-emphasis text-body-2 font-weight-bold">None</div>
          </div>
        </v-list-item-subtitle>
      </v-list-item>
    </div>
  </BaseRightDrawer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import parsePhoneNumber from 'libphonenumber-js'

import type { IContact } from '@/models/contact'

import { useFetchContacts } from '@/composables/contacts'

import BaseRightDrawer from '@/components/base/BaseRightDrawer.vue'
import ContactDetailOptionsButton from './ContactDetailOptionsButton.vue'

interface Props {
  contactId: number
}
const props = defineProps<Props>()

interface Emit {
  (e: 'contact-edit-pressed', contact: IContact): void
  (e: 'contact-delete-pressed', contact: IContact): void
}
const emit = defineEmits<Emit>()

const drawer = defineModel<boolean>('drawer')

const { getContactById } = useFetchContacts()

const contact = computed(() => {
  return getContactById.value(props.contactId)
})
</script>

<style lang="scss">
.al-list .v-list-item--three-line .v-list-item-subtitle {
  -webkit-line-clamp: 5;
}

.al-subheader {
  .v-list-subheader__text {
    align-items: center;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
  }
}

.detail-item-subtitle {
  opacity: 1;
}
</style>

<template>
  <v-form ref="form" v-model="valid" validate-on="input">
    <h1>Let’s select our shift's start and end time</h1>
    <div class="d-flex">
      <div class="ml-4 mr-16" style="width: fit-content">
        <div class="text-center text-grey font-weight-medium" style="letter-spacing: 2px">
          SHIFT START
        </div>
        <v-input
          v-model="shiftStart"
          :rules="shiftStartRule"
          :error-messages="fieldErrors['shift_start'] as string[]"
        >
          <v-card
            class="ma-2"
            label
            id="start-menu-activator"
            rounded
            width="168"
            style="border-top: solid 7px"
            hover
          >
            <v-card-item class="text-center">
              <v-card-title class="pt-3" style="font-size: 60px; line-height: 3rem">
                {{ dateAdapter.formatByString(shiftStart as Date, 'dd') }}
              </v-card-title>
              <v-card-subtitle class="pt-2 pb-3"
                ><div style="font-size: 18px">
                  {{ dateAdapter.formatByString(shiftStart as Date, 'MMM yyyy') }}
                </div></v-card-subtitle
              >
              <v-card-subtitle class="align-center"
                ><v-icon class="mr-1">mdi-clock</v-icon
                >{{ dateAdapter.formatByString(shiftStart as Date, 'hh:mm a') }}</v-card-subtitle
              >
            </v-card-item>
          </v-card>
          <v-menu
            offset="10"
            activator="#start-menu-activator"
            :close-on-content-click="false"
            location="end"
          >
            <DateTimePicker v-model="shiftStart" :allowedDates="allowedShiftStartDates" />
          </v-menu>
        </v-input>
      </div>

      <div style="width: fit-content">
        <div class="text-center text-grey font-weight-medium" style="letter-spacing: 2px">
          SHIFT END
        </div>
        <v-input
          v-model="shiftEnd"
          :rules="shiftEndRule"
          :error-messages="fieldErrors['shift_end'] as string[]"
        >
          <v-card
            class="ma-2"
            label
            id="end-menu-activator"
            rounded
            style="border-top: solid 7px"
            width="168"
            hover
          >
            <v-card-item class="text-center">
              <v-card-title class="pt-3" style="font-size: 60px; line-height: 3rem">
                {{ dateAdapter.formatByString(shiftEnd as Date, 'dd') }}
              </v-card-title>
              <v-card-subtitle class="pt-2 pb-3"
                ><div style="font-size: 18px">
                  {{ dateAdapter.formatByString(shiftEnd as Date, 'MMM yyyy') }}
                </div></v-card-subtitle
              >
              <v-card-subtitle class="align-center"
                ><v-icon class="mr-1">mdi-clock</v-icon
                >{{ dateAdapter.formatByString(shiftEnd as Date, 'hh:mm a') }}</v-card-subtitle
              >
            </v-card-item>
          </v-card>
          <v-menu
            offset="10"
            activator="#end-menu-activator"
            :close-on-content-click="false"
            location="end"
          >
            <DateTimePicker v-model="shiftEnd" hide-header :allowedDates="allowedShiftEndDates" />
          </v-menu>
        </v-input>
      </div>
    </div>
    <div class="create-event-actions d-flex justify-space-between flex-row"></div>
  </v-form>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useDate } from 'vuetify'
import type DateFnsUtils from '@date-io/date-fns'
import type { VForm } from 'vuetify/components'

import type { IFieldErrors } from '@/models/error'

import DateTimePicker from '@/components/common/DateTimePicker.vue'

const shiftStart = defineModel<Date | string>('shift_start', { default: new Date() })
const shiftEnd = defineModel<Date | string>('shift_end', { default: new Date() })

interface Props {
  fieldErrors?: IFieldErrors
}

withDefaults(defineProps<Props>(), {
  fieldErrors: () => ({})
})

const dateAdapter = useDate() as DateFnsUtils

const form = ref<VForm>()
const valid = ref(false)

const shiftStartRule = [
  (start: Date) => {
    const isValid = dateAdapter.isAfter(new Date(), start)
    return isValid || 'Cannot Start Shift in the future'
  }
]

const shiftEndRule = [
  (end: Date) => {
    const isValid = dateAdapter.isBefore(shiftStart.value as Date, end)
    return isValid || 'Shift end must be after start'
  }
]

function clearErrors() {
  form.value!.resetValidation()
}

function validate() {
  clearErrors()
  return form.value!.validate()
}

function allowedShiftEndDates(val: string) {
  const date = new Date(shiftStart.value)
  date.setDate(date.getDate() + 1)
  return (
    dateAdapter.isSameDay(date, new Date(val)) ||
    dateAdapter.isSameDay(shiftStart.value as Date, new Date(val))
  )
}

function allowedShiftStartDates(val: string) {
  return (
    dateAdapter.isAfterDay(new Date(), new Date(val)) ||
    dateAdapter.isSameDay(new Date(), new Date(val))
  )
}

defineExpose({ validate, clearErrors })
</script>

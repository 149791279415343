/**
 * An abstract base class for all models
 */

import { SystemError, type IFieldErrorsData, type ISystemErrorData } from './error'

export abstract class AbstractModel {
  // Note: Type assertion (keyof <T>)[] ensures that the properties
  // that we iterate over are actually valid properties of the data object.
  protected validate<T>(data: T, requiredFields: (keyof T)[]) {
    const invalidFields: IFieldErrorsData = {}

    for (const field of requiredFields) {
      // Explicitly checking the values below to ensure that "0" is treated as valid
      if (data[field] === undefined || data[field] === null || data[field] === '') {
        invalidFields[field as string] = [`Missing required property`]
      }
    }
    // Throw error if any invalid fields found
    if (Object.keys(invalidFields).length > 0) {
      throw new SystemError({
        message: 'Internal Error',
        fieldErrors: invalidFields
      } as ISystemErrorData)
    }
  }
}

// visual representation of states
export interface IStateVisualData {
  value: number
  display: string
  icon?: string
  color?: string
}

export interface IStateVisual extends IStateVisualData {}

<template>
  <BaseRightDrawer v-model:drawer="drawer" @right-drawer-close="drawer = false">
    <template #loading>
      <v-progress-linear
        class="position-absolute"
        style="top: 0"
        indeterminate
        :active="isPending"
      />
    </template>

    <template #error>
      <ErrorAlert
        v-if="error != null"
        :error="error"
        @clearErrors="clearErrors()"
        class="mb-4 rounded"
      />
    </template>

    <template #header-before-close v-if="vehicleLog">
      <VehicleLogDetailOptionsButton
        class="me-4"
        :vehicle-log="vehicleLog"
        @vehicle-log-edit-pressed="
          (vehicleLog: IVehicleLog) => emit('vehicle-log-edit-pressed', vehicleLog)
        "
        @vehicle-log-delete-pressed="
          (vehicleLog: IVehicleLog) => emit('vehicle-log-delete-pressed', vehicleLog)
        "
      />
    </template>

    <div class="d-flex flex-column flex-grow-1" v-if="vehicleLog">
      <v-list-item lines="three" class="position-relative rounded-lg mx-6 mb-2 px-0 pb-1 pt-0">
        <v-list-item-action>
          <v-avatar
            variant="flat"
            color="secondary"
            size="74"
            class="mb-6 px-3 text-h4"
            style="border-radius: 5px"
          >
            <span class="text-white font-weight-medium"><v-icon>mdi-car</v-icon></span>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-title class="text-high-emphasis text-h4 font-weight-bold mt-n2">
          {{ (vehicleLog.vehicle as IVehicle).plate }}
          <div
            class="text-h6 font-weight-regular text-black mt-n3 pb-6"
            style="padding-inline-start: 2px"
          >
            {{ (vehicleLog.vehicle as IVehicle).make }}
            {{ (vehicleLog.vehicle as IVehicle).model }}
          </div>
        </v-list-item-title>
        <v-list-subheader
          class="text-medium-emphasis text-body-2 font-weight-regular pb-3"
          style="min-height: 22px"
        >
          Vehicle Log Information
        </v-list-subheader>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Guard
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <span>
                {{ vehicleLog.owner }}
              </span>
            </div>
          </div>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Site
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <span>
                {{ (vehicleLog.shift as IShift).site }}
              </span>
            </div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Date
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              {{
                vehicleLog.created ? dateAdapter.formatByString(vehicleLog.created, 'PPP') : 'None'
              }}
            </div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Mileage
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <span>
                {{ vehicleLog.mileage }}
              </span>
            </div>
          </div>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Fuel
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <span> {{ Number(vehicleLog.fuel_percentage).toFixed(1) }}% </span>
            </div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              Notes
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <span> {{ vehicleLog.notes }} </span>
            </div>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle
          v-for="(attachment, attachmentsIndex) in attachments"
          :key="attachmentsIndex"
          class="detail-item-subtitle text-high-emphasis pb-4"
          style="padding-inline-start: 2px"
        >
          <div class="d-flex flex-column">
            <div
              class="d-flex flex-row flex-grow-1 align-space-between text-medium-emphasis text-caption font-weight-medium"
              style="line-height: 0.9rem"
            >
              {{ attachment.description }}
            </div>
            <div class="text-body-2 font-weight-medium text-black">
              <v-sheet variant="flat" class="rounded-lg mb-2" tag="ul">
                <div class="w-full d-flex flex-row flex-grow-1 align-center pb-1">
                  <div class="text-subtitle-2 d-block address mt-2 text-wrap">
                    <div class="d-block">
                      <v-hover>
                        <template v-slot:default="{ isHovering, props }">
                          <v-card
                            :ripple="false"
                            v-bind="props"
                            theme="dark"
                            color="surface-lighten-1"
                            variant="elevated"
                            class="d-flex align-center justify-center rounded"
                          >
                            <v-toolbar
                              absolute
                              density="comfortable"
                              style="top: 0px"
                              :style="{
                                transform: `translateY(${isHovering ? '0px' : '-48px'})`,
                                zIndex: 5,
                                background:
                                  'linear-gradient(rgba(var(--v-theme-surface), 1), rgba(var(--v-theme-surface), 0))'
                              }"
                              height="48"
                            >
                              <v-spacer />
                              <v-btn
                                variant="tonal"
                                class="rounded mr-2"
                                size="28"
                                icon
                                density="comfortable"
                                @click="openDeleteVehicleAttachmentDialog(attachment)"
                              >
                                <v-icon size="20" icon="mdi-delete" />
                              </v-btn>
                            </v-toolbar>
                            <ImageViewer
                              height="auto"
                              width="100%"
                              :src="attachment.file as string"
                            />
                          </v-card>
                        </template>
                      </v-hover>
                    </div>
                  </div>
                </div>
              </v-sheet>
            </div>
          </div>
        </v-list-item-subtitle>
      </v-list-item>
    </div>

    <ConfirmationDialog
      v-if="selectedVehicleLogAttachment"
      width="500"
      title="Delete attachment"
      v-model="vehicleAttachmentDeleteDialog"
      v-model:error="deleteAttachmentError"
    >
      <template #message>
        Are you sure you want to delete this vehicle log?
        <br />
        <div class="pt-2">This action cannot be undone.</div>
      </template>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          variant="flat"
          :disabled="deleteInProgress"
          :loading="deleteInProgress"
          @click="removeVehicleAttachment(selectedVehicleLogAttachment)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationDialog>
  </BaseRightDrawer>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useDate } from 'vuetify'

import type DateFnsUtils from '@date-io/date-fns/build/date-fns-utils'
import type {
  IVehicle,
  IVehicleLog,
  IVehicleLogAttachment
} from '@/models/fleet-management/vehicle'
import type { IShift } from '@/models/shift'
import type { ISystemError } from '@/models/error'

import {
  useDeleteVehicleLogAttachment,
  useFetchVehicleLog,
  useFetchVehicleLogAttachments
} from '@/composables/vehicle-log'

import BaseRightDrawer from '@/components/base/BaseRightDrawer.vue'
import VehicleLogDetailOptionsButton from './VehicleLogDetailOptionsButton.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import ImageViewer from '@/components/common/ImageViewer.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'

interface Props {
  vehicleLogId: number
}
const props = defineProps<Props>()

interface Emit {
  (e: 'vehicle-log-edit-pressed', vehicleLog: IVehicleLog): void
  (e: 'vehicle-log-delete-pressed', vehicleLog: IVehicleLog): void
}
const emit = defineEmits<Emit>()

const drawer = defineModel<boolean>('drawer')

const dateAdapter = useDate() as DateFnsUtils

const vehicleLogId = computed(() => props.vehicleLogId!)

const { log: vehicleLog, isPending, error: fetchError } = useFetchVehicleLog(vehicleLogId)
watch(fetchError, (value) => {
  if (value) {
    error.value = value
  }
})

const { attachments, error: attachmentError } = useFetchVehicleLogAttachments(vehicleLogId)
watch(attachmentError, (value) => {
  if (value) {
    error.value = value
  }
})

const vehicleAttachmentDeleteDialog = ref(false)

const selectedVehicleLogAttachment = ref<IVehicleLogAttachment>()

function openDeleteVehicleAttachmentDialog(attachment: IVehicleLogAttachment) {
  clearErrors()
  selectedVehicleLogAttachment.value = attachment
  vehicleAttachmentDeleteDialog.value = true
}

const error = ref<ISystemError | null>(null)

function clearErrors() {
  error.value = null
}

const {
  isPending: deleteInProgress,
  error: deleteAttachmentError,
  mutate: deleteVehicleLogAttachment
} = useDeleteVehicleLogAttachment(vehicleLogId)
watch(deleteAttachmentError, (value) => {
  if (value) {
    error.value = value
  }
})

function removeVehicleAttachment(vehicleAttachment: IVehicleLogAttachment) {
  clearErrors()
  deleteVehicleLogAttachment(vehicleAttachment.id!, {
    onSuccess() {
      vehicleAttachmentDeleteDialog.value = false
    }
  })
}
</script>

<style lang="scss">
.detail-item-subtitle {
  opacity: 1;
}
</style>

import { AbstractModel } from '../base'
import type { IClient, ISite } from '../client'
import { Client, Site } from '../client'
import type { ICamera, ICameraData } from './camera.types'

export class Camera extends AbstractModel implements ICamera {
  readonly id?: number
  readonly name: string
  readonly notes: string
  readonly site: number | ISite
  readonly client?: number | IClient

  constructor(data: ICameraData) {
    super()

    // Validate data
    const requiredFields: (keyof ICameraData)[] = ['name', 'site']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.name = data.name!
    this.site = typeof data.site! === 'number' ? data.site! : new Site(data.site!)

    this.notes = data.notes ?? ''

    if (data.id) {
      this.id = data.id
    }

    if (data.client) {
      this.client = typeof data.client! === 'number' ? data.client! : new Client(data.client!)
    }
  }
}

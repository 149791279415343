<template>
  <v-navigation-drawer permanent width="250" class="border">
    <v-toolbar density="compact" color="transparent" class="border-b">
      <template #title>
        <span class="text-subtitle-1 font-weight-medium">Checkpoints</span>
      </template>
      <template #append>
        <v-tooltip location="bottom" text="Add new checkpoint">
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              class="rounded-circle"
              variant="text"
              density="comfortable"
              size="32"
              @click="createPostOrderInstructionSection()"
              icon
            >
              <template v-slot:default>
                <v-icon color="primary" size="22" icon="mdi-plus" />
              </template>
            </v-btn>
          </template>
        </v-tooltip>
      </template>
    </v-toolbar>

    <ErrorAlert v-if="error != null" :error="error" @clearErrors="clearErrors()" class="mb-2">
    </ErrorAlert>
    <v-progress-linear :active="isPending" height="2" indeterminate color="primary" />
    <v-list :lines="false" density="compact" nav slim ref="sectionList">
      <v-list-item
        nav
        v-for="(section, index) in localSections"
        :key="index"
        :title="section.section.title"
        @click="emit('select-section-pressed', section)"
        :active="section.id == selectedSection"
      >
        <template v-slot:prepend>
          <v-icon class="handle" style="cursor: move" size="20" icon="mdi-drag-vertical" />
        </template>

        <template v-slot:append>
          <div>
            <MoreOptionsMenuButton
              v-if="
                currentUser?.hasPermission('post_orders.change_postorderinstructionssection') ||
                currentUser?.hasPermission('post_orders.delete_postorderinstructionssection')
              "
              class="mt-n1"
              :size="20"
              :iconSize="20"
            >
              <v-list-item
                v-if="currentUser?.hasPermission('post_orders.change_postorderinstructionssection')"
                title="Edit section"
                @click="emit('edit-section-pressed', section)"
              >
                <template #prepend>
                  <v-icon size="18" icon="mdi-pencil" class="me-1" />
                </template>
              </v-list-item>

              <template
                v-if="currentUser.hasPermission('post_orders.delete_postorderinstructionssection')"
              >
                <v-divider
                  class="pb-1"
                  v-if="
                    currentUser.hasPermission('post_orders.change_postorderinstructionssection')
                  "
                />
                <ConfirmationDialog
                  width="500"
                  title="Delete Checkpoint"
                  v-model="isDeleteSectionDialogActive"
                  v-model:error="deleteSectionError"
                >
                  <template #activator="{ props }">
                    <v-list-item title="Delete Section" class="text-error" v-bind="props">
                      <template #prepend>
                        <v-icon size="18" icon="mdi-delete" class="me-1" />
                      </template>
                    </v-list-item>
                  </template>

                  <template #message>
                    Are you sure you want to delete
                    <span class="text-medium-high-emphasis font-weight-bold">{{
                      section.section.title
                    }}</span
                    >?
                    <br />
                    <div class="pt-2">This action cannot be undone.</div>
                  </template>

                  <template #actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      variant="flat"
                      :disabled="deleteSectionIsPending"
                      :loading="deleteSectionIsPending"
                      @click="deletePostOrderSection(section.id!)"
                    >
                      Delete
                    </v-btn>
                  </template>
                </ConfirmationDialog>
              </template>
            </MoreOptionsMenuButton>
          </div>
        </template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'

import { useDraggable } from 'vue-draggable-plus'
import {
  useDeletePostOrderInstructionsSection,
  useUpdatePostOrderInstructionsSection
} from '@/composables/post-order'

import { FormSection } from '@/models/form'
import { useAuthStore } from '@/stores'
import { storeToRefs } from 'pinia'
import {
  PostOrderInstructionsSection,
  type IPostOrderInstructionsSection
} from '@/models/post-order'

import MoreOptionsMenuButton from '@/components/common/MoreOptionsMenuButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import ErrorAlert from '../common/ErrorAlert.vue'

interface Props {
  sections: IPostOrderInstructionsSection[]
  postOrderId: number
  postOrderInstructionId: number
  selectedSection?: number
}

const props = defineProps<Props>()

interface Emits {
  (name: 'edit-section-pressed', section: IPostOrderInstructionsSection): void
  (name: 'select-section-pressed', section: IPostOrderInstructionsSection): void
  (name: 'create-post-order-instruction-section-pressed'): void
}

const emit = defineEmits<Emits>()

const postOrderId = computed(() => props.postOrderId)
const postOrderInstructionId = computed(() => props.postOrderInstructionId)

const localSections = ref<IPostOrderInstructionsSection[]>([])

const sectionList = ref<HTMLDivElement | null>(null)

const draggable = useDraggable(sectionList, localSections, {
  onEnd: updateSectionListOrder,
  handle: '.handle'
})

onMounted(() => {
  draggable.start()
})

watch(
  () => props.sections,
  (value) => {
    localSections.value = [...value]
  },
  {
    immediate: true
  }
)

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const isDeleteSectionDialogActive = ref(false)

const {
  isPending: deleteSectionIsPending,
  error: deleteSectionError,
  mutate: deleteSection,
  reset: deleteSectionReset
} = useDeletePostOrderInstructionsSection(postOrderId, postOrderInstructionId)
// delete Section

function deletePostOrderSection(id: number) {
  deleteSectionReset()
  deleteSection(id, {
    onSuccess: () => {
      isDeleteSectionDialogActive.value = false
    }
  })
}

function compareSectionOrder(otherArray: IPostOrderInstructionsSection[]) {
  return function (current: IPostOrderInstructionsSection) {
    return (
      otherArray.filter(function (other: IPostOrderInstructionsSection) {
        return other.id == current.id && other.section.order == current.section.order
      }).length == 0
    )
  }
}

function updateSectionListOrder() {
  clearErrors()
  const sectionsClone: IPostOrderInstructionsSection[] = [...localSections.value]

  const sections = sectionsClone.map((term: IPostOrderInstructionsSection, index: number) => {
    return new PostOrderInstructionsSection({
      ...term,
      section: new FormSection({
        ...term.section,
        order: index + 1
      })
    })
  })

  const difference = sections.filter(compareSectionOrder(sectionsClone))

  difference.map((section) => {
    return updatePostOrderInstructionSection(section, {
      onError() {
        localSections.value = [...props.sections]
      }
    })
  })
}

function createPostOrderInstructionSection() {
  emit('create-post-order-instruction-section-pressed')
}

const {
  isPending: isPending,
  error: error,
  mutate: updatePostOrderInstructionSection,
  reset: clearErrors
} = useUpdatePostOrderInstructionsSection(postOrderId, postOrderInstructionId)
</script>

<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 400 : 677">
    <AlFormCard title="Update Incident Level" subtitle="Incident Level update">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="emit('clearErrors')"
          class="mb-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form ref="form" v-model="isIncidentLevelFormValid" validate-on="submit lazy">
        <v-row class="mb-2">
          <v-col cols="12">
            <v-select
              :model-value="status.state"
              label="Incident Level"
              variant="outlined"
              hide-details="auto"
              :items="incidentStatusVisuals"
              item-title="display"
              item-value="state"
              chips
              readonly
            >
              <template #chip="{ item, props }">
                <v-list-item
                  density="compact"
                  class="pl-2 py-0"
                  :title="item.raw.display"
                  v-bind="props"
                  :style="`border-left: solid ${
                    item.raw.color || getReportIncidentStatusVisualByState(item.raw.state)?.color
                  } 8px;`"
                ></v-list-item>
              </template>
            </v-select>
          </v-col>

          <template v-if="!observation.incident_report">
            <v-col cols="12">
              <v-radio-group
                inline
                hide-details="auto"
                v-model="incidentReportLinkChoice"
                :rules="[reportChoiceValidator]"
              >
                <v-radio label="Link existing incident report" value="link_report"></v-radio>
                <v-radio
                  label="Create new incident report"
                  value="new_report"
                  @click="currentObservationIncidentReport = null"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-expand-transition mode="out-in">
              <v-col cols="12" v-if="incidentReportLinkChoice == 'link_report'">
                <IncidentReportAutoCompletePicker
                  v-model="currentObservationIncidentReport"
                  v-model:search="currentObservationIncidentReportSearchQuery"
                  hide-details="auto"
                  :readonly="currentObservationIncidentReport != null"
                  clearable
                  :filter="{ site: (report.site as ISite).code }"
                  :persistent-hint="!!currentObservationIncidentReport"
                  @update:model-value="
                    (report: any) => updateCurrentObservationIncidentReport(report)
                  "
                  :error-messages="createIncidentReportMutation.error.value?.message"
                  :rules="[requiredValidator]"
                  :hint="
                    !!observation.incident_level
                      ? `An Incident report will be created with ${status.display}`
                      : ''
                  "
                />
              </v-col>
            </v-expand-transition>
          </template>

          <v-col cols="12">
            <v-textarea
              v-model="incidentLevelChangeReason"
              :rules="[requiredValidator]"
              label="Reason"
              variant="outlined"
              hide-details="auto"
              density="compact"
            />
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="saveIncidentLevelDialog()"
          >Save</v-btn
        >
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

import type { VForm } from 'vuetify/components'

import type { ISystemError } from '@/models/error'
import type { ISite } from '@/models/client'
import {
  type IReport,
  type IGuardReportActivityObservation,
  type ICCTVReportActivityObservation
} from '@/models/report'
import { useReportStore, type IReportIncidentStateVisual } from '@/stores'
import { useCreateIncidentReport } from '@/composables/incident-report'

import { reportChoiceValidator, requiredValidator, type ReportLinkChoice } from '@/utils/validators'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import IncidentReportAutoCompletePicker from '../incident/IncidentReportAutoCompletePicker.vue'

interface Props {
  report: IReport
  observation: IGuardReportActivityObservation | ICCTVReportActivityObservation
  error: ISystemError | null
  loading: boolean
  status: IReportIncidentStateVisual
}

const props = defineProps<Props>()

interface Emit {
  (e: 'clearErrors'): void
  (
    e: 'save-incident-report',
    payload: {
      id: number
      incident_level: number
      incident_report: number
      reason: string
      is_new_incident_report: boolean
    }
  ): void
}

const emit = defineEmits<Emit>()

const dialog = defineModel<boolean>('dialog', { default: false })

const incidentReportLinkChoice = ref<ReportLinkChoice>('no_report')

watch(
  () => dialog.value,
  (value) => {
    if (value) {
      incidentLevelChangeReason.value = ''
      currentObservationIncidentReport.value = props.observation.incident_report
      currentObservationIncidentReportSearchQuery.value =
        props.observation.incident_report?.toString() ?? ''

      if (props.observation.incident_report) {
        incidentReportLinkChoice.value = 'link_report'
      } else {
        incidentReportLinkChoice.value = 'no_report'
      }
    }
  }
)

function closeDialog() {
  dialog.value = false
}

const form = ref<VForm>()

const reportStore = useReportStore()
const { incidentStatusVisuals, getReportIncidentStatusVisualByState } = storeToRefs(reportStore)

const isIncidentLevelFormValid = ref(false)

const incidentLevelChangeReason = ref('')
const currentObservationIncidentReport = ref<number | null>(null)
const currentObservationIncidentReportSearchQuery = ref('')

const createIncidentReportMutation = useCreateIncidentReport()

function updateCurrentObservationIncidentReport(report: number | null) {
  currentObservationIncidentReport.value = report as number | null
  emit('clearErrors')
}

async function saveIncidentLevelDialog() {
  const { valid } = await form.value!.validate()

  if (valid) {
    const payload = {
      id: props.observation.id!,
      incident_level: props.status.state!,
      incident_report: currentObservationIncidentReport.value!,
      reason: incidentLevelChangeReason.value,
      is_new_incident_report: incidentReportLinkChoice.value == 'new_report'
    }

    emit('save-incident-report', payload)
  }
}
</script>

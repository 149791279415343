<template>
  <v-dialog v-model="dialog" v-bind="$attrs" persistent max-width="900">
    <AlFormCard class="wizard-form" title="Hourly Guard Report" width="100%" height="100%">
      <template #error>
        <ErrorAlert v-if="error != null" :error="error" @clearErrors="clearErrors()" class="mb-2">
        </ErrorAlert>
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-window v-model="windowStep">
        <v-window-item :value="BaseReportWindowSteps.Client">
          <div class="content-container pt-8">
            <div class="create-event-content">
              <div class="main-content">
                <h1>Let’s start by selecting the client and site.</h1>
                <div class="d-flex">
                  <v-col cols="5" class="pr-0">
                    <v-form ref="clientFormRef" v-model="isClientValid" validate-on="submit lazy">
                      <div class="flex-grow-1">
                        <v-label
                          for="reportClientId"
                          text="Client"
                          class="text-body-2 text-high-emphasis"
                        />
                        <ClientAutoCompletePicker
                          class="mt-2"
                          label=""
                          id="reportClientId"
                          variant="outlined"
                          hide-details="auto"
                          density="compact"
                          v-model="reportFormData.client"
                          @update:model-value="clearErrors()"
                          :rules="[requiredValidator]"
                          :error-messages="error && (error.fieldErrors['client'] as string[])"
                        />
                      </div>
                    </v-form>
                  </v-col>

                  <v-col cols="7" class="pl-5">
                    <v-card
                      :loading="clientSitesLoading"
                      :disabled="clientSitesLoading"
                      variant="flat"
                      class="site-container bg-transparent"
                    >
                      <v-fade-transition mode="out-in">
                        <NoDataAvailablePlaceholder
                          v-if="!reportFormData.client || sites?.length == 0"
                          width="400"
                          header="No site available"
                          header-class="font-weight-medium text-h5"
                        >
                          <template #description>
                            <p class="mt-0 text-caption text-medium-emphasis">
                              <span>
                                You do not have any sites available.
                                <template v-if="!reportFormData.client"
                                  >Please select a Client</template
                                >
                              </span>
                            </p>
                          </template>
                        </NoDataAvailablePlaceholder>
                        <ReportSiteForm
                          v-else
                          ref="reportSiteFormRef"
                          v-model="reportFormData.site"
                          :sites="sites ?? []"
                          :error="sitesError"
                        >
                        </ReportSiteForm>
                      </v-fade-transition>
                    </v-card>
                  </v-col>
                </div>
              </div>
            </div>
          </div>
        </v-window-item>

        <v-window-item :value="BaseReportWindowSteps.Shift">
          <div class="content-container pt-8">
            <div class="create-event-content">
              <div class="main-content">
                <ReportShiftForm
                  ref="reportShiftFormRef"
                  v-model:shift_start="reportFormData.shift!.start"
                  v-model:shift_end="reportFormData.shift!.end"
                  :field-errors="{
                    shift_start: error?.fieldErrors['shift']
                      ? (error.fieldErrors['shift'] as IFieldErrors)['start']
                      : [],
                    shift_end: error?.fieldErrors['shift']
                      ? (error.fieldErrors['shift'] as IFieldErrors)['end']
                      : []
                  }"
                />
              </div>
            </div>
          </div>
        </v-window-item>

        <v-window-item :value="BaseReportWindowSteps.Memo">
          <div class="content-container pt-8">
            <div class="create-event-content">
              <div class="main-content">
                <v-form ref="memoFormRef" v-model="isMemoValid">
                  <h1>Also want to add an optional memo?</h1>
                  <div class="d-flex flex-column">
                    <div class="ml-4 mr-16 mt-5">
                      <v-textarea
                        label="Memo"
                        v-model="reportFormData.memo"
                        variant="outlined"
                        rows="7"
                        no-resize
                        persistent-hint
                        hint="This is optional"
                      >
                      </v-textarea>
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </v-window-item>
      </v-window>

      <template #actions>
        <template v-if="windowStep == BaseReportWindowSteps.Client">
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="flat" @click="next()">Continue</v-btn>
        </template>

        <template v-if="windowStep == BaseReportWindowSteps.Shift">
          <v-btn
            class="ml-n4"
            color="primary"
            variant="text"
            @click="windowStep = BaseReportWindowSteps.Client"
            >Previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="flat" @click="next()"> Continue </v-btn>
        </template>

        <template v-if="windowStep == BaseReportWindowSteps.Memo">
          <v-btn
            class="ml-n4"
            color="primary"
            variant="text"
            @click="windowStep = BaseReportWindowSteps.Shift"
            >Previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="flat"
            @click="save()"
            :loading="isPending"
            :disabled="!isMemoValid"
          >
            Save
          </v-btn>
        </template>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { cloneDeep } from 'lodash'
import { useRouter } from 'vue-router'

import type { IClientData, ISite } from '@/models/client'
import {
  BaseReportWindowSteps,
  GuardReport,
  type IGuardReport,
  type IGuardReportData
} from '@/models/report'
import { type ISystemError, type IFieldErrors } from '@/models/error'

import { useCreateGuardReport, useUpdateGuardReport } from '@/composables/guard-report'
import { useSnackbarStore } from '@/stores'
import { useFetchSites } from '@/composables/site'
import { requiredValidator } from '@/utils/validators'

import type { VForm } from 'vuetify/components/VForm'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import ClientAutoCompletePicker from '@/components/clients/ClientAutoCompletePicker.vue'
import ReportShiftForm from '../common/ReportShiftForm.vue'
import ReportSiteForm from '../common/ReportSiteForm.vue'
import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'

const dialog = defineModel<boolean>('dialog')

interface Props {
  guardReport?: IGuardReport
  isEdit: boolean
}
const props = defineProps<Props>()

interface Emits {
  (e: 'saved-report'): void
}
const emit = defineEmits<Emits>()

const router = useRouter()
const snackbarStore = useSnackbarStore()

const reportFormData = ref<IGuardReportData>({
  shift: {
    start: new Date(),
    end: new Date()
  }
})

watch(
  () => dialog.value,
  () => {
    reportFormData.value = props.isEdit
      ? cloneDeep(props.guardReport!)
      : // set default date for date object
        ({
          shift: {
            start: new Date(),
            end: new Date()
          }
        } as IGuardReportData)
  }
)

const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updateMutate
} = useUpdateGuardReport()
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createMutate
} = useCreateGuardReport()

const isPending = computed(() => (props.isEdit ? isUpdatePending.value : isCreatePending.value))

const error = ref<ISystemError | null>(null)

watch(
  () => (props.isEdit ? updateError.value : createError.value),
  (value) => {
    error.value = value
  }
)

const windowStep = ref(BaseReportWindowSteps.Client)

const isMemoValid = ref(false)
const isClientValid = ref(false)
const memoFormRef = ref<typeof VForm>()
const clientFormRef = ref<typeof VForm>()
const reportSiteFormRef = ref<typeof ReportSiteForm>()
const reportShiftFormRef = ref<typeof ReportShiftForm>()

function clearErrors() {
  clientFormRef.value?.resetValidation()
  reportSiteFormRef.value?.clearErrors()
  reportShiftFormRef.value?.clearErrors()
  error.value = null
}

function closeDialog() {
  dialog.value = false
  clearErrors()
  windowStep.value = BaseReportWindowSteps.Client
}

async function next() {
  clearErrors()
  switch (windowStep.value) {
    case BaseReportWindowSteps.Client: {
      if ((await validateClientForm()) && (await validateSiteForm())) {
        windowStep.value = BaseReportWindowSteps.Shift
      }
      break
    }
    case BaseReportWindowSteps.Shift: {
      if (await validateReportShiftForm()) {
        windowStep.value = BaseReportWindowSteps.Memo
      }
      break
    }
  }
}

async function validateReportShiftForm(): Promise<boolean> {
  const { valid } = await reportShiftFormRef.value!.validate()
  return valid
}

async function validateClientForm(): Promise<boolean> {
  const { valid } = await clientFormRef.value!.validate()
  return valid
}

async function validateSiteForm(): Promise<boolean> {
  // in case of no sites found
  if (!reportSiteFormRef.value) return false
  const { valid } = await reportSiteFormRef.value.validate()
  return valid
}

async function validateMemoForm(): Promise<boolean> {
  const { valid } = await memoFormRef.value!.validate()
  return valid
}

async function save() {
  clearErrors()

  const valid = await validateMemoForm()

  if (valid) {
    let report: IGuardReport | null = null

    try {
      report = new GuardReport({
        ...reportFormData.value,
        shift: {
          site: (reportFormData.value.site! as ISite).code! ?? reportFormData.value.site,
          ...reportFormData.value.shift
        }
      })
    } catch (e: any) {
      console.warn(e)
      error.value = e
      //end here if there's an error validating the model
      return
    }

    //shift site is needed on update so might as well pass in the same payload as we do for create to maximize efficiency
    let payload = {
      id: report.id,
      site: (report.site! as ISite).code! ?? report.site,
      shift: {
        ...report.shift,
        site: (report.site! as ISite).code! ?? report.site
      },
      memo: report.memo
    } as IGuardReportData

    const mutate = props.isEdit ? updateMutate : createMutate

    mutate(payload, {
      onSuccess: (data) => {
        const actionText = props.isEdit ? 'updated' : 'created'
        snackbarStore.showSnackbar(`Guard Report ${actionText} successfully`)
        closeDialog()
        emit('saved-report')
        if (!props.isEdit) {
          router.push({ name: 'guard-detail', params: { id: data.id } })
        }
      },
      onError: (err) => {
        if (err.fieldErrors) {
          if (err.fieldErrors['shift']) {
            windowStep.value = BaseReportWindowSteps.Shift
          } else if (err.fieldErrors['site']) {
            windowStep.value = BaseReportWindowSteps.Client
          }
        }
      }
    })
  }
}

const selectedClient = computed(() => {
  const clientId =
    (reportFormData.value.client as IClientData)?.id ?? (reportFormData.value.client as number)
  return clientId
})
const { sites, isLoading: clientSitesLoading, error: sitesError } = useFetchSites(selectedClient)
</script>

<style lang="scss">
.wizard-form {
  .al-card-item {
    padding-left: auto;
  }
  .al-card-item--title {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.54);
    display: inline;
    padding-left: 16px;
  }

  .content-container {
    flex: 1;
    left: 0;
    right: 0;
    margin-left: 24px;
    margin-right: 24px;
    height: 100%;
    position: relative;

    .main-content {
      display: flex;
      flex: 1;
      flex-flow: column nowrap;
      width: 100%;
      max-height: 360px;
      min-height: 360px;
      padding-left: 0;
    }

    h1 {
      margin-bottom: 20px;
      font-size: 30px;
      line-height: 40px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.87);
    }

    .create-event-actions {
      align-items: end;
      align-self: stretch;
      display: inline-flex;
      justify-content: space-between;
      margin-top: 72px;
      flex-direction: column;
      min-width: 100%;
      flex-wrap: wrap-reverse;
    }
  }

  .create-event-content {
    align-items: stretch;
    display: flex;
    height: auto;
  }

  .site-container {
    height: 260px;
    width: 100%;
    background: #d3d3d326;
  }
}
</style>

<template>
  <v-container class="d-flex flex-column align-center justify-center text-center my-8">
    <slot name="prepend">
      <v-card
        variant="flat"
        class="mask-bottom-card mb-3"
        color="transparent"
        width="180"
        height="120"
      >
        <v-list
          class="bg-transparent"
          style="color: rgba(var(--v-theme-on-surface), var(--v-disabled-opacity))"
          variant="outlined"
          density="compact"
          lines="two"
        >
          <v-list-item class="rounded-lg-xl mb-3 px-3">
            <v-skeleton-loader
              class="bg-transparent me-n3 ms-n4 my-n2"
              type="text, text"
              boilerplate
            />
          </v-list-item>
          <v-list-item class="rounded-lg-xl mb-3 px-3">
            <v-skeleton-loader
              class="bg-transparent me-n3 ms-n4 my-n2"
              type="text, text"
              boilerplate
            />
          </v-list-item>
        </v-list>
      </v-card>
    </slot>

    <v-card variant="flat" color="transparent" :width="width">
      <h4 id="empty-state-header" :class="headerClass" class="mt-0 mb-3">
        {{ header }}
      </h4>
      <slot name="description">
        <p class="mt-0 mb-5 text-caption text-medium-emphasis" v-if="description">
          {{ description }}
        </p>
      </slot>

      <div class="d-flex align-center justify-center mb-2">
        <v-btn-group>
          <v-defaults-provider :defaults="actionBtnDefaults.secondary">
            <slot name="secondaryAction" />
          </v-defaults-provider>

          <v-defaults-provider :defaults="actionBtnDefaults.primary">
            <slot name="primaryAction" />
          </v-defaults-provider>
        </v-btn-group>
      </div>

      <v-defaults-provider :defaults="actionBtnDefaults.tertiary">
        <slot name="tertiaryAction" />
      </v-defaults-provider>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import { reactive } from 'vue'

interface Props {
  header: string
  headerClass?: string
  level?: number
  description?: string
  width?: string | number
}

withDefaults(defineProps<Props>(), {
  level: 4,
  headerClass: 'text-h6 font-weight-regular text-high-emphasis'
})

const actionBtnDefaults = reactive({
  primary: {
    VBtn: {
      class: 'rounded',
      height: '',
      color: 'primary',
      density: 'default',
      flat: true,
      prependIcon: 'mdi-plus'
    }
  },
  secondary: {
    VBtn: {
      class: 'rounded mr-2',
      height: '',
      density: 'default',
      flat: true
    }
  },
  tertiary: {
    VBtn: {
      class: 'rounded',
      height: '',
      density: 'default',
      flat: true
    }
  }
})
</script>

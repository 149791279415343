<template>
  <v-card width="min-content">
    <v-date-picker
      class="pt-2"
      hide-header
      show-adjacent-months
      v-model="date"
      @update:model-value="updateDateTimeModel()"
      v-bind="$attrs"
    >
    </v-date-picker>
    <v-divider></v-divider>
    <TimePicker
      class="pa-3"
      v-model:hours.number="time.hours"
      v-model:minutes.number="time.minutes"
  /></v-card>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue'
import TimePicker from '@/components/common/TimePicker.vue'

const props = defineProps({
  modelValue: {
    type: [Date, String],
    default: () => new Date()
  }
})

const emit = defineEmits<{
  'update:modelValue': [date: Date]
}>()

const time = ref({
  hours: (props.modelValue as Date).getHours(),
  minutes: (props.modelValue as Date).getMinutes()
})

const date = ref<Date>(props.modelValue as Date)

watch(
  () => time.value,
  () => {
    updateDateTimeModel()
  },
  { deep: true }
)

const updateDateTimeModel = () => {
  const updatedDateTime = date.value.setHours(time.value.hours, time.value.minutes)
  date.value = new Date(updatedDateTime)
  emit('update:modelValue', date.value)
}
</script>

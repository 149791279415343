import { nextTick } from 'vue'
import { defineStore } from 'pinia'
import type { ISnackbarState } from './snackbar.types'

export const useSnackbarStore = defineStore('snackbar', {
  state: () =>
    ({
      visible: false,
      message: '',
      color: 'success',
      timeout: 6000 // timeout in ms
    }) as ISnackbarState,

  actions: {
    async showSnackbar(message: string, color = 'success') {
      // refresh timer on every call back
      this.visible = false
      await nextTick()

      this.message = message
      this.color = color
      this.visible = true
    },

    closeSnackbar() {
      this.visible = false
    }
  }
})

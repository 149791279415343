export enum FormFieldTypeEnum {
  Label,
  TextField,
  TextArea,
  ComboBox,
  CheckBox,
  RadioButton,
  DatePicker,
  FileField,
  Custom
}

export enum FormFieldSubTypeEnum {
  VehicleLog,
  HTMLDisplay,
  IncidentReport
}

export enum FieldConstraintState {
  Required,
  Optional,
  Disabled
}

import type { AxiosInstance } from 'axios'
import type { ISetSiteApproverPayload, ISiteManagementService } from './site-management.types'
import axios from '@/axios'

/**
 * A class representing an SiteManagementService API handler.
 */
export class SiteManagementService implements ISiteManagementService {
  private endpoint: string

  // SiteManagementService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/site-management/'
  }

  setSiteApprover(data: ISetSiteApproverPayload): Promise<void> {
    return this.axios
      .post(`${this.endpoint}sites/${data.code}/set-approver/`, {
        employee: data.employeeId,
        is_site_approver: data.is_site_approver
      })
      .then(({ data }) => data)
  }
}

// Default export instantiation of the SiteManagementService
const siteManagementService = new SiteManagementService(axios)
export default siteManagementService

import { AbstractModel } from '../base'

import type { IClient, IClientData } from './client.types'
import type { ISite } from './site.types'
import { Site } from './site'

/**
 * A class representing a Client
 */
export class Client extends AbstractModel implements IClient {
  readonly id?: number
  readonly name: string
  readonly sites: ISite[] = []
  readonly created?: Date
  readonly modified?: Date

  constructor(data: IClientData) {
    super()

    // Validate data
    const requiredFields: (keyof IClientData)[] = ['name']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.name = data.name!

    if (data.id) {
      this.id = data.id
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }

    if (data.sites) {
      this.sites = data.sites.map((site) => new Site(site))
    }
  }

  toString(): string {
    return this.name
  }

  getInitial(): string {
    return this.name[0].toUpperCase()
  }
}

<template>
  <v-tooltip location="bottom" open-delay="450">
    <template #activator="{ props }">
      <span ref="activatorRef">
        <slot name="button" v-bind="{ attrs, props }">
          <v-btn
            v-bind="{ ...attrs, ...props }"
            :variant="variant"
            color="medium-emphasis"
            :size="size"
          >
            <v-icon :size="iconSize" icon="mdi-dots-horizontal" />
          </v-btn>
        </slot>
      </span>

      <v-menu :activator="activatorRef" :target="activatorRef">
        <v-list density="comfortable" slim nav class="rounded">
          <slot name="default" />
        </v-list>
      </v-menu>
    </template>
    {{ tooltipText }}
  </v-tooltip>
</template>

<script setup lang="ts">
import { ref, useAttrs } from 'vue'
import { VBtn } from 'vuetify/components/VBtn'

const attrs = useAttrs()

interface Props {
  size?: number
  iconSize?: number
  variant?: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'
  tooltipText?: string
}

withDefaults(defineProps<Props>(), {
  size: 32,
  iconSize: 28,
  variant: 'text',
  tooltipText: 'Options'
})

const activatorRef = ref<HTMLDivElement>()
</script>

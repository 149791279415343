import { defineStore } from 'pinia'

import { Camera, type ICameraData } from '@/models/camera'
import cameraService from '@/services/camera/camera'

import type { ICameraFilterParam } from '@/services'

import type { ICameraState } from './camera.types'
// Pinia store for managing camera-related states and actions
export const useCameraStore = defineStore('camera', {
  state: () =>
    ({
      count: 0,
      cameras: [],
      lastPromise: null
    }) as ICameraState,

  getters: {
    // Getter to retrieve cameras from the store
    getCameras: (state: ICameraState) => {
      return state.cameras.map((camera) => new Camera(camera))
    },

    // Getter to retrieve a camera by ID from the store,
    getCameraById: (state: ICameraState) => (id: number) => {
      const camera = state.cameras.find((camera) => camera.id === id)
      if (!camera) {
        return undefined
      }
      return new Camera(camera)
    }
  },

  actions: {
    // Action method to fetch list of cameras and update the store
    async fetchCameras(filter?: ICameraFilterParam): Promise<void> {
      const promise = cameraService.fetchCameras(filter)
      this.lastPromise = promise

      return promise.then((data) => {
        if (promise == this.lastPromise) {
          this.count = data.count
          this.cameras = data.results

          this.lastPromise = null
        }
      })
    },

    // Action method to fetch a single camera and update the store
    async fetchCamera(id: number): Promise<void> {
      const camera = await cameraService.fetchCamera(id)

      const index = this.cameras.findIndex((v) => v.id === id)
      if (index === -1) {
        this.cameras.push(camera)
      } else {
        this.cameras[index] = camera
      }
    },

    // Action method to create a new camera
    async createCamera(data: ICameraData): Promise<void> {
      const camera = await cameraService.createCamera(data)
      this.cameras.push(camera)
    },

    // Action method to update an existing camera
    async updateCamera(data: ICameraData): Promise<void> {
      const camera = await cameraService.updateCamera(data)
      const index = this.cameras.findIndex((v) => v.id === data.id)
      this.cameras[index] = camera
    },

    // Action method to delete an existing camera
    async deleteCamera(id: number): Promise<void> {
      await cameraService.deleteCamera(id)

      const index = this.cameras.findIndex((v) => v.id === id)
      if (index !== -1) {
        this.cameras.splice(index, 1)
      }
    }
  }
})

<template>
  <v-input
    v-model="modelValue"
    :v-bind="$attrs"
    :rules="rules"
    :error-messages="errorMessages"
    :disabled="disabled"
  >
    <template #default="{ isDisabled, isValid }">
      <v-card
        :loading="loading"
        :disabled="isDisabled.value"
        variant="flat"
        color="transparent"
        class="attachment-box d-flex flex-column flex-grow-1"
      >
        <div
          class="attachment-box-container"
          :class="{ 'attachment-box-container--error': isValid.value === false }"
          ref="target"
          @paste="onPaste"
          style="cursor: pointer"
        >
          Click here, then use Control-V to paste the image(s).
          <br />
          or
          <br />
          <v-btn @click.stop="openFileInput()" class="text-none">Upload File(s)</v-btn>
        </div>
      </v-card>
    </template>
  </v-input>

  <input
    ref="fileInputRef"
    accept=".jpg, .jpeg, .png, .gif"
    type="file"
    class="d-none"
    multiple
    @change="(e) => setFileImage(e)"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { ValidationRule } from 'node_modules/v-phone-input/dist/components/VPhoneInput.vue'
import type { IIncidentReportObservationAttachmentData } from '@/models/report'

const modelValue = defineModel<IIncidentReportObservationAttachmentData[]>({
  required: false,
  default: () => []
})

interface Props {
  errorMessages?: string | string[] | null
  rules?: readonly ValidationRule[]
  clearable?: boolean
  loading?: boolean
  disabled?: boolean
}

defineProps<Props>()

const fileInputRef = ref<HTMLInputElement | null>(null)

function onPaste(event: ClipboardEvent) {
  const clipboardData = event.clipboardData || (window as any).clipboardData.getData('Text') //get clipboard from window if it doesn't get from event
  const items = clipboardData.items
  const newItemList = []
  for (let i = 0; i < items.length; i++) {
    if (items[i].type.indexOf('image') !== -1) {
      newItemList.push({ file: items[i].getAsFile() })
    }
  }
  modelValue.value = newItemList
}

function openFileInput() {
  fileInputRef.value!.click()
}

function setFileImage(e: Event) {
  if (!e.target) return

  const target = e.target as HTMLInputElement

  modelValue.value = [...(target.files ?? [])].map((file) => {
    return { file }
  })
}
</script>

<style lang="scss">
.attachment-box .attachment-box-container {
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.attachment-box .attachment-box-container:hover {
  border-color: #555;
  background-color: #fff;
  color: #333;
}

.attachment-box .attachment-box-container:focus {
  outline: none;
}

.attachment-box .attachment-box-container.container--error {
  border: 2px dashed rgba(var(--v-theme-error)) !important;
}
</style>

<template>
  <v-list-item
    lines="three"
    class="position-relative border mb-2 px-3"
    :class="[isSelected ? '' : 'rounded-lg']"
    variant="outlined"
    slim
    @click="emit('contact-selected', !isSelected)"
  >
    <template #prepend>
      <v-avatar variant="tonal" color="primary" size="36" class="me-2">{{
        contact.getInitials()
      }}</v-avatar>
    </template>

    <v-list-item-title
      tag="h6"
      class="text-body-2 font-weight-medium text-link text-high-emphasis"
      @click="emit('contact-selected', !isSelected)"
    >
      {{ contact.getFullName() }}
    </v-list-item-title>

    <v-list-item-subtitle>
      {{ contact.title }}
    </v-list-item-subtitle>

    <v-list-item-subtitle
      @click.stop=""
      tag="a"
      :href="`mailto:${contact.email}`"
      target="_blank"
      rel="noopener noreferrer"
      class="font-weight-medium text-link"
    >
      {{ contact.email }}
    </v-list-item-subtitle>

    <v-list-item-subtitle
      v-if="contact.phone"
      @click.stop=""
      tag="a"
      :href="parsePhoneNumber(contact.phone)?.getURI()"
      target="_blank"
      rel="noopener noreferrer"
      class="font-weight-medium text-link"
    >
      {{ parsePhoneNumber(contact.phone)?.formatNational() }}
    </v-list-item-subtitle>

    <template #append>
      <v-list-item-action end>
        <MoreOptionsMenuButton
          color="medium-emphasis"
          class="ml-auto align-self-start mt-n3"
          :size="26"
          v-if="
            actionable &&
            (currentUser?.hasPermission('contacts.change_contact') ||
              currentUser?.hasPermission('contacts.delete_contact'))
          "
        >
          <v-list-item
            v-if="currentUser.hasPermission('contacts.change_contact')"
            @click="$emit('contact-edit-pressed', contact)"
            title="Edit Contact"
          />

          <v-divider class="mb-1" />

          <ConfirmationDialog
            width="auto"
            title="Delete contact"
            v-model="deleteContactDialog"
            v-model:error="deleteError"
          >
            <template #activator="{ props }">
              <v-list-item
                v-if="currentUser.hasPermission('contacts.delete_contact')"
                title="Delete Contact"
                class="text-error"
                v-bind="props"
              />
            </template>

            <template #message>
              Are you sure you want to delete
              <span class="text-medium-high-emphasis font-weight-bold">{{
                contact.getFullName()
              }}</span
              >?
              <br />
              <div class="pt-2">This action cannot be undone.</div>
            </template>

            <template #actions>
              <v-spacer></v-spacer>
              <v-btn
                variant="flat"
                :disabled="deleteInProgress"
                @click="closeDeleteContactDialog()"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                variant="flat"
                :loading="deleteInProgress"
                @click="deleteSelectedContact(contact.id!)"
              >
                Delete
              </v-btn>
            </template>
          </ConfirmationDialog>
        </MoreOptionsMenuButton>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import parsePhoneNumber from 'libphonenumber-js'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'
import { useDeleteContacts } from '@/composables/contacts'

import type { IContact } from '@/models/contact'

import ConfirmationDialog from '../common/ConfirmationDialog.vue'
import MoreOptionsMenuButton from '../common/MoreOptionsMenuButton.vue'

interface Props {
  contact: IContact
  isSelected: boolean
  actionable?: boolean
}

withDefaults(defineProps<Props>(), {
  isSelected: false,
  actionable: false
})

interface Emit {
  (e: 'contact-selected', selected: boolean): void
  (e: 'contact-edit-pressed', contact: IContact): void
  (e: 'contact-create-pressed'): void
}
const emit = defineEmits<Emit>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const deleteContactDialog = ref(false)
function closeDeleteContactDialog() {
  deleteContactDialog.value = false
}

const { loading: deleteInProgress, error: deleteError, deleteContact } = useDeleteContacts()

function deleteSelectedContact(contactId: number) {
  return deleteContact(contactId).then(() => {
    closeDeleteContactDialog()
  })
}
</script>

<template>
  <v-card variant="flat" class="d-flex flex-column flex-grow-1" :loading="isPending">
    <div class="handle align-self-center mt-n3 pb-1" style="cursor: move">
      <v-icon color="medium-emphasis">mdi-drag-horizontal</v-icon>
    </div>

    <div class="flex-grow-1">
      <div class="d-flex flex-column flex-grow-1 align-space-between justify-space-between pb-2">
        <div class="d-flex flex-row flex-grow-1 align-space-between justify-space-between">
          <v-label
            :for="`option-${optionIndex + 1}`"
            text="Option"
            class="font-weight-medium text-body-2 opacity-90 pb-1"
          />

          <ConfirmationDialog
            width="auto"
            title="Delete option"
            v-model="deleteDialog"
            v-model:error="deleteOptionError"
          >
            <template #activator="{ props }">
              <v-btn
                size="24"
                variant="text"
                color="medium-emphasis"
                density="compact"
                class="rounded-circle"
                v-bind="props"
              >
                <v-icon size="20">mdi-delete</v-icon>
              </v-btn>
            </template>

            <template #message>
              Are you sure you want to delete
              <span class="text-medium-high-emphasis font-weight-bold">{{
                fieldOption.option.value
              }}</span
              >?
              <br />
              <div class="pt-2">This action cannot be undone.</div>
              .
            </template>

            <template #actions>
              <v-spacer></v-spacer>
              <v-btn
                variant="flat"
                :disabled="deleteOptionInProgress"
                @click="closeOptionDeleteDialog()"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                variant="flat"
                :loading="deleteOptionInProgress"
                @click="deleteFieldOption(option.id!)"
              >
                Delete
              </v-btn>
            </template>
          </ConfirmationDialog>
        </div>

        <v-text-field
          :id="`option-${optionIndex + 1}`"
          single-line
          variant="outlined"
          density="compact"
          v-model="fieldOption.option.value"
          @update:model-value="debounceSaveFieldOption(fieldOption)"
          :error-messages="
            error &&
            error.fieldErrors['option'] &&
            ((error.fieldErrors['option'] as IFieldErrors)['value'] as string[])
          "
          hide-details="auto"
        />
      </div>

      <div class="d-flex flex-column flex-grow-1 align-space-between justify-space-between pb-2">
        <v-label
          :for="`option-observation-text-${optionIndex + 1}`"
          text="Observation text"
          class="font-weight-medium text-body-2 opacity-90 pb-1"
        />
        <v-textarea
          :id="`option-observation-text-${optionIndex + 1}`"
          single-line
          variant="outlined"
          density="compact"
          v-model="fieldOption.observation_text"
          @update:model-value="debounceSaveFieldOption(fieldOption)"
          :error-messages="error && (error.fieldErrors['observation_text'] as string[])"
          hide-details="auto"
          rows="3"
        />
      </div>

      <div class="d-flex flex-column flex-grow-1 align-space-between justify-space-between">
        <v-btn
          :color="getReportIncidentStatusVisualByState(fieldOption.incident_level)?.color"
          density="comfortable"
          class="text-white text-none font-weight-medium"
          block
          variant="flat"
        >
          {{ getReportIncidentStatusVisualByState(fieldOption.incident_level)?.display }}
          <v-icon end class="hidden-sm-and-down ms-1" style="margin-block-start: -2px"
            >mdi-chevron-down
          </v-icon>
        </v-btn>
        <v-menu activator="parent">
          <v-list density="compact" class="py-0">
            <template v-for="(status, i) in incidentStatusVisuals">
              <v-list-item
                :style="`border-left: solid ${status.color} 8px;`"
                :key="i"
                v-if="status.state != fieldOption.incident_level"
                @click="updateFieldOptionIncidentLevel(status.state)"
              >
                <v-list-item-title>{{ status.display }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'
import { cloneDeep, debounce } from 'lodash'
import { storeToRefs } from 'pinia'

import { useQueryClient } from '@tanstack/vue-query'
import { useReportStore } from '@/stores'
import {
  useDeletePostOrderInstructionsFieldOption,
  useFetchPostOrderInstructionsFields,
  useUpdatePostOrderInstructionsFieldOption
} from '@/composables/post-order'

import type { IFieldErrors } from '@/models/error'
import type { ReportActivityStatusEnum } from '@/models/report'
import { PostOrderSymbol } from './postOrderProvide'

import type {
  IPostOrderInstructionsFieldOption,
  IPostOrderInstructionsFieldOptionData
} from '@/models/post-order'

import ConfirmationDialog from '../common/ConfirmationDialog.vue'

interface Props {
  option: IPostOrderInstructionsFieldOption
  optionIndex: number

  postOrderFieldId: number
}
const props = defineProps<Props>()

const fieldOption = ref(props.option)

watch(
  () => props.option,
  (value) => {
    if (value) {
      fieldOption.value = cloneDeep(props.option)
    }
  },
  {
    immediate: true,
    deep: true
  }
)

const reportStore = useReportStore()
const { incidentStatusVisuals, getReportIncidentStatusVisualByState } = storeToRefs(reportStore)

function updateFieldOptionIncidentLevel(level: ReportActivityStatusEnum) {
  fieldOption.value.incident_level = level

  debounceSaveFieldOption(fieldOption.value)
}

const postOrderContext = inject(PostOrderSymbol)

if (!postOrderContext) throw new Error('[Post Order] Could not find injected post order context')

const postOrderFieldId = computed(() => props.postOrderFieldId)

const {
  error,
  reset: clearErrors,
  mutate: updatePostOrderFormFieldOption,
  isPending
} = useUpdatePostOrderInstructionsFieldOption(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId,
  postOrderFieldId
)

const useFetchPostOrderInstructionFieldsQuery = useFetchPostOrderInstructionsFields(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

const queryClient = useQueryClient()

function saveFieldOption(option: IPostOrderInstructionsFieldOptionData) {
  clearErrors()
  updatePostOrderFormFieldOption(option, {
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: useFetchPostOrderInstructionFieldsQuery.queryKey
      })
    }
  })
}

const debounceSaveFieldOption = debounce(saveFieldOption, 1000)

const deleteDialog = ref(false)
const {
  error: deleteOptionError,
  reset: clearOptionErrors,
  mutate: deletePostOrderFormFieldOption,
  isPending: deleteOptionInProgress
} = useDeletePostOrderInstructionsFieldOption(
  postOrderContext.postOrderId,
  postOrderContext.instructionId,
  postOrderContext.sectionId
)

function deleteFieldOption(optionId: number) {
  clearOptionErrors()

  deletePostOrderFormFieldOption(
    { fieldId: postOrderFieldId.value, optionId },
    {
      onSuccess() {
        closeOptionDeleteDialog()
        queryClient.invalidateQueries({
          queryKey: useFetchPostOrderInstructionFieldsQuery.queryKey
        })
      }
    }
  )
}

function closeOptionDeleteDialog() {
  deleteDialog.value = false
}
</script>

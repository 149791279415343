import type { ICameraData } from '@/models/camera'
import type { ICameraService, ICameraPaginatedListData, ICameraFilterParam } from './camera.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'

/**
 * A class representing a CameraService API handler.
 */
export class CameraService implements ICameraService {
  private endpoint: string

  // CameraService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/cameras/'
  }

  // Method to fetch a list of cameras from the API
  fetchCameras(filter?: ICameraFilterParam): Promise<ICameraPaginatedListData> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined
    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single camera by their ID from the API
  fetchCamera(id: number): Promise<ICameraData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new camera on the API
  createCamera(data: ICameraData): Promise<ICameraData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing camera on the API
  updateCamera(data: ICameraData): Promise<ICameraData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a camera by their ID from the API
  deleteCamera(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the CameraService
const cameraService = new CameraService(axios)
export default cameraService

import { computed, ref, toRef, type MaybeRef } from 'vue'

import {
  IncidentReport,
  type IIncidentReportActivityObservationData,
  type IIncidentReportData,
  type IReportActivityData,
  type IIncidentReportObservationAttachmentData
} from '@/models/report'
import incidentReportService from '@/services/incident_report/incident-report'
import incidentReportActivityReportService from '@/services/incident_report/incident-report-activity'
import incidentReportActivityObservationService from '@/services/incident_report/incident-report-activity-observation'
import incidentReportActivityObservationAttachmentService from '@/services/incident_report/incident-report-activity-observation-attachment'

import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import { useDownloadFileLoader } from '@/composables/file-loader'

import type { IReportFilterParam, ISetIncidentReportLevelParams } from '@/services'
import { useSnackbarStore } from '@/stores'

export function useFetchIncidentReports(filter?: IReportFilterParam) {
  const count = ref<number>(0)

  const queryKey = ref(['incident-reports', filter])

  const {
    data: reports,
    isFetching,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => incidentReportService.fetchIncidentReports(filter),

    select: (data) => {
      count.value = data.count
      return data.results.map((report) => new IncidentReport(report))
    }
  })

  return { queryKey, reports, count, isFetching, isPending, error }
}

export function useFetchIncidentReport(id: MaybeRef<number>) {
  const reportRef = toRef(id)

  const queryKey = ref(['incident-report', reportRef.value])

  const {
    data: report,
    isLoading,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => incidentReportService.fetchIncidentReport(reportRef.value),

    select: (data) => new IncidentReport(data)
  })

  return { report, isLoading, isPending, error, queryKey }
}

// TODO - LH  - 2024-01-31 - Check why inferred typing of useMutation cannot be found
export function useDeleteIncidentReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-incident-report'],
    mutationFn: (reportId: number) => incidentReportService.deleteIncidentReport(reportId),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['incident-reports']) })
      snackbarStore.showSnackbar('Incident Report deleted successfully', '')
    }
  })
  return {
    deleteInProgress: isPending,
    isDeleteError: isError,
    deleteError: error,
    isDeleteSuccess: isSuccess,
    deleteIncidentReport: mutate,
    clearDeleteErrors: reset
  }
}

export function useCreateIncidentReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-incident-report'],
    mutationFn: (report: IIncidentReportData) => incidentReportService.createIncidentReport(report),

    onSuccess: (data) => {
      queryClient.setQueryData(['incident-report', data.id], data)
      snackbarStore.showSnackbar('Incident Report created successfully')
    },
    // Why: Upon creating an incident report, extend the loading state to the refreshing the report list
    // https://tanstack.com/query/latest/docs/framework/vue/guides/optimistic-updates#via-the-ui
    onSettled: async () => {
      return await queryClient.invalidateQueries({ queryKey: ['incident-reports'] })
    }
  })

  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateIncidentReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-incident-report'],
    mutationFn: (report: IIncidentReportData) => incidentReportService.updateIncidentReport(report),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['incident-report', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['incident-reports']) })
      snackbarStore.showSnackbar('Incident Report updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Activities
export function useCreateIncidentReportActivity(reportId: MaybeRef<number>) {
  const reportIdRef = toRef(reportId)
  const queryClient = useQueryClient()
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-incident-report-activity'],
    mutationFn: () =>
      incidentReportActivityReportService.createIncidentReportActivity(reportIdRef.value),

    onSuccess: (activity) => {
      queryClient.setQueryData(
        ['incident-report', reportIdRef.value],
        (oldIncidentReport?: IIncidentReportData) => {
          if (oldIncidentReport) {
            const activities = oldIncidentReport.activities! ?? []
            activities.push(activity)

            return {
              ...oldIncidentReport,
              activities: activities
            }
          }
          return oldIncidentReport
        }
      )
      snackbarStore.showSnackbar('Report activity created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateIncidentReportActivity(reportId: number) {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-incident-report-activity'],
    mutationFn: (activity: IReportActivityData) =>
      incidentReportActivityReportService.updateIncidentReportActivity(reportId, activity),

    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteIncidentReportActivity(reportId: number) {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-incident-report-activity'],
    mutationFn: (activityId: number) =>
      incidentReportActivityReportService.deleteIncidentReportActivity(reportId, activityId),

    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Activity Observations

export function useCreateIncidentReportActivityObservation(
  reportId: MaybeRef<number>,
  activityId: MaybeRef<number>
) {
  const reportIdRef = toRef(reportId)
  const activityIdRef = toRef(activityId)
  const queryClient = useQueryClient()
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-incident-report-activity-observation', activityIdRef.value],
    mutationFn: (data: IIncidentReportActivityObservationData) =>
      incidentReportActivityObservationService.createIncidentReportActivityObservation(
        reportIdRef.value,
        activityIdRef.value,
        data
      ),

    onSuccess: (observation) => {
      queryClient.setQueryData(
        ['incident-report', reportIdRef.value],
        (oldIncidentReport?: IIncidentReportData) => {
          if (oldIncidentReport) {
            const activityIndex = oldIncidentReport.activities?.findIndex(
              (v) => v.id === activityIdRef.value
            )

            const activities = oldIncidentReport.activities!

            if (activityIndex !== undefined) {
              activities[activityIndex].observations!.push(observation)
            }

            // https://tanstack.com/query/v4/docs/framework/vue/guides/updates-from-mutation-responses#immutability
            return {
              ...oldIncidentReport,
              activities: activities
            }
          }

          return oldIncidentReport
        }
      )
      snackbarStore.showSnackbar('Report activity observation created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateIncidentReportActivityObservation(
  reportId: number,
  activityId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-incident-report-activity-observation', activityIdRef.value],
    mutationFn: (observation: IIncidentReportActivityObservationData) =>
      incidentReportActivityObservationService.updateIncidentReportActivityObservation(
        reportId,
        activityIdRef.value,
        observation
      ),

    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteIncidentReportActivityObservation(
  reportId: number,
  activityId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-incident-report-activity-observation', activityIdRef.value],
    mutationFn: (observationId: number) =>
      incidentReportActivityObservationService.deleteIncidentReportActivityObservation(
        reportId,
        activityIdRef.value,
        observationId
      ),

    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation deleted successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Attachments
export function useCreateIncidentReportActivityObservationAttachment(
  reportId: MaybeRef<number>,
  activityId: MaybeRef<number>,
  observationId: MaybeRef<number>
) {
  const reportIdRef = toRef(reportId)
  const activityIdRef = toRef(activityId)
  const observationIdRef = toRef(observationId)
  const queryClient = useQueryClient()

  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: [
      'create-incident-report-activity-observation',
      activityIdRef.value,
      observationIdRef.value
    ],
    mutationFn: (data: IIncidentReportObservationAttachmentData) => {
      return incidentReportActivityObservationAttachmentService.createIncidentReportActivityObservationAttachment(
        reportIdRef.value,
        activityIdRef.value,
        observationIdRef.value,
        data
      )
    },

    onSuccess: (attachment) => {
      queryClient.setQueryData(
        ['incident-report', reportIdRef.value],
        (oldIncidentReport?: IIncidentReportData) => {
          if (oldIncidentReport) {
            const activities = oldIncidentReport.activities!

            let observations: IIncidentReportActivityObservationData[] = []

            const activityIndex = activities.findIndex((v) => v.id === activityIdRef.value)

            if (activityIndex !== -1) {
              const observationIndex = activities[activityIndex]!.observations!.findIndex(
                (v) => v.id === observationIdRef.value
              )
              if (observationIndex !== -1) {
                observations = activities[activityIndex]!.observations!

                observations[observationIndex].attachments?.push(attachment)

                activities[activityIndex].observations = observations
              }
            }

            // https://tanstack.com/query/v4/docs/framework/vue/guides/updates-from-mutation-responses#immutability
            return {
              ...oldIncidentReport,
              activities: activities
            }
          }

          return oldIncidentReport
        }
      )
      snackbarStore.showSnackbar('Report activity observation attachment created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateIncidentReportActivityObservationAttachment(
  reportId: number,
  activityId: MaybeRef<number>,
  observationId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const observationIdRef = toRef(observationId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: [
      'create-incident-report-activity-observation',
      activityIdRef.value,
      observationIdRef.value
    ],
    mutationFn: (data: IIncidentReportObservationAttachmentData) =>
      incidentReportActivityObservationAttachmentService.updateIncidentReportActivityObservationAttachment(
        reportId,
        activityIdRef.value,
        observationIdRef.value,
        data
      ),

    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation attachment updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useDeleteIncidentReportActivityObservationAttachment(
  reportId: number,
  activityId: MaybeRef<number>,
  observationId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const observationIdRef = toRef(observationId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: [
      'create-incident-report-activity-observation',
      activityIdRef.value,
      observationIdRef.value
    ],
    mutationFn: (attachmentId: number) =>
      incidentReportActivityObservationAttachmentService.deleteIncidentReportActivityObservationAttachment(
        reportId,
        activityIdRef.value,
        observationIdRef.value,
        attachmentId
      ),

    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation attachment deleted successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useSetIncidentReportLevel() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const mutationKey = ref(['set-incident-report-level'])

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: mutationKey,
    mutationFn: (data: ISetIncidentReportLevelParams) =>
      incidentReportService.setIncidentLevel(data),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['incident-report', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['incident-report-logs', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['incident-reports']) })
      snackbarStore.showSnackbar('Incident Report updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useCreateIncidentReportFromObservation(successAction: () => void) {
  const selectedIncidentReportId = ref<number | null>(null)

  function incidentReportCreatedHandler(data: {
    observation: IIncidentReportActivityObservationData
    incidentReportId: number
    fileAttachment?: File | string
  }) {
    selectedIncidentReportId.value = data.incidentReportId

    createIncidentReportActivityMutation.mutate(undefined, {
      onSuccess: async (activity) => {
        createdIncidentReportActivityId.value = activity.id!

        await createIncidentReportActivityObservationMutation.mutateAsync(data.observation, {
          onSettled: async (observation) => {
            if (observation) {
              createdIncidentReportActivityObservationId.value = observation.id!

              // Add the attachment to newly created observation
              if (data.fileAttachment) {
                let fileName = ''
                // initialize to empty Blob
                let fileBlob: Blob = new Blob()

                if (!(data.fileAttachment instanceof File)) {
                  // Set the value used for composable to fetch
                  attachmentFilePath.value = data.fileAttachment

                  const { data: downloadedBlob } = await downloadFile()

                  if (downloadedBlob) {
                    fileBlob = downloadedBlob

                    const mimeType: string = downloadedBlob.type

                    // Split MIME type by '/'
                    const parts: string[] = mimeType.split('/')
                    const extension: string = parts[1] // Get the second part as the file extension
                    fileName = `attachment.${extension}`
                  }
                  fileBlob = new File([fileBlob], fileName)
                } else {
                  fileBlob = data.fileAttachment
                }

                await createIncidentReportActivityObservationAttachmentMutation.mutateAsync({
                  file: fileBlob as File
                })
              }
            }
            successAction()
          }
        })
      }
    })
  }

  const computedCreatedIncidentReportId = computed(() => selectedIncidentReportId.value!)

  const createdIncidentReportActivityId = ref<number | null>(null)

  const computedCreatedIncidentReportActivityId = computed(
    () => createdIncidentReportActivityId.value!
  )

  const createIncidentReportActivityMutation = useCreateIncidentReportActivity(
    computedCreatedIncidentReportId
  )

  const createIncidentReportActivityObservationMutation =
    useCreateIncidentReportActivityObservation(
      computedCreatedIncidentReportId,
      computedCreatedIncidentReportActivityId
    )

  const createdIncidentReportActivityObservationId = ref<number | null>(null)

  const computedCreatedIncidentReportActivityObservationId = computed(
    () => createdIncidentReportActivityObservationId.value!
  )
  const createIncidentReportActivityObservationAttachmentMutation =
    useCreateIncidentReportActivityObservationAttachment(
      computedCreatedIncidentReportId,
      computedCreatedIncidentReportActivityId,
      computedCreatedIncidentReportActivityObservationId
    )

  const attachmentFilePath = ref<string>('')
  const { refetch: downloadFile } = useDownloadFileLoader(attachmentFilePath)

  return {
    incidentReportCreatedHandler,
    selectedIncidentReportId
  }
}

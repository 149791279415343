import { type RouteRecordRaw, type Router } from 'vue-router'
import type { IAuthPermissionsData } from '@/models/auth'

export function generateDynamicRoutes(
  permissions: IAuthPermissionsData,
  routes: RouteRecordRaw[]
): RouteRecordRaw[] {
  // Helper function to recursively filter routes
  function filterRoutes(route: RouteRecordRaw): RouteRecordRaw[] {
    // Check if the route has permissions defined in its meta
    if (route.meta && route.meta.permissions) {
      // Check if at least one of the required permissions exists in the user's permissions
      const hasPermission = (route.meta.permissions as string[]).some(
        (perm: string) => permissions[perm]
      )

      // If the user has at least one of the required permissions, keep the route
      if (hasPermission) {
        // If the route has children, recursively filter them
        if (route.children) {
          const filteredChildren = route.children.map(filterRoutes).flat()
          if (filteredChildren.length > 0) {
            return [{ ...route, children: filteredChildren }]
          }
        }
        return [route]
      }
    }
    return []
  }

  // Filter the top-level routes
  return routes.map(filterRoutes).flat()
}

export function generateNavRoutes(navRoutes: RouteRecordRaw[]): RouteRecordRaw[] {
  function excludeHiddenRoutes(route: RouteRecordRaw): RouteRecordRaw[] {
    let routes: RouteRecordRaw[] = []
    // Check if the route has hidden defined as false in its meta
    if (route.meta && !route.meta.hidden) {
      // If the route has children, recursively filter them
      if (route.children) {
        const filteredChildren = route.children.map(excludeHiddenRoutes).flat()
        if (filteredChildren.length > 0) {
          routes = [{ ...route, children: filteredChildren }]
        }
      } else {
        routes = [route]
      }
    }

    return routes
  }

  // Filter the top-level routes
  return navRoutes.map(excludeHiddenRoutes).flat()
}

export interface INavItem {
  title: string
  value: string
  prependIcon: string
  to?: Record<string, any>
  children?: INavItem[]
}

export function generateNavItem(router: Router, route: RouteRecordRaw): INavItem {
  if (route.name && !router.hasRoute(route.name)) {
    throw new Error(`This route does not exist in route list: ${route.name.toString()}`)
  }

  if (!route.meta) {
    throw new Error(`Invalid or missing 'meta' property`)
  }

  return {
    title: route.meta.title as string,
    value: route.name as string,
    prependIcon: route.meta.icon as string,
    to: route.name ? { name: route.name } : undefined,
    children: route.children?.map((route) => generateNavItem(router, route))
  }
}

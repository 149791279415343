<template>
  <BaseWidget
    class="d-flex flex-column align-center justify-center text-center fill-height border"
    color="secondary-lighten-1"
    variant="flat"
  >
    <div class="d-flex flex-column align-center justify-center fill-height white--text">
      <div class="text-body-1 font-weight-regular mb-2">
        {{ nowDateFormattedTime }}
      </div>
      <div class="text-h3 text-lg-h2 font-weight-medium mx-n2">
        {{ nowHourFormattedTime }}
      </div>
    </div>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useDate } from 'vuetify'
import { useNow } from '@vueuse/core'

import type DateFnsUtils from '@date-io/date-fns/build/date-fns-utils'
import BaseWidget from '../base/BaseWidget.vue'

const currentDateTime = useNow()

const dateAdapter = useDate() as DateFnsUtils

const nowHourFormattedTime = computed(() =>
  dateAdapter.formatByString(currentDateTime.value, 'H:mm')
)
const nowDateFormattedTime = computed(() =>
  dateAdapter.formatByString(currentDateTime.value, 'E, MMMM d')
)
</script>

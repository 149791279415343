import {
  type IReportState,
  type IReportIncidentStatus,
  ReportActivityStatusEnum,
  ReportStateEnum
} from '.'
import { AbstractModel } from '../base'
import { Client, Site, type IClient, type ISite } from '../client'
import { Employee, type IEmployee } from '../employee'
import { ReportShift, type IReportShift } from '../report'

import type {
  IGuardReport,
  IGuardReportActivity,
  IGuardReportActivityData,
  IGuardReportActivityObservation,
  IGuardReportActivityObservationData,
  IGuardReportData,
  IGuardReportStatistics,
  IGuardReportStatisticsData
} from './guard-report.types'

export class GuardReport extends AbstractModel implements IGuardReport {
  readonly id?: number
  readonly report_id?: string
  readonly owner?: string
  readonly client?: number | IClient
  readonly site: number | ISite
  readonly shift: IReportShift
  readonly memo: string
  readonly state?: IReportState
  readonly created?: Date
  readonly modified?: Date
  readonly activities: IGuardReportActivity[] = []
  readonly approvers: IEmployee[] = []

  constructor(data: IGuardReportData) {
    super()

    // Validate data
    const requiredFields: (keyof IGuardReportData)[] = ['site', 'shift']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.site = typeof data.site! === 'number' ? data.site! : new Site(data.site!)

    this.shift = new ReportShift(data.shift!)

    if (data.id) {
      this.id = data.id
    }

    if (data.client) {
      this.client = typeof data.client === 'number' ? data.client! : new Client(data.client!)
    }

    if (data.activities) {
      this.activities = data.activities.map((activity) => {
        return new GuardReportActivity(activity)
      })
    }

    if (data.state) {
      this.state = data.state
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }

    if (data.report_id) {
      this.report_id = data.report_id
    }

    if (data.owner) {
      this.owner = data.owner
    }

    this.memo = data.memo ?? ''

    if (data.approvers) {
      this.approvers = data.approvers.map((approver) => {
        return new Employee(approver)
      })
    }
  }

  canSubmit(): boolean {
    return this.state?.value == ReportStateEnum.Draft
  }

  canApprove(): boolean {
    return this.state?.value == ReportStateEnum.Submitted
  }
}

export class GuardReportActivity implements IGuardReportActivity {
  readonly id?: number
  readonly observations: IGuardReportActivityObservation[] = []
  readonly activity_time?: Date
  readonly created_by?: string
  readonly modified_by?: string
  readonly created?: Date
  readonly modified?: Date

  constructor(data?: IGuardReportActivityData) {
    if (data) {
      if (data.id) {
        this.id = data.id
      }

      if (data.observations) {
        this.observations = data.observations.map(
          (observation) => new GuardReportActivityObservation(observation)
        )
      }

      if (data.activity_time) {
        this.activity_time =
          typeof data.activity_time === 'string' ? new Date(data.activity_time) : data.activity_time
      }

      if (data.created_by) {
        this.created_by = data.created_by
      }

      if (data.modified_by) {
        this.modified_by = data.modified_by
      }

      if (data.created) {
        this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
      }

      if (data.modified) {
        this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
      }
    }
  }
}

export class GuardReportActivityObservation
  extends AbstractModel
  implements IGuardReportActivityObservation
{
  readonly id?: number
  readonly text: string
  readonly file: string | File
  readonly checkpoint: string
  readonly incident_level: IReportIncidentStatus | ReportActivityStatusEnum
  readonly reporter?: string
  readonly incident_report: number | null = null

  constructor(data: IGuardReportActivityObservationData) {
    super()

    // Validate data
    const requiredFields: (keyof IGuardReportActivityObservationData)[] = [
      'text',
      'file',
      'checkpoint',
      'incident_level'
    ]
    this.validate(data, requiredFields)

    if (data.id) {
      this.id = data.id
    }

    this.text = data.text!
    this.file = data.file!
    this.checkpoint = data.checkpoint!

    this.incident_level = data.incident_level!

    if (data.incident_report) {
      this.incident_report = data.incident_report
    }

    if (data.reporter) {
      this.reporter = data.reporter
    }
  }
}

export class GuardReportStatistics implements IGuardReportStatistics {
  draft_count: number = 0
  approval_pending_count: number = 0
  waiting_submission_count: number = 0
  waiting_approval_count: number = 0
  recently_created_count: number = 0

  constructor(data?: IGuardReportStatisticsData) {
    if (data) {
      if (data.draft_count) {
        this.draft_count = data.draft_count
      }

      if (data.approval_pending_count) {
        this.approval_pending_count = data.approval_pending_count
      }

      if (data.waiting_submission_count) {
        this.waiting_submission_count = data.waiting_submission_count
      }

      if (data.waiting_approval_count) {
        this.waiting_approval_count = data.waiting_approval_count
      }

      if (data.recently_created_count) {
        this.recently_created_count = data.recently_created_count
      }
    }
  }
}

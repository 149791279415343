import type { ISampleObservationData } from '@/models/report'
import type { ISampleObservationService } from './sample-observation.types'
import type { AxiosInstance } from 'axios'
import axios from '@/axios'

/**
 * A class representing a SampleObservationService API handler.
 */
export class SampleObservationService implements ISampleObservationService {
  private endpoint: string

  // SampleObservationService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/sample-observations/'
  }

  // Method to fetch a list of sampleObservations from the API
  fetchSampleObservations(): Promise<ISampleObservationData[]> {
    return this.axios.get(`${this.endpoint}`).then(({ data }) => data)
  }

  // Method to fetch a single sampleObservation by their ID from the API
  fetchSampleObservation(id: number): Promise<ISampleObservationData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new sampleObservation on the API
  createSampleObservation(data: ISampleObservationData): Promise<ISampleObservationData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing sampleObservation on the API
  updateSampleObservation(data: ISampleObservationData): Promise<ISampleObservationData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a sampleObservation by their ID from the API
  deleteSampleObservation(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the SampleObservationService
const sampleObservationService = new SampleObservationService(axios)
export default sampleObservationService

import { defineStore } from 'pinia'

import { SampleObservation, type ISampleObservationData } from '@/models/report'
import sampleObservationService from '@/services/report/sample-observation'

import type { ISampleObservationState } from './sample-observation.types'

// Pinia store for managing sampleObservation-related states and actions
export const useSampleObservationStore = defineStore('sampleObservation', {
  state: () =>
    ({
      sampleObservations: []
    }) as ISampleObservationState,

  getters: {
    // Getter to retrieve sampleObservations from the store
    getSampleObservations: (state: ISampleObservationState) => {
      return state.sampleObservations.map(
        (sampleObservation) => new SampleObservation(sampleObservation)
      )
    },

    // Getter to retrieve a sampleObservation by ID from the store,
    getSampleObservationById: (state: ISampleObservationState) => (id: number) => {
      const sampleObservation = state.sampleObservations.find(
        (sampleObservation) => sampleObservation.id === id
      )
      if (!sampleObservation) {
        return undefined
      }
      return new SampleObservation(sampleObservation)
    }
  },

  actions: {
    // Action method to fetch list of sampleObservations and update the store
    async fetchSampleObservations(): Promise<void> {
      const data = await sampleObservationService.fetchSampleObservations()
      this.sampleObservations = data
    },

    // Action method to fetch a single sampleObservation and update the store
    async fetchSampleObservation(id: number): Promise<void> {
      const sampleObservation = await sampleObservationService.fetchSampleObservation(id)

      const index = this.sampleObservations.findIndex((v) => v.id === id)
      if (index === -1) {
        this.sampleObservations.push(sampleObservation)
      } else {
        this.sampleObservations[index] = sampleObservation
      }
    },

    // Action method to create a new sampleObservation
    async createSampleObservation(data: ISampleObservationData): Promise<void> {
      const sampleObservation = await sampleObservationService.createSampleObservation(data)
      this.sampleObservations.push(sampleObservation)
    },

    // Action method to update an existing sampleObservation
    async updateSampleObservation(data: ISampleObservationData): Promise<void> {
      const sampleObservation = await sampleObservationService.updateSampleObservation(data)
      const index = this.sampleObservations.findIndex((v) => v.id === data.id)
      this.sampleObservations[index] = sampleObservation
    },

    // Action method to delete an existing sampleObservation
    async deleteSampleObservation(id: number): Promise<void> {
      await sampleObservationService.deleteSampleObservation(id)

      const index = this.sampleObservations.findIndex((v) => v.id === id)
      if (index !== -1) {
        this.sampleObservations.splice(index, 1)
      }
    }
  }
})

import {
  ALL_REGIONS,
  REGION_CODE_MAX_LENGTH,
  type IRegion,
  US_STATES,
  CANADIAN_PROVINCES,
  POSTAL_CODE_CODE_MAX_LENGTH
} from '.'
import { AbstractModel } from '../base'
import type { IAddress, IAddressData, IRegionData } from './address.types'
import { type ICountry, Country, COUNTRY_CODE_MAX_LENGTH } from '../country'

/**
 * A class representing a Region
 */
export class Region extends AbstractModel implements IRegion {
  readonly code: string
  readonly name: string

  constructor(data: IRegionData) {
    super()

    // Validate data
    const requiredFields: (keyof IRegionData)[] = ['code', 'name']
    this.validate(data, requiredFields)

    this.code = data.code!
    this.name = data.name!
  }

  toString() {
    return this.name
  }
}

/**
 * A class representing an Address
 */
export class Address extends AbstractModel implements IAddress {
  readonly id?: number
  readonly address1: string
  readonly address2: string
  readonly city: string
  readonly region: string
  readonly country: string | ICountry
  readonly postal_code: string

  constructor(data: IAddressData) {
    super()

    // Validate data
    const requiredFields: (keyof IAddressData)[] = ['address1', 'city', 'region', 'country']
    this.validate(data, requiredFields)

    if (data.postal_code && data.postal_code.length > POSTAL_CODE_CODE_MAX_LENGTH) {
      throw new Error(
        `Postal code length is greater than ${POSTAL_CODE_CODE_MAX_LENGTH} characters`
      )
    }

    if (data.region!.length != REGION_CODE_MAX_LENGTH) {
      throw new Error(`Region code does not meet length of ${REGION_CODE_MAX_LENGTH} characters`)
    }

    if (typeof data.country! === 'string' && data.country!.length != COUNTRY_CODE_MAX_LENGTH) {
      throw new Error(`Country code does not meet length of ${COUNTRY_CODE_MAX_LENGTH} characters`)
    }

    // Initialize object assuming data valid
    if (data.id) {
      this.id = data.id
    }

    this.address1 = data.address1!
    this.address2 = data.address2 ?? ''
    this.city = data.city!
    this.region = data.region!
    this.postal_code = data.postal_code ?? ''
    this.country = typeof data.country === 'string' ? data.country! : new Country(data.country!)
  }

  getAddressString(): string {
    const addresses: string[] = []

    const firstAddressGroup = []
    firstAddressGroup.push(this.address1)
    if (this.address2) firstAddressGroup.push(this.address2)

    addresses.push(firstAddressGroup.join(', '))

    const cityAddressGroup = []
    cityAddressGroup.push(this.city)
    cityAddressGroup.push(this.region)

    addresses.push(cityAddressGroup.join(' '))

    const countryAddressGroup = []
    if (typeof this.country == 'string') {
      countryAddressGroup.push(this.country)
    } else {
      countryAddressGroup.push((this.country as ICountry).name)
    }
    addresses.push(countryAddressGroup.join(' '))

    return addresses.join(', \n')
  }

  static getRegions(): IRegionData[] {
    return ALL_REGIONS.map((region) => new Region(region))
  }

  static getRegionsByCountry(code: string): IRegion[] {
    switch (code) {
      case 'CA':
        return CANADIAN_PROVINCES.map((province) => new Region(province))
      case 'US':
        return US_STATES.map((state) => new Region(state))

      default:
        return this.getRegions()
    }
  }
}

import { Address } from '@/models/address/address'

import { AbstractModel } from '../base'

import type { ISite, ISiteData, ISiteLocation, ISiteLocationData } from './site.types'
import type { IAddress } from '@/models/address/address.types'

/**
 * A class representing a Site
 */
export class Site extends AbstractModel implements ISite {
  readonly code: number
  readonly address: IAddress

  constructor(data: ISiteData) {
    super()

    // Validate data
    const requiredFields: (keyof ISiteData)[] = ['code', 'address']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.code = data.code!
    this.address = new Address(data.address!)
  }

  toString() {
    return `${this.address.address1}, ${this.address.city}`
  }
}

export class SiteLocation extends AbstractModel implements ISiteLocation {
  readonly id?: number
  readonly name: string

  constructor(data: ISiteLocationData) {
    super()

    // Validate data
    const requiredFields: (keyof ISiteLocationData)[] = ['name']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.name = data.name!
    if (data.id) {
      this.id = data.id
    }
  }
}

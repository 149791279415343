<template>
  <v-container class="pt-0" style="max-width: 1200px">
    <BasePage>
      <template #page-header:subtitle>
        <BackButton class="mr-4" ref="backButton" :fallback-to="{ name: 'cctv' }" />
        {{ `${route.meta.title}` }}
      </template>

      <template #page-header:bottom>
        <ErrorAlert
          v-if="error != null"
          dense
          :error="error"
          @clearErrors="clearErrors"
          class="mb-0 mt-4 rounded"
        />
      </template>

      <v-progress-linear color="primary" indeterminate v-if="isLoading" />

      <template v-else-if="report">
        <ReportStateCard
          v-if="report.state && reportVisualState"
          :color="reportVisualState.color"
          :display="report.state.display!"
          :icon="reportVisualState.icon!"
        >
          <template #actions>
            <CCTVReportDraftStateActions
              :can-submit="report.canSubmit()"
              v-if="reportVisualState.state == ReportStateEnum.Draft"
              :loading="isLoading || isSubmitCCTVReportPending || isDownloadCCTVReportPdfPending"
              @submitted="submit()"
              @download-report-pressed="downloadReportPdf()"
              @edit-report-pressed="openEditReportDialog()"
              @delete-report-pressed="openDeleteReportDialog()"
            />
            <CCTVReportSubmittedStateActions
              :can-approve="report.canApprove()"
              v-if="reportVisualState.state == ReportStateEnum.Submitted"
              :loading="isLoading || isApproveCCTVReportPending || isDownloadCCTVReportPdfPending"
              @approved="approve()"
              @download-report-pressed="downloadReportPdf()"
              @edit-report-pressed="openEditReportDialog()"
              @delete-report-pressed="openDeleteReportDialog()"
            />
            <CCTVReportApprovedStateActions
              v-if="reportVisualState.state == ReportStateEnum.Approved"
              :loading="isLoading || isDownloadCCTVReportPdfPending"
              @download-report-pressed="downloadReportPdf()"
            />
          </template>
        </ReportStateCard>

        <ReportDetailView class="mt-5" report-title="CCTV Activity Report" :report="report">
          <template #append-report-info>
            <tr class="report-table__row">
              <td class="report-table__cell pr-3" colspan="1">
                <strong class="wv-text--strong">Start Time:</strong>
              </td>
              <td class="report-table__cell-info" colspan="1">
                <span class="wv-text wv-text--body"
                  >{{ format(report.shift_start as Date, 'MMMM dd, yyyy - HH:mm') }}
                </span>
              </td>
            </tr>
            <tr class="report-table__row">
              <td class="report-table__cell pr-3" colspan="1">
                <strong class="wv-text--strong">End Time:</strong>
              </td>
              <td class="report-table__cell-info" colspan="1">
                <span class="wv-text wv-text--body"
                  >{{ format(report.shift_end as Date, 'MMMM dd, yyyy - HH:mm') }}
                </span>
              </td>
            </tr>
            <tr class="report-table__row">
              <td class="report-table__cell pr-3" colspan="1">
                <strong class="wv-text--strong">Guard:</strong>
              </td>
              <td class="report-table__cell-info" colspan="1">
                <span class="wv-text wv-text--body">{{ report.owner }} </span>
              </td>
            </tr>
          </template>

          <template #memo-content>
            <section class="mx-16 my-3" v-if="report.memo">
              <div class="d-flex flex-grow-1 justify-space-between align-center pt-1">
                <strong class="text-high-emphasis"> Memo </strong>
              </div>
              <div class="text-medium-emphasis mb-10" :style="{ whiteSpace: 'pre-line' }">
                {{ report.memo }}
              </div>
            </section>
          </template>

          <template #content>
            <CCTVReportDetailActivityCard :report="report" />
          </template>
        </ReportDetailView>
      </template>

      <CCTVReportDetailFormDialog
        v-model:dialog="cctvEditDialog"
        :cctv-report="report"
        :is-edit="true"
        @saved-report="updateReportCache()"
      />

      <ConfirmationDialog
        v-model="cctvDeleteDialog"
        v-model:error="deleteError"
        title="Delete CCTV Report"
      >
        <template v-slot:message>
          Are you sure you want to delete CCTV Report
          <span class="text-medium-high-emphasis font-weight-bold">#{{ report!.report_id }}</span
          >?
          <br />
          <div class="pt-2">This action cannot be undone.</div>
        </template>

        <template v-slot:actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            variant="flat"
            class="text-capitalize"
            :loading="deleteInProgress"
            @click.stop="removeCCTVReport(report!)"
          >
            Delete
          </v-btn>
        </template>
      </ConfirmationDialog>
    </BasePage>
  </v-container>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { format } from 'date-fns'
import { storeToRefs } from 'pinia'

import { useDeleteCCTVReport, useFetchCCTVReport } from '@/composables/cctv-report'
import { useReportStore } from '@/stores'
import { useMutation, useQueryClient } from '@tanstack/vue-query'

import cctvReportService from '@/services/report/cctv-report'
import { createPdf } from '@/utils/helpers'

import type { ISystemError } from '@/models/error'
import { ReportStateEnum, type ICCTVReport } from '@/models/report'

import BasePage from '@/components/base/BasePage.vue'
import BackButton from '@/components/common/BackButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import CCTVReportApprovedStateActions from '@/components/reports/cctv/state/CCTVReportApprovedStateActions.vue'
import CCTVReportDraftStateActions from '@/components/reports/cctv/state/CCTVReportDraftStateActions.vue'
import CCTVReportSubmittedStateActions from '@/components/reports/cctv/state/CCTVReportSubmittedStateActions.vue'
import CCTVReportDetailFormDialog from '@/components/reports/cctv/CCTVReportDetailFormDialog.vue'
import ReportDetailView from '@/components/reports/common/ReportDetailView.vue'
import ReportStateCard from '@/components/reports/common/ReportStateCard.vue'
import CCTVReportDetailActivityCard from '@/components/reports/cctv/CCTVReportDetailActivityCard.vue'

// CORE
const queryClient = useQueryClient()
const route = useRoute()
const router = useRouter()

// Managing multiple errors into one property
const error = ref<ISystemError | null>(null)
const cctvDeleteDialog = ref(false)
const cctvEditDialog = ref(false)

const reportStore = useReportStore()
const { getReportVisualByState } = storeToRefs(reportStore)

const reportVisualState = computed(
  () => report.value && getReportVisualByState.value(report.value.state!.value!)
)

const {
  report,
  isLoading,
  error: fetchError,
  queryKey
} = useFetchCCTVReport(Number(route.params.id))
// update error to crud action error display
watch(fetchError, (value) => {
  error.value = value
})

function openDeleteReportDialog() {
  cctvDeleteDialog.value = true
}
function openEditReportDialog() {
  cctvEditDialog.value = true
}

const { isPending: isSubmitCCTVReportPending, mutate: submitCCTVReport } = useMutation({
  mutationKey: ['submit-cctv-report'],
  mutationFn: (reportId: number) => cctvReportService.submitCCTVReport(reportId)
})
// STATE TRANSITIONS
function submit() {
  clearErrors()
  submitCCTVReport(report.value!.id!, {
    onSuccess: () => {
      updateReportCache()
    },
    onError(submitError) {
      error.value = submitError
    }
  })
}

const { isPending: isApproveCCTVReportPending, mutate: approveCCTVReport } = useMutation({
  mutationKey: ['approve-cctv-report'],
  mutationFn: (reportId: number) => cctvReportService.approveCCTVReport(reportId)
})
function approve() {
  clearErrors()
  approveCCTVReport(report.value!.id!, {
    onSuccess: () => {
      updateReportCache()
    },
    onError(approveError) {
      error.value = approveError
    }
  })
}

const { isPending: isDownloadCCTVReportPdfPending, mutate: downloadCCTVReportPDF } = useMutation({
  mutationKey: ['approve-cctv-report'],
  mutationFn: (reportId: number) => cctvReportService.exportCCTVReportPdf(reportId)
})

function downloadReportPdf() {
  clearErrors()
  downloadCCTVReportPDF(report.value!.id!, {
    onSuccess: (data) => {
      updateReportCache()
      createPdf(data, report.value!.report_id!)
    },
    onError(downloadError) {
      error.value = downloadError
    }
  })
}

function clearErrors() {
  error.value = null
}

const backButton = ref<typeof BackButton>()

const { deleteInProgress, deleteError, deleteCCTVReport } = useDeleteCCTVReport()

function updateReportCache() {
  // TODO - LH - 2024-02-20 - Should find a better way to invalidate both queries
  // invalidate current incident report cache to update this cache
  queryClient.invalidateQueries({ queryKey: queryKey.value })
  // invalidate list cache regarding incident
  queryClient.invalidateQueries({ queryKey: ref(['cctv-reports']) })
}

function removeCCTVReport(report: ICCTVReport) {
  clearErrors()
  deleteCCTVReport(report.id!, {
    onSuccess: () => {
      cctvDeleteDialog.value = false
      queryClient.invalidateQueries({ queryKey: ref(['cctv-reports']) })
      router.push(backButton.value?.backPath)
    }
  })
}
</script>

<template>
  <v-navigation-drawer
    :width="400"
    location="end"
    class="scrollable-content"
    v-model:model-value="drawer"
    v-model:rail="drawerRail"
    :scrim="true"
    temporary
    order="2"
  >
    <template #prepend>
      <slot name="loading"> </slot>
      <slot name="error"> </slot>
      <slot name="header">
        <BaseRightDrawerHeader
          :title="props.title"
          @cancel="($event) => emit('right-drawer-close', $event)"
        >
          <template #title>
            <slot name="header-title"></slot>
          </template>

          <template #before-close>
            <slot name="header-before-close"></slot>
          </template>
        </BaseRightDrawerHeader>
      </slot>
      <slot name="header-bottom"> </slot>
    </template>

    <template #default>
      <slot name="default"></slot>
    </template>

    <template #append>
      <slot name="append"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import BaseRightDrawerHeader from './BaseRightDrawerHeader.vue'

interface Props {
  title?: string
}

interface Emit {
  (e: 'right-drawer-close', el: MouseEvent): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emit>()

const drawer = defineModel('drawer', { type: Boolean, default: false })
const drawerRail = defineModel('rail', { type: Boolean, default: false })
</script>

import type { IPermissionGroupData } from '@/models/permission'
import type { IPermissionGroupService } from './permission.types'
import type { AxiosInstance } from 'axios'
import axios from '@/axios'

/**
 * A class representing a PermissionGroupService API handler.
 */
export class PermissionGroupService implements IPermissionGroupService {
  private endpoint: string

  // PermissionGroupService constructor
  constructor(private axios: AxiosInstance) {
    // TODO: update url endpoint when moved
    this.endpoint = '/auth/groups/'
  }

  // Method to fetch a list of permission groups from the API
  fetchPermissionGroups(): Promise<IPermissionGroupData[]> {
    return this.axios.get(`${this.endpoint}?ordering=id`).then(({ data }) => data)
  }

  // Method to fetch a single permission group by their ID from the API
  fetchPermissionGroup(id: number): Promise<IPermissionGroupData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new permission group on the API
  createPermissionGroup(data: IPermissionGroupData): Promise<IPermissionGroupData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing permission group on the API
  updatePermissionGroup(data: IPermissionGroupData): Promise<IPermissionGroupData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a permission group by their ID from the API
  deletePermissionGroup(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the PermissionGroupService
const permissionGroupService = new PermissionGroupService(axios)
export default permissionGroupService

<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 'auto' : 677">
    <AlFormCard title="Site Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mb-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form ref="form" v-model="valid" validate-on="submit lazy">
        <h2 class="text-subtitle-2 font-weight-medium my-3">Site Info</h2>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="siteData.code"
              label="Site Number*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['code'] as string[])"
              :disabled="isEdit"
            ></v-text-field>
          </v-col>
        </v-row>
        <h2 class="text-subtitle-2 font-weight-medium my-3">Address</h2>

        <v-row class="mb-3">
          <v-col cols="12">
            <v-text-field
              v-model="siteData.address!.address1"
              label="Address1*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="
                error &&
                error.fieldErrors['address'] &&
                ((error.fieldErrors['address'] as IFieldErrors)['address1'] as string[])
              "
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="siteData.address!.address2"
              label="Address2"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :error-messages="
                error &&
                error.fieldErrors['address'] &&
                ((error.fieldErrors['address'] as IFieldErrors)['address2'] as string[])
              "
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="siteData.address!.city"
              label="City*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="
                error &&
                error.fieldErrors['address'] &&
                ((error.fieldErrors['address'] as IFieldErrors)['city'] as string[])
              "
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <RegionComboBox
              v-model="siteData.address!.region"
              :label="`${regionComboBoxLabel}*`"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="
                error &&
                error.fieldErrors['address'] &&
                ((error.fieldErrors['address'] as IFieldErrors)['region'] as string[])
              "
              :country="addressCountryCode"
            ></RegionComboBox>
          </v-col>

          <v-col cols="12" md="6">
            <CountryPicker
              v-model="siteData.address!.country"
              label="Country*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="
                error &&
                error.fieldErrors['address'] &&
                ((error.fieldErrors['address'] as IFieldErrors)['country'] as string[])
              "
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="siteData.address!.postal_code"
              label="Postal Code"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :error-messages="
                error &&
                error.fieldErrors['address'] &&
                ((error.fieldErrors['address'] as IFieldErrors)['postal_code'] as string[])
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'

import type { ISystemError, IFieldErrors } from '@/models/error'
import type { VForm } from 'vuetify/components'
import { Site, type ISite, type ISiteData } from '@/models/client'

import { diffDeep } from '@/utils/helpers'

import AlFormCard from '../common/AlFormCard.vue'
import ErrorAlert from '../common/ErrorAlert.vue'
import CountryPicker from '../common/CountryPicker.vue'
import RegionComboBox from '../common/RegionComboBox.vue'
import { requiredValidator } from '@/utils/validators'
import { useCreateSite, useUpdateSite } from '@/composables/site'

interface Props {
  site?: ISite
  isEdit: boolean
  clientId: number
}

const props = defineProps<Props>()

const siteData = ref<ISiteData>({
  address: {}
})

const dialog = defineModel<boolean>('dialog', { required: true, default: false })

watch(dialog, (value) => {
  if (value) {
    clearErrors()
    siteData.value = !props.isEdit
      ? {
          address: {
            country: 'CA',
            region: 'ON'
          }
        }
      : cloneDeep(props.site!)
  }
})

const form = ref<VForm>()
const valid = ref(false)

const subtitle = computed(() => `${props.isEdit ? 'Update' : 'Create'} site information`)

const regionComboBoxLabel = computed<string>(() => {
  switch (siteData.value.address!.country) {
    case 'CA':
      return 'Province'
    case 'US':
      return 'State'
    default:
      return 'Region'
  }
})
const addressCountryCode = computed(() => {
  return typeof siteData.value.address!.country! === 'string'
    ? siteData.value.address!.country
    : siteData.value.address!.country!.code
})

const clientId = computed(() => props.clientId!)
const {
  isPending: isUpdatePending,
  error: updateError,
  mutate: updateSite,
  reset: updateReset
} = useUpdateSite(clientId)
const {
  isPending: isCreatePending,
  error: createError,
  mutate: createSite,
  reset: createReset
} = useCreateSite(clientId)

const localError = ref<ISystemError | null>(null)

const loading = computed(() => (props.isEdit ? isUpdatePending.value : isCreatePending.value))

const error = computed({
  get: () => {
    return localError.value || props.isEdit ? updateError.value : createError.value
  },
  set: (value) => {
    localError.value = value
  }
})

function clearErrors() {
  props.isEdit ? updateReset() : createReset()
  error.value = null
}

function closeDialog() {
  clearErrors()
  dialog.value = false
}

async function save() {
  clearErrors()
  const { valid } = await form.value!.validate()

  if (valid) {
    let siteInstance: ISite | null = null

    try {
      siteInstance = new Site(siteData.value)
    } catch (e: any) {
      // When constructing the instance before saving the possibility of an error can occur the local error is to store that error instance and display it
      error.value = e
    }

    if (siteInstance != null) {
      const saveAction = props.isEdit ? updateSite : createSite

      let payload = siteInstance

      if (props.isEdit) {
        payload = diffDeep(siteInstance, props.site, true)

        payload.code = siteInstance.code!
      }

      saveAction(payload!, {
        onSuccess: () => {
          closeDialog()
        }
      })
    }
  }
}
</script>

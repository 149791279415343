<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <AlFormCard title="Activity Time" subtitle="Edit Activity Time Report`">
      <template #error>
        <ErrorAlert v-if="error != null" :error="error" @clearErrors="clearErrors()" class="mb-2">
        </ErrorAlert>
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form ref="form" v-model="valid" validate-on="submit lazy">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="activityTime"
              :value="dateAdapter.formatByString(activityTime as Date, 'MMMM dd, yyyy - HH:mm')"
              label="Activity Time"
              variant="outlined"
              hide-details="auto"
              readonly
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['activity_time'] as string[])"
            >
              <v-menu
                activator="parent"
                :close-on-content-click="false"
                location="bottom"
                min-width="360"
              >
                <DateTimePicker v-model="activityTime" />
              </v-menu>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <slot name="actions" v-bind="{ id: activity.id, activity_time: activityTime }"> </slot>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import type { VForm } from 'vuetify/components'
import type DateFnsUtils from '@date-io/date-fns'
import { useDate } from 'vuetify'

import { requiredValidator } from '@/utils/validators'

import type { ISystemError } from '@/models/error'
import { type IReportActivityData } from '@/models/report'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import DateTimePicker from '@/components/common/DateTimePicker.vue'

interface Props {
  reportId: number
  activity: IReportActivityData
  error: ISystemError | null
  isEdit: boolean
}
const props = defineProps<Props>()
const dialog = defineModel<boolean>('dialog')

interface Emit {
  (e: 'clearErrors'): void
}
const emit = defineEmits<Emit>()

const dateAdapter = useDate() as DateFnsUtils

const form = ref<VForm>()
const activityTime = ref<Date>(new Date())
const valid = ref(false)

watch(
  () => dialog.value,
  (value) => {
    if (value) {
      activityTime.value = props.activity.activity_time as Date
    }
  },
  { immediate: true }
)

function clearErrors() {
  form.value!.resetValidation()
  emit('clearErrors')
}

function closeDialog() {
  clearErrors()
  dialog.value = false
}

async function validate() {
  return form.value?.validate()
}

defineExpose({ validate })
</script>

import type { IContactData } from '@/models/contact'
import type {
  IContactService,
  IContactPaginatedListData,
  IContactFilterParam
} from './contact.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'

/**
 * A class representing a ContactService API handler.
 */
export class ContactService implements IContactService {
  private endpoint: string

  // ContactService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/contacts/'
  }

  // Method to fetch a list of contacts from the API
  fetchContacts(filter?: IContactFilterParam): Promise<IContactPaginatedListData> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined
    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single contact by their ID from the API
  fetchContact(id: number): Promise<IContactData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new contact on the API
  createContact(data: IContactData): Promise<IContactData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing contact on the API
  updateContact(data: IContactData): Promise<IContactData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a contact by their ID from the API
  deleteContact(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the ContactService
const contactService = new ContactService(axios)
export default contactService

import { computed, ref, toRef, type MaybeRef } from 'vue'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import siteService from '@/services/client/site'
import { Site, type ISiteData } from '@/models/client'
import { useAuthStore, useSnackbarStore } from '@/stores'
import { storeToRefs } from 'pinia'

export function useFetchSites(clientId: MaybeRef<number>) {
  const clientIdRef = toRef(clientId)

  const authStore = useAuthStore()
  const { getUser: currentUser } = storeToRefs(authStore)

  const queryKey = ref(['sites', clientIdRef])

  const {
    isLoading,
    isError,
    error,
    data: sites
  } = useQuery({
    // To be able to call sites it must have a client and the permission
    enabled: computed(() =>
      Boolean(currentUser.value?.hasPermission('clients.view_site') && clientIdRef.value)
    ),
    queryKey: queryKey,
    queryFn: () => siteService.fetchSites(clientIdRef.value),

    select: (data) => {
      return data.map((site) => new Site(site))
    }
  })

  return { queryKey, sites, error, isError, isLoading }
}

export function useFetchSite(clientId: number, siteId: MaybeRef<number>) {
  const siteIdRef = toRef(siteId)

  const queryKey = ref(['sites', clientId, siteIdRef])

  const {
    data: site,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => siteService.fetchSite(clientId, siteIdRef.value),
    select: (data) => new Site(data)
  })

  return { site, isPending, error, queryKey }
}

export function useCreateSite(clientId: MaybeRef<number>) {
  const clientIdRef = toRef(clientId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-site'],
    mutationFn: (site: ISiteData) => siteService.createSite(clientIdRef.value, site),

    onSuccess: (data) => {
      queryClient.setQueryData(['site', data.code], data)
      queryClient.invalidateQueries({ queryKey: ref(['sites', clientId]) })
      snackbarStore.showSnackbar('Site created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateSite(clientId: MaybeRef<number>) {
  const clientIdRef = toRef(clientId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-site'],
    mutationFn: (site: ISiteData) => siteService.updateSite(clientIdRef.value, site),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['site', data.code]) })
      queryClient.invalidateQueries({ queryKey: ref(['sites', clientId]) })
      snackbarStore.showSnackbar('Site updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteSite(clientId: MaybeRef<number>) {
  const clientIdRef = toRef(clientId)

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ref(['delete-site']),
    mutationFn: (id: number) => siteService.deleteSite(clientIdRef.value, id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['sites', clientId]) })
      snackbarStore.showSnackbar('Site deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

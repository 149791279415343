<template>
  <v-card flat v-bind="$attrs" class="al-form-card">
    <slot name="error" />

    <v-card-item class="al-card-item align-start pt-6">
      <template v-slot:prepend v-if="slots.prependAction">
        <slot name="prependAction"></slot>
      </template>

      <template v-slot:append v-if="slots.appendAction">
        <slot name="appendAction"></slot>
      </template>

      <template v-slot:title>
        <h2
          class="al-card-item--title font-weight-medium text-h5"
          tabIndex="-1"
          :aria-label="title"
        >
          <slot name="title">{{ title }}</slot>
        </h2>
      </template>

      <template v-slot:subtitle
        ><div class="al-card-item--subtitle font-weight-regular pa-0">{{ subtitle }}</div></template
      >
    </v-card-item>

    <v-card-text class="al-form-card-text--default" v-if="slots.default">
      <slot name="default"> </slot>
    </v-card-text>

    <v-divider class="mx-6 mb-1" v-if="slots.actions"></v-divider>

    <div class="al-form-card--bottom" v-if="showActions">
      <v-card-actions class="px-6 pb-4">
        <v-defaults-provider
          :defaults="{
            VBtn: {
              class: 'rounded text-capitalize',
              density: 'default'
            }
          }"
        >
          <slot name="actions"> </slot>
        </v-defaults-provider>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

defineProps({
  title: String,
  subtitle: String,
  showActions: {
    type: Boolean,
    default: () => true
  }
})

const slots = useSlots()
</script>

<style lang="scss" scoped>
.al-card-item--title {
  line-height: 1.5rem;
}

.al-form-dialog--bottom {
  .v-btn {
    font-weight: 400 !important;
    text-transform: capitalize !important;
  }
}
</style>

import type { IIncidentReportObservationAttachmentData } from '@/models/report'
import type { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'

import axios, { isAxios401Error } from '@/axios'
import { objectToFormData } from '@/utils/helpers'

import type { IIncidentReportActivityObservationAttachmentService } from './incident-report-activity-observation-attachment.types'
/**
 * A class representing a IncidentReportActivityService API handler.
 */
export class IncidentReportActivityObservationAttachmentService
  implements IIncidentReportActivityObservationAttachmentService
{
  private endpoint: string

  // IncidentReportActivityService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/incidents/'
  }

  // Method to fetch a list of reports from the API
  fetchIncidentReportActivityObservationAttachments(
    reportId: number,
    activityId: number,
    observationId: number
  ): Promise<IIncidentReportObservationAttachmentData[]> {
    return this.axios
      .get(
        `${this.endpoint}${reportId}/activities/${activityId}/observations/${observationId}/attachments/`
      )
      .then(({ data }) => data)
  }

  // Method to fetch a single report by their ID from the API
  fetchIncidentReportActivityObservationAttachment(
    reportId: number,
    activityId: number,
    observationId: number,
    attachmentId: number
  ): Promise<IIncidentReportObservationAttachmentData> {
    return this.axios
      .get(
        `${this.endpoint}${reportId}/activities/${activityId}/observations/${observationId}/attachments/${attachmentId}/`
      )
      .then(({ data }) => data)
  }

  // Method to create a new report on the API
  createIncidentReportActivityObservationAttachment(
    reportId: number,
    activityId: number,
    observationId: number,
    data: IIncidentReportObservationAttachmentData
  ): Promise<IIncidentReportObservationAttachmentData> {
    const formData = objectToFormData(data)
    return this.axios
      .post(
        `${this.endpoint}${reportId}/activities/${activityId}/observations/${observationId}/attachments/`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          // LF-2023-01-13: Hack to ensure file is set on token refresh (if required).
          // Required if 401 occurred while handling multipart/form-data
          'axios-retry': {
            retries: 1,
            retryCondition: isAxios401Error || axiosRetry.isSafeRequestError
          }
        }
      )
      .then(({ data }) => data)
  }

  // Method to update an existing report on the API
  updateIncidentReportActivityObservationAttachment(
    reportId: number,
    activityId: number,
    observationId: number,
    data: IIncidentReportObservationAttachmentData
  ): Promise<IIncidentReportObservationAttachmentData> {
    const formData = objectToFormData(data)
    return this.axios
      .patch(
        `${this.endpoint}${reportId}/activities/${activityId}/observations/${observationId}/attachments/${data.id}/`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          // LF-2023-01-13: Hack to ensure file is set on token refresh (if required).
          // Required if 401 occurred while handling multipart/form-data
          'axios-retry': {
            retries: 1,
            retryCondition: isAxios401Error || axiosRetry.isSafeRequestError
          }
        }
      )
      .then(({ data }) => data)
  }

  // Method to delete a report by their ID from the API
  deleteIncidentReportActivityObservationAttachment(
    reportId: number,
    activityId: number,
    observationId: number,
    attachmentId: number
  ): Promise<void> {
    return this.axios
      .delete(
        `${this.endpoint}${reportId}/activities/${activityId}/observations/${observationId}/attachments/${attachmentId}/`
      )
      .then(({ data }) => data)
  }
}

// Default export instantiation of the IncidentReportActivityObservationAttachmentService
const incidentReportActivityObservationAttachmentService =
  new IncidentReportActivityObservationAttachmentService(axios)
export default incidentReportActivityObservationAttachmentService

<template>
  <v-select
    v-model="incidentLevel"
    label="Incident Level"
    variant="outlined"
    hide-details="auto"
    :items="incidentItems"
    item-title="display"
    item-value="state"
    chips
    v-bind="$attrs"
  >
    <template #chip="{ item, props }">
      <v-list-item
        density="compact"
        class="pl-2 py-0"
        :title="item.raw.display"
        v-bind="props"
        :style="`border-left: solid ${
          item.raw.color || getReportIncidentStatusVisualByState(item.raw.state)?.color
        } 8px;`"
      ></v-list-item>
    </template>
    <template #item="{ item, index, props }">
      <v-list-item
        v-bind="props"
        :class="{ 'mb-2': index !== incidentStatusVisuals.length - 1 }"
        :style="`border-left: solid ${item.raw.color} 8px;`"
      >
      </v-list-item>
    </template>
  </v-select>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useReportStore } from '@/stores'
import { ReportActivityStatusEnum } from '@/models/report'

const incidentLevel = defineModel({ required: true })

const reportStore = useReportStore()
const { incidentStatusVisuals, getReportIncidentStatusVisualByState } = storeToRefs(reportStore)

const incidentItems = computed(() =>
  incidentStatusVisuals.value.filter(
    (incident) => incident.state > ReportActivityStatusEnum.SecurityLevel1
  )
)
</script>

import 'nprogress/nprogress.css'

import NProgress from 'nprogress'
import type { Router } from 'vue-router'

import { useAuthStore } from '@/stores'

import { generateDynamicRoutes } from './utils'
import { asyncRoutes, constantRoutes } from './routes'

NProgress.configure({ showSpinner: false })

export const setupGuards = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    // Start progress bar
    NProgress.start()

    // Determine whether the user is authenticated

    const authStore = useAuthStore()

    let user = null
    try {
      user = await authStore.tryGetOrFetchAuthUser()
    } catch {
      // No action required - User not authenticated and hence set to null
    }

    if (user) {
      // user is authenticated
      if (to.path === '/login') {
        // If is logged in, redirect to the home page
        next({ path: '/' })
      } else {
        if (router.getRoutes().length == constantRoutes.length) {
          // Generate accessible routes based on user permissions
          const dynamicRoutes = generateDynamicRoutes(authStore.user!.permissions!, asyncRoutes)
          dynamicRoutes.forEach((route) => {
            router.addRoute(route)
          })

          if (dynamicRoutes.length > 0) {
            // Set the replace: true, so the navigation will not leave a history record
            next({ ...to, replace: true })
          } else {
            next()
          }
        } else {
          next()
        }
      }
    } else {
      // TODO: LH 2023-12-12 CHECK WITH TEAM IF ROUTE NOT STATIC THEN 404 SHOWN
      // user not authenticated
      if (to.meta.public) {
        next()
      } else {
        // redirect to login page
        next(`/login?redirect=${to.path}`)
      }
    }
  })

  router.afterEach(() => {
    // Finish progress bar
    NProgress.done()
  })
}

<template>
  <v-text-field
    readonly
    ref="modelActivator"
    v-model="model"
    :clearable="model != null"
    v-bind="$attrs"
  ></v-text-field>

  <v-menu
    v-model="modelRangeMenu"
    :activator="modelActivator"
    :target="modelActivator"
    :close-on-content-click="false"
    location="bottom"
  >
    <v-date-picker
      v-model="internalModel"
      @update:model-value="modelRangeMenu = false"
      hide-header
      show-adjacent-months
    />
  </v-menu>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useDate } from 'vuetify'

import type { VTextField } from 'vuetify/components'

import type DateFnsUtils from '@date-io/date-fns/build/date-fns-utils'

const dateAdapter = useDate() as DateFnsUtils

const model = defineModel<string>({
  default: ''
})

const modelActivator = ref<VTextField>()

const modelRangeMenu = ref(false)

const internalModel = computed<Date>({
  get() {
    return dateAdapter.parseISO(model.value)
  },
  set(value: Date) {
    model.value = dateAdapter.formatByString(value, 'yyyy-MM-dd')
  }
})
</script>

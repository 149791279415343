<template>
  <v-autocomplete
    label="Camera"
    v-model="camera"
    v-model:search="filter.search"
    @update:search="(search: string) => (filter.search = search)"
    :items="cameras"
    item-value="id"
    item-title="name"
    :loading="loading"
    :error-messages="combinedErrorMessages"
    no-filter
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { ref, watch, computed, type PropType } from 'vue'

import { debounce, isArray } from 'lodash'

import type { ICameraFilterParam } from '@/services'

import { useFetchCameras } from '@/composables/camera'
import type { ICamera } from '@/models/camera'

const camera = defineModel<null | ICamera | number>({ default: null })

const props = defineProps({
  errorMessages: {
    type: [String, Array, null] as PropType<string | string[] | null>,
    default: () => []
  },
  filter: {
    type: Object as PropType<ICameraFilterParam>,
    default: () => ({})
  }
})

const combinedErrorMessages = computed(() => {
  let error: string[] = []

  if (fetchError.value) {
    error.push(fetchError.value.message)
  }
  if (isArray(props.errorMessages)) {
    error.concat(props.errorMessages)
  } else {
    if (props.errorMessages) {
      error.push(props.errorMessages)
    }
  }

  return error
})

const filter = ref<ICameraFilterParam>({ search: '', page: 1, ...props.filter })
const loading = ref(false)

const { cameras, error: fetchError, fetchCameras } = useFetchCameras()

// Define a debounced function for fetching data
const debouncedFetchData = debounce(() => {
  fetchCameras(filter.value).finally(() => (loading.value = false))
}, 200) // Adjust the debounce delay as needed

watch(
  () => props.filter,
  (newValue) => {
    filter.value = { search: filter.value.search, page: 1, ...newValue }
  }
)

watch(
  filter.value,
  () => {
    loading.value = true
    debouncedFetchData()
  },
  { immediate: true }
)
</script>

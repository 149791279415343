import { defineStore } from 'pinia'

import type { IAppState } from '.'

// Pinia store for managing auth-related states and actions
export const useAppStore = defineStore('app', {
  state: (): IAppState => ({
    verticalBar: { drawer: true, mini: false }
  }),

  getters: {
    getDrawer(state: IAppState) {
      return state.verticalBar.drawer
    },

    getMini(state: IAppState) {
      return state.verticalBar.mini
    }
  },

  actions: {
    toggleDrawer() {
      this.verticalBar.drawer = !this.verticalBar.drawer
    },

    toggleMini() {
      this.verticalBar.mini = !this.verticalBar.mini
    }
  }
})

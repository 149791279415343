import { ref, toRef, type MaybeRef } from 'vue'

import {
  CCTVReport,
  CCTVReportActivity,
  type ICCTVReportActivityObservationData,
  type ICCTVReportData,
  type IReportActivityData
} from '@/models/report'
import cctvReportService from '@/services/report/cctv-report'
import cctvReportActivityReportService from '@/services/report/cctv-activity'
import cctvReportActivityObservationReportService from '@/services/report/cctv-activity-observation'

import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'
import type { IReportFilterParam } from '@/services'
import { useSnackbarStore } from '@/stores'

export function useFetchCCTVReports(filter?: IReportFilterParam) {
  const count = ref<number>(0)

  const queryKey = ref(['cctv-reports', filter])

  const {
    data: reports,
    isFetching,
    error
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => cctvReportService.fetchCCTVReports(filter),
    staleTime: 60 * 1000, //Cache data for 6 seconds
    select: (data) => {
      count.value = data.count
      return data.results.map((report) => new CCTVReport(report))
    }
  })

  return { queryKey, reports, count, isFetching, error }
}

export function useFetchCCTVReport(id: number) {
  const queryKey = ref(['cctv-report', id])

  const {
    data: report,
    isLoading,
    error
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => cctvReportService.fetchCCTVReport(id),
    staleTime: 60 * 1000, //Cache data for 60 seconds
    select: (data) => new CCTVReport(data)
  })

  return { report, isLoading, error, queryKey }
}

// TODO - LH  - 2024-01-31 - Check why inferred typing of useMutation cannot be found
export function useDeleteCCTVReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-cctv-report'],
    mutationFn: (reportId: number) => cctvReportService.deleteCCTVReport(reportId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['cctv-reports']) })
      snackbarStore.showSnackbar('CCTV Report deleted successfully', '')
    }
  })
  return {
    deleteInProgress: isPending,
    isDeleteError: isError,
    deleteError: error,
    isDeleteSuccess: isSuccess,
    deleteCCTVReport: mutate,
    clearDeleteErrors: reset
  }
}

export function useCreateCCTVReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['create-cctv-report'],
    mutationFn: (report: ICCTVReportData) => cctvReportService.createCCTVReport(report),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['cctv-report', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['cctv-reports']) })
      snackbarStore.showSnackbar('CCTV Report created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useUpdateCCTVReport() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-cctv-report'],
    mutationFn: (report: ICCTVReportData) => cctvReportService.updateCCTVReport(report),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['cctv-report', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['cctv-reports']) })
      snackbarStore.showSnackbar('CCTV Report updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Activities
export function useFetchCCTVReportActivities(id: number) {
  const queryKey = ref(['cctv-report-activities', id])

  const {
    data: activities,
    isLoading,
    error
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => cctvReportActivityReportService.fetchCCTVActivities(id),
    staleTime: 60 * 1000, //Cache data for 60 seconds
    select: (data) => data.map((activity) => new CCTVReportActivity(activity))
  })

  return { activities, isLoading, error, queryKey }
}

export function useCreateCCTVReportActivity(reportId: number) {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['create-cctv-report-activity'],
    mutationFn: () => cctvReportActivityReportService.createCCTVActivity(reportId),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useUpdateCCTVReportActivity(reportId: number) {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-cctv-report-activity'],
    mutationFn: (activity: IReportActivityData) =>
      cctvReportActivityReportService.updateCCTVActivity(reportId, activity),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteCCTVReportActivity(reportId: number) {
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-cctv-report-activity'],
    mutationFn: (activityId: number) =>
      cctvReportActivityReportService.deleteCCTVActivity(reportId, activityId),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

// Activity Observations

export function useCreateCCTVReportActivityObservation(
  reportId: number,
  activityId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['create-cctv-report-activity-observation', activityIdRef.value],
    mutationFn: (data: ICCTVReportActivityObservationData) =>
      cctvReportActivityObservationReportService.createCCTVActivityObservation(
        reportId,
        activityIdRef.value,
        data
      ),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useUpdateCCTVReportActivityObservation(
  reportId: number,
  activityId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['update-cctv-report-activity-observation', activityIdRef.value],
    mutationFn: (observation: ICCTVReportActivityObservationData) =>
      cctvReportActivityObservationReportService.updateCCTVActivityObservation(
        reportId,
        activityIdRef.value,
        observation
      ),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useDeleteCCTVReportActivityObservation(
  reportId: number,
  activityId: MaybeRef<number>
) {
  const activityIdRef = toRef(activityId)
  const snackbarStore = useSnackbarStore()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['delete-cctv-report-activity-observation', activityIdRef.value],
    mutationFn: (observationId: number) =>
      cctvReportActivityObservationReportService.deleteCCTVActivityObservation(
        reportId,
        activityIdRef.value,
        observationId
      ),
    onSuccess: () => {
      snackbarStore.showSnackbar('Report activity observation deleted successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

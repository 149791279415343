<template>
  <div class="d-flex mb-2 align-center justify-space-between" style="background-color: #c9c9c9">
    <div class="ml-3 py-1" style="font-size: large; font-weight: 500">
      {{ format(activity.activity_time!, 'MMMM dd, yyyy - HH:mm') }}
    </div>
    <MoreOptionsMenuButton
      v-if="
        currentUser?.hasPermission('cctv_reports.change_cctvreport') ||
        currentUser?.hasPermission('cctv_reports.delete_cctvreport')
      "
      tooltip-text="Activity Options"
    >
      <v-list-item
        v-if="currentUser.hasPermission('cctv_reports.change_cctvreport')"
        @click="openActivityDialog()"
      >
        <v-list-item-title>Edit Activity</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="currentUser.hasPermission('cctv_reports.delete_cctvreport')"
        @click="openDeleteActivityDialog()"
      >
        <v-list-item-title> Delete Activity</v-list-item-title>
      </v-list-item>
    </MoreOptionsMenuButton>
  </div>

  <CCTVReportActivityDetailTimeFormDialog
    v-if="activity"
    v-model:dialog="activityDetailDialog"
    :activity="activity"
    is-edit
    :report-id="report.id!"
  />

  <ConfirmationDialog v-model="activityDeleteDialog" v-model:error="error" title="Delete Activity">
    <template v-slot:message>
      Are you sure you want to delete this activity?
      <br />
      <div class="pt-2">This action cannot be undone.</div>
    </template>

    <template v-slot:actions>
      <v-spacer></v-spacer>

      <v-btn
        density="comfortable"
        color="error"
        variant="flat"
        class="text-capitalize"
        :loading="isDeletePending"
        @click.stop="deleteActivity()"
      >
        Delete
      </v-btn>
    </template>
  </ConfirmationDialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { format } from 'date-fns'

import { useAuthStore } from '@/stores'
import type { ICCTVReport, ICCTVReportActivity } from '@/models/report'

import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import MoreOptionsMenuButton from '@/components/common/MoreOptionsMenuButton.vue'
import CCTVReportActivityDetailTimeFormDialog from './CCTVReportActivityDetailTimeFormDialog.vue'
import { useDeleteCCTVReportActivity } from '@/composables/cctv-report'

interface Props {
  report: ICCTVReport
  activity: ICCTVReportActivity
}
const props = defineProps<Props>()

interface Emits {
  (e: 'refresh-report'): void
}
const emit = defineEmits<Emits>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const activityDetailDialog = ref(false)
const activityDeleteDialog = ref(false)

function openActivityDialog() {
  activityDetailDialog.value = true
}

function openDeleteActivityDialog() {
  activityDeleteDialog.value = true
}

const {
  isPending: isDeletePending,
  error: error,
  mutate
} = useDeleteCCTVReportActivity(props.report.id!)

function deleteActivity() {
  mutate(props.activity.id!, {
    onSuccess: () => {
      emit('refresh-report')
      activityDeleteDialog.value = false
    }
  })
}
</script>

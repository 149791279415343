import {
  ReportStateEnum,
  type ICCTVReportData,
  type ICCTVReportActivity,
  type ICCTVReportActivityData,
  type ICCTVReportActivityObservation,
  type ICCTVReportActivityObservationData,
  type ICCTVReportActivityObservationCameraStatus,
  CCTVReportActivityStatusEnum,
  type IReportIncidentStatus,
  type ICCTVReport,
  type IReportState,
  type ICCTVReportStatisticsData,
  type ICCTVReportStatistics
} from '.'
import { AbstractModel } from '../base'
import { Camera, type ICamera } from '../camera'
import { type IClient, type ISite, Client, Site } from '../client'
import { Employee, type IEmployee } from '../employee'
import { User, type IUser } from '../user'

export class CCTVReport extends AbstractModel implements ICCTVReport {
  readonly id?: number
  readonly client: IClient | number
  readonly site: ISite | number
  readonly shift_start: Date
  readonly shift_end: Date
  readonly state?: IReportState
  readonly created?: Date
  readonly modified?: Date
  readonly activities: ICCTVReportActivity[] = []
  readonly report_id?: string
  readonly owner?: string
  readonly guards: IUser[] = []
  readonly memo: string = ''
  readonly approvers: IEmployee[] = []

  constructor(data: ICCTVReportData) {
    super()

    // Validate data
    const requiredFields: (keyof ICCTVReportData)[] = ['client', 'site', 'shift_start', 'shift_end']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.client = typeof data.client! !== 'number' ? new Client(data.client!) : data.client!
    this.site = typeof data.site! !== 'number' ? new Site(data.site!) : data.site!
    this.shift_start =
      typeof data.shift_start! === 'string' ? new Date(data.shift_start!) : data.shift_start!
    this.shift_end =
      typeof data.shift_end! === 'string' ? new Date(data.shift_end!) : data.shift_end!

    if (data.id) {
      this.id = data.id
    }

    if (data.activities) {
      this.activities = data.activities.map((activity) => {
        return new CCTVReportActivity(activity)
      })
    }

    if (data.state) {
      this.state = data.state
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }

    if (data.report_id) {
      this.report_id = data.report_id
    }

    if (data.owner) {
      this.owner = data.owner
    }

    if (data.guards) {
      this.guards = data.guards.map((guard) => new User(guard))
    }

    if (data.memo) {
      this.memo = data.memo ?? ''
    }

    if (data.approvers) {
      this.approvers = data.approvers.map((approver) => {
        return new Employee(approver)
      })
    }
  }

  canSubmit(): boolean {
    return this.state?.value == ReportStateEnum.Draft
  }

  canApprove(): boolean {
    return this.state?.value == ReportStateEnum.Submitted
  }
}

export class CCTVReportActivity extends AbstractModel implements ICCTVReportActivity {
  readonly id?: number
  readonly observations: ICCTVReportActivityObservation[] = []
  readonly activity_time?: Date
  readonly created_by?: string
  readonly modified_by?: string
  readonly created?: Date
  readonly modified?: Date

  constructor(data: ICCTVReportActivityData) {
    super()

    // Validate data
    const requiredFields: (keyof ICCTVReportActivityData)[] = ['observations']
    this.validate(data, requiredFields)

    if (data.id) {
      this.id = data.id
    }

    if (data.observations) {
      this.observations = data.observations.map(
        (observation) => new CCTVReportActivityObservation(observation)
      )
    }

    if (data.activity_time) {
      this.activity_time =
        typeof data.activity_time === 'string' ? new Date(data.activity_time) : data.activity_time
    }

    if (data.created_by) {
      this.created_by = data.created_by
    }

    if (data.modified_by) {
      this.modified_by = data.modified_by
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }
  }
}

export class CCTVReportActivityObservation
  extends AbstractModel
  implements ICCTVReportActivityObservation
{
  readonly id?: number
  readonly text: string
  readonly file: string | File
  readonly camera: number | ICamera
  readonly incident_level: IReportIncidentStatus | CCTVReportActivityStatusEnum
  readonly status: ICCTVReportActivityObservationCameraStatus
  readonly reporter?: string
  readonly incident_report: number | null = null

  constructor(data: ICCTVReportActivityObservationData) {
    super()

    // Validate data
    const requiredFields: (keyof ICCTVReportActivityObservationData)[] = [
      'text',
      'file',
      'camera',
      'status',
      'incident_level'
    ]
    this.validate(data, requiredFields)

    if (data.id) {
      this.id = data.id
    }

    this.text = data.text!
    this.file = data.file!
    this.camera = typeof data.camera! === 'number' ? data.camera! : new Camera(data.camera!)
    this.status = data.status!
    this.incident_level = data.incident_level!

    if (data.incident_report) {
      this.incident_report = data.incident_report
    }

    if (data.reporter) {
      this.reporter = data.reporter
    }
  }
}

export class CCTVReportStatistics implements ICCTVReportStatistics {
  draft_count: number = 0
  approval_pending_count: number = 0
  waiting_submission_count: number = 0
  waiting_approval_count: number = 0
  recently_created_count: number = 0

  constructor(data?: ICCTVReportStatisticsData) {
    if (data) {
      if (data.draft_count) {
        this.draft_count = data.draft_count
      }

      if (data.approval_pending_count) {
        this.approval_pending_count = data.approval_pending_count
      }

      if (data.waiting_submission_count) {
        this.waiting_submission_count = data.waiting_submission_count
      }

      if (data.waiting_approval_count) {
        this.waiting_approval_count = data.waiting_approval_count
      }

      if (data.recently_created_count) {
        this.recently_created_count = data.recently_created_count
      }
    }
  }
}

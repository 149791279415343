<template>
  <v-alert
    type="error"
    closable
    icon="mdi-alert-circle"
    @click:close="clearAllErrors()"
    :title="error.message"
    :text="errorDetail"
  >
  </v-alert>
</template>

<script lang="ts">
import { SystemError, type ISystemError } from '@/models/error'

export default {
  props: {
    error: {
      type: Error,
      required: true
    }
  },

  computed: {
    isSystemError(): boolean {
      return this.error instanceof SystemError
    },

    errorDetail(): string {
      let detail = ''
      if (this.isSystemError) {
        detail = (this.error as ISystemError).detail
      }
      return detail
    }
  },

  methods: {
    clearAllErrors(): void {
      this.$emit('clearErrors')
    }
  }
}
</script>

<style></style>

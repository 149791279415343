<template>
  <v-dialog v-model="dialog" max-width="750" persistent>
    <AlFormCard
      title="Observation Report"
      :subtitle="`${isEdit ? 'Edit' : 'Create'}  Observation Report`"
    >
      <template #error>
        <ErrorAlert
          v-if="error != null || attachmentsError != null"
          :error="error! || attachmentsError!"
          @clearErrors="clearErrors()"
          class="mb-2"
        >
        </ErrorAlert>
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form ref="form" v-model="valid" validate-on="submit lazy">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="observationFormData.location"
              label="Location"
              variant="outlined"
              hide-details="auto"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['location'] as string[])"
              clearable
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <ReportsObservationTextarea
              rows="10"
              v-model="observationFormData.text"
              label="Notes"
              variant="outlined"
              hide-details="auto"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['text'] as string[])"
              clearable
              no-resize
            >
            </ReportsObservationTextarea>
          </v-col>
        </v-row>
      </v-form>
      <v-form ref="attachmentForm" v-model="attachmentValid" validate-on="submit lazy">
        <v-row>
          <v-col cols="12">
            <IncidentReportDetailObservationAttachmentsInput
              v-model="observationFormData.attachments"
              :loading="attachmentsLoading"
              :disabled="attachmentsLoading"
              @update:model-value="clearAttachmentError()"
              :error-messages="attachmentsError?.message"
              clearable
            />
          </v-col>
          <v-col cols="12">
            <div
              v-for="(attachment, attachmentIndex) in imageAttachments"
              :key="attachmentIndex"
              class="pb-3"
            >
              <ImageViewer width="100%" height="auto" :src="attachment.file as string" />
            </div>
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { VForm } from 'vuetify/components'

import { cloneDeep } from 'lodash'
import { diffDeep } from '@/utils/helpers'
import { requiredValidator } from '@/utils/validators'

import type { ISystemError } from '@/models/error'
import {
  type IIncidentReport,
  type IIncidentReportActivityObservation,
  type IIncidentReportActivityObservationData,
  IncidentReportActivityObservation,
  type IIncidentReportObservationAttachmentData
} from '@/models/report'

import {
  useCreateIncidentReportActivityObservation,
  useCreateIncidentReportActivityObservationAttachment,
  useUpdateIncidentReportActivityObservation,
  useUpdateIncidentReportActivityObservationAttachment
} from '@/composables/incident-report'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import ReportsObservationTextarea from '../common/ReportsObservationTextarea.vue'
import IncidentReportDetailObservationAttachmentsInput from './IncidentReportDetailObservationAttachmentsInput.vue'
import ImageViewer from '@/components/common/ImageViewer.vue'

interface Props {
  report: IIncidentReport
  activityId: number
  observation?: IIncidentReportActivityObservation
  isEdit: boolean
}
const props = defineProps<Props>()
const dialog = defineModel<boolean>('dialog')

interface Emits {
  (e: 'saved-activity-observation'): void
}
const emit = defineEmits<Emits>()

const observationFormData = ref<IIncidentReportActivityObservationData>({})

const form = ref<VForm>()
const attachmentForm = ref<VForm>()

const valid = ref(false)

watch(
  dialog,
  (value) => {
    if (value) {
      observationFormData.value = props.observation
        ? cloneDeep(props.observation)
        : {
            attachments: []
          }
    }
  },
  { immediate: true }
)

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const activityId = computed(() => props.activityId)
// keep activityId a ref of the property to ensure the latest value is always being passed in
const updateMutation = useUpdateIncidentReportActivityObservation(props.report.id!, activityId)
const createMutation = useCreateIncidentReportActivityObservation(props.report.id!, activityId)

const loading = computed(() =>
  props.isEdit ? updateMutation.isPending.value : createMutation.isPending.value
)
const mutationError = computed(() =>
  props.isEdit ? updateMutation.error.value : createMutation.error.value
)

const error = ref<ISystemError | null>(null)

// update error to crud action error display
watch(mutationError, (value) => {
  error.value = value
})

function clearErrors() {
  props.isEdit ? updateMutation.reset() : createMutation.reset()
  clearAttachmentError()
  form.value?.resetValidation()
}

async function save() {
  clearErrors()

  const { valid } = await form.value!.validate()

  if (valid) {
    let observationInstance: IIncidentReportActivityObservation | null = null

    try {
      observationInstance = new IncidentReportActivityObservation(observationFormData.value)
    } catch (e: any) {
      console.warn(e)

      error.value = e
    }

    if (observationInstance != null) {
      const payload = props.isEdit
        ? diffDeep(observationInstance, props.observation, true)
        : observationInstance

      const saveAction = props.isEdit ? updateMutation.mutate : createMutation.mutate

      saveAction(payload, {
        onSuccess: (data) => {
          // cache attachments before value is reset by api call
          const attachments = observationFormData.value.attachments ?? []

          // set observation with created/updated data to set the observation Id used in mutation
          observationFormData.value = data
          saveAttachments(attachments).finally(() => {
            if (!attachmentsError.value) {
              emit('saved-activity-observation')
              closeDialog()
            }
          })
        }
      })
    }
  }
}

// Attachments
const imageAttachments = ref<IIncidentReportObservationAttachmentData[]>([])
watch(
  () => observationFormData.value.attachments,
  async (attachments) => {
    imageAttachments.value = []
    if (attachments?.length) {
      for (const attachment of attachments) {
        if (attachment.file) {
          if (typeof attachment.file == 'string') {
            imageAttachments.value.push({ file: attachment.file, id: attachment.id })
          } else {
            const fileURL = (await blobToData(attachment.file)) ?? ''
            imageAttachments.value.push({ file: fileURL, id: attachment.id })
          }
        }
      }
    }
  },
  { immediate: true, deep: true }
)

function blobToData(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })
}

const attachmentValid = ref(false)
const observationId = computed(() => observationFormData.value.id!)

const createAttachmentMutation = useCreateIncidentReportActivityObservationAttachment(
  props.report.id!,
  activityId,
  observationId!
)

const updateAttachmentMutation = useUpdateIncidentReportActivityObservationAttachment(
  props.report.id!,
  activityId,
  observationId!
)

const attachmentsLoading = computed(() =>
  props.isEdit ? updateAttachmentMutation.isPending.value : createAttachmentMutation.isPending.value
)

const attachmentsError = ref<ISystemError | null>(null)

function clearAttachmentError() {
  attachmentsError.value = null
}

async function saveAttachments(attachments: IIncidentReportObservationAttachmentData[]) {
  clearErrors()

  const { valid } = await form.value!.validate()

  if (valid) {
    const updatedAttachments =
      attachments.filter((attachment) => attachment.file instanceof File) ?? []

    for (let index = 0; index < updatedAttachments.length; index++) {
      const attachment = updatedAttachments[index]

      const saveAction = !attachment.id
        ? createAttachmentMutation.mutateAsync
        : updateAttachmentMutation.mutateAsync

      await saveAction(attachment, {
        onError: (err) => {
          attachmentsError.value = err
        }
      })
    }
  }
}
</script>

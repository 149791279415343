<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <BaseWidget
        v-bind="props"
        :title="reportType.title"
        bg-color="white"
        :variant="isHovering ? 'elevated' : 'flat'"
        :elevation="isHovering ? 2 : 0"
        class="report-card-statistics border cursor-pointer"
        :style="`border-block-end: 2px solid rgba(var(--v-theme-${color}), ${
          isHovering ? 1 : 'var(--v-disabled-opacity))'
        } !important`"
      >
        <div class="d-flex align-center mb-2 mt-n3" style="column-gap: 1rem">
          <v-avatar variant="flat" class="rounded" size="42" color="white" density="comfortable">
            <v-icon :color="color" icon="mdi-cctv" size="28" />
          </v-avatar>
          <h5 class="text-h4 font-weight-medium">
            {{ count }}
          </h5>
          <div class="text-body-1">{{ text }}</div>
        </div>

        <div class="d-flex align-center gap-x-2" style="column-gap: 0.5rem">
          <v-btn
            variant="text"
            density="compact"
            :to="{
              name: reportType.route,
              query: filters
            }"
            class="d-flex flex-row align-center text-link text-caption ml-auto"
          >
            <v-fade-transition mode="out-in">
              <v-icon start>mdi-arrow-right</v-icon>
            </v-fade-transition>
            GO TO REPORTS
          </v-btn>
        </div>
      </BaseWidget>
    </template>
  </v-hover>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import BaseWidget from '../base/BaseWidget.vue'

const props = defineProps<{
  reportType?: 'cctv' | 'guard' | 'incident'
  count: number
  filters: Record<string, string | number | undefined>
  text: string
  color: string
}>()

const reportType = computed(() => {
  switch (props.reportType) {
    case 'cctv':
      return {
        title: 'CCTV Reports',
        route: 'cctv'
      }
    case 'guard':
      return {
        title: 'Guard Reports',
        route: 'guard'
      }
    case 'incident':
      return {
        title: 'Incident Reports',
        route: 'incident'
      }
    default:
      return { title: '', route: '' }
  }
})
</script>

<style lang="scss">
@use 'vuetify/tools' as tools;

.report-card-statistics {
  @include tools.elevationTransition();

  transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}
</style>

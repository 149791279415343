<template>
  <MoreOptionsMenuButton
    v-bind="attrs"
    v-if="
      currentUser?.hasPermission('contacts.change_contact') ||
      currentUser?.hasPermission('contacts.delete_contact')
    "
  >
    <v-list-item
      v-if="currentUser.hasPermission('contacts.change_contact')"
      @click="emit('contact-edit-pressed', contact)"
      title="Edit Contact"
    >
      <template #prepend>
        <v-icon size="18" icon="mdi-pencil" class="me-1" />
      </template>
    </v-list-item>

    <template v-if="currentUser.hasPermission('contacts.delete_contact')">
      <v-divider class="pb-1" />
      <v-list-item
        class="text-error"
        @click="emit('contact-delete-pressed', contact)"
        title="Delete Contact"
      >
        <template #prepend>
          <v-icon size="18" icon="mdi-delete" class="me-1" />
        </template>
      </v-list-item>
    </template>
  </MoreOptionsMenuButton>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue'
import { storeToRefs } from 'pinia'

import type { IContact } from '@/models/contact'

import { useAuthStore } from '@/stores'

import MoreOptionsMenuButton from '../common/MoreOptionsMenuButton.vue'

interface Props {
  contact: IContact
}
defineProps<Props>()

interface Emit {
  (e: 'contact-edit-pressed', contact: IContact): void
  (e: 'contact-delete-pressed', contact: IContact): void
}
const emit = defineEmits<Emit>()

const attrs = useAttrs()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>

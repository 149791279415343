import type { AxiosInstance } from 'axios'
import type {
  IAuthService,
  IAuthServiceRefreshTokenData,
  IResetPasswordPayload
} from './auth.types'
import type { IUserData } from '@/models/user'
import axios from '@/axios'

/**
 * A class representing a AuthService API handler.
 */
export class AuthService implements IAuthService {
  private endpoint: string

  // UserService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'auth/'
  }

  // Method to login user given a username and password
  login(username: string, password: string): Promise<IUserData> {
    return this.axios
      .post(`${this.endpoint}login/`, { username, password })
      .then(({ data }) => data.user)
  }

  // Method to logout currently authenticated user
  logout(): Promise<void> {
    return this.axios.post(`${this.endpoint}logout/`)
  }

  refreshToken(): Promise<IAuthServiceRefreshTokenData> {
    return this.axios.post(`${this.endpoint}token/refresh/`).then(({ data }) => data)
  }

  forgotPassword(email: string): Promise<void> {
    return this.axios
      .post(`${this.endpoint}password/reset/`, { email: email })
      .then(({ data }) => data)
  }

  resetPassword(payload: IResetPasswordPayload): Promise<void> {
    return this.axios
      .post(`${this.endpoint}password/reset/confirm/`, {
        new_password1: payload.password1,
        new_password2: payload.password2,
        uid: payload.uid,
        token: payload.token
      })
      .then(({ data }) => data)
  }

  async changePassword(new_password1: string, new_password2: string) {
    return this.axios
      .post(`${this.endpoint}password/change/`, {
        new_password1,
        new_password2
      })
      .then(({ data }) => data)
  }

  // Method to fetch currently authenticated user from the API
  fetchAuthUser(): Promise<IUserData> {
    return this.axios.get(`${this.endpoint}user/`).then(({ data }) => data)
  }

  // Method to update currently authenticated user from the API
  updateAuthUser(data: IUserData): Promise<IUserData> {
    return this.axios.patch(`${this.endpoint}user/`, data).then(({ data }) => data)
  }
}

// Default export instantiation of the AuthService
const authService = new AuthService(axios)
export default authService

<template>
  <VPhoneInput
    v-bind="$attrs"
    v-model="phoneInput"
    :countryProps="{
      hideDetails: 'auto'
    }"
  >
  </VPhoneInput>
</template>

<script setup lang="ts">
import type { VPhoneInput } from 'node_modules/v-phone-input/dist'

const phoneInput = defineModel()
</script>

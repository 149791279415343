<template>
  <ErrorAlert v-if="error != null" :error="error" class="mt-0 mb-6 rounded" @click.stop />
  <v-fade-transition mode="out-in">
    <v-row v-if="isFetching">
      <v-col v-for="n in 3" :key="n">
        <v-skeleton-loader class="rounded-lg" height="150" type="image" />
      </v-col>
    </v-row>
    <v-row v-else>
      <template v-for="(reportData, reportDataIndex) in reportStatisticsDashboardData">
        <v-col :key="reportDataIndex" cols="12" md="4" v-if="reportData.hasPermission">
          <ReportWidget
            report-type="cctv"
            :count="reportData.count"
            :text="reportData.text"
            :color="reportData.color"
            :filters="reportData.filters"
          />
        </v-col>
      </template>
    </v-row>
  </v-fade-transition>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useDate } from 'vuetify'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'

import { ReportStateEnum, CCTVReportStatistics } from '@/models/report'

import type DateFnsUtils from '@date-io/date-fns/build/date-fns-utils'
import ErrorAlert from '../common/ErrorAlert.vue'
import ReportWidget from './ReportWidget.vue'

import { useQuery } from '@tanstack/vue-query'
import cctvReportService from '@/services/report/cctv-report'

const dateAdapter = useDate() as DateFnsUtils

function useFetchCCTVReportStatistics() {
  const queryKey = ref(['cctv-reports-statistics'])

  const {
    data: statistics,
    isFetching,
    error
  } = useQuery({
    queryKey: queryKey.value,
    queryFn: () => cctvReportService.fetchCCTVReportStatistics(),
    staleTime: 60 * 1000, //Cache data for 6 seconds
    select: (data) => new CCTVReportStatistics(data)
  })

  return { queryKey, statistics, isFetching, error }
}

const { statistics, isFetching, error } = useFetchCCTVReportStatistics()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const reportStatisticsDashboardData = computed(() => [
  {
    hasPermission:
      currentUser.value?.hasPermission('cctv_reports.add_cctvreport') &&
      !currentUser.value?.hasPermission('cctv_reports.approve_cctvreport'),
    color: 'draft',
    count: statistics.value?.draft_count ?? 0,
    text: `of your report${statistics.value?.draft_count != 1 ? 's are' : ' is'} in draft`,
    filters: {
      owner: currentUser.value?.id!,
      state: ReportStateEnum.Draft
    }
  },
  {
    hasPermission: currentUser.value?.hasPermission('cctv_reports.approve_cctvreport'),
    color: 'draft',
    count: statistics.value?.waiting_submission_count ?? 0,
    text: `report${statistics.value?.waiting_submission_count != 1 ? 's are' : ' is'} in draft`,
    filters: {
      state: ReportStateEnum.Draft
    }
  },
  {
    hasPermission: currentUser.value?.hasPermission('cctv_reports.add_cctvreport'),
    color: 'submitted',
    count: statistics.value?.approval_pending_count ?? 0,
    text: `report${
      statistics.value?.approval_pending_count != 1 ? 's' : ''
    } you have pending approval`,
    filters: {
      owner: currentUser.value?.id,
      state: ReportStateEnum.Submitted
    }
  },
  {
    hasPermission: currentUser.value?.hasPermission('cctv_reports.approve_cctvreport'),
    color: 'primary',
    count: statistics.value?.waiting_approval_count ?? 0,
    text: `report${
      statistics.value?.waiting_approval_count != 1 ? 's are' : ' is'
    } awaiting approval`,
    filters: {
      state: ReportStateEnum.Submitted
    }
  },
  {
    hasPermission: !currentUser.value?.hasPermission('cctv_reports.approve_cctvreport'),
    color: 'approved',
    count: statistics.value?.recently_created_count ?? 0,
    text: `report${
      statistics.value?.recently_created_count != 1 ? 's' : ''
    } created this past week`,
    filters: {
      created_after: dateAdapter.formatByString(dateAdapter.addWeeks(new Date(), -1), 'yyyy-MM-dd'),
      owner: currentUser.value?.id
    }
  }
])
</script>

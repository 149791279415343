import type { IGuardReportData, IGuardReportStatisticsData } from '@/models/report'
import type { IGuardReportService, IGuardReportPaginatedListData } from './guard-report.types'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from '@/axios'
import type { IReportFilterParam } from '..'

/**
 * A class representing a GuardReportService API handler.
 */
export class GuardReportService implements IGuardReportService {
  private endpoint: string

  // GuardReportService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/reports/guards/'
  }

  // Method to fetch a list of reports from the API
  fetchGuardReports(filter?: IReportFilterParam): Promise<IGuardReportPaginatedListData> {
    const config: AxiosRequestConfig | undefined = filter ? { params: filter } : undefined
    return this.axios.get(`${this.endpoint}`, config).then(({ data }) => data)
  }

  // Method to fetch a single report by their ID from the API
  fetchGuardReport(id: number): Promise<IGuardReportData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // Method to create a new report on the API
  createGuardReport(data: IGuardReportData): Promise<IGuardReportData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  // Method to update an existing report on the API
  updateGuardReport(data: IGuardReportData): Promise<IGuardReportData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  // Method to delete a report by their ID from the API
  deleteGuardReport(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // stats
  fetchGuardReportStatistics(): Promise<IGuardReportStatisticsData> {
    return this.axios.get(`${this.endpoint}statistics/`).then(({ data }) => data)
  }

  //exports
  exportGuardReportPdf(id: number): Promise<Blob> {
    return this.axios
      .get(`${this.endpoint}${id}/export-pdf/`, {
        responseType: 'blob',
        timeout: 30 * 1000 // 30 seconds
      })
      .then(({ data }) => data)
  }

  //transitions
  submitGuardReport(id: number): Promise<IGuardReportData> {
    return this.axios.put(`${this.endpoint}${id}/submit/`).then(({ data }) => data)
  }
  approveGuardReport(id: number): Promise<IGuardReportData> {
    return this.axios.put(`${this.endpoint}${id}/approve/`).then(({ data }) => data)
  }
}

// Default export instantiation of the GuardReportService
const guardReportService = new GuardReportService(axios)
export default guardReportService

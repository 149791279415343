<template>
  <NoDataAvailablePlaceholder
    v-if="report.activities.length == 0"
    style="margin-top: 32px !important; margin-bottom: 0 !important"
    header="No activities available"
    :description="
      currentUser?.hasPermission('incident_reports.add_incidentreportactivity')
        ? 'No activities available, you can create an activity here'
        : ''
    "
  >
    <template #primaryAction>
      <v-btn
        v-if="currentUser?.hasPermission('incident_reports.add_incidentreportactivity')"
        prepend-icon=""
        class="text-capitalize"
        @click="createIncidentReportActivity()"
      >
        <v-icon left class="hidden-sm-and-down mr-1" icon="mdi-alert-octagon" />
        Create Activity
      </v-btn>
    </template>
  </NoDataAvailablePlaceholder>

  <div v-else v-for="(activity, i) in report.activities" :key="i" class="mb-3">
    <IncidentReportDetailActivityCardHeader
      :report="report"
      :activity="activity"
      @refresh-report="updateReportCache()"
    />

    <div>
      <v-row class="ma-0" v-for="(observation, i) in activity.observations" :key="i">
        <IncidentReportDetailActivityObservationCard
          :report="report"
          :activity="activity"
          :observation="observation"
          @open-activity-observation-detail="openActivityObservationDialog(activity, observation)"
          @refresh-report="updateReportCache()"
        />
      </v-row>
      <v-row
        class="ma-0"
        v-if="currentUser?.hasPermission('incident_reports.change_incidentreport')"
      >
        <v-col>
          <v-btn
            block
            flat
            variant="tonal"
            color="secondary"
            class="text-capitalize"
            @click="openActivityObservationDialog(activity)"
          >
            <v-icon left class="hidden-sm-and-down mr-1">mdi-eye </v-icon>
            Create observation
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>

  <v-btn
    v-if="report.activities.length > 0"
    block
    flat
    class="text-capitalize mb-4"
    color="secondary"
    @click="createIncidentReportActivity()"
    :loading="isCreateActivityLoading"
  >
    <v-icon left class="hidden-sm-and-down mr-1" icon="mdi-alert-octagon" />
    Create Activity
  </v-btn>

  <IncidentReportActivityDetailTimeFormDialog
    v-if="selectedActivity"
    v-model:dialog="activityDetailDialog"
    :activity="selectedActivity"
    is-edit
    :report-id="report.id!"
  />

  <IncidentReportDetailObservationFormDialog
    v-if="selectedActivity"
    :report="report"
    v-model:dialog="activityDetailObservationDialog"
    :activity-id="selectedActivity?.id!"
    :is-edit="!!selectedObservation"
    :observation="selectedObservation"
    @saved-activity-observation="updateReportCache()"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'
import {
  useCreateIncidentReportActivity,
  useFetchIncidentReport
} from '@/composables/incident-report'
import { useQueryClient } from '@tanstack/vue-query'

import {
  type IIncidentReportActivityObservation,
  type IIncidentReport,
  type IIncidentReportActivity,
  IncidentReportActivity
} from '@/models/report'

import NoDataAvailablePlaceholder from '@/components/common/NoDataAvailablePlaceholder.vue'

import IncidentReportActivityDetailTimeFormDialog from './IncidentReportActivityDetailTimeFormDialog.vue'
import IncidentReportDetailObservationFormDialog from './IncidentReportDetailObservationFormDialog.vue'
import IncidentReportDetailActivityCardHeader from './IncidentReportDetailActivityCardHeader.vue'
import IncidentReportDetailActivityObservationCard from './IncidentReportDetailActivityObservationCard.vue'

interface Props {
  report: IIncidentReport
}

const queryClient = useQueryClient()

const props = defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const { queryKey } = useFetchIncidentReport(props.report.id!)

const activityDetailDialog = ref(false)
const activityDetailObservationDialog = ref(false)

const selectedActivity = ref<IIncidentReportActivity>()
const selectedObservation = ref<IIncidentReportActivityObservation>()

function updateReportCache() {
  queryClient.invalidateQueries({ queryKey })
}

const { isPending: isCreateActivityLoading, mutate: createActivityMutate } =
  useCreateIncidentReportActivity(props.report.id!)

function createIncidentReportActivity() {
  createActivityMutate(undefined, {
    onSuccess: (activity) => {
      openActivityObservationDialog(new IncidentReportActivity(activity))
    }
  })
}

function openActivityObservationDialog(
  activity: IIncidentReportActivity,
  observation?: IIncidentReportActivityObservation
) {
  selectedObservation.value = observation
  selectedActivity.value = activity

  activityDetailObservationDialog.value = true
}
</script>

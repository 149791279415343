<template>
  <v-container class="pt-0" style="max-width: 1200px">
    <IncidentReportDetailCard :title="`${route.meta.title}`" :reportId="Number(route.params.id)" />
  </v-container>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

import IncidentReportDetailCard from '@/components/reports/incident/IncidentReportDetailCard.vue'

const route = useRoute()
</script>

import { AbstractModel } from '../base'
import { User } from '@/models/user'
import type {
  IEmployee,
  IEmployeeData,
  IEmployeeProfile,
  IEmployeeProfileData
} from './employee.types'
import { PermissionGroup, type IPermissionGroup } from '../permission'
import { SiteAssignment, type ISiteAssignment } from '../site-management'

/**
 * A class representing an EmployeeProfile.
 */
export class EmployeeProfile extends AbstractModel implements IEmployeeProfile {
  readonly id?: number
  readonly phone: string
  readonly security_license: number | null
  readonly title: string
  readonly site_assignments: ISiteAssignment[] = []

  constructor(data: IEmployeeProfileData) {
    super()

    // Validate data
    const requiredFields: (keyof IEmployeeProfileData)[] = ['phone']
    this.validate(data, requiredFields)

    this.phone = data.phone!

    if (data.id) {
      this.id = data.id
    }

    this.security_license = data.security_license ?? null
    this.title = data.title ?? ''

    if (data.site_assignments) {
      this.site_assignments = data.site_assignments.map(
        (assignment) => new SiteAssignment(assignment)
      )
    }
  }
}

/**
 * A class representing a Employee.
 */
export class Employee extends User implements IEmployee {
  readonly profile: IEmployeeProfile
  readonly groups: (number | IPermissionGroup)[] = []

  constructor(data: IEmployeeData) {
    super(data)

    // Validate data
    const requiredFields: (keyof IEmployeeData)[] = ['profile']
    this.validate(data, requiredFields)

    this.profile = new EmployeeProfile(data.profile!)

    if (data.groups) {
      this.groups = data.groups.map((group) => {
        return typeof group !== 'number' ? new PermissionGroup(group) : group
      })
    }
  }
}

import { AbstractModel } from '@/models/base'

import { type IReportState, ReportStateEnum } from '@/models/report'

import { Client, Site, type IClient, type ISite } from '@/models/client'

import type {
  IIncidentReport,
  IIncidentReportActivity,
  IIncidentReportActivityData,
  IIncidentReportActivityObservation,
  IIncidentReportActivityObservationData,
  IIncidentReportData,
  IIncidentReportObservationAttachment,
  IIncidentReportObservationAttachmentData,
  IIncidentReportStatistics,
  IIncidentReportStatisticsData
} from './incident-report.types'
import { Employee, type IEmployee } from '@/models/employee'

export class IncidentReport extends AbstractModel implements IIncidentReport {
  readonly id?: number
  readonly report_id?: string
  readonly owner?: string
  readonly client?: number | IClient
  readonly site: number | ISite
  readonly state?: IReportState
  readonly created?: Date
  readonly modified?: Date
  readonly activities: IIncidentReportActivity[] = []
  readonly incident_level: number
  readonly approvers: IEmployee[] = []

  constructor(data: IIncidentReportData) {
    super()

    // Validate data
    const requiredFields: (keyof IIncidentReportData)[] = ['site', 'incident_level']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid

    this.site = typeof data.site! === 'number' ? data.site! : new Site(data.site!)
    this.incident_level = data.incident_level!

    if (data.id) {
      this.id = data.id
    }

    if (data.client) {
      this.client = typeof data.client === 'number' ? data.client! : new Client(data.client!)
    }

    if (data.activities) {
      this.activities = data.activities.map((activity) => {
        return new IncidentReportActivity(activity)
      })
    }

    if (data.state) {
      this.state = data.state
    }

    if (data.created) {
      this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
    }

    if (data.modified) {
      this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
    }

    if (data.report_id) {
      this.report_id = data.report_id
    }

    if (data.owner) {
      this.owner = data.owner
    }

    if (data.approvers) {
      this.approvers = data.approvers.map((approver) => {
        return new Employee(approver)
      })
    }
  }

  canSubmit(): boolean {
    return this.state?.value == ReportStateEnum.Draft
  }

  canApprove(): boolean {
    return this.state?.value == ReportStateEnum.Submitted
  }

  toString() {
    return `${this.report_id} - ${this.client?.toString()}`
  }
}

export class IncidentReportActivity implements IIncidentReportActivity {
  readonly id?: number
  readonly observations: IIncidentReportActivityObservation[] = []
  readonly activity_time?: Date
  readonly created_by?: string
  readonly modified_by?: string
  readonly created?: Date
  readonly modified?: Date

  constructor(data?: IIncidentReportActivityData) {
    if (data) {
      if (data.id) {
        this.id = data.id
      }

      if (data.observations) {
        this.observations = data.observations.map(
          (observation) => new IncidentReportActivityObservation(observation)
        )
      }

      if (data.activity_time) {
        this.activity_time =
          typeof data.activity_time === 'string' ? new Date(data.activity_time) : data.activity_time
      }

      if (data.created_by) {
        this.created_by = data.created_by
      }

      if (data.modified_by) {
        this.modified_by = data.modified_by
      }

      if (data.created) {
        this.created = typeof data.created === 'string' ? new Date(data.created) : data.created
      }

      if (data.modified) {
        this.modified = typeof data.modified === 'string' ? new Date(data.modified) : data.modified
      }
    }
  }
}

export class IncidentReportActivityObservation
  extends AbstractModel
  implements IIncidentReportActivityObservation
{
  readonly id?: number
  readonly text: string
  readonly attachments: IIncidentReportObservationAttachment[] = []
  readonly location: string
  readonly reporter?: string

  constructor(data: IIncidentReportActivityObservationData) {
    super()

    // Validate data
    const requiredFields: (keyof IIncidentReportActivityObservationData)[] = ['text', 'location']
    this.validate(data, requiredFields)

    if (data.id) {
      this.id = data.id
    }

    this.text = data.text!

    this.location = data.location!

    if (data.attachments) {
      this.attachments = data.attachments.map((activity) => {
        return new IncidentReportObservationAttachment(activity)
      })
    }

    if (data.reporter) {
      this.reporter = data.reporter
    }
  }
}

export class IncidentReportObservationAttachment
  extends AbstractModel
  implements IIncidentReportObservationAttachment
{
  readonly id?: number
  readonly file: string | File

  constructor(data: IIncidentReportObservationAttachmentData) {
    super()

    // Validate data
    const requiredFields: (keyof IIncidentReportObservationAttachmentData)[] = ['file']
    this.validate(data, requiredFields)

    if (data.id) {
      this.id = data.id
    }

    this.file = data.file!
  }
}

export class IncidentReportStatistics implements IIncidentReportStatistics {
  draft_count: number = 0
  approval_pending_count: number = 0
  waiting_submission_count: number = 0
  waiting_approval_count: number = 0
  recently_created_count: number = 0

  constructor(data?: IIncidentReportStatisticsData) {
    if (data) {
      if (data.draft_count) {
        this.draft_count = data.draft_count
      }

      if (data.approval_pending_count) {
        this.approval_pending_count = data.approval_pending_count
      }

      if (data.waiting_submission_count) {
        this.waiting_submission_count = data.waiting_submission_count
      }

      if (data.waiting_approval_count) {
        this.waiting_approval_count = data.waiting_approval_count
      }

      if (data.recently_created_count) {
        this.recently_created_count = data.recently_created_count
      }
    }
  }
}

import { AbstractModel } from '../base'
import type { ILogEntry, ILogEntryData } from './log-entry.types'

export class LogEntry extends AbstractModel implements ILogEntry {
  readonly id: number
  readonly action: string
  readonly actor: string | null = null //TODO: 2024-03-15 - LH - only null because backend has null bug
  readonly changes: Record<string, any>
  readonly timestamp: Date
  readonly additional_data: Record<string, any> | null = null //TODO: 2024-03-15  - maybe expect additional data to be null

  constructor(data: ILogEntryData) {
    super()

    // Validate data
    const requiredFields: (keyof ILogEntryData)[] = ['id', 'action', 'changes', 'timestamp']
    this.validate(data, requiredFields)

    this.id = data.id!
    this.action = data.action!

    if (data.actor) {
      this.actor = data.actor!
    }

    this.changes = data.changes!
    this.timestamp = typeof data.timestamp === 'string' ? new Date(data.timestamp) : data.timestamp!

    if (data.additional_data) {
      this.additional_data = data.additional_data
    }
  }
}

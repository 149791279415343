import axios, { AxiosError, type AxiosResponse } from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import axiosRetry from 'axios-retry'

// import router, { resetRouter } from '@/router'

import { useAuthStore } from './stores'
import { SystemError, type ISystemError, type ISystemErrorData, FieldErrors } from './models/error'

const config = {
  baseURL: import.meta.env.VITE_APP_BASE_API_URL,
  timeout: 30000, // 30 seconds -- timeout in ms
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
}

const instance = axios.create(config)

// Axios retry on error functionality
axiosRetry(instance, {
  retries: 2,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: axiosRetry.isSafeRequestError
})

// Refresh authorization token functionality

// LF-2023-11-02: Intentionally disabling no-unused-vars incase
// in future we would like to process these function arguments
// Note: A separate instance of axios is required otherwise we end
// up in an infinite loop as the interceptor continuously triggers.
// See https://stackoverflow.com/questions/65789806/axios-dosent-catch-error-with-axios-auth-refresh-library
const refreshAuth = (failedRequest: AxiosError) =>
  axios
    .post(`auth/token/refresh/`, undefined, config)
    .then(() => {
      return Promise.resolve()
    })
    .catch(() => {
      // Refreshing token failed. Redirect user to login page
      const authStore = useAuthStore()

      // Note: Calling logout to clear store although the
      // logout API call will fail since token is invalid
      authStore.logout().catch(() => {
        // Logout is expected to fail here because token invalid
        // Catching failure, but not having any specific handling
      })

      // resetRouter()

      // TODO: LF-2024-01-03 - This is causing issue going to the forget password page
      // router.push({
      //   name: 'login',
      //   query: { redirect: router.currentRoute.value.path }
      // })
      return Promise.reject(failedRequest)
    })

// Instantiate the interceptor
createAuthRefreshInterceptor(instance, refreshAuth)

function httpErrorHandler(error: any): ISystemError {
  const systemErrorData: ISystemErrorData = {
    name: error.name,
    message: error.message
  }

  if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      systemErrorData.message = error.response.statusText
      if (error.response.data.detail) {
        systemErrorData.detail = error.response.data.detail
      } else if (FieldErrors.isFieldError(error.response.data)) {
        systemErrorData.fieldErrors = new FieldErrors(error.response.data)
      }
    } else if (error.request) {
      // The request was made but no response was received (Can happen on 500 error)
      systemErrorData.detail =
        'There was an error in processing your request. Please check with IT Admin'
    }
  }

  return new SystemError(systemErrorData)
}

// Axios Response interceptor
instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    // TODO: LF-2023-12-31 - Somehow on 401 where we refresh token, this error block in
    // response interceptor is being called twice. One with the original error followed by
    // the SystemError. Need to investigate why the SystemError is being passed here
    // Added a temporary hack to just return the system error directly
    return Promise.reject(error instanceof SystemError ? error : httpErrorHandler(error))
  }
)

export const isAxios401Error = (error: any): boolean => {
  return axios.isAxiosError(error) && !!error.response && error.response.status === 401
}

export default instance

import { AbstractModel } from '../base'
import type {
  IGeoJson,
  IGeoJsonData,
  IGeoJsonFeature,
  IGeoJsonFeatureData,
  IGeoJsonGeometry,
  IGeoJsonGeometryData
} from './geo-json.types'

export class GeoJson extends AbstractModel implements IGeoJson {
  readonly type: string
  readonly features: IGeoJsonFeature[] = []

  constructor(data: IGeoJsonData) {
    super()

    // Validate data
    const requiredFields: (keyof IGeoJsonData)[] = ['type', 'features']
    this.validate(data, requiredFields)

    this.type = data.type
    this.features = data.features.map((feature) => new GeoJsonFeature(feature))
  }
}

export class GeoJsonFeature extends AbstractModel implements IGeoJsonFeature {
  readonly type: string
  readonly geometry: IGeoJsonGeometry
  readonly properties: any

  constructor(data: IGeoJsonFeatureData) {
    super()

    // Validate data
    const requiredFields: (keyof IGeoJsonFeatureData)[] = ['type', 'geometry', 'properties']
    this.validate(data, requiredFields)

    this.type = data.type
    this.geometry = new GeoJsonGeometry(data.geometry)
    this.properties = data.properties
  }
}

export class GeoJsonGeometry extends AbstractModel implements IGeoJsonGeometry {
  readonly type: string
  readonly coordinates: number[] = []

  constructor(data: IGeoJsonGeometryData) {
    super()

    // Validate data
    const requiredFields: (keyof IGeoJsonGeometryData)[] = ['type', 'coordinates']
    this.validate(data, requiredFields)

    this.type = data.type
    this.coordinates = data.coordinates
  }
}

import type { ISiteAssignment, ISiteAssignmentData } from '.'
import { AbstractModel } from '../base'
import { Site, type ISite } from '../client'

export class SiteAssignment extends AbstractModel implements ISiteAssignment {
  readonly id?: number
  readonly is_site_approver: boolean
  readonly site: ISite

  constructor(data: ISiteAssignmentData) {
    super()

    // Validate data
    const requiredFields: (keyof ISiteAssignmentData)[] = ['is_site_approver', 'site']
    this.validate(data, requiredFields)

    this.is_site_approver = data.is_site_approver!
    this.site = new Site(data.site!)

    if (data.id) {
      this.id = data.id
    }
  }

  toString() {
    return this.site.toString()
  }
}

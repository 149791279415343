<template>
  <ConfirmationDialog width="470" title="Set User Password" v-model="dialog" v-model:error="error">
    <template #message> This will update the login credentials of this user </template>

    <template #activator="{ props }">
      <slot name="activator" v-bind="{ props }" />
    </template>
    <v-form ref="form" v-model="valid" validate-on="submit lazy" @submit.prevent="">
      <v-row class="my-1">
        <!-- optionally hidden username field https://goo.gl/9p2vKq -->
        <v-col class="visually-hidden">
          <v-text-field
            hidden
            :model-value="user.username"
            label="Username"
            name="username"
            variant="outlined"
            placeholder="admin"
            hide-details="auto"
            @input="clearErrors()"
            :error-messages="error && (error.fieldErrors['non_field_errors'] as string[])"
            autocomplete="username"
          />
        </v-col>
        <v-col>
          <v-text-field
            id="password-id"
            v-model="password"
            name="password"
            variant="outlined"
            label="Password"
            placeholder="············"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"
            @input="clearErrors()"
            :error-messages="error && (error.fieldErrors['password'] as string[])"
            hide-details="auto"
            autocomplete="new-password"
          />
        </v-col>
      </v-row>
    </v-form>

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn variant="flat" :disabled="isPending" @click="closeDialog()"> Cancel </v-btn>
      <v-btn color="error" variant="flat" :loading="isPending" @click="changeUserPassword()">
        Save
      </v-btn>
    </template>
  </ConfirmationDialog>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import type { IUser } from '@/models/user'
import type { VForm } from 'vuetify/components'

import ConfirmationDialog from '../common/ConfirmationDialog.vue'
import { useChangePasswordEmployee } from '@/composables/employee'

interface Props {
  user: IUser
}

const props = defineProps<Props>()

const dialog = defineModel<boolean>('dialog', { default: false })

const valid = ref(false)
const form = ref<VForm>()

const password = ref('')
const isPasswordVisible = ref(false)

function closeDialog() {
  dialog.value = false
}

watch(
  () => dialog.value,
  (value) => {
    if (value) {
      password.value = ''
      isPasswordVisible.value = false
    }
  }
)

const userId = computed(() => props.user.id!)
const { isPending, error, mutate, reset: clearErrors } = useChangePasswordEmployee(userId)

async function changeUserPassword() {
  clearErrors()
  const { valid } = await form.value!.validate()

  if (valid) {
    mutate(password.value, {
      onSuccess: () => {
        closeDialog()
      }
    })
  }
}
</script>

<template>
  <v-autocomplete
    label="Employee"
    v-model="employee"
    :items="employees"
    v-model:search="queryFilters.search"
    item-value="id"
    :item-title="
      (item: IEmployee) => {
        return item instanceof Employee ? item.getFullName() : item
      }
    "
    :loading="isLoading"
    :error-messages="combinedErrorMessages"
    no-filter
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { computed, type PropType, reactive } from 'vue'

import { useFetchEmployees } from '@/composables/employee'
import { isArray } from 'lodash'

import type { IEmployeeFilterParam } from '@/services'
import { Employee, type IEmployee } from '@/models/employee'

const props = defineProps({
  errorMessages: {
    type: [String, Array] as PropType<string | string[]>,
    default: () => []
  }
})

const employee = defineModel<null | number>({ default: null })
const queryFilters = reactive<IEmployeeFilterParam>({ search: '', page: 1 })

const { employees, error: fetchError, isLoading } = useFetchEmployees(queryFilters)

const combinedErrorMessages = computed(() => {
  let error: string[] = []

  if (fetchError.value) {
    error.push(fetchError.value.message)
  }
  if (isArray(props.errorMessages)) {
    error.concat(props.errorMessages)
  } else {
    error.push(props.errorMessages)
  }

  return error
})
</script>

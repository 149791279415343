import { AbstractModel } from '../base'
import type { ISampleObservation, ISampleObservationData } from './sample-observation.types'

export class SampleObservation extends AbstractModel implements ISampleObservation {
  readonly id?: number
  readonly text: string

  constructor(data: ISampleObservationData) {
    super()

    // Validate data
    const requiredFields: (keyof ISampleObservationData)[] = ['text']
    this.validate(data, requiredFields)

    // Initialize object assuming data valid
    this.text = data.text!

    if (data.id) {
      this.id = data.id
    }
  }
}

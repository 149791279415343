<template>
  <div class="d-flex">
    <v-btn
      v-if="canSubmit"
      class="mr-3 text-capitalize"
      color="primary"
      flat
      :loading="loading"
      @click="emit('submitted')"
      >Submit</v-btn
    >

    <CCTVReportActionsMenuBtn
      :report-state="ReportStateEnum.Draft"
      :disabled="loading"
      @edit-report-pressed="emit('edit-report-pressed')"
      @delete-report-pressed="emit('delete-report-pressed')"
    />
  </div>
</template>

<script setup lang="ts">
import { ReportStateEnum } from '@/models/report'

import CCTVReportActionsMenuBtn from '../CCTVReportActionsMenuBtn.vue'

interface Props {
  canSubmit: boolean
  loading: boolean
}
defineProps<Props>()

interface Emit {
  (e: 'submitted'): void
  (e: 'edit-report-pressed'): void
  (e: 'delete-report-pressed'): void
}
const emit = defineEmits<Emit>()
</script>

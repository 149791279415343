<template>
  <v-dialog v-model="dialog" max-width="650" persistent>
    <AlFormCard
      title="Observation Report"
      :subtitle="`${isEdit ? 'Edit' : 'Create'}  Observation Report`"
    >
      <template #error>
        <ErrorAlert v-if="error != null" :error="error" @clearErrors="clearErrors()" class="mb-2">
        </ErrorAlert>
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form ref="form" v-model="valid" validate-on="submit lazy">
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="observationFormData.incident_level"
              label="Incident Level"
              variant="outlined"
              hide-details="auto"
              :items="incidentStatusVisuals"
              @update:model-value="checkIncidentReport"
              item-title="display"
              item-value="state"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['incident_level'] as string[])"
              chips
              :disabled="isEdit"
            >
              <template #chip="{ item, props }">
                <v-list-item
                  density="compact"
                  class="pl-2 py-0"
                  :title="item.raw.display"
                  v-bind="props"
                  :style="`border-left: solid ${
                    item.raw.color || getReportIncidentStatusVisualByState(item.raw.state)?.color
                  } 8px;`"
                ></v-list-item>
              </template>
              <template #item="{ item, index, props }">
                <v-list-item
                  v-bind="props"
                  :class="{ 'mb-2': index !== incidentStatusVisuals.length - 1 }"
                  :style="`border-left: solid ${item.raw.color} 8px;`"
                >
                </v-list-item>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            v-if="observationIncidentReportLevelValue > ReportActivityStatusEnum.SecurityLevel1"
          >
            <v-radio-group
              inline
              hide-details="auto"
              v-model="incidentReportLinkChoice"
              :rules="[reportChoiceValidator]"
            >
              <v-radio label="Link existing incident report" value="link_report"></v-radio>
              <v-radio
                label="Create new incident report"
                value="new_report"
                @click="observationFormData.incident_report = null"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-expand-transition mode="out-in">
            <v-col
              cols="12"
              v-if="
                observationIncidentReportLevelValue > ReportActivityStatusEnum.SecurityLevel1 &&
                incidentReportLinkChoice == 'link_report'
              "
            >
              <IncidentReportAutoCompletePicker
                v-model="observationFormData.incident_report"
                v-model:search="incidentReportSearchQuery"
                hide-details="auto"
                @update:model-value="
                  (value) => {
                    observationFormData.incident_report = value as number | null
                    clearErrors()
                  }
                "
                :rules="[requiredValidator]"
                :error-messages="
                  (error?.fieldErrors['incident_report'] as string[]) ||
                  createIncidentReportError?.message
                "
                :readonly="observationFormData.incident_report != null"
                clearable
                :filter="{ site: (report.site as ISite).code }"
                :persistent-hint="!!observationFormData.incident_report"
                :hint="
                  !!observationFormData.incident_level
                    ? `An Incident report will be created with ${getReportIncidentStatusVisualByState(observationIncidentReportLevelValue)?.display}`
                    : ''
                "
              />
            </v-col>
          </v-expand-transition>

          <v-col cols="12">
            <v-text-field
              v-model="observationFormData.checkpoint"
              label="Checkpoint"
              variant="outlined"
              hide-details="auto"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['camera'] as string[])"
              clearable
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <ReportsObservationTextarea
              v-model="observationFormData.text"
              label="Notes"
              variant="outlined"
              hide-details="auto"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['text'] as string[])"
              clearable
              no-resize
            >
            </ReportsObservationTextarea>
          </v-col>

          <v-col cols="12">
            <FileClipboardInput
              ref="fileClipboardRef"
              v-model="observationFormData.file"
              @update:model-value="clearErrors()"
              :rules="requiredFileRule"
              :error-messages="error && (error.fieldErrors['file'] as string[])"
              clearable
            />
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { VForm } from 'vuetify/components'
import { storeToRefs } from 'pinia'

import { cloneDeep } from 'lodash'
import { diffDeep } from '@/utils/helpers'
import { reportChoiceValidator, requiredValidator, type ReportLinkChoice } from '@/utils/validators'

import { useReportStore } from '@/stores'

import type { ISystemError } from '@/models/error'
import {
  type IGuardReport,
  type IGuardReportActivityObservation,
  type IGuardReportActivityObservationData,
  GuardReportActivityObservation,
  ReportActivityStatusEnum,
  type IReportIncidentStatusData
} from '@/models/report'
import type { ISite } from '@/models/client'

import {
  useCreateGuardReportActivityObservation,
  useUpdateGuardReportActivityObservation
} from '@/composables/guard-report'

import AlFormCard from '@/components/common/AlFormCard.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import FileClipboardInput from '@/components/common/FileClipboardInput.vue'
import ReportsObservationTextarea from '../common/ReportsObservationTextarea.vue'
import IncidentReportAutoCompletePicker from '../incident/IncidentReportAutoCompletePicker.vue'
import { useCreateIncidentReport } from '@/composables/incident-report'

interface Props {
  report: IGuardReport
  activityId: number
  observation?: IGuardReportActivityObservation
  isEdit: boolean
}
const props = defineProps<Props>()
const dialog = defineModel<boolean>('dialog')

interface Emits {
  (e: 'saved-activity-observation'): void
  (
    e: 'incident-report-created',
    payload: {
      observation: IGuardReportActivityObservation
      incidentReportId: number
      fileAttachment?: File | string
    }
  ): void
}
const emit = defineEmits<Emits>()

const observationFormData = ref<IGuardReportActivityObservationData>({})
const incidentReportSearchQuery = ref('')

const reportStore = useReportStore()
const { incidentStatusVisuals, getReportIncidentStatusVisualByState } = storeToRefs(reportStore)

const observationIncidentReportLevelValue = computed(() => {
  return (
    (observationFormData.value.incident_level as IReportIncidentStatusData)?.value ??
    (observationFormData.value.incident_level as number)
  )
})

const form = ref<VForm>()

const requiredFileRule = [
  () => {
    let isValid = observationFormData.value.file instanceof File || !!observationFormData.value.file
    return isValid || 'This field is required'
  }
]

const valid = ref(false)

const incidentReportLinkChoice = ref<ReportLinkChoice>('link_report')
watch(
  dialog,
  (value) => {
    if (value) {
      observationFormData.value = props.observation ? cloneDeep(props.observation) : {}
      incidentReportSearchQuery.value = ''
      if (observationFormData.value.incident_report) {
        incidentReportLinkChoice.value = 'link_report'
      } else {
        incidentReportLinkChoice.value = 'no_report'
      }
    }
  },
  { immediate: true }
)

function checkIncidentReport(value: null | number | IReportIncidentStatusData) {
  if (value) {
    const incidentLevel = (value as IReportIncidentStatusData)?.value
      ? (value as IReportIncidentStatusData).value!
      : (value as number)

    if (incidentLevel == ReportActivityStatusEnum.SecurityLevel1) {
      incidentReportLinkChoice.value = 'link_report'
    } else {
      incidentReportLinkChoice.value = 'no_report'
    }
  }
  clearErrors()
}

function closeDialog() {
  clearErrors()
  dialog.value = false
}

const activityId = computed(() => props.activityId)
// keep activityId a ref of the property to ensure the latest value is always being passed in
const updateMutation = useUpdateGuardReportActivityObservation(props.report.id!, activityId)
const createMutation = useCreateGuardReportActivityObservation(props.report.id!, activityId)

const loading = computed(() =>
  props.isEdit ? updateMutation.isPending.value : createMutation.isPending.value
)
const mutationError = computed(() =>
  props.isEdit ? updateMutation.error.value : createMutation.error.value
)

const error = ref<ISystemError | null>(null)

// update error to crud action error display
watch(mutationError, (value) => {
  error.value = value
})

function clearErrors() {
  props.isEdit ? updateMutation.reset() : createMutation.reset()
  form.value?.resetValidation()

  if (createIncidentReportError.value) {
    createIncidentReportErrorReset()
  }
}

const {
  error: createIncidentReportError,
  mutateAsync: mutateCreateIncidentReport,
  reset: createIncidentReportErrorReset
} = useCreateIncidentReport()

function createIncidentReport() {
  return mutateCreateIncidentReport({
    site: (props.report.site as ISite)!.code,
    // Can be an object or number depending on the operation being done
    incident_level:
      (observationFormData.value.incident_level as IReportIncidentStatusData)?.value ??
      (observationFormData.value.incident_level as number)
  })
}

async function save() {
  clearErrors()

  const { valid } = await form.value!.validate()

  if (valid) {
    let observationInstance: IGuardReportActivityObservation | null = null

    // if creating the incident report passes then save the observation
    try {
      observationInstance = new GuardReportActivityObservation(observationFormData.value)
    } catch (e: any) {
      console.warn(e)

      error.value = e
    }

    if (observationInstance != null) {
      const payload = props.isEdit
        ? diffDeep(observationInstance, props.observation, true)
        : observationInstance

      const saveAction = props.isEdit ? updateMutation.mutate : createMutation.mutate

      saveAction(payload, {
        onSuccess: async (data) => {
          if (incidentReportLinkChoice.value == 'new_report') {
            const incidentReport = await createIncidentReport()

            // wait for update of the updateMutation then move forward
            await updateMutation.mutateAsync(
              {
                id: data.id,
                incident_report: incidentReport.id
              },
              {
                onSuccess: () => {
                  emit('incident-report-created', {
                    observation: new GuardReportActivityObservation(data),
                    incidentReportId: incidentReport.id!,
                    fileAttachment: observationInstance!.file as File | string
                  })
                }
              }
            )
          }
          closeDialog()
          emit('saved-activity-observation')
        }
      })
    }
  }
}
</script>

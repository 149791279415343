<template>
  <v-tooltip location="left" open-delay="250" :disabled="!camera.notes">
    <template #activator="{ props }">
      <v-list-item
        v-bind="props"
        variant="flat"
        density="compact"
        slim
        @click="emit('camera-selected', camera)"
      >
        <template #prepend>
          <v-icon style="margin-top: -2px" size="22" icon="mdi-cctv" />
        </template>

        <v-list-item-title class="font-weight-regular">
          {{ camera.name }}
        </v-list-item-title>

        <template #append>
          <MoreOptionsMenuButton color="medium-emphasis" class="ml-auto mt-n3" :size="26">
            <v-list-item
              v-if="currentUser?.hasPermission('cameras.change_camera')"
              @click="emit('camera-edit-pressed', camera)"
              title="Edit Camera"
            />

            <v-divider class="mb-1" />

            <ConfirmationDialog
              width="auto"
              title="Delete camera"
              v-model="deleteDialog"
              v-model:error="deleteError"
            >
              <template #activator="{ props }">
                <v-list-item
                  v-if="currentUser?.hasPermission('cameras.delete_camera')"
                  title="Delete Camera"
                  class="text-error"
                  v-bind="props"
                />
              </template>

              <template #message>
                Are you sure you want to delete
                <span class="text-medium-high-emphasis font-weight-bold">{{ camera.name }}</span
                >?
                <br />
                <div class="pt-2">This action cannot be undone.</div>
                .
              </template>

              <template #actions>
                <v-spacer></v-spacer>
                <v-btn variant="flat" :disabled="deleteInProgress" @click="closeDeleteDialog()">
                  Cancel
                </v-btn>
                <v-btn
                  color="error"
                  variant="flat"
                  :loading="deleteInProgress"
                  @click="deleteSelectedCamera(camera.id!)"
                >
                  Delete
                </v-btn>
              </template>
            </ConfirmationDialog>
          </MoreOptionsMenuButton>
        </template>
      </v-list-item>
    </template>
    <span>{{ camera.notes }}</span>
  </v-tooltip>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/stores'
import { useDeleteCameras } from '@/composables/camera'

import type { ICamera } from '@/models/camera'

import ConfirmationDialog from '../common/ConfirmationDialog.vue'
import MoreOptionsMenuButton from '../common/MoreOptionsMenuButton.vue'

interface Props {
  camera: ICamera
}

defineProps<Props>()

interface Emit {
  (e: 'camera-selected', camera: ICamera): void
  (e: 'camera-edit-pressed', camera: ICamera): void
}
const emit = defineEmits<Emit>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const deleteDialog = ref(false)
function closeDeleteDialog() {
  deleteDialog.value = false
}
const { loading: deleteInProgress, error: deleteError, deleteCamera } = useDeleteCameras()

function deleteSelectedCamera(cameraId: number) {
  return deleteCamera(cameraId).then(() => {
    closeDeleteDialog()
  })
}
</script>

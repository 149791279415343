import { isEmpty, isUndefined, isNull } from 'lodash'

export const requiredValidator = (value: unknown) => {
  if (
    isUndefined(value) ||
    isNull(value) ||
    (Array.isArray(value) && value.length === 0) ||
    value === false
  )
    return 'This field is required'

  return !!String(value).trim().length || 'This field is required'
}

export const emailValidator = (value: unknown) => {
  if (isEmpty(value)) return true

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value))
    return value.every((val) => re.test(String(val))) || 'The Email field must be a valid email'

  return re.test(String(value)) || 'The Email field must be a valid email'
}

export type ReportLinkChoice = 'link_report' | 'new_report' | 'no_report'

export const reportChoiceValidator = (value: ReportLinkChoice) => {
  switch (value) {
    case 'link_report':
    case 'new_report':
      return true
    default:
      return 'This field is required'
  }
}

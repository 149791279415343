<template>
  <v-list-subheader class="site-subheader d-inline-flex align-center font-weight-medium mb-2 px-6">
    <div class="text-subtitle-2 d-inline-flex align-center justify-start">
      <div class="me-2 align-self-center text-high-emphasis">Contacts</div>

      <v-card
        variant="tonal"
        width="24"
        height="24"
        style="display: inline-grid"
        class="align-center justify-center text-caption font-weight-bold"
      >
        <span v-if="!loading">
          {{ contacts.length }}
        </span>
        <span v-else> 0 </span>
      </v-card>
    </div>

    <div class="ml-auto">
      <v-fade-transition mode="out-in">
        <v-progress-circular v-if="loading" indeterminate color="primary" width="2" size="20" />
        <div v-else-if="actionable && currentUser?.hasPermission('contacts.add_contact')">
          <v-tooltip location="bottom" open-delay="450">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="action-btn rounded"
                variant="text"
                color="primary"
                size="32"
                icon
                density="comfortable"
                @click="openContactFormDialog()"
              >
                <v-icon size="18" icon="mdi-plus" />
              </v-btn>
            </template>
            Add Contact
          </v-tooltip>
        </div>
      </v-fade-transition>
    </div>
  </v-list-subheader>
  <v-expand-transition>
    <div v-show="!loading">
      <div class="d-flex flex-column px-6">
        <template v-if="contacts.length > 0">
          <v-list class="contact-list px-0 rounded-lg mb-6" variant="flat" nav>
            <v-item-group multiple class="d-flex flex-column">
              <template v-for="(contact, contactIndex) in contacts" :key="contactIndex">
                <v-item v-slot="{ isSelected, select }">
                  <ContactDrawerListItem
                    :actionable="actionable"
                    :contact="contact"
                    :is-selected="isSelected"
                    @contact-selected="select"
                    @contact-edit-pressed="openContactFormDialog"
                    @contact-create-pressed="openContactFormDialog()"
                  />
                </v-item>
              </template>
            </v-item-group>
          </v-list>
        </template>
        <template v-else>
          <NoDataAvailablePlaceholder width="300" header="Add a contact here" class="mt-0 mb-0">
            <template #description>
              <p class="mt-0 mb-6 text-caption text-medium-emphasis">
                <span v-if="currentUser?.hasPermission('contacts.add_contact')">
                  Looks like you haven't added any contacts yet. Get started by creating a new
                  contact or explore our features to enhance your client experience!
                </span>
                <span v-else>
                  It seems you currently don't have the necessary permissions to add a new contact.
                  To request access or for further assistance, please contact your system
                  administrator.
                </span>
              </p>
            </template>

            <template
              #primaryAction
              v-if="actionable && currentUser?.hasPermission('contacts.add_contact')"
            >
              <v-btn @click="openContactFormDialog()">Add Contact</v-btn>
            </template>
          </NoDataAvailablePlaceholder>
        </template>

        <ContactFormDialog
          v-model:dialog="isContactFormDialogActive"
          :contact="selectedContact"
          :isEdit="!!selectedContact"
          :client-id="client.id!"
        />
      </div>
    </div>
  </v-expand-transition>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores'

import type { IClient } from '@/models/client'
import type { IContact } from '@/models/contact'

import NoDataAvailablePlaceholder from '../common/NoDataAvailablePlaceholder.vue'
import ContactDrawerListItem from './ContactDrawerListItem.vue'
import ContactFormDialog from './ContactFormDialog.vue'

interface Props {
  client: IClient
  contacts: IContact[]
  actionable?: boolean
  loading: boolean
}

withDefaults(defineProps<Props>(), {
  actionable: false
})

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const isContactFormDialogActive = ref(false)
const selectedContact = ref<IContact>()

function openContactFormDialog(contact?: IContact) {
  selectedContact.value = contact

  isContactFormDialogActive.value = true
}
</script>

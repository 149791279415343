<template>
  <v-list nav class="d-flex flex-column rounded rounded-t-0 pt-0 px-0 mb-2">
    <div class="mb-2" key="create-camera" v-if="currentUser?.hasPermission('cameras.add_camera')">
      <v-btn
        class="border rounded"
        height="40"
        variant="outlined"
        flat
        color="primary"
        prependIcon="mdi-plus"
        block
        @click="openCameraFormDialog()"
      >
        Add Camera
      </v-btn>
    </div>

    <CameraListItem
      v-for="(camera, cameraIndex) in cameras"
      :key="`camera-list-${cameraIndex}`"
      :camera="camera"
      @camera-edit-pressed="openCameraFormDialog"
    />
    <CameraFormDialog
      v-model:dialog="isCameraFormDialogActive"
      :camera="selectedCamera"
      :isEdit="!!selectedCamera"
      :client-id="clientId"
      :site-code="site.code!"
    />
  </v-list>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores'

import type { ICamera } from '@/models/camera'

import CameraListItem from './CameraListItem.vue'
import CameraFormDialog from './CameraFormDialog.vue'
import type { ISite } from '@/models/client'

interface Props {
  cameras: ICamera[]
  site: ISite
  clientId: number
}

defineProps<Props>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const isCameraFormDialogActive = ref(false)
const selectedCamera = ref<ICamera>()

function openCameraFormDialog(camera?: ICamera) {
  selectedCamera.value = camera

  isCameraFormDialogActive.value = true
}
</script>

import { defineStore } from 'pinia'

import { Contact, type IContactData } from '@/models/contact'
import contactService from '@/services/contact/contact'

import type { IContactFilterParam } from '@/services'

import type { IContactState } from './contact.types'
// Pinia store for managing contact-related states and actions
export const useContactStore = defineStore('contact', {
  state: () =>
    ({
      count: 0,
      contacts: [],
      lastPromise: null
    }) as IContactState,

  getters: {
    // Getter to retrieve contacts from the store
    getContacts: (state: IContactState) => {
      return state.contacts.map((contact) => new Contact(contact))
    },

    // Getter to retrieve a contact by ID from the store,
    getContactById: (state: IContactState) => (id: number) => {
      const contact = state.contacts.find((contact) => contact.id === id)
      if (!contact) {
        return undefined
      }
      return new Contact(contact)
    }
  },

  actions: {
    // Action method to fetch list of contacts and update the store
    async fetchContacts(filter?: IContactFilterParam): Promise<void> {
      const promise = contactService.fetchContacts(filter)
      this.lastPromise = promise

      return promise.then((data) => {
        if (promise == this.lastPromise) {
          this.count = data.count
          this.contacts = data.results

          this.lastPromise = null
        }
      })
    },

    // Action method to fetch a single contact and update the store
    async fetchContact(id: number): Promise<void> {
      const contact = await contactService.fetchContact(id)

      const index = this.contacts.findIndex((v) => v.id === id)
      if (index === -1) {
        this.contacts.push(contact)
      } else {
        this.contacts[index] = contact
      }
    },

    // Action method to create a new contact
    async createContact(data: IContactData): Promise<void> {
      const contact = await contactService.createContact(data)
      this.contacts.push(contact)
    },

    // Action method to update an existing contact
    async updateContact(data: IContactData): Promise<void> {
      const contact = await contactService.updateContact(data)
      const index = this.contacts.findIndex((v) => v.id === data.id)
      this.contacts[index] = contact
    },

    // Action method to delete an existing contact
    async deleteContact(id: number): Promise<void> {
      await contactService.deleteContact(id)

      const index = this.contacts.findIndex((v) => v.id === id)
      if (index !== -1) {
        this.contacts.splice(index, 1)
      }
    }
  }
})

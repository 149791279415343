import { defineStore } from 'pinia'

import { Country, countries } from '@/models/country'

import type { ICountryState } from './country.types'

// Pinia store for managing country-related states and actions
export const useCountryStore = defineStore('country', {
  state: () =>
    ({
      count: 0,
      countries
    }) as ICountryState,

  getters: {
    // Getter to retrieve countries from the store
    getCountries: (state: ICountryState) => {
      return state.countries.map((country) => new Country(country))
    },

    // Getter to retrieve a country by Code from the store,
    getCountryByCode: (state: ICountryState) => (code: string) => {
      const country = state.countries.find((country) => country.code === code)
      if (!country) {
        return undefined
      }
      return new Country(country)
    }
  }
})

<template>
  <v-form ref="form" v-model="valid" validate-on="input">
    <v-label for="report-site" text="Sites" class="ml-2 text-body-2 text-high-emphasis" />
    <v-sheet class="overflow-y-auto" max-height="220">
      <v-radio-group
        v-model="siteModel"
        id="report-site"
        @update:selected="clearErrors()"
        hide-details="auto"
        class="pt-0"
        :rules="[requiredValidator, siteValidator]"
        :error-messages="error?.message"
      >
        <v-radio
          v-for="(siteValue, index) in sites"
          :value="siteValue.code"
          :key="index"
          :label="siteValue.address.getAddressString()"
          color="secondary"
        >
        </v-radio>
      </v-radio-group>
    </v-sheet>
  </v-form>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { watch } from 'vue'

import { requiredValidator } from '@/utils/validators'

import type { VForm } from 'vuetify/components'
import type { ISite, ISiteData } from '@/models/client'
import type { ISystemError } from '@/models/error'

const site = defineModel<ISiteData | number | null>({ default: null })

const siteModel = computed<ISiteData | number | null>({
  get() {
    return (site.value as ISiteData)?.code ?? site.value
  },
  set(value: ISiteData | number | null) {
    site.value = value
  }
})

interface Props {
  sites: ISite[]
  error: ISystemError | null
}

const props = defineProps<Props>()

const form = ref<VForm>()

const valid = ref(false)

function clearErrors() {
  form.value?.resetValidation()
}

function validate() {
  return form.value!.validate()
}

watch(
  () => props.sites,
  (value) => {
    // Update model with new site
    const firstSite = value[0]
    if (firstSite && !siteModel.value) {
      siteModel.value = firstSite.code!
    }
  },
  { immediate: true }
)

/**
 * Validates that the site selected is in the list of sites props
 * @param value
 */
const siteValidator = (value: ISite | number) => {
  const site = props.sites.find((site) => {
    const valueCode = typeof value == 'number' ? value : value.code
    return site.code === valueCode
  })

  return !!site || 'Select a site from the options above'
}

defineExpose({ validate, clearErrors })
</script>

<template>
  <AlDialog
    v-model="dialog"
    title="Employee Site Access"
    subtitle="Give your employee access to view specific TAG sites"
    :error="error"
    @clearErrors="clearErrors()"
    :width="864"
  >
    <EmployeeDetailSitesForm
      class="mb-4"
      :employee-id="employeeId"
      ref="employeeDetailSitesFormRef"
      @save="closeDialog()"
    />

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" variant="flat" :loading="isPending" @click="saveEmployeeSites()"
        >Save</v-btn
      >
    </template>
  </AlDialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { ISystemError } from '@/models/error'

import AlDialog from '@/components/common/AlDialog.vue'
import EmployeeDetailSitesForm from './EmployeeDetailSitesForm.vue'

interface Props {
  employeeId: number
}

const dialog = defineModel<boolean>('dialog', { default: false })

const props = defineProps<Props>()

const employeeId = computed(() => props.employeeId)

const employeeDetailSitesFormRef = ref<typeof EmployeeDetailSitesForm>()

const isPending = computed<boolean>(() => employeeDetailSitesFormRef.value?.isPending)
const error = computed<ISystemError | null>(() => employeeDetailSitesFormRef.value?.error)

function clearErrors() {
  employeeDetailSitesFormRef.value?.clearErrors()
}
function saveEmployeeSites() {
  return employeeDetailSitesFormRef.value?.saveEmployeeSites()
}

function closeDialog() {
  dialog.value = false
}
</script>

<style lang="scss">
.al-form-card {
  padding: 1rem;
}
</style>

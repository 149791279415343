import { ref, toRef, type MaybeRef } from 'vue'

import type { IClientFilterParam } from '@/services'
import { useSnackbarStore } from '@/stores/snackbar'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import clientService from '@/services/client/client'
import { Client, type IClientData } from '@/models/client'

export function useFetchClients(filter?: IClientFilterParam) {
  const queryKey = ref(['clients', filter])

  const count = ref<number>(0)

  const {
    isLoading,
    isError,
    error,
    data: clients
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => clientService.fetchClients(filter),

    select: (data) => {
      count.value = data.count
      return data.results.map((client) => new Client(client))
    }
  })

  return { queryKey, clients, count, error, isError, isLoading }
}

export function useFetchClient(id: MaybeRef<number>) {
  const clientIdRef = toRef(id)

  const queryKey = ref(['client', clientIdRef])

  const {
    data: client,
    isPending,
    error
  } = useQuery({
    queryKey: queryKey,
    queryFn: () => clientService.fetchClient(clientIdRef.value),
    select: (data) => new Client(data)
  })

  return { client, isPending, error, queryKey }
}

export function useCreateClient() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, mutateAsync, reset } = useMutation({
    mutationKey: ['create-client'],
    mutationFn: (client: IClientData) => clientService.createClient(client),

    onSuccess: (data) => {
      queryClient.setQueryData(['client', data.id], data)
      queryClient.invalidateQueries({ queryKey: ref(['clients']) })
      snackbarStore.showSnackbar('Client created successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    mutateAsync,
    reset
  }
}

export function useUpdateClient() {
  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: ['update-client'],
    mutationFn: (client: IClientData) => clientService.updateClient(client),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ref(['client', data.id]) })
      queryClient.invalidateQueries({ queryKey: ref(['clients']) })
      snackbarStore.showSnackbar('Client updated successfully')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

export function useDeleteClient() {
  const mutationKey = ref(['delete-client'])

  const snackbarStore = useSnackbarStore()
  const queryClient = useQueryClient()

  const { isPending, isError, error, isSuccess, mutate, reset } = useMutation({
    mutationKey: mutationKey.value,
    mutationFn: (id: number) => clientService.deleteClient(id),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ref(['clients']) })
      snackbarStore.showSnackbar('Client deleted successfully', '')
    }
  })
  return {
    isPending,
    isError,
    error,
    isSuccess,
    mutate,
    reset
  }
}

import type { AxiosInstance } from 'axios'
import axios from '@/axios'

import type { IVehicleLogService } from './vehicle-log.types'
import type { IVehicleLogAttachmentData, IVehicleLogData } from '@/models/fleet-management'

/**
 * A class representing a VehicleService API handler.
 */
export class VehicleLogService implements IVehicleLogService {
  private endpoint: string

  // VehicleService constructor
  constructor(private axios: AxiosInstance) {
    this.endpoint = 'v1/fleet/vehicle-logs/'
  }

  // LOGS
  fetchVehicleLogs(): Promise<IVehicleLogData[]> {
    return this.axios.get(`${this.endpoint}`).then(({ data }) => data)
  }

  fetchVehicleLog(id: number): Promise<IVehicleLogData> {
    return this.axios.get(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  createVehicleLog(data: IVehicleLogData): Promise<IVehicleLogData> {
    return this.axios.post(`${this.endpoint}`, data).then(({ data }) => data)
  }

  updateVehicleLog(data: IVehicleLogData): Promise<IVehicleLogData> {
    return this.axios.patch(`${this.endpoint}${data.id}/`, data).then(({ data }) => data)
  }

  deleteVehicleLog(id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${id}/`).then(({ data }) => data)
  }

  // ATTACHMENTS
  fetchVehicleLogAttachments(logId: number): Promise<IVehicleLogAttachmentData[]> {
    return this.axios.get(`${this.endpoint}${logId}/attachments/`).then(({ data }) => data)
  }
  fetchVehicleLogAttachment(logId: number, id: number): Promise<IVehicleLogAttachmentData> {
    return this.axios.get(`${this.endpoint}${logId}/attachments/${id}/`).then(({ data }) => data)
  }

  createVehicleLogAttachment(
    logId: number,
    data: IVehicleLogAttachmentData
  ): Promise<IVehicleLogAttachmentData> {
    return this.axios.post(`${this.endpoint}${logId}/attachments/`, data).then(({ data }) => data)
  }

  updateVehicleLogAttachment(
    logId: number,
    data: IVehicleLogAttachmentData
  ): Promise<IVehicleLogAttachmentData> {
    return this.axios
      .patch(`${this.endpoint}${logId}/attachments/${data.id}/`, data)
      .then(({ data }) => data)
  }

  deleteVehicleLogAttachment(logId: number, id: number): Promise<void> {
    return this.axios.delete(`${this.endpoint}${logId}/attachments/${id}/`).then(({ data }) => data)
  }
}

// Default export instantiation of the VehicleService
const vehicleService = new VehicleLogService(axios)
export default vehicleService

<template>
  <v-dialog v-model="dialog" persistent :width="$vuetify.display.smAndDown ? 400 : 677">
    <AlFormCard title="Camera Detail" :subtitle="subtitle">
      <template #error>
        <ErrorAlert
          v-if="error != null"
          :error="error"
          @clearErrors="clearErrors()"
          class="mb-0 mb-4 rounded"
        />
      </template>

      <template #appendAction>
        <v-btn
          variant="tonal"
          class="rounded"
          size="32"
          @click="closeDialog()"
          icon
          color="default"
          density="comfortable"
        >
          <v-icon size="18" icon="mdi-close" />
        </v-btn>
      </template>

      <v-form ref="form" v-model="valid" validate-on="submit lazy">
        <v-row class="mb-2">
          <v-col cols="6">
            <v-text-field
              v-model="cameraData.name"
              label="Name*"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['name'] as string[])"
            />
          </v-col>

          <v-col cols="6">
            <SiteAutoCompletePicker
              :client="clientId!"
              v-model="cameraData.site"
              label="Site"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :rules="[requiredValidator]"
              :error-messages="error && (error.fieldErrors['site'] as string[])"
              disabled
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="cameraData.notes"
              label="Notes"
              variant="outlined"
              hide-details="auto"
              density="compact"
              @update:model-value="clearErrors()"
              :error-messages="error && (error.fieldErrors['notes'] as string[])"
            />
          </v-col>
        </v-row>
      </v-form>

      <template #actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="flat" :loading="loading" @click="save()">Save</v-btn>
      </template>
    </AlFormCard>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'

import type { ISystemError } from '@/models/error'
import { useCameraStore } from '@/stores/camera'
import { useSnackbarStore } from '@/stores/snackbar'

import AlFormCard from '../common/AlFormCard.vue'
import ErrorAlert from '../common/ErrorAlert.vue'
import SiteAutoCompletePicker from '../sites/SiteAutoCompletePicker.vue'

import { Camera, type ICamera, type ICameraData } from '@/models/camera'
import type { VForm } from 'vuetify/components'

import { diffDeep } from '@/utils/helpers'
import { requiredValidator } from '@/utils/validators'

interface Props {
  camera?: ICameraData
  isEdit: boolean
  siteCode: number
  clientId: number
}

const props = defineProps<Props>()

const cameraData = ref<ICameraData>({})

const dialog = defineModel<boolean>('dialog', { required: true, default: false })

watch(dialog, (value) => {
  if (value) {
    clearErrors()
    cameraData.value = !props.isEdit ? { site: props.siteCode } : cloneDeep(props.camera!)
  }
})

const form = ref<VForm>()
const loading = ref(false)
const valid = ref(false)

const subtitle = computed(() => `${props.isEdit ? 'Update' : 'Create'} camera information`)

const error = ref<ISystemError | null>(null)

function clearErrors() {
  error.value = null
}

const cameraStore = useCameraStore()
const snackbarStore = useSnackbarStore()

function closeDialog() {
  clearErrors()
  dialog.value = false
}

async function save() {
  clearErrors()
  const { valid } = await form.value!.validate()

  if (valid) {
    let cameraInstance: ICamera | null = null

    try {
      cameraInstance = new Camera(cameraData.value)
    } catch (e: any) {
      error.value = e
    }

    if (cameraInstance != null) {
      loading.value = true

      const saveAction = props.isEdit ? cameraStore.updateCamera : cameraStore.createCamera

      let payload = cameraData.value
      if (props.isEdit) {
        payload = diffDeep(cameraData.value, props.camera, true)
      }

      saveAction(payload)
        .then(() => {
          const actionText = props.isEdit ? 'updated' : 'created'
          snackbarStore.showSnackbar(`Camera ${actionText} successfully`)
          closeDialog()
        })
        .catch((e) => {
          error.value = e
        })
        .finally(() => {
          loading.value = false
        })
    }
  }
}
</script>

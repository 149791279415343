<template>
  <BasePage v-bind="$attrs">
    <template #page-header:subtitle>
      <BackButton class="mr-4" ref="backButton" :fallback-to="{ name: 'incident' }" />
      {{ title }}
    </template>

    <template #page-header:bottom>
      <ErrorAlert
        v-if="error != null"
        dense
        :error="error"
        @clearErrors="clearErrors"
        class="mb-0 mt-4 rounded"
      />
    </template>

    <v-progress-linear color="primary" indeterminate v-if="isPending || isLoading" />

    <template v-else-if="report">
      <ReportStateCard
        v-if="report.state && reportVisualState"
        :color="reportVisualState.color"
        :display="report.state.display!"
        :icon="reportVisualState.icon!"
      >
        <template #actions>
          <IncidentReportDraftStateActions
            :can-submit="report.canSubmit()"
            v-if="reportVisualState.state == ReportStateEnum.Draft"
            :loading="
              isPending || isSubmitIncidentReportPending || isDownloadIncidentReportPdfPending
            "
            @submitted="submit()"
            @download-report-pressed="downloadReportPdf()"
            @edit-report-pressed="openEditReportDialog()"
            @delete-report-pressed="openDeleteReportDialog()"
            @view-report-logs-pressed="openReportAuditLogsDialog()"
          />
          <IncidentReportSubmittedStateActions
            :can-approve="report.canApprove()"
            v-if="reportVisualState.state == ReportStateEnum.Submitted"
            :loading="
              isPending || isApproveIncidentReportPending || isDownloadIncidentReportPdfPending
            "
            @approved="approve()"
            @download-report-pressed="downloadReportPdf()"
            @edit-report-pressed="openEditReportDialog()"
            @delete-report-pressed="openDeleteReportDialog()"
            @view-report-logs-pressed="openReportAuditLogsDialog()"
          />
          <IncidentReportApprovedStateActions
            v-if="reportVisualState.state == ReportStateEnum.Approved"
            :loading="isPending || isDownloadIncidentReportPdfPending"
            @download-report-pressed="downloadReportPdf()"
            @view-report-logs-pressed="openReportAuditLogsDialog()"
          />
          <IncidentReportAuditLogsDialog :report-id="report.id" v-model:dialog="auditLogsDialog" />
        </template>
      </ReportStateCard>

      <ReportDetailView class="mt-5" report-title="Incident Report" :report="report">
        <template #append-report-info>
          <tr class="report-table__row">
            <td class="report-table__cell pr-3" colspan="1">
              <strong class="wv-text--strong">Created at:</strong>
            </td>
            <td class="report-table__cell-info" colspan="1">
              <span class="wv-text wv-text--body"
                >{{ format(report.created!, 'MMMM dd, yyyy - HH:mm') }}
              </span>
            </td>
          </tr>

          <tr class="report-table__row">
            <td class="report-table__cell pr-3" colspan="1">
              <strong class="wv-text--strong">Incident:</strong>
            </td>
            <td class="report-table__cell-info" colspan="1">
              <span class="wv-text wv-text--body">
                <v-btn
                  :color="getReportIncidentStatusVisualByState(report.incident_level)?.color"
                  density="comfortable"
                  class="text-white text-none"
                  block
                  variant="flat"
                  :loading="isPending"
                  ><div class="font-weight-medium pr-1" style="font-size: large">
                    {{ getReportIncidentStatusVisualByState(report.incident_level)?.display }}
                  </div>
                  <v-icon left class="hidden-sm-and-down mr-n1">mdi-chevron-down </v-icon>
                </v-btn>
                <v-menu
                  activator="parent"
                  v-if="currentUser?.hasPermission('incident_reports.change_incidentreport')"
                >
                  <v-list density="compact" class="py-1">
                    <template v-for="(status, i) in incidentItems">
                      <v-list-item
                        :class="{}"
                        :style="`border-left: solid ${status.color} 8px;`"
                        :key="i"
                        v-if="status.state != report.incident_level"
                        @click="updateReportIncidentLevel(status.state)"
                      >
                        <v-list-item-title>{{ status.display }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </span>
            </td>
          </tr>
          <tr class="report-table__row">
            <td class="report-table__cell pr-3" colspan="1">
              <strong class="wv-text--strong">Guard:</strong>
            </td>
            <td class="report-table__cell-info" colspan="1">
              <span class="wv-text wv-text--body">{{ report.owner }} </span>
            </td>
          </tr>
        </template>
        <template #content>
          <IncidentReportDetailActivityCard :report="report" />
        </template>
      </ReportDetailView>
    </template>

    <IncidentReportDetailFormDialog
      v-model:dialog="incidentEditDialog"
      :incident-report="report"
      :is-edit="true"
      @saved-report="updateReportCache()"
    />

    <ConfirmationDialog
      v-model="incidentDeleteDialog"
      v-model:error="deleteError"
      title="Delete CCTV Report"
    >
      <template v-slot:message>
        Are you sure you want to delete CCTV Report
        <span class="text-medium-high-emphasis font-weight-bold">#{{ report!.report_id }}</span
        >?
        <br />
        <div class="pt-2">This action cannot be undone.</div>
      </template>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn
          color="error"
          variant="flat"
          class="text-capitalize"
          :loading="deleteInProgress"
          @click.stop="removeIncidentReport(report!)"
        >
          Delete
        </v-btn>
      </template>
    </ConfirmationDialog>
  </BasePage>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import {
  useDeleteIncidentReport,
  useFetchIncidentReport,
  useUpdateIncidentReport
} from '@/composables/incident-report'
import { useAuthStore, useReportStore } from '@/stores'
import { useMutation, useQueryClient } from '@tanstack/vue-query'

import incidentReportService from '@/services/incident_report/incident-report'

import {
  ReportStateEnum,
  type IIncidentReport,
  type IIncidentReportData,
  ReportActivityStatusEnum
} from '@/models/report'
import type { ISystemError } from '@/models/error'
import { createPdf } from '@/utils/helpers'
import { format } from 'date-fns'

import BasePage from '@/components/base/BasePage.vue'
import BackButton from '@/components/common/BackButton.vue'
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue'
import ErrorAlert from '@/components/common/ErrorAlert.vue'
import IncidentReportApprovedStateActions from '@/components/reports/incident/IncidentReportApprovedStateActions.vue'
import IncidentReportDraftStateActions from '@/components/reports/incident/IncidentReportDraftStateActions.vue'
import IncidentReportSubmittedStateActions from '@/components/reports/incident/IncidentReportSubmittedStateActions.vue'
import IncidentReportDetailFormDialog from '@/components/reports/incident/IncidentReportDetailFormDialog.vue'
import ReportDetailView from '@/components/reports/common/ReportDetailView.vue'
import ReportStateCard from '@/components/reports/common/ReportStateCard.vue'
import IncidentReportDetailActivityCard from '@/components/reports/incident/IncidentReportDetailActivityCard.vue'
import IncidentReportAuditLogsDialog from '@/components/reports/incident/IncidentReportAuditLogsDialog.vue'

interface Props {
  title?: string
  reportId: number
}
const props = defineProps<Props>()
// CORE
const queryClient = useQueryClient()
const router = useRouter()

// Managing multiple errors into one property
const error = ref<ISystemError | null>(null)
const incidentDeleteDialog = ref(false)
const incidentEditDialog = ref(false)

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const reportStore = useReportStore()
const { incidentStatusVisuals, getReportIncidentStatusVisualByState, getReportVisualByState } =
  storeToRefs(reportStore)

const incidentItems = computed(() =>
  incidentStatusVisuals.value.filter(
    (incident) => incident.state > ReportActivityStatusEnum.SecurityLevel1
  )
)

const reportVisualState = computed(
  () => report.value && getReportVisualByState.value(report.value.state!.value!)
)

const {
  report,
  isPending,
  isLoading,
  error: fetchError,
  queryKey
} = useFetchIncidentReport(props.reportId)
// update error to crud action error display
watch(fetchError, (value) => {
  error.value = value
})

function openDeleteReportDialog() {
  incidentDeleteDialog.value = true
}
function openEditReportDialog() {
  incidentEditDialog.value = true
}

const { isPending: isSubmitIncidentReportPending, mutate: submitIncidentReport } = useMutation({
  mutationKey: ['submit-incident-report'],
  mutationFn: (reportId: number) => incidentReportService.submitIncidentReport(reportId)
})
// STATE TRANSITIONS
function submit() {
  clearErrors()
  submitIncidentReport(report.value!.id!, {
    onSuccess: () => {
      updateReportCache()
    },
    onError(submitError) {
      error.value = submitError
    }
  })
}

const { isPending: isApproveIncidentReportPending, mutate: approveIncidentReport } = useMutation({
  mutationKey: ['approve-incident-report'],
  mutationFn: (reportId: number) => incidentReportService.approveIncidentReport(reportId)
})
function approve() {
  clearErrors()
  approveIncidentReport(report.value!.id!, {
    onSuccess: () => {
      updateReportCache()
    },
    onError(approveError) {
      error.value = approveError
    }
  })
}

const { isPending: isDownloadIncidentReportPdfPending, mutate: downloadIncidentReportPDF } =
  useMutation({
    mutationKey: ['approve-incident-report'],
    mutationFn: (reportId: number) => incidentReportService.exportIncidentReportPdf(reportId)
  })

function downloadReportPdf() {
  clearErrors()
  downloadIncidentReportPDF(report.value!.id!, {
    onSuccess: (data) => {
      updateReportCache()
      createPdf(data, report.value!.report_id!)
    },
    onError(downloadError) {
      error.value = downloadError
    }
  })
}

function clearErrors() {
  error.value = null
}

const backButton = ref<typeof BackButton>()

const { deleteInProgress, deleteError, deleteIncidentReport } = useDeleteIncidentReport()

function updateReportCache() {
  // TODO - LH - 2024-02-20 - Should find a better way to invalidate both queries
  // invalidate current incident report cache to update this cache
  queryClient.invalidateQueries({ queryKey: queryKey.value })
  // invalidate list cache regarding incident
  queryClient.invalidateQueries({ queryKey: ref(['incident-reports']) })
}

function removeIncidentReport(report: IIncidentReport) {
  clearErrors()
  deleteIncidentReport(report.id!, {
    onSuccess: () => {
      incidentDeleteDialog.value = false
      queryClient.invalidateQueries({ queryKey: ref(['incident-reports']) })
      router.push(backButton.value?.backPath)
    }
  })
}

// Update state
const { error: updateError, mutate } = useUpdateIncidentReport()
// update error to crud action error display
watch(updateError, (value) => {
  error.value = value
})

function updateReportIncidentLevel(incidentLevel: number) {
  const payload = {
    id: report.value!.id,
    incident_level: incidentLevel
  } as IIncidentReportData

  mutate(payload, {
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ref(['incident-report', report.value!.id]) })
  })
}

// audit logs
const auditLogsDialog = ref(false)

function openReportAuditLogsDialog() {
  auditLogsDialog.value = true
}
</script>

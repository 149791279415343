<template>
  <v-hover v-slot="{ isHovering }">
    <v-card
      :variant="variant"
      :elevation="isHovering && isClickable ? 4 : 0"
      class="rounded-lg"
      v-bind="$attrs"
    >
      <slot name="top"></slot>

      <v-card-text class="text--primary" :class="dashboardContentClasses">
        <slot name="default" />
      </v-card-text>

      <slot name="bottom"></slot>
    </v-card>
  </v-hover>
</template>

<script lang="ts" setup>
import { computed, useAttrs, type PropType } from 'vue'

const props = defineProps({
  noPadding: {
    type: Boolean,
    default: () => false
  },
  variant: {
    type: String as PropType<'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'>,
    default: () => 'outlined'
  }
})

const attrs = useAttrs()

const dashboardContentClasses = computed<string>(() => {
  if (props.noPadding) return 'pa-0'
  return 'pa-4'
})

const isLink = computed<boolean>(() => {
  return !!attrs['to'] || !!attrs['href'] || !!attrs['link']
})

const isClickable = computed<boolean>(() => {
  if (attrs['disabled']) return false

  return Boolean(isLink.value || attrs['onClick'] || attrs['tabindex'])
})
</script>

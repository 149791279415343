<template>
  <v-form ref="sitesForm" v-model="isSitesFormValid" validate-on="submit lazy">
    <div class="position-relative d-flex flex-column flex-grow-1 mt-2">
      <div class="d-flex flex-row overflow-x-auto">
        <!-- section 1 -->
        <v-sheet class="flex-grow-1 pr-6 py-6" min-width="350">
          <v-responsive class="mr-auto" style="margin-block-start: -3px" max-width="350">
            <v-label for="reportClientId" text="Client" class="text-body-2 text-high-emphasis" />
            <ClientAutoCompletePicker
              width="200"
              class="mt-2"
              label=""
              id="reportClientId"
              variant="outlined"
              hide-details="auto"
              :readonly="!!selectedClient"
              clearable
              density="compact"
              v-model="selectedClient"
              v-model:search="clientQueryFilters.search"
              :error-messages="clientsError && clientsError.message"
              return-object
            />
            <div class="h-100 overflow-y-auto mt-6">
              <v-card
                :loading="isClientsLoading"
                :disabled="isClientsLoading"
                variant="flat"
                min-height="300"
                class="d-flex flex-row flex-grow-1 overflow-y-auto"
              >
                <div class="d-flex flex-row flex-grow-1 overflow-y-auto">
                  <div style="flex: 1" class="d-flex flex-row flex-grow-1">
                    <div class="d-flex flex-column flex-grow-1">
                      <div class="d-flex flex-row align-center">
                        <v-label
                          for="report-site"
                          text="Sites"
                          class="text-body-2 text-high-emphasis"
                        />

                        <v-spacer />

                        <v-tooltip location="right" text="Select all sites">
                          <template #activator="{ props }">
                            <button
                              v-bind="props"
                              class="v-btn v-btn--size-small text-none text-decoration-underline px-0"
                              :class="{
                                'v-btn--disabled': displayedSites.length == 0
                              }"
                              @click.prevent.stop="addAllSites(displayedSites)"
                            >
                              Select all sites
                            </button>
                          </template>
                        </v-tooltip>
                      </div>

                      <v-list
                        max-height="300"
                        density="compact"
                        nav
                        class="px-0 pr-1 py-0 mt-3"
                        :items="displayedSites"
                        :item-title="(site: ISite) => site.toString()"
                        :item-props="
                          () => ({
                            class: 'bg-background px-3'
                          })
                        "
                      >
                        <template #title="{ title }">
                          <span class="text-medium-emphasis font-weight-regular">{{ title }}</span>
                        </template>

                        <template #append="{ item }">
                          <AlTooltipButton
                            location="left"
                            text="Select site"
                            icon="mdi-plus-circle"
                            color="success"
                            size="small"
                            icon-size="large"
                            variant="text"
                            class="rounded-circle"
                            @click.stop="select(item, true)"
                          />
                        </template>
                      </v-list>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </v-responsive>
        </v-sheet>
        <!-- section 1 -->

        <!-- section 2 -->
        <v-card
          color="background"
          class="d-flex flex-grow-1 flex-column pa-6 rounded"
          max-height="470"
          width="450"
          min-width="350"
          variant="flat"
        >
          <v-label class="text-body-2 text-high-emphasis">
            {{ employee?.first_name }} can view the following sites:
          </v-label>
          <v-card-text class="overflow-y-auto pa-0 pr-5 mr-n4">
            <v-fade-transition mode="out-in">
              <NoDataAvailablePlaceholder
                v-if="currentGroupedSelectedSiteAssignments.length == 0"
                class="mt-12 mb-0"
                width="205"
                header="No sites assigned"
                header-class="font-weight-medium text-h5"
              >
                <template #description>
                  <p class="mt-0 text-caption text-medium-emphasis">
                    <span> No sites are assigned to this employee. </span>
                  </p>
                </template>
              </NoDataAvailablePlaceholder>
              <template v-else>
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row mt-5 mb-n6">
                    <v-spacer></v-spacer>
                    <div class="mr-11 text-caption text-high-emphasis">
                      &nbsp;
                      <span v-if="currentUser?.hasPermission('site_management.set_site_approver')"
                        >Approver
                      </span>
                    </div>
                  </div>
                  <v-list
                    density="compact"
                    :selectable="false"
                    :activatable="false"
                    nav
                    class="mt-n6 px-0 bg-transparent"
                    :items="currentGroupedSelectedSiteAssignments"
                    :item-title="(site: ISite) => site.toString()"
                    :item-props="
                      () => ({
                        link: false,
                        bgColor: 'white',
                        class: 'bg-white px-3 ml-2'
                      })
                    "
                  >
                    <template #subheader="{ props: subheaderProps }">
                      <v-list-subheader
                        class="text-medium-emphasis text-uppercase font-weight-bold mt-4"
                        style="padding-inline-start: 0px !important"
                      >
                        {{ subheaderProps.title }}
                      </v-list-subheader>
                    </template>
                    <template #title="{ title }">
                      <span class="text-medium-emphasis font-weight-regular">{{ title }}</span>
                    </template>
                    <template #append="{ item }">
                      <v-checkbox-btn
                        v-if="currentUser?.hasPermission('site_management.set_site_approver')"
                        class="mr-4"
                        density="compact"
                        color="medium-emphasis"
                        :ripple="false"
                        :model-value="(item as ISiteAssignment).is_site_approver"
                        @click="toggleSiteApprover(item as ISiteAssignment)"
                      />

                      <AlTooltipButton
                        text="Remove site"
                        icon="mdi-minus-circle"
                        color="error"
                        size="small"
                        icon-size="large"
                        variant="text"
                        class="rounded-circle"
                        @click.stop="select((item as ISiteAssignment).site, false)"
                      />
                    </template>
                  </v-list>
                </div>
              </template>
            </v-fade-transition>
          </v-card-text>
        </v-card>
        <!-- section 2 -->
      </div>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import { useFetchEmployee, useSetEmployeeSites } from '@/composables/employee'
import { computed, reactive, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

import type { VForm } from 'vuetify/components'
import { Client, type IClient, type ISite } from '@/models/client'
import type { ISystemError } from '@/models/error'
import { SiteAssignment, type ISiteAssignment } from '@/models/site-management'

import { useFetchClients } from '@/composables/client'
import { useAuthStore } from '@/stores'
import { useQueryClient } from '@tanstack/vue-query'
import { useSetSiteApprover } from '@/composables/site-management'

import ClientAutoCompletePicker from '../clients/ClientAutoCompletePicker.vue'
import NoDataAvailablePlaceholder from '../common/NoDataAvailablePlaceholder.vue'
import AlTooltipButton from '../common/AlTooltipButton.vue'

interface Props {
  employeeId: number
}
const props = defineProps<Props>()

interface Emits {
  (name: 'save'): void
}
const emit = defineEmits<Emits>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)

const employeeId = computed(() => props.employeeId)

const isSitesFormValid = ref(false)

const sitesForm = ref<VForm>()

const clientQueryFilters = reactive({
  search: ''
})

const { clients, error: clientsError, isLoading: isClientsLoading } = useFetchClients()

const selectedClient = ref<IClient | null>(null)

const { employee } = useFetchEmployee(employeeId)

function addAllSites(sites: ISite[]) {
  sites.forEach((site) => {
    select(site, true)
  })
}

const currentSelectedSiteAssignments = ref<ISiteAssignment[]>([])

watch(
  employee,
  (value) => {
    currentSelectedSiteAssignments.value = value ? [...value.profile.site_assignments] : []
  },
  { immediate: true }
)

function toggleSiteApprover(assignment: ISiteAssignment) {
  const index = currentSelectedSiteAssignments.value.findIndex(
    (v) => v.site.code === assignment.site.code
  )

  if (index !== -1) {
    currentSelectedSiteAssignments.value[index] = new SiteAssignment({
      ...currentSelectedSiteAssignments.value[index],
      is_site_approver: !assignment.is_site_approver
    })
  }
}

// On load of clients pre-select first client if current client not selected
watch(
  clients,
  (value) => {
    if (!selectedClient.value && value) {
      selectedClient.value = value[0]
    }
  },
  { immediate: true }
)

const displayedSites = computed(() => {
  return (selectedClient.value?.sites ?? []).filter((site) => {
    return !currentSelectedSiteAssignments.value.some((assignment) => {
      return assignment.site.code === site.code
    })
  })
})

/** @param add - false means to remove */
function select(site: ISite, add = true) {
  const index = currentSelectedSiteAssignments.value.findIndex(
    (currentSite) => currentSite.site.code == site.code
  )

  if (add && index === -1) {
    currentSelectedSiteAssignments.value.push(new SiteAssignment({ site, is_site_approver: false }))
  } else if (!add && index !== -1) {
    currentSelectedSiteAssignments.value.splice(index, 1)
    setSelectedClientFromSite(site)
  }
}

function setSelectedClientFromSite(site: ISite) {
  // if removing then set the currently selected client to that of the removed site
  const client = clients.value?.find(
    (client) => !!client.sites.find((clientSite) => clientSite.code == site.code)
  )

  if (client) {
    selectedClient.value = client
  }
}

function mapSitesCodesFromAssignments(assignments: ISiteAssignment[]): number[] {
  return assignments.map((assignment) => assignment.site.code)
}

const currentGroupedSelectedSiteAssignments = computed(() => {
  const list: (
    | ISiteAssignment
    | {
        type?: 'subheader'
        toString: () => string
      }
  )[] = []

  const otherClient = new Client({ name: 'Other' })

  const clientSiteAssignmentMap = new Map<IClient, ISiteAssignment[]>()
  for (let index = 0; index < currentSelectedSiteAssignments.value.length; index++) {
    const siteAssignment = currentSelectedSiteAssignments.value[index]

    const siteClient = clients.value?.find((client) =>
      client.sites.find((site) => site.code === siteAssignment.site.code)
    )

    const client = siteClient ? siteClient : otherClient

    if (clientSiteAssignmentMap.has(client)) {
      const localSiteAssignments = clientSiteAssignmentMap.get(client)
      localSiteAssignments!.push(siteAssignment)
      clientSiteAssignmentMap.set(client, localSiteAssignments!.sort())
    } else {
      clientSiteAssignmentMap.set(client, [siteAssignment])
    }
  }

  new Map([...clientSiteAssignmentMap.entries()].sort()).forEach((assignments, client) => {
    list.push({
      type: 'subheader',
      toString: () => client.toString()
    })
    // add list of overlapping sites
    list.push(...assignments)
  })

  return list
})

const { isPending, error, mutate, reset } = useSetEmployeeSites(employeeId)

function clearErrors() {
  siteApproverError.value = null
  reset()
}

async function validate(): Promise<boolean> {
  const { valid } = await sitesForm.value!.validate()
  return valid
}

/** Save employee with list of selected sites */
async function saveEmployeeSites() {
  clearErrors()

  const valid = await validate()

  if (valid) {
    // store locally in function scope to not update after site assignment update
    const payload = [...currentSelectedSiteAssignments.value]
    const sitesPayload = mapSitesCodesFromAssignments(payload)

    mutate(sitesPayload, {
      onSuccess: () => {
        saveSiteApproversFromAssignmentsList(payload)
      }
    })
  }
}

const { mutateAsync: _setSiteApprover } = useSetSiteApprover()

const queryClient = useQueryClient()

const siteApproverError = ref<ISystemError | null>(null)

async function saveSiteApproversFromAssignmentsList(assignments: ISiteAssignment[]) {
  const difference = assignments.filter(
    compareSiteApprover(employee.value!.profile.site_assignments)
  )

  const siteApproverPromises = difference.map((assignment) =>
    _setSiteApprover({
      code: assignment.site.code,
      is_site_approver: assignment.is_site_approver,
      employeeId: props.employeeId
    })
  )

  await Promise.all(siteApproverPromises)
    .then(async () => {
      await queryClient.invalidateQueries({ queryKey: ref(['employee', employeeId]) })
      emit('save')
      clearErrors()
    })
    .catch((e: any) => {
      siteApproverError.value = e
    })
}

/**
 * Compare the original list of sites with each iteration of another SiteAssignment and check that the code and approve flag as the same
 * @param originalAssignments
 */
function compareSiteApprover(originalAssignments: ISiteAssignment[]) {
  return function (currentAssignment: ISiteAssignment) {
    return (
      originalAssignments.filter(function (original: ISiteAssignment) {
        return (
          original.site.code == currentAssignment.site.code &&
          original.is_site_approver == currentAssignment.is_site_approver
        )
      }).length == 0
    )
  }
}

defineExpose({
  saveEmployeeSites,
  validate,
  error: computed(() => error.value || siteApproverError.value),
  isPending: isPending,
  clearErrors
})
</script>

<style lang="scss">
.al-form-card {
  padding: 1rem;
}
</style>

<template>
  <Component :is="resolveNavItemComponent(smAndUp)" :items="routes" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useDisplay } from 'vuetify'

import HorizontalNavBar from './HorizontalNavBar.vue'
import VerticalNavBar from './VerticalNavBar.vue'

import { useAuthStore } from '@/stores'
import { generateNavRoutes, generateDynamicRoutes } from '@/router/utils'
import { generateNavItem } from '@/router/utils'
import { asyncRoutes, constantRoutes } from '@/router/routes'
import { useRouter } from 'vue-router'

const router = useRouter()
const authStore = useAuthStore()
const dynamicRoutes = generateDynamicRoutes(authStore.user!.permissions!, asyncRoutes)
const navRoutes = generateNavRoutes(constantRoutes.concat(dynamicRoutes))

const routes = computed(() =>
  navRoutes.map((route) => {
    return generateNavItem(router, route)
  })
)

const { smAndUp } = useDisplay()

const resolveNavItemComponent = (smAndUp: boolean) => {
  if (smAndUp) return HorizontalNavBar
  return VerticalNavBar
}
</script>

/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify/vuetify'
import pinia from './pinia'
import router from '../router'
import { vPhoneInput } from './phoneInput'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { vueQueryPluginOptions } from './vue-query'

// Types
import type { App } from 'vue'

export function registerPlugins(app: App) {
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(vPhoneInput)
    .use(VueQueryPlugin, vueQueryPluginOptions)
}

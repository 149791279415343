<template>
  <div>
    <v-btn
      v-if="currentUser?.hasPermission('incident_reports.download_incidentreport')"
      class="mr-3 text-capitalize"
      color="primary"
      flat
      :loading="loading"
      @click="emit('download-report-pressed')"
      >Download PDF</v-btn
    >

    <MoreOptionsMenuButton>
      <template #button="props">
        <v-btn
          v-bind="props"
          :loading="loading"
          :disabled="loading"
          class="text-capitalize"
          append-icon="mdi-menu-down"
          variant="outlined"
        >
          More Options
        </v-btn>
      </template>

      <v-list-item title="View Audit Logs" @click="emit('view-report-logs-pressed')"> </v-list-item>
    </MoreOptionsMenuButton>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores'
import MoreOptionsMenuButton from '@/components/common/MoreOptionsMenuButton.vue'

interface Props {
  loading?: boolean
}
defineProps<Props>()

interface Emit {
  (e: 'download-report-pressed'): void
  (e: 'view-report-logs-pressed'): void
}
const emit = defineEmits<Emit>()

const authStore = useAuthStore()
const { getUser: currentUser } = storeToRefs(authStore)
</script>

/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'

// Composables
import { createVuetify } from 'vuetify'

import DateFnsAdapter from '@date-io/date-fns'
import { darkTheme, lightTheme } from './theme'

import { VChip } from 'vuetify/components/VChip'

const dateFnsAdapter = new DateFnsAdapter()

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  defaults: {
    VBtn: {
      class: 'rounded text-capitalize'
    },
    StatusChip: {
      small: true,
      style: 'width: 150px',
      class: 'align-center justify-center',
      label: true,
      variant: 'flat'
    }
  },
  aliases: {
    StatusChip: VChip
  },
  theme: {
    defaultTheme: 'light',
    variations: {
      colors: ['primary', 'secondary', 'surface'],
      lighten: 3,
      darken: 3
    },
    themes: {
      light: lightTheme,
      dark: darkTheme
    }
  },
  date: {
    adapter: dateFnsAdapter
  }
})
